import { handleDeltaReducer, createDeltaReducer } from "shared/reduxUtils"
import * as ListingActions from "./actions"

const initState = {
  query: {
    sortByName: null,
    sortByListingPrice: null,
    searchTextInListing: null,
    dealType: null,
    transactionType: null,
    fromListing: null,
    toListing: null,
    viewType: null,
    daysOnMarket: null,
    skip: "0",
    limit: 4,
    listingState: null,
    teamMemberIds: null,
  },
  isMultipleEnvelopeShare:false,
  isListingDrawerOpen: false,
  isDeleteModalOpen: {
    bool: false,
    id: "",
  },
  isMLSModalOpen: false,
  isStatusChangeModalOpenListing: {
    bool: false,
    statusChangedTo: "",
    listingID: null,
  },
  isListingCreationModalOpen: false,
  listingData: {
    dealId: null,
    editingAgentId: null,
    transactionId: null,
    listingStatus: "incomplete",
    propertyData: null,
    agents: null,
    sellers: null,
    listingTerms: null,
    propertyDataErrorCount: null,
    agentsDataErrorCount: null,
    sellersDataErrorCount: null,
    listingTermsErrorCount: null,
    isMiniListingDetailsViewEnabled: false,
    contractId: null,
    coversheetDisabled: false,
  },
  viewListingSendUI: false,
  isListingToContractModalOpen: false,
  isViewListingContractModalOpen: false,
  isViewComplianceForListing: false,
  currentListingTab: "cover_sheet",
  currentContractsTab: "contract_details",
  isOnMarketModalOn: false,
  currentState: "california",
  isCardViewToggled: false,
  isListingCardFromCRM: false,
  optionalMLSID: null,
  marketData: {
    mlsId: null,
    uniqueId: null,
  },
  checklistTemplate: null,
  envelopeListings: {}, 
  mergedEnvelopes: [],
  checklistObject: [],
  offersDocListView: [],
  listingDocsListView: [],
  uploadedDocsList: {
    sharedWithMe: [],
    teamDocs: [],
  },
  currentEnvelopeId:null,
  isCreateEnvelopeOpen: false,
  listingIdToView: null,
  completeListingPackageToBeSent: {},
  listingTabs: [
    {
      value: "cover_sheet",
      label: "Create Listing",
      isDisabled: false,
    },
    {
      value: "contract_details",
      label: "Contract Details",
      isDisabled: false,
    },
    {
      value: "compliance",
      label: "Paperwork",
      isDisabled: false,
    },
  ],
  isViewListingModalOpen: {
    bool: false,
  },
  isShowSignedDocs: false,
  isDeleteCoversheetModalOpen: false,
  isListingsDocumentProcessing: false,
  isShowAddDocument: false,
  envelopeIdForAddDocument: null,
  isLoadingViewListingPage: false,
  listingCollapseValues: ["property_information"],
  ...createDeltaReducer("getListingsResponse"),
  ...createDeltaReducer("getListingMetricsResponse"),
  ...createDeltaReducer("getPaginationCountResponse"),
  ...createDeltaReducer("deleteListingResponse"),
  ...createDeltaReducer("initiateListingResponse"),
  ...createDeltaReducer("getListingMappingsResponse"),
  ...createDeltaReducer("updatePropertyInformationResponse"),
  ...createDeltaReducer("validateListingFormResponse"),
  ...createDeltaReducer("getBrokerageListingsStatusesResponse"),
  ...createDeltaReducer("updateBrokerageListingStatusResponse"),
  ...createDeltaReducer("updateListingAgentInformationResponse"),
  ...createDeltaReducer("updateListingAgentInformationPostMlsSelectionResponse"),
  ...createDeltaReducer("getListingAgentsDetailsResponse"),
  ...createDeltaReducer("getListingDataAgentsDataPostMLSResponse"),
  ...createDeltaReducer("performNecessaryActionsBeforeLoadingListingResponse"),
  ...createDeltaReducer("updatePropertyInformationPostMLSUpdateResponse"),
  ...createDeltaReducer("updateSellerInformationForListingResponse"),
  ...createDeltaReducer("updateListingTermsForListingResponse"),
  ...createDeltaReducer("validateAndSubmitResponse"),
  ...createDeltaReducer("fetchAgentInformationForCoverSheetResponse"),
  ...createDeltaReducer("moveToOnMarketResponse"),
  ...createDeltaReducer("fetchChecklistMetadataResponse"),
  ...createDeltaReducer("fetchChecklistTemplateResponse"),
  ...createDeltaReducer("fetchEnvelopeListingsResponse"),
  ...createDeltaReducer("fetchMergedEnvelopesResponse"),
  ...createDeltaReducer("createReceiverListingViewV2Response"),
  ...createDeltaReducer("fetchListingPackageDocumentsResponse"),
  ...createDeltaReducer("fetchOfferDocumentsListResponse"),
  ...createDeltaReducer("fetchListingDocumentsListResponse"),
  ...createDeltaReducer("fetchLibraryUploadedFilesListResponse"),
  ...createDeltaReducer("updateLibraryUploadsListResponse"),
  ...createDeltaReducer("deleteLibraryUploadedFileListResponse"),
  ...createDeltaReducer("updateEnvelopesResponse"),
  ...createDeltaReducer("createEnvelopeChecklistResponse"),
  ...createDeltaReducer("processDeletionOfFieldsInCoversheetResponse"),
  ...createDeltaReducer("sendListingPackageMailResponse"),
  ...createDeltaReducer("refreshListingsV2DocumentsResponse"),
  ...createDeltaReducer("updateChecklistTemplateMetadataResponse"),
  ...createDeltaReducer("sendOffersChecklistDocumentsResponse"),
  ...createDeltaReducer("sendReminderListingResponse"),
  ...createDeltaReducer("sendReminderOfferChecklistResponse"),
  ...createDeltaReducer("viewListingPersistenceResponse")
}

export default function listings(state = initState, action) {
  switch (action.type) {
    case ListingActions.getListingsAction.REQUEST:
    case ListingActions.getListingsAction.FAILED:
    case ListingActions.getListingsAction.FAILURE:
    case ListingActions.getListingsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.getListingsAction,
          "getListingsResponse"
        ),
      }
    }

    case ListingActions.getListingMetricsAction.REQUEST:
    case ListingActions.getListingMetricsAction.FAILED:
    case ListingActions.getListingMetricsAction.FAILURE:
    case ListingActions.getListingMetricsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.getListingMetricsAction,
          "getListingMetricsResponse"
        ),
      }
    }

    case ListingActions.getPaginationCountAction.REQUEST:
    case ListingActions.getPaginationCountAction.FAILED:
    case ListingActions.getPaginationCountAction.FAILURE:
    case ListingActions.getPaginationCountAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.getPaginationCountAction,
          "getPaginationCountResponse"
        ),
      }
    }

    case ListingActions.deleteListingAction.REQUEST:
    case ListingActions.deleteListingAction.FAILED:
    case ListingActions.deleteListingAction.FAILURE:
    case ListingActions.deleteListingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.deleteListingAction,
          "deleteListingResponse"
        ),
      }
    }

    case ListingActions.deleteListingEnvelopeAction.REQUEST:
    case ListingActions.deleteListingEnvelopeAction.FAILED:
    case ListingActions.deleteListingEnvelopeAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.deleteListingEnvelopeAction,
          "deleteListingEnvelopeResponse"
        ),
      }
    }

    case ListingActions.deleteOfferEnvelopeAction.REQUEST:
    case ListingActions.deleteOfferEnvelopeAction.FAILED:
    case ListingActions.deleteOfferEnvelopeAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.deleteOfferEnvelopeAction,
          "deleteOfferEnvelopeResponse"
        ),
      }
    }

    case ListingActions.initiateListingAction.REQUEST:
    case ListingActions.initiateListingAction.FAILED:
    case ListingActions.initiateListingAction.FAILURE:
    case ListingActions.initiateListingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.initiateListingAction,
          "initiateListingResponse"
        ),
      }
    }

    case ListingActions.getListingMappingsAction.REQUEST:
    case ListingActions.getListingMappingsAction.FAILED:
    case ListingActions.getListingMappingsAction.FAILURE:
    case ListingActions.getListingMappingsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.getListingMappingsAction,
          "getListingMappingsResponse"
        ),
      }
    }

    case ListingActions.updatePropertyInformationAction.REQUEST:
    case ListingActions.updatePropertyInformationAction.FAILED:
    case ListingActions.updatePropertyInformationAction.FAILURE:
    case ListingActions.updatePropertyInformationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updatePropertyInformationAction,
          "updatePropertyInformationResponse",
        ),
      }
    }

    case ListingActions.validateListingFormAction.REQUEST:
    case ListingActions.validateListingFormAction.FAILED:
    case ListingActions.validateListingFormAction.FAILURE:
    case ListingActions.validateListingFormAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.validateListingFormAction,
          "validateListingFormResponse",
        ),
      }
    }

    case ListingActions.getBrokerageListingsStatusesAction.REQUEST:
    case ListingActions.getBrokerageListingsStatusesAction.FAILED:
    case ListingActions.getBrokerageListingsStatusesAction.FAILURE:
    case ListingActions.getBrokerageListingsStatusesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.getBrokerageListingsStatusesAction,
          "getBrokerageListingsStatusesResponse",
        ),
      }
    }

    case ListingActions.updateBrokerageListingStatusAction.REQUEST:
    case ListingActions.updateBrokerageListingStatusAction.FAILED:
    case ListingActions.updateBrokerageListingStatusAction.FAILURE:
    case ListingActions.updateBrokerageListingStatusAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updateBrokerageListingStatusAction,
          "updateBrokerageListingStatusResponse",
        ),
      }
    }

    case ListingActions.updateListingAgentInformationAction.REQUEST:
    case ListingActions.updateListingAgentInformationAction.FAILED:
    case ListingActions.updateListingAgentInformationAction.FAILURE:
    case ListingActions.updateListingAgentInformationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updateListingAgentInformationAction,
          "updateListingAgentInformationResponse",
        ),
      }
    }

    case ListingActions.updateListingAgentInformationPostMlsSelectionAction.REQUEST:
    case ListingActions.updateListingAgentInformationPostMlsSelectionAction.FAILED:
    case ListingActions.updateListingAgentInformationPostMlsSelectionAction.FAILURE:
    case ListingActions.updateListingAgentInformationPostMlsSelectionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updateListingAgentInformationPostMlsSelectionAction,
          "updateListingAgentInformationPostMlsSelectionResponse",
        ),
      }
    }

    case ListingActions.getListingAgentsDetailsAction.REQUEST:
    case ListingActions.getListingAgentsDetailsAction.FAILED:
    case ListingActions.getListingAgentsDetailsAction.FAILURE:
    case ListingActions.getListingAgentsDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.getListingAgentsDetailsAction,
          "getListingAgentsDetailsResponse",
        ),
      }
    }

    case ListingActions.getListingDataAgentsDataPostMLSAction.REQUEST:
    case ListingActions.getListingDataAgentsDataPostMLSAction.FAILED:
    case ListingActions.getListingDataAgentsDataPostMLSAction.FAILURE:
    case ListingActions.getListingDataAgentsDataPostMLSAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.getListingDataAgentsDataPostMLSAction,
          "getListingDataAgentsDataPostMLSResponse",
        ),
      }
    }

    case ListingActions.performNecessaryActionsBeforeLoadingListingAction.REQUEST:
    case ListingActions.performNecessaryActionsBeforeLoadingListingAction.FAILED:
    case ListingActions.performNecessaryActionsBeforeLoadingListingAction.FAILURE:
    case ListingActions.performNecessaryActionsBeforeLoadingListingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.performNecessaryActionsBeforeLoadingListingAction,
          "performNecessaryActionsBeforeLoadingListingResponse",
        ),
      }
    }

    case ListingActions.updatePropertyInformationPostMLSUpdateAction.REQUEST:
    case ListingActions.updatePropertyInformationPostMLSUpdateAction.FAILED:
    case ListingActions.updatePropertyInformationPostMLSUpdateAction.FAILURE:
    case ListingActions.updatePropertyInformationPostMLSUpdateAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updatePropertyInformationPostMLSUpdateAction,
          "updatePropertyInformationPostMLSUpdateResponse",
        ),
      }
    }

    case ListingActions.updateSellerInformationForListingAction.REQUEST:
    case ListingActions.updateSellerInformationForListingAction.FAILED:
    case ListingActions.updateSellerInformationForListingAction.FAILURE:
    case ListingActions.updateSellerInformationForListingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updateSellerInformationForListingAction,
          "updateSellerInformationForListingResponse",
        ),
      }
    }

    case ListingActions.updateListingTermsForListingAction.REQUEST:
    case ListingActions.updateListingTermsForListingAction.FAILED:
    case ListingActions.updateListingTermsForListingAction.FAILURE:
    case ListingActions.updateListingTermsForListingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updateListingTermsForListingAction,
          "updateListingTermsForListingResponse",
        ),
      }
    }

    case ListingActions.validateAndSubmitAction.REQUEST:
    case ListingActions.validateAndSubmitAction.FAILED:
    case ListingActions.validateAndSubmitAction.FAILURE:
    case ListingActions.validateAndSubmitAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.validateAndSubmitAction,
          "validateAndSubmitResponse",
        ),
      }
    }

    case ListingActions.fetchAgentInformationForCoverSheetAction.REQUEST:
    case ListingActions.fetchAgentInformationForCoverSheetAction.FAILED:
    case ListingActions.fetchAgentInformationForCoverSheetAction.FAILURE:
    case ListingActions.fetchAgentInformationForCoverSheetAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.fetchAgentInformationForCoverSheetAction,
          "validateAndSubmitResponse",
        ),
      }
    }

    case ListingActions.moveToOnMarketAction.REQUEST:
    case ListingActions.moveToOnMarketAction.FAILED:
    case ListingActions.moveToOnMarketAction.FAILURE:
    case ListingActions.moveToOnMarketAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.moveToOnMarketAction,
          "moveToOnMarketResponse",
        ),
      }
    }

    case ListingActions.processDeletionOfFieldsInCoversheetAction.REQUEST:
    case ListingActions.processDeletionOfFieldsInCoversheetAction.FAILED:
    case ListingActions.processDeletionOfFieldsInCoversheetAction.FAILURE:
    case ListingActions.processDeletionOfFieldsInCoversheetAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.processDeletionOfFieldsInCoversheetAction,
          "processDeletionOfFieldsInCoversheetResponse",
        ),
      }
    }

    // case ListingActions.processBeforeViewingContractAction.REQUEST:
    // case ListingActions.processBeforeViewingContractAction.FAILED:
  // case ListingActions.processBeforeViewingContractAction.FAILURE:
    // case ListingActions.processBeforeViewingContractAction.SUCCESS: {
    //   return {
    //     ...state,
    //     ...handleDeltaReducer(
    //       state,
    //       action,
    //       ListingActions.processBeforeViewingContractAction,
    //       "processBeforeViewingContractResponse",
    //     ),
    //   }
    // }

    case ListingActions.toggleDeleteModalAction.type: {
      return {
        ...state,
        isDeleteModalOpen: {
          ...action.data,
        },
      }
    }

    case ListingActions.constructListingsQueryAction.type: {
      const {
        constructed,
      } = action.data || {}

      return {
        ...state,
        query: { ...constructed },
      }
    }

    case ListingActions.updateListingsQueryAction.type: {
      const {
        query,
      } = action.data || {}

      return {
        ...state,
        query: { ...query },
      }
    }

    case ListingActions.toggleListingsFilterDrawerAction.type: {
      return {
        ...state,
        isListingDrawerOpen: action.data,
      }
    }

    case ListingActions.toggleMLSModalAction.type: {
      return {
        ...state,
        isMLSModalOpen: action.data,
      }
    }

    case ListingActions.toggleStatusChangeModalAction.type: {
      console.log("isStatusChangeModalOpen", action.data)
      return {
        ...state,
        isStatusChangeModalOpenListing: {
          ...action.data,
        },
      }
    }

    case ListingActions.createListingForModalAction.type: {
      return {
        ...state,
        isListingCreationModalOpen: action.data,
      }
    }

    case ListingActions.currentDealDetailsObjectAction.type: {
      return {
        ...state,
        listingData: {
          ...action.data,
        },
      }
    }

    case ListingActions.setCurrentListingTabAction.type: {
      return {
        ...state,
        currentListingTab: action.data,
      }
    }

    case ListingActions.setCurrentContractsTabAction.type: {
      return {
        ...state,
        currentContractsTab: action.data,
      }
    }

    case ListingActions.togglePropertyFormOrCardViewAction.type: {
      return {
        ...state,
        isCardViewToggled: action.data,
      }
    }

    case ListingActions.handleCollapseValuesAction.type: {
      return {
        ...state,
        listingCollapseValues: action.data,
      }
    }

    // case ListingActions.
    case ListingActions.clearExistingDataAction.type: {
      return {
        ...state,
        getListingDataAgentsDataPostMLSResponse: {
          ...state.getListingDataAgentsDataPostMLSResponse,
          data: null,
          isFetching: false,
        },
        isShowAddDocument:false,
        envelopeIdForAddDocument:null,
        isShowSignedDocs: false,
        checklistTemplate:null,
        isCardViewToggled: false,
        isListingCardFromCRM: false,
        isMultipleEnvelopeShare:false,
        currentListingTab: "cover_sheet",
        currentContractsTab: "contract_details",
        ...createDeltaReducer("performNecessaryActionsBeforeLoadingListingResponse"),
        ...createDeltaReducer("processDeletionOfFieldsInCoversheetResponse"),
        listingData: {
          dealId: null,
          editingAgentId: null,
          transactionId: null,
          listingStatus: "incomplete",
          propertyData: null,
          agents: null,
          sellers: null,
          listingTerms: null,
          propertyDataErrorCount: null,
          agentsDataErrorCount: null,
          sellersDataErrorCount: null,
          listingTermsErrorCount: null,
          isMiniListingDetailsViewEnabled: false,
          contractId: null,
          coversheetDisabled: false,
        },
        listingTabs: [
          {
            value: "cover_sheet",
            label: "Create Listing",
            isDisabled: false,
          },
          {
            value: "contract_details",
            label: "Contract Details",
            isDisabled: false,
          },
          {
            value: "compliance",
            label: "Paperwork",
            isDisabled: false,
          },
        ],
        listingIdToView: null,
        currentEnvelopeId:null,
        completeListingPackageToBeSent: {},
        isDocumentProcessing: false,
        envelopeListings: {},
        mergedEnvelopes: [],
        checklistObject: [],
        offersDocListView: [],
        listingDocsListView: [],
        viewListingSendUI: false,
        isViewComplianceForListing: false,
        uploadedDocsList: {
          sharedWithMe: [],
          teamDocs: [],
        },
        isListingsDocumentProcessing: false,
        marketData: {
          mslId: null,
          uniqueId: null,
        },
      }
    }

    case ListingActions.toggleMoveToMarketAction.type: {
      return {
        ...state,
        isOnMarketModalOn: action.data,
      }
    }
    
    case ListingActions.setMLSIdForOnMarketAction.type: {
      const {
        mlsId,
        uniqueId,
      } = action.data || {}
      return {
        ...state,
        // optionalMLSID: action.data,
        marketData: {
          mlsId,
          uniqueId,
        },
      }
    }

    case ListingActions.listingToTransactionConversionConfirmationModalToggleAction.type: {
      return {
        ...state,
        isListingToContractModalOpen: action.data,
      }
    }

    case ListingActions.viewListingContractAction.type: {
      return {
        ...state,
        isViewListingContractModalOpen: action.data,
      }
    }

    case ListingActions.viewComplianceForListingAction.type: {
      return {
        ...state,
        isViewComplianceForListing: action.data,
      }
    }

    case ListingActions.setCurrentListingsStateAction.type: {
      return {
        ...state,
        currentState: action.data,
      }
    }

    case ListingActions.fetchChecklistMetadataAction.REQUEST:
    case ListingActions.fetchChecklistMetadataAction.FAILED:
    case ListingActions.fetchChecklistMetadataAction.FAILURE:
    case ListingActions.fetchChecklistMetadataAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.fetchChecklistMetadataAction,
          "fetchChecklistMetadataResponse"
        ),
      }
    }

    case ListingActions.fetchChecklistTemplateAction.REQUEST:
    case ListingActions.fetchChecklistTemplateAction.FAILED:
    case ListingActions.fetchChecklistTemplateAction.FAILURE:
    case ListingActions.fetchChecklistTemplateAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.fetchChecklistTemplateAction,
          "fetchChecklistTemplateResponse"
        )
      }
    }


    case ListingActions.setChecklistTemplateAction.type: {
      return {
        ...state,
        checklistTemplate: action.data,
      }
    }

    case ListingActions.updateChecklistTemplateMetadataAction.REQUEST:
    case ListingActions.updateChecklistTemplateMetadataAction.FAILED:
    case ListingActions.updateChecklistTemplateMetadataAction.FAILURE:
    case ListingActions.updateChecklistTemplateMetadataAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.updateChecklistTemplateMetadataAction,
          "updateChecklistTemplateMetadataResponse"
        ),
      }
    }

    case ListingActions.updateEnvelopeObjectAction.type: {
      return {
        ...state,
        envelopeListings:{...action.data},
      }
    }

    case ListingActions.fetchEnvelopeListingsAction.REQUEST:
    case ListingActions.fetchEnvelopeListingsAction.FAILED:
    case ListingActions.fetchEnvelopeListingsAction.FAILURE:
    case ListingActions.fetchEnvelopeListingsAction.SUCCESS:{
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.fetchEnvelopeListingsAction,
          "fetchEnvelopeListingsResponse"
        )
      }
    }

    case ListingActions.updateMergeEnvelopeObjectAction.type: {
      return {
        ...state,
        mergedEnvelopes: action.data
      }
    }

    case ListingActions.fetchMergedEnvelopesAction.REQUEST:
    case ListingActions.fetchMergedEnvelopesAction.FAILED:
    case ListingActions.fetchMergedEnvelopesAction.FAILURE:
    case ListingActions.fetchMergedEnvelopesAction.SUCCESS:{
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ListingActions.fetchMergedEnvelopesAction,
          "fetchMergedEnvelopesResponse"
        )
      }
    }

    case ListingActions.toggleCreateEnvelopeAction.type: {
      return {
        ...state,
        isCreateEnvelopeOpen: action.data,
      }
    }

    case ListingActions.createReceiverListingViewV2Action.REQUEST:
    case ListingActions.createReceiverListingViewV2Action.SUCCESS:
    case ListingActions.createReceiverListingViewV2Action.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state, 
          action, 
          ListingActions.createReceiverListingViewV2Action, 
          "createReceiverListingViewV2Response"
        ),
      }
    }

    case ListingActions.fetchOfferDocumentsListAction.REQUEST:
    case ListingActions.fetchOfferDocumentsListAction.SUCCESS:
    case ListingActions.fetchOfferDocumentsListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.fetchOfferDocumentsListAction, "fetchOfferDocumentsListResponse"),
      }
    }

    case ListingActions.fetchListingPackageDocumentsAction.REQUEST:
    case ListingActions.fetchListingPackageDocumentsAction.SUCCESS:
    case ListingActions.fetchListingPackageDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.fetchListingPackageDocumentsAction, "fetchListingPackageDocumentsResponse"),
      }
    }

    case ListingActions.updateListingPackageDetailsAction.type: {
      return {
        ...state,
        completeListingPackageToBeSent: action.data,
      }
    }
    case ListingActions.updateOfferDocsListViewAction.type: {
      return {
        ...state,
        offersDocListView: action.data,
      }
    }

    case ListingActions.fetchListingDocumentsListAction.REQUEST:
    case ListingActions.fetchListingDocumentsListAction.SUCCESS:
    case ListingActions.fetchListingDocumentsListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.fetchListingDocumentsListAction, "fetchListingDocumentsListResponse"),
      }
    }

    case ListingActions.updateListingDocsAction.type: {
      return {
        ...state,
        listingDocsListView: action.data,
      }
    }

    case ListingActions.fetchLibraryUploadedFilesListAction.REQUEST:
    case ListingActions.fetchLibraryUploadedFilesListAction.SUCCESS:
    case ListingActions.fetchLibraryUploadedFilesListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.fetchLibraryUploadedFilesListAction, "fetchLibraryUploadedFilesListResponse"),
      }
    }

    case ListingActions.updateUploadedDocsListObjectAction.type: {
      return {
        ...state,
        uploadedDocsList: action.data,
      }
    }

    case ListingActions.updateLibraryUploadsListAction.REQUEST:
    case ListingActions.updateLibraryUploadsListAction.SUCCESS:
    case ListingActions.updateLibraryUploadsListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.updateLibraryUploadsListAction, "updateLibraryUploadsListResponse"),
      }
    }

    case ListingActions.deleteLibraryUploadedFileListAction.REQUEST:
    case ListingActions.deleteLibraryUploadedFileListAction.SUCCESS:
    case ListingActions.deleteLibraryUploadedFileListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.deleteLibraryUploadedFileListAction, "deleteLibraryUploadedFileListResponse"),
      }
    }

    case ListingActions.updateEnvelopesAction.REQUEST:
    case ListingActions.updateEnvelopesAction.SUCCESS:
    case ListingActions.updateEnvelopesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.updateEnvelopesAction, "updateEnvelopesResponse"),
      }
    }

    case ListingActions.createEnvelopeChecklistAction.REQUES:
    case ListingActions.createEnvelopeChecklistAction.SUCCESS:
    case ListingActions.createEnvelopeChecklistAction.FAILURE:{
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.createEnvelopeChecklistAction, "createEnvelopeChecklistResponse"),
      }
    }

    case ListingActions.setListingIdAction.type: {
      return {
        ...state,
        listingIdToView : action.data
      }
    }

    case ListingActions.updateChecklistObjectAction.type: {
      return {
        ...state,
        checklistObject: action.data
      }
    }
    

    case ListingActions.toggleDeleteFieldsConfirmationInCoverSheetAction.type: {
      return {
        ...state,
        isDeleteCoversheetModalOpen: action.data,
      }
    }

    case ListingActions.updateListingTabDisabledStatusAction.type: {
      const {
        key,
        bool,
      } = action.data || {}

      const curr = state.listingTabs
      const modified = curr.map((item) => {
        if (item.value === key) {
          item.isDisabled = bool
        }
        return item
      })

      return {
        ...state,
        listingTabs: [...modified],
      }
    }

    case ListingActions.sendListingPackageMailAction.REQUEST:
    case ListingActions.sendListingPackageMailAction.SUCCESS:
    case ListingActions.sendListingPackageMailAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.sendListingPackageMailAction, "sendListingPackageMailResponse"),
      }
    }

    case ListingActions.documentsListingsProcessingAction.type: {
      return {
        ...state,
        isListingsDocumentProcessing: action.data,
      }
    }

    case ListingActions.refreshListingsV2DocumentsAction.REQUEST:
    case ListingActions.refreshListingsV2DocumentsAction.SUCCESS:
    case ListingActions.refreshListingsV2DocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.refreshListingsV2DocumentsAction, "refreshListingsV2DocumentsResponse"),
      }
    }

    case ListingActions.viewListingModalAction.type: {
      const {
        bool,
      } = action.data

      return {
        ...state,
        isViewListingModalOpen: {
          bool,
        },
      }
    }

    case ListingActions.toggleMultipleEnvelopeShareAction.type: {
      return {
        ...state,
        isMultipleEnvelopeShare: action.data
      }
    }

    case ListingActions.setViewListingSendUIAction.type: {
      return {
        ...state,
        viewListingSendUI: action.data
      }
    }

    case ListingActions.setEnvelopeIdAction.type: {
      return {
        ...state,
        currentEnvelopeId: action.data
      }
    }

    case ListingActions.sendOffersChecklistDocumentsAction.REQUEST:
    case ListingActions.sendOffersChecklistDocumentsAction.SUCCESS:
    case ListingActions.sendOffersChecklistDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.sendOffersChecklistDocumentsAction, "sendOffersChecklistDocumentsResponse"),
      }
    }

    case ListingActions.sendReminderListingAction.REQUEST:
    case ListingActions.sendReminderListingAction.SUCCESS:
    case ListingActions.sendReminderListingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.sendReminderListingAction, "sendReminderListingResponse"),
      }
    }

    case ListingActions.sendReminderOfferChecklistAction.REQUEST:
    case ListingActions.sendReminderOfferChecklistAction.SUCCESS:
    case ListingActions.sendReminderOfferChecklistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.sendReminderOfferChecklistAction, "sendReminderOfferChecklistResponse"),
      }
    }

    case ListingActions.viewListingPersistenceAction.REQUEST:
    case ListingActions.viewListingPersistenceAction.SUCCESS:
    case ListingActions.viewListingPersistenceAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ListingActions.viewListingPersistenceAction, "viewListingPersistenceResponse")
      }
    }

    case ListingActions.toggleAddSignedDocsAction.type: {
      return {
        ...state,
        isShowSignedDocs: action.data
      }
    }

    case ListingActions.showAddDocumentAction.type: {
      return {
        ...state,
        isShowAddDocument: action.data
      }
    }

    case ListingActions.setEnvelopeIdForAddDocumentAction.type: {
      return {
        ...state,
        envelopeIdForAddDocument: action.data
      }
    } 

    case ListingActions.setIsLoadingViewListingPageAction.type: {
      return {
        ...state,
        isLoadingViewListingPage: action.data,
      }
    }

    case ListingActions.setListingCardFromCRMAction.type: {
      return {
        ...state,
        isListingCardFromCRM: action.data,
      }
    }
    
    default: {
      return state
    }
  }
}
