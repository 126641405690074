import {
  takeLatest,
  call,
  put,
  throttle,
} from "redux-saga/effects"
import {
  BUYER_INFORMATION_STEP,
  PROPERTY_INFORMATION_STEP,
  PROPERTY_INFORMATION_FORMS,
  DEAL_TERMS_STEP,
  DEAL_TERMS_FORMS,
  getStateLowerCase,
} from "dumbComponents/OffersV2/consts"
import { toast as createToast } from "react-toastify"
import uuid from "uuid"
import root from "window-or-global"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import { isSuccess } from "services/Utils"
import * as OfferV1Action from "container/TechAssets/actions"
import * as OffersV2Api from "./api"
import * as OffersV2Actions from "./actions"
import { message } from "antd";

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}

const toastErrorConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "red-background ",
}


function* handleFetchLibraryUploads(action) {
  try {
    let query = ""

    if (action?.data?.payload) {
      Object.keys(action.data.payload).forEach((dt) => {
        query += `${dt}=${action.data.payload[dt]}&`
      })
    }

    const res = yield call(OffersV2Api.fetchLibraryUploadsAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data || {}

      if (response) {
        const payload = {
          sharedWithMe: [],
          teamDocs: [],
          transactionDocs: [],
        }

        payload.sharedWithMe = [...response.for_own_use]
        payload.sharedWithMe.forEach((item, iter) => {
          item.name = item.title || `Personal Document ${iter + 1}`
          item.isSelected = false
        })

        payload.teamDocs = [...response.shared_with_team]
        payload.teamDocs.forEach((item, iter) => {
          item.name = item.title || `Team Document ${iter + 1}`
          item.isSelected = false
        })

        payload.transactionDocs = [...response.transaction_documents]
        payload.transactionDocs.forEach((item, iter) => {
          item.name = item.title || `Transaction Document ${iter + 1}`
          item.isSelected = false
        })

        yield put(OffersV2Actions.updateUploadedDocsObjectAction.call(payload))
      }

      yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.success(response))
    }
  } catch (error) {
    customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.failure(error))
  }
}

function* handleFetchActiveEnvelopes(action) {
  try {
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const res = yield call(OffersV2Api.getActiveEnvelopesAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      if (response && response.length > 0) {
        const filtered = response.filter(item => item.status !== "active" && item.status !== "created")
        filtered.forEach((item) => {
          item.isSelected = false
          item.showFillAndSign = false
          item.date = item.created_at_epoch
        })
        yield put(OffersV2Actions.updateEnvelopesObjectAction.call(filtered))
      } else {
        yield put(OffersV2Actions.updateEnvelopesObjectAction.call([]))
      }

      yield put(OffersV2Actions.fetchActiveEnvelopesFilesAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchActiveEnvelopesFilesAction.failure(e))
  }
}

function* handleFetchBuyersOffers(action) {
  try {
    const {
      transactionId,
    } = action.data
    const res = yield call(OffersV2Api.getOffersFromBuyersAPI, transactionId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.getOfferStatusMappingsAction.request())
      yield put(OffersV2Actions.fetchBuyerOffersAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchBuyerOffersAction.failure(e))
  }
}

function* handleFetchInfoByMLSId(action) {
  try {
    const {
      mlsId,
    } = action.data
    const res = yield call(OffersV2Api.fetchInfoBasedOnMLSIdAPI, mlsId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)

      yield put(OffersV2Actions.fetchInfoBasedOnMLSIdAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchInfoBasedOnMLSIdAction.failure(e))
  }
}

function* handleFetchInfoBasedOnPropertyAddress() {
  try {
    const {
      propertyAddress,
    } = action.data
    const res = yield call(OffersV2Api.fetchInfoBasedOnPropertyAddressAPI, propertyAddress)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)

      yield put(OffersV2Actions.fetchInfoBasedOnPropertyAddressAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchInfoBasedOnPropertyAddressAction.failure(e))
  }
}

function* handleCreateOfferFromPropertyType(action) {
  try {
    const {
      payload,
      history,
      propertyPayload,
      parsedQuery,
      callback,
    } = action.data
    const res = yield call(OffersV2Api.createOfferFromPropertyTypeAPI, payload)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      const offerId = response.offer_id

      console.log(response)
      yield put(OfferV1Action.setDealToView.call(offerId))
      if (history) {
        yield put(OffersV2Actions.toggleBasicOfferDetailsAction.call(false))
        //testing required
        // yield call(handleFetchValidateCoverSheet, { data: { id: offerId } })
        if (propertyPayload) {
          yield put(OffersV2Actions.updatePropertyInformationTabAction.request({
            payload: propertyPayload,
            id: offerId,
            noRedirect: true,
            callback,
          }))
          yield put(OffersV2Actions.fetchPropertyInformationAction.request({
            id: offerId,
            current_offer_step: propertyPayload.current_offer_step,
          }))
        }
        if (parsedQuery) {
          const queryParam = `transaction_id=${parsedQuery.transaction_id}&req_id=${parsedQuery.req_id}&client_id=${parsedQuery.client_id}&client_source=${parsedQuery.client_source}&state=${parsedQuery.state}`
          history.push(`/realty/offers-v3/${offerId}?${queryParam}`)
        } else {
          history.push(`/realty/offers-v3/${offerId}`)
        }
      }

      yield put(OffersV2Actions.createOfferFromPropertyTypeAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    console.log(e)
    yield put(OffersV2Actions.createOfferFromPropertyTypeAction.failure(e))
  }
}

function* handleFetchOffersStatusMappings() {
  try {
    const res = yield call(OffersV2Api.getOfferStatusMappingsAPI)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)

      yield put(OffersV2Actions.getOfferStatusMappingsAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.getOfferStatusMappingsAction.failure(e))
  }
}

function* handleUpdateBuyerInformation(action) {
  try {
    const {
      payload,
      id,
      callback,
      currentFinancingType,
    } = action.data
    if (payload.current_offer_step === "insert_lender") {
      yield put(OffersV2Actions.reduxUpdatingLenderAction.call(true))
    } else if (payload.current_offer_step === "insert_vendor") {
      yield put(OffersV2Actions.reduxUpdatingVendorAction.call(true))
    }

    if (payload?.clients) {
      payload.clients = payload.clients.filter((item) => {
        if (item.is_primary === 1) {
          return item
        }

        if (item.is_primary === 0) {
          const copy = { ...item }
          delete copy.is_primary

          const isEmptyTemp = Object.values(copy).every(key => key === null || key === undefined)
          if (!isEmptyTemp && item.name !== "undefined") {
            return item
          }
        }
      })
    }

    if (payload?.agents) {
      payload.agents = payload.agents.filter((item) => {
        if (item.is_primary === 1) {
          return item
        }

        if (item.is_primary === 0) {
          const copy = { ...item }
          delete copy.is_primary

          const isEmptyTemp = Object.values(copy).every(key => key === null || key === undefined)
          if (!isEmptyTemp && item.name !== "undefined") {
            return item
          }
        }
      })
    }

    const res = yield call(OffersV2Api.updateBuyerInformationTabAPI, id, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      //yield put(OfferV1Action.getCurrentDealDetailsAction.request({id ,step: "buyers_information_mobile" }))
      yield put(OffersV2Actions.updateBuyerInformationTabAction.success(response))

      if (payload.current_offer_step === "insert_lender") {
        yield put(OfferV1Action.getCurrentDealDetailsAction.request({
          id,
          step: "buyers_information_mobile",
          updateLenderOnForm: payload.current_offer_step === "insert_lender",
          currentFinancingType,
        }))
        yield put(OffersV2Actions.reduxUpdatingLenderAction.call(false))
      } else if (payload.current_offer_step === "insert_vendor") {
        yield put(OfferV1Action.getCurrentDealDetailsAction.request({
          id,
          step: "buyers_information_mobile",
          updateVendorOnForm: payload.current_offer_step === "insert_vendor",
        }))
        yield put(OffersV2Actions.reduxUpdatingVendorAction.call(false))
      } else {
        yield put(OffersV2Actions.reduxUpdatingLenderAction.call(false))
        yield put(OfferV1Action.showUpdatingBuyerInformationLoader.call(false))
        yield put(OffersV2Actions.reduxUpdatingVendorAction.call(false))
      }

      yield put(OffersV2Actions.toggleCoverSheetAction.call("property_information"))
      yield put(OffersV2Actions.toggleSideNavAction.call("property_information"))

      if (callback) {
        callback()
      }
    }
  } catch (e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updateBuyerInformationTabAction.failure(e))
  }
}

function* handleLenderUpdate(action) {
  try {
    const {
      lenderId,
      payload
    } = action.data
    const res = yield call(OffersV2Api.updateLenderAPI, lenderId, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(OffersV2Actions.updateLenderInformationAction.success(response && response.response))
    }
  } catch (e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updateLenderInformationAction.failure(e))
  }
}

function* handleVendorUpdate(action) {
  try {
    const {
      vendorId,
      payload
    } = action.data
    const res = yield call(OffersV2Api.updateVendorAPI, vendorId, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(OffersV2Actions.updateVendorInformationAction.success(response && response.response))
    }
  } catch (e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updateVendorInformationAction.failure(e))
  }
}

function* handleUpdatePropertyInformation(action) {
  try {
    const {
      payload,
      id,
      callback,
      doNotReload,
    } = action.data
    const res = yield call(OffersV2Api.updatePropertyInformationTabAPI, id, payload)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      if (callback) {
        callback(response)
      }

      console.log(response)
      yield put(OffersV2Actions.updatePropertyInformationTabAction.success(response))
      if (!doNotReload) {
        yield put(OffersV2Actions.fetchPropertyInformationAction.request({
          current_offer_step: PROPERTY_INFORMATION_STEP,
          id,
        }))
      }
      
      yield put(OfferV1Action.getCurrentDealDetailsAction.request({
        step: BUYER_INFORMATION_STEP,
        id,
      }))
      // if (isRedirect) {
      //   window.location.reload()
      // }
    }
  } catch (e) {
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updatePropertyInformationTabAction.failure(e))
  }
}

function* handleUpdateDealInformation(action) {
  try {
    const {
      payload,
      id,
    } = action.data
    const res = yield call(OffersV2Api.updateDealInformationTabAPI, id, payload)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)

      yield put(OffersV2Actions.updateDealTermsInformationTabAction.success(response))
      // yield put(OffersV2Actions.toggleActiveTabAction.call("documents"))
      // yield put(OffersV2Actions.toggleSideNavAction.call("templates"))
      // yield call(handleFetchValidateCoverSheet, { data: { id } })
      yield put(OffersV2Actions.fetchValidateCoverSheetAction.request({
        id,
      }))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updateDealTermsInformationTabAction.failure(e))
  }
}

function* handleFetchValidateCoverSheet(actions) {
  try {
    const {
      id,
    } = actions.data
    const res = yield call(OffersV2Api.fetchValidateCoverSheetAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(OffersV2Actions.fetchValidateCoverSheetAction.success(response))
      yield put(OffersV2Actions.getOfferStatusAction.request({ id }))
      const { buyers_information_mobile, deal_information_mobile, property_information_mobile } = res.data.response || {}
      if (buyers_information_mobile === 0 && deal_information_mobile === 0 && property_information_mobile === 0) {
        customToast("Coversheet validated, navigating to the offer package now.")
        yield put(OffersV2Actions.toggleActiveTabAction.call("documents"))
        yield put(OffersV2Actions.toggleSideNavAction.call("templates"))
        customToast("Generating documents...")
      } else {
        root.scrollTo(0, 0)
        customToast("Please fill in the mandatory fields marked with * and try again.", "error")
      }
    }
  } catch (e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchValidateCoverSheetAction.failure(e))
  }
}

function* handleFetchDealTermsInformation(actions) {
  try {
    const {
      id,
      current_offer_step,
    } = actions.data
    const res = yield call(OffersV2Api.fetchDealTermsInformationAPI, id, current_offer_step)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response.state)
      const currentState = response?.state || ""

      if (currentState) {
        const lowerCaseState = getStateLowerCase(currentState)
        const payload = {
          currentState: lowerCaseState,
          form: {
            [lowerCaseState]: {
              ...response,
            },
          },
          type_of_transaction: response.type_of_transaction,
        }
        yield put(OffersV2Actions.updateDealTermsObjectAction.call(payload))
      }

      yield put(OffersV2Actions.fetchDealTermsInformationAction.success(response))
    }
  } catch (e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchDealTermsInformationAction.failure(e))
  }
}

function* handleFetchPropertyInformation(actions) {
  try {
    const {
      id,
      current_offer_step,
    } = actions.data
    yield put(OfferV1Action.setDealToView.call(id))
    const res = yield call(OffersV2Api.fetchPropertyInformationAPI, id, current_offer_step)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      const state = response.state || "california"
      const currentState = state.toLowerCase()
      yield put(OffersV2Actions.updateStateForOffersAction.call(currentState))
      const listing_agents = [...response.listing_agents]

      listing_agents.forEach((item) => {
        const name = item.name?.split(" ") || ""
        const firstName = name && name[0]
        const lastName = name && name.length > 0 && name[1] || ""

        item.listing_agent_first_name = firstName
        item.listing_agent_last_name = lastName
        item.listing_agent_id = item.id
        item.isListingAgentEditVisible = false
      })

      const sellers = [...response.sellers]

      sellers.forEach((item) => {
        // item.firstname = item.firstname
        // item.lastname = item.lastname
        item.seller_id = item.id
        item.isSellerEditVisible = false
      })

      const currentForm = PROPERTY_INFORMATION_FORMS[currentState]

      if (currentForm) {
        for(let key in response) {
          if (currentForm.hasOwnProperty(key)) {
            currentForm[key] = response[key]
          }
        }

        for (let key in currentForm) {
          currentForm[key] = response[key]
        }
      }


      const payload = {
        addressRepresentation: false,
        currentState,
        type_of_transaction: response.type_of_transaction,
        form: {
          [currentState]: {
            ...currentForm,
          },
        },
        listing_agents,
        sellers,
        mls_number: response.typedMLSId || response.mls_number,
      }
      yield put(OffersV2Actions.updatePropertyInformationObjectAction.call(payload))

      yield put(OffersV2Actions.fetchPropertyInformationAction.success(response))
    }
  } catch (e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchPropertyInformationAction.failure(e))
  }
}

function* handleFetchVendorInfo(action) {
  try{
    const {
      id,
      agentId,
      current_offer_step,
      callback,
    } = action.data
    const res = yield call(OffersV2Api.fetchVendorInfoAPI, id, current_offer_step, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.fetchVendorInfoAction.success(response))
      if(callback) callback(response)
    }
  } catch(e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchVendorInfoAction.failure(e))
  }
}

function* handleOfferPurchaseTypeUpdate(action) {
  try {
    const {
      payload,
      id,
    } = action.data
    const res = yield call(OffersV2Api.updateOfferPurchaseTypeAPI, id, payload)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)

      yield put(OffersV2Actions.updateOfferPurchaseTypeAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updateOfferPurchaseTypeAction.failure(e))
  }
}

//handleFetchOfferDocs
function* handleFetchOfferDocs(action) {
  try {
    const {
      state,
    } = action.data
    const res = yield call(OffersV2Api.fetchOfferDocumentsAPI, state)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      response.forEach((item) => {
        item.isSelected = false
      })
      yield put(OffersV2Actions.updateOfferDocsListAction.call(response))

      yield put(OffersV2Actions.fetchOfferDocumentsAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchOfferDocumentsAction.failure(e))
  }
}

function* handleMLSBasedUpdates(action) {
  try {
    const payload = {
      clients: [],
      agents: [],
      mls_select: "",
      mls_number: "",
      apn_number: "",
      street: "",
      apt: "",
      city: "",
      state: "",
      zipcode: "",
      county: "",
      type_of_transaction: "",
      draft: 1,
      current_offer_step: "property_information_mobile",
    }

    const {
      data,
    } = action || {}

    console.log(data)

    // const {
    //   payload,
    //   id,
    // } = action.data
    // const res = yield call(OffersV2Api.updateOfferPurchaseTypeAPI, id, payload)
    // console.log(res)
    // if (isSuccess(res)) {
    //   const {
    //     response,
    //   } = res.data

    //   console.log(response)

    //   yield put(OffersV2Actions.updateOfferPurchaseTypeAction.success(response))
    // }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updateOfferPurchaseTypeAction.failure(e))
  }
}

function* handleUpdateRecommendedDocs(action) {
  try {
    const {
      payload,
      id,
      callBack,
    } = action.data
    const res = yield call(OffersV2Api.updateRecommendedListOfDocsAPI, payload)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)

      yield put(OffersV2Actions.updateRecommendedListOfDocsAction.success(response))
      yield put(OffersV2Actions.toggleSideNavAction.call("templates"))
      if (callBack) {
        callBack()
        yield put(OfferV1Action.getOfferDocuments.request({
          payload: {
            offerId: id,
          },
        }))
      }
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.updateRecommendedListOfDocsAction.failure(e))
  }
}

function* handleListingDocumentsFetch(action) {
  try {
    const {
      state,
    } = action.data
    const res = yield call(OffersV2Api.fetchListingDocumentsAPI, state)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)
      response.forEach((item) => {
        item.isSelected = false
      })

      yield put(OffersV2Actions.updateListingOfferDocsAction.call(response))
      yield put(OffersV2Actions.fetchListingDocumentsAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchListingDocumentsAction.failure(e))
  }
}

function* handleUploadToLibrary(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(OffersV2Api.updateLibraryUploadsAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.updateLibraryUploadsAction.success(response))

      // yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.request())
      if (payload.metadata && payload.metadata.transaction_type && payload.metadata.transaction_id) {
        yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.request({
          payload: {
            transaction_type: payload.metadata.transaction_type,
            transaction_id: payload.metadata.transaction_id,
          },
        }))
      } else if (payload.transaction_type && payload.transaction_id) {
        yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.request({
          payload: {
            transaction_type: payload.transaction_type,
            transaction_id: payload.transaction_id,
          },
        }))
      } else {
        yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.request())
      }
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    message.error("Something went wrong")
    yield put(OffersV2Actions.updateLibraryUploadsAction.failure(e))
  }
}

function* handleDeleteFileFromLibrary(action) {
  try {
    const {
      id,
      transaction_type,
      transaction_id,
    } = action.data
    const res = yield call(OffersV2Api.deleteLibraryUploadedFileAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.deleteLibraryUploadedFileAction.success(response))
      customToast("Document deleted.")

      // yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.request())
      if (transaction_type && transaction_id) {
        yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.request({
          payload: {
            transaction_type,
            transaction_id,
          },
        }))
      } else {
        yield put(OffersV2Actions.fetchLibraryUploadedFilesAction.request())
      }
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    customToast("Something went wrong", "error")
    yield put(OffersV2Actions.deleteLibraryUploadedFileAction.failure(e))
  }
}

function* handleDeleteOffer(action) {
  try {
    const {
      offerId,
      callback
    } = action.data
    const res = yield call(OffersV2Api.deleteOffer, offerId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.deleteOfferAction.success(response))
      customToast("Success! Deleted the selected transaction.")
      if(callback){
        callback()
      }

    }
  } catch (e) {
    console.log(e)
    yield put(OffersV2Actions.deleteOfferAction.failure(e))
  }
}

function* handleFetchOfferRelatedDocuments(action) {
  try {
    const {
      id,
      type,
    } = action.data
    const res = yield call(OffersV2Api.fetchOfferRelatedDocumentsAPI, id, type)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log("response", response)

      yield put(OffersV2Actions.fetchOfferRelatedDocumentsAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchOfferRelatedDocumentsAction.failure(e))
  }
}

function* handleUploadOfferRelatedDocuments(action) {
  try {
    const {
      id,
      payload,
    } = action.data
    const res = yield call(OffersV2Api.uploadOfferRelatedAPI, id, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      const {
        step,
      } = payload || {}

      if (step === "proof_of_funds") {
        yield put(OffersV2Actions.fetchProofOfFundsAction.request({
          id,
          type: step,
        }))
      } else if (step === "cover_letter") {
        yield put(OffersV2Actions.fetchCoverLettersAction.request({
          id,
          type: step,
        }))
      } else if (step === "pre_qualification") {
        yield put(OffersV2Actions.fetchPreApprovedAction.request({
          id,
          type: step,
        }))
      } else if (step === "others") {
        yield put(OffersV2Actions.fetchOtherAdditionalDocsAction.request({
          id,
          type: step,
        }))
      }

      yield put(OffersV2Actions.uploadOfferRelatedAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.uploadOfferRelatedAction.failure(e))
  }
}

function* handleSendReminder(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(OffersV2Api.sendReminderAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log("response", response)

      yield put(OffersV2Actions.sendReminderAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.sendReminderAction.failure(e))
  }
}

function* handleReceiverViewForOffers(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(OffersV2Api.createReceiverOfferViewV2API, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log("response", response)
      root.open(response.url, "_blank")
      yield put(OffersV2Actions.createReceiverOfferViewV2Action.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.createReceiverOfferViewV2Action.failure(e))
  }
}

function* handleFetchProofOfFunds(action) {
  try {
    const {
      id,
      type,
    } = action.data
    const res = yield call(OffersV2Api.fetchOfferRelatedDocumentsAPI, id, type)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      response.forEach((item) => {
        item.isSelected = false
        item.name = item.title
        // item.id = uuid.v4()
      })
      yield put(OffersV2Actions.updateProofOfFundsAction.call(response))
      yield put(OffersV2Actions.fetchProofOfFundsAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchProofOfFundsAction.failure(e))
  }
}

function* handleFetchPreApproved(action) {
  try {
    const {
      id,
      type,
    } = action.data
    const res = yield call(OffersV2Api.fetchOfferRelatedDocumentsAPI, id, type)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      response.forEach((item) => {
        item.isSelected = false
        item.name = item.title
        // item.id = uuid.v4()
      })
      yield put(OffersV2Actions.updatePreApprovedAction.call(response))
      yield put(OffersV2Actions.fetchPreApprovedAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchPreApprovedAction.failure(e))
  }
}

function* handleFetchCoverLetters(action) {
  try {
    const {
      id,
      type,
    } = action.data
    const res = yield call(OffersV2Api.fetchOfferRelatedDocumentsAPI, id, type)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      response.forEach((item) => {
        item.isSelected = false
        item.name = item.title
        // item.id = uuid.v4()
      })
      yield put(OffersV2Actions.updateCoverLetterAction.call(response))
      yield put(OffersV2Actions.fetchCoverLettersAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchCoverLettersAction.failure(e))
  }
}

function* handleFetchOtherAdditionalDocs(action) {
  try {
    const {
      id,
      type,
    } = action.data
    const res = yield call(OffersV2Api.fetchOfferRelatedDocumentsAPI, id, type)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      response.forEach((item) => {
        item.isSelected = false
        item.name = item.title
        item.id = uuid.v4()
      })
      yield put(OffersV2Actions.updateOtherDocsAction.call(response))
      yield put(OffersV2Actions.fetchOtherAdditionalDocsAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchOtherAdditionalDocsAction.failure(e))
  }
}

//
function* handleUploadTransactionDocuments(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(OffersV2Api.transactionFileUploadAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      const tID = payload.transaction_id

      yield put(OffersV2Actions.transactionUploadFilesAction.success(response))
      yield put(OffersV2Actions.fetchTransactionFilesAction.request({
        id: tID,
      }))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.transactionUploadFilesAction.failure(e))
  }
}

function* handleDeleteTransactionFile(action) {
  try {
    const {
      clientId,
      fileId,
      transactionId,
    } = action.data
    const res = yield call(OffersV2Api.deleteTransactionFileAPI, clientId, fileId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.fetchTransactionFilesAction.request({
        id: transactionId,
      }))

      yield put(OffersV2Actions.deleteTransactionFileAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.deleteTransactionFileAction.failure(e))
  }
}

function* handleFetchTransactionFiles(action) {
  try {
    const {
      id,
    } = action.data
    const res = yield call(OffersV2Api.fetchTransactionFilesAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.fetchTransactionFilesAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchTransactionFilesAction.failure(e))
  }
}

function* handleFetchOfferPackageDocuments(action) {
  try {
    const {
      id,
      payload,
      callback,
    } = action.data
    console.log("action.data",action.data)
    const res = yield call(OffersV2Api.fetchOfferPackageDocumentsAPI, id ,payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      const envelopes = response.filter(item => item.type === "docusign_envelope")
      const proofOfFundsDocs = response.filter(item => item.type === "proof_of_funds")
      const coverLetters = response.filter(item => item.type === "cover_letter")
      const preQualified = response.filter(item => item.type === "pre_qualification")

      const payload = {
        offer_package_to_recipient: "",
        offer_package_subject: "",
        custom_message: "",
        envelopes: [...envelopes],
        proofOfFundsDocs: proofOfFundsDocs || [],
        coverLetters: coverLetters || [],
        preQualified: preQualified || [],
      }

      yield put(OffersV2Actions.updateOfferPackageDetailsAction.call(payload))
      yield put(OffersV2Actions.fetchOfferPackageDocumentsAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.fetchOfferPackageDocumentsAction.failure(e))
  }
}

function* handleSendOfferPackageMail(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(OffersV2Api.sendOfferPackageMailAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      customToast("Package sent.")

      yield put(OffersV2Actions.sendOfferPackageMailAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.sendOfferPackageMailAction.failure(e))
  }
}

//handleGetOfferPackage
function* handleGetOfferPackage(action) {
  try {
    const {
      id,
    } = action.data
    const res = yield call(OffersV2Api.getOfferPackageAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      const envelopes = response.envelopeIds

      const payload = {
        envelopes,
        attachments: response.attachmentNames,
        date: response.created_at,
      }

      yield put(OffersV2Actions.updateCurrentOfferPackageObjectAction.call(payload))
      yield put(OffersV2Actions.getOfferPackageAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    // customToast("Something went wrong", "error")
    console.log(e)
    yield put(OffersV2Actions.getOfferPackageAction.failure(e))
  }
}

function* handleGetOfferStatus(action) {
  try {
    const {
      id,
    } = action.data
    const res = yield call(OffersV2Api.getOfferStatusAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(OffersV2Actions.getOfferStatusAction.success(response))
    }
  } catch (e) {
    console.log(e)
    // customToast("Something went wrong", "error")
    yield put(OffersV2Actions.getOfferStatusAction.failure(e))
  }
}

export default function* main() {
  yield takeLatest(OffersV2Actions.fetchLibraryUploadedFilesAction.REQUEST, handleFetchLibraryUploads)
  yield takeLatest(OffersV2Actions.fetchActiveEnvelopesFilesAction.REQUEST, handleFetchActiveEnvelopes)
  yield takeLatest(OffersV2Actions.fetchBuyerOffersAction.REQUEST, handleFetchBuyersOffers)
  yield takeLatest(OffersV2Actions.fetchInfoBasedOnMLSIdAction.REQUEST, handleFetchInfoByMLSId)
  yield takeLatest(OffersV2Actions.fetchInfoBasedOnPropertyAddressAction.REQUEST, handleFetchInfoBasedOnPropertyAddress)
  yield takeLatest(OffersV2Actions.createOfferFromPropertyTypeAction.REQUEST, handleCreateOfferFromPropertyType)
  yield takeLatest(OffersV2Actions.getOfferStatusMappingsAction.REQUEST, handleFetchOffersStatusMappings)
  yield throttle(2000, OffersV2Actions.updateBuyerInformationTabAction.REQUEST, handleUpdateBuyerInformation)
  yield takeLatest(OffersV2Actions.updateLenderInformationAction.REQUEST, handleLenderUpdate)
  yield takeLatest(OffersV2Actions.updateVendorInformationAction.REQUEST, handleVendorUpdate)
  //yield takeLatest(OffersV2Actions.updatePropertyInformationTabAction.REQUEST, handleUpdatePropertyInformation)
  yield throttle(4000, OffersV2Actions.updatePropertyInformationTabAction.REQUEST, handleUpdatePropertyInformation)
  yield takeLatest(OffersV2Actions.updateDealTermsInformationTabAction.REQUEST, handleUpdateDealInformation)
  yield takeLatest(OffersV2Actions.fetchDealTermsInformationAction.REQUEST, handleFetchDealTermsInformation)
  yield takeLatest(OffersV2Actions.fetchPropertyInformationAction.REQUEST, handleFetchPropertyInformation)
  yield takeLatest(OffersV2Actions.fetchVendorInfoAction.REQUEST, handleFetchVendorInfo)
  yield takeLatest(OffersV2Actions.fetchValidateCoverSheetAction.REQUEST, handleFetchValidateCoverSheet)
  yield takeLatest(OffersV2Actions.updateOfferPurchaseTypeAction.REQUEST, handleOfferPurchaseTypeUpdate)
  yield takeLatest(OffersV2Actions.setMLSDataAction.type, handleMLSBasedUpdates)
  yield takeLatest(OffersV2Actions.fetchOfferDocumentsAction.REQUEST, handleFetchOfferDocs)
  yield takeLatest(OffersV2Actions.updateRecommendedListOfDocsAction.REQUEST, handleUpdateRecommendedDocs)
  yield takeLatest(OffersV2Actions.fetchListingDocumentsAction.REQUEST, handleListingDocumentsFetch)
  yield takeLatest(OffersV2Actions.updateLibraryUploadsAction.REQUEST, handleUploadToLibrary)
  yield takeLatest(OffersV2Actions.deleteLibraryUploadedFileAction.REQUEST, handleDeleteFileFromLibrary)
  yield takeLatest(OffersV2Actions.deleteOfferAction.REQUEST, handleDeleteOffer)
  yield takeLatest(OffersV2Actions.fetchOfferRelatedDocumentsAction.REQUEST, handleFetchOfferRelatedDocuments)
  yield takeLatest(OffersV2Actions.uploadOfferRelatedAction.REQUEST, handleUploadOfferRelatedDocuments)
  yield takeLatest(OffersV2Actions.sendReminderAction.REQUEST, handleSendReminder)
  yield takeLatest(OffersV2Actions.createReceiverOfferViewV2Action.REQUEST, handleReceiverViewForOffers)
  yield takeLatest(OffersV2Actions.fetchProofOfFundsAction.REQUEST, handleFetchProofOfFunds)
  yield takeLatest(OffersV2Actions.fetchPreApprovedAction.REQUEST, handleFetchPreApproved)
  yield takeLatest(OffersV2Actions.fetchCoverLettersAction.REQUEST, handleFetchCoverLetters)
  yield takeLatest(OffersV2Actions.fetchOtherAdditionalDocsAction.REQUEST, handleFetchOtherAdditionalDocs)
  yield takeLatest(OffersV2Actions.transactionUploadFilesAction.REQUEST, handleUploadTransactionDocuments)
  yield takeLatest(OffersV2Actions.deleteTransactionFileAction.REQUEST, handleDeleteTransactionFile)
  yield takeLatest(OffersV2Actions.fetchTransactionFilesAction.REQUEST, handleFetchTransactionFiles)
  yield takeLatest(OffersV2Actions.fetchOfferPackageDocumentsAction.REQUEST, handleFetchOfferPackageDocuments)
  yield takeLatest(OffersV2Actions.sendOfferPackageMailAction.REQUEST, handleSendOfferPackageMail)
  yield takeLatest(OffersV2Actions.getOfferPackageAction.REQUEST, handleGetOfferPackage)
  yield takeLatest(OffersV2Actions.getOfferStatusAction.REQUEST, handleGetOfferStatus)
}
