/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react"
import OffersV2 from "container/OffersV2"
import createToast from "dumbComponents/common/Toast/customToast"
import { debounce } from "lodash"
import { withRouter } from "react-router-dom"
import { Form, Popover } from "antd"
import uuid from "uuid"
import { ListingCard } from "dumbComponents/common/ListingsCommon"
import {
  FloatingCTA,
} from "dumbComponents/common/InputFields"
import Listings from "container/Listings/index"
import ValidateNumber from "dumbComponents/common/ValidateNumber"
import {
  ListingModal,
  DeleteInformationFromCoversheet,
} from "dumbComponents/ListingsV2/Components/Modals"

import {
  PropertyInformationForm,
  ListingAgentForm,
  SellerInformationForm,
  ListingTermsForm,
} from "../ListingsForms/Forms"
import {
  Wrap,
  StyledCollapse,
  StyledPanel,
} from "./style"
import {
  PROPERTY_INFORMATION,
  LISTING_AGENT_INFORMATION,
  SELLER_INFORMATION,
  LISTING_TERMS_INFORMATION,
} from "./const"
import {
  getImages,
  getStateFromAbbrevition,
} from "./utils"

const DEBOUNCE_TIMEOUT = 500

const CollapsibleForm = ({
  listingData,
  initiateListing,
  isCardViewToggled,
  togglePropertyFormOrCardView,
  updateCurrentListingData,
  initiateListingResponse,
  history,
  updateListingAgentInformation,
  updateListingAgentInformationResponse,
  updateListingAgentInformationPostMlsSelection,
  updateListingAgentInformationPostMlsSelectionResponse,
  //getListingAgentsDetailsResponse,
  getListingDataAgentsDataPostMLSResponse,
  updatePropertyInformation,
  updatePropertyInformationPostMLSUpdate,
  updateSellerInformationForListing,
  updatePropertyInformationPostMLSUpdateResponse,
  updateListingTermsForListing,
  validateAndSubmit,
  fetchAgentInformationForCoverSheet,
  listingCollapseValues,
  handleCollapseValues,
  toggleDeleteFieldsConfirmationInCoverSheet,
  isDeleteCoversheetModalOpen,
  processDeletionOfFieldsInCoversheet,
  updatePropertyInformationResponse,
  updateSellerInformationForListingResponse,
  updateListingTermsForListingResponse,
  validateAndSubmitResponse,
  setCurrentListingsState,
  currentState,
  isListingCardFromCRM,
  setListingCardFromCRM,
  setCoversheetErrorsList,
}) => {
  const [activeKeyArray, setActiveKeyArray] = useState(["property_information"])
  const [currentPropertyInformation, setPropertyInformation] = useState({ ...PROPERTY_INFORMATION })
  const [currentListingAgentInformation, setListingAgentInformation] = useState([])
  const [currentSellersInformation, setSellerInformation] = useState([])
  const [currentListingTerms, setListingTerms] = useState({ ...LISTING_TERMS_INFORMATION })
  const [PropertyInformationFormRef] = Form.useForm()
  const [ListingAgentFormRef] = Form.useForm()
  const [SellerInformationFormRef] = Form.useForm()
  const [ListingTermsFormRef] = Form.useForm()
  const listingAgentFormRef = useRef(null)
  const propertyInformationReference = useRef(null)

  const {
    isFetching: initating,
  } = initiateListingResponse || {}

  const {
    isFetching: updatingAgent,
  } = updateListingAgentInformationResponse || {}

  const {
    isFetching: updatingProperty,
  } = updatePropertyInformationResponse || {}

  const {
    isFetching: updatingSeller,
  } = updateSellerInformationForListingResponse || {}

  const {
    isFetching: updatingListingTerms,
  } = updateListingTermsForListingResponse || {}

  const {
    isFetching: postMLSListingAgentDetailsFetch,
    data: postMLSData,
  } = getListingDataAgentsDataPostMLSResponse || {}

  const {
    isFetching: validating,
  } = validateAndSubmitResponse || {}

  const {
    dealId,
    propertyDataErrorCount,
    agentsDataErrorCount,
    sellersDataErrorCount,
    listingTermsErrorCount,
    coversheetDisabled,
    editingAgentId,
  } = listingData || {}

  const [isListingAgentData, setIsListingAgentData] = useState(false);

  useEffect(() => {
    if (dealId) {
      const {
        agents,
        propertyData,
        sellers,
        listingTerms = {},
      } = listingData || {}
      if (propertyData) {
        setPropertyInformation({ ...propertyData })
      }

      if (agents) {
        setListingAgentInformation(() => {
          if (ListingAgentFormRef) {
            ListingAgentFormRef.resetFields()
          }

          return [...agents]
        })
      }

      if (sellers) {
        setSellerInformation([
          ...sellers,
        ])
      }

      if (listingTerms) {
        setListingTerms({
          ...listingTerms,
        })
      }

      setTimeout(() => {
        handleCollapseValues([
          "property_information",
          "seller_information",
          "listing_terms",
          "listing_agent_information"
        ]);
        setIsListingAgentData(true);
      }, 500)
      if (!isListingCardFromCRM) {
        togglePropertyFormOrCardView(true)
      } else if (propertyInformationReference.current) {
        propertyInformationReference.current.setFieldsValue({
          ...propertyData,
        })
      }
    }

    return () => {
      // if (ListingAgentFormRef) {
      //   ListingAgentFormRef.resetFields()
      // }

      // if (SellerInformationFormRef) {
      //   SellerInformationFormRef.resetFields()
      // }

      // if (ListingTermsFormRef) {
      //   ListingTermsFormRef.resetFields()
      // }
    }
  }, [])

  /**GLITCHY*/
  useEffect(() => {
    if (!postMLSListingAgentDetailsFetch && postMLSData && dealId) {
      if (ListingAgentFormRef) {
        ListingAgentFormRef.resetFields()
      }
      /**BUG After property update listing agent information doesn't get filled*/
      handleCollapseValues(["property_information", "seller_information", "listing_terms"])
      setTimeout(() => {
        setListingAgentInformation(postMLSData.agents)
        handleCollapseValues(["property_information",
          "listing_agent_information",
          "seller_information",
          "listing_terms"]);
          setIsListingAgentData(true)
      }, 500)
    }
  }, [getListingDataAgentsDataPostMLSResponse, postMLSListingAgentDetailsFetch])

  const saveFormData = useCallback(
    debounce(async (payload, type, id, callback) => {
      try {
        switch (type) {
          case "agent_info":
            const finalPayload = {
              ...payload,
            }

            const {
              agents,
            } = finalPayload

            finalPayload.agents = [...agents.map((item, index) => {
              if (index > 0) {
                return {
                  name: item.name?.length ? item.name : "",
                  agent_id: item.agent_id ? parseInt(item.agent_id, 10) : 0,
                  email: item.email?.length ? item.email : "",
                  phone: item.phone?.length ? item.phone : "",
                  landline: item.landline?.length ? item.landline : "",
                  listing_brokerage: item.listing_brokerage?.length ? item.listing_brokerage : "",
                  brokerage_license_number: item.brokerage_license_number?.length ? item.brokerage_license_number : "",
                  brokerage_address: item.brokerage_address?.length ? item.brokerage_address : "",
                  apt: item.apt?.length ? item.apt : "",
                  city: item.city?.length ? item.city : "",
                  zipcode: item.zipcode?.length ? item.zipcode : "",
                  agent_license_number: item.agent_license_number?.length ? item.agent_license_number : "",
                  is_primary: 0,
                  state: item.state?.length ? item.state : "",
                  agent_type: item.agent_type?.length ? item.agent_type : "",
                }
              }
              return item
            })]

            updateListingAgentInformation({
              payload: finalPayload,
              dealId: id,
            })
            break
          case "property_information_update":
            updatePropertyInformation({
              payload,
              dealId: id,
            })
            break
          case "seller_info":
            const sellerFinalPayload = {
              ...payload,
            }

            const {
              sellers,
            } = sellerFinalPayload

            sellerFinalPayload.sellers = [...sellers.map((item, index) => ({
              address: item.address?.length ? item.address : "",
              city: item.city?.length ? item.city : "",
              email: item.email?.length ? item.email : "",
              phone: item.phone?.length ? item.phone : "",
              landline: item.landline?.length ? item.landline : "",
              firstname: item.firstname?.length ? item.firstname : "",
              lastname: item.lastname?.length ? item.lastname : "",
              zipcode: item.zipcode?.length ? item.zipcode : "",
              is_primary: index > 0 ? 0 : 1,
              state: item.state?.length ? item.state : "",
              seller_image_url: item.seller_image_url?.length ? item.seller_image_url : "",
              type_of_ownership: item.type_of_ownership?.length ? item.type_of_ownership : "",
              source: item.source?.length ? item.source : "",
              ownership_name: item.ownership_name?.length ? item.ownership_name : "",
              apt: item.apt?.length ? item.apt : "",
            }
            ))]

            updateSellerInformationForListing({
              payload: sellerFinalPayload,
              dealId: id,
            })

            if (callback) {
              callback()
            }
            break
          case "source_time":
            updateListingTermsForListing({
              dealId: id,
              payload,
            })
            break
          default:
            break
        }
      } catch (e) {
        console.log(e)
      }
    }, DEBOUNCE_TIMEOUT),
    []
  )

  /**Agent Update Function Call*/
  const saveFormDataAgentUpdate = useCallback(
    debounce(async (payload, type, id, callback) => {
      try {
        switch (type) {
          case "agent_info":
            const finalPayload = {
              ...payload,
            }

            const {
              agents,
            } = finalPayload

            finalPayload.agents = [...agents.map((item, index) => {
              if (index > 0) {
                return {
                  name: item.name || "",
                  agent_id: item.agent_id ? parseInt(item.agent_id, 10) : 0,
                  email: item.email || "",
                  phone: item.phone || "",
                  landline: item.landline || "",
                  listing_brokerage: item.listing_brokerage || "",
                  brokerage_license_number: item.brokerage_license_number || "",
                  brokerage_address: item.brokerage_address || "",
                  apt: item.apt || "",
                  city: item.city || "",
                  zipcode: item.zipcode || "",
                  agent_license_number: item.agent_license_number || "",
                  is_primary: 0,
                  state: item.state || "",
                  agent_type: item.agent_type || "",
                }
              }
              return item
            })]

            updateListingAgentInformation({
              payload: finalPayload,
              dealId: id,
            })
            break
          default:
            break
        }
      } catch (e) {
        console.log(e)
      }
    }, DEBOUNCE_TIMEOUT),
    []
  )

  /**Property Update Function Call*/
  const saveFormDataPropertyUpdate = useCallback(
    debounce(async (payload, type, id, callback) => {
      try {
        switch (type) {
          case "property_information_update":
            updatePropertyInformation({
              payload,
              dealId: id,
            })
            break
          default:
            break
        }
      } catch (e) {
        console.log(e)
      }
    }, DEBOUNCE_TIMEOUT),
    []
  )

  /**Transaction Details Update Function Call*/
  const saveFormDataTransactionDetails = useCallback(
    debounce(async (payload, type, id, callback) => {
      try {
        switch (type) {
          case "source_time":
            updateListingTermsForListing({
              dealId: id,
              payload,
            })
            break
          default:
            break
        }
      } catch (e) {
        console.log(e)
      }
    }, DEBOUNCE_TIMEOUT),
    []
  )

  /**Seller Update Function Call*/
  const saveFormDataSellerInfo = useCallback(
    debounce(async (payload, type, id, callback) => {
      try {
        switch (type) {
          case "seller_info":
            const sellerFinalPayload = {
              ...payload,
            }

            const {
              sellers,
            } = sellerFinalPayload

            sellerFinalPayload.sellers = [...sellers.map((item, index) => ({
              address: item.address?.length ? item.address : "",
              city: item.city?.length ? item.city : "",
              email: item.email?.length ? item.email : "",
              phone: item.phone?.length ? item.phone : "",
              landline: item.landline?.length ? item.landline : "",
              firstname: item.firstname?.length ? item.firstname : "",
              lastname: item.lastname?.length ? item.lastname : "",
              zipcode: item.zipcode?.length ? item.zipcode : "",
              is_primary: index > 0 ? 0 : 1,
              state: item.state?.length ? item.state : "",
              seller_image_url: item.seller_image_url?.length ? item.seller_image_url : "",
              type_of_ownership: item.type_of_ownership?.length ? item.type_of_ownership : "",
              source: item.source?.length ? item.source : "",
              ownership_name: item.ownership_name?.length ? item.ownership_name : "",
              apt: item.apt?.length ? item.apt : "",
            }
            ))]

            updateSellerInformationForListing({
              payload: sellerFinalPayload,
              dealId: id,
            })

            if (callback) {
              callback()
            }
            break
          default:
            break
        }
      } catch (e) {
        console.log(e)
      }
    }, DEBOUNCE_TIMEOUT),
    []
  )

  const validateAllForms = async () => {
    // Default scroll options
    const scrollOptions = {
      block: "end",
      scrollMode: "always",
      behavior: (actions) => {
        const [{ el, top }] = actions
        el.scrollTop = top - 200
        return "smooth"
      },
    }

    // Check if all form refs exist
    const formConfigs = [
      { ref: PropertyInformationFormRef, name: "Property's information" },
      { ref: ListingAgentFormRef, name: "Listing agent's information" },
      { ref: SellerInformationFormRef, name: "Seller's information" },
      { ref: ListingTermsFormRef, name: "Listing term's information" },
    ]

    try {
      const validationResults = await Promise.all(
        formConfigs.map(async ({ ref, name }) => {
          try {
            await ref.validateFields()
            return { isValid: true, errors: [] }
          } catch (error) {
            return {
              isValid: false,
              errors: error.errorFields.map(field => ({
                formName: name,
                formRef: ref,
                fieldName: field.name.join("."),
                fieldNamePath: field.name,
                errors: field.errors,
                isChecked: false,
              })),
            }
          }
        })
      )

      const allErrors = validationResults.flatMap(result => result.errors).filter(Boolean)

      if (allErrors.length > 0) {
        const groupedErrors = allErrors.reduce((acc, error) => {
          if (!acc[error.formName]) {
            acc[error.formName] = {
              formRef: error.formRef,
              fields: [],
            }
          }

          acc[error.formName].fields.push({
            fieldName: error.fieldName,
            fieldNamePath: error.fieldNamePath,
            isChecked: error.isChecked,
            errors: error.errors,
          })

          return acc
        }, {})

        // Convert to array format
        const formattedErrors = Object.entries(groupedErrors).map(([formName, item]) => ({
          formName,
          formRef: item.formRef,
          fields: item.fields,
        }))

        // Create and scroll to error message
        // const errorMessage = allErrors
        //   .map(error => `${error.formName}: ${error.fieldName} - ${error.errors.join(", ")}`)
        //   .join("\n")
        // if (allErrors[0]?.fieldName) {
        //   formConfigs.forEach(({ ref }) => {
        //     ref?.scrollToField(allErrors[0].fieldName, scrollOptions)
        //   })
        // }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return {
          isValid: false,
          errors: formattedErrors,
        }
      }

      console.log("All forms validated successfully")
      return {
        isValid: true,
        errors: [],
      }
    } catch (error) {
      console.log("Form validation failed:", error)
      return {
        isValid: false,
        errors: [],
      }
    }
  }

  const handleFinalSubmit = async () => {
    togglePropertyFormOrCardView(false)

    const result = await validateAllForms()

    setCoversheetErrorsList(result)

    setTimeout(() => {
      if (ListingAgentFormRef && SellerInformationFormRef && ListingTermsFormRef) {
        const validateAllForms = Promise.all([
          ListingAgentFormRef.validateFields(),
          SellerInformationFormRef.validateFields(),
          ListingTermsFormRef.validateFields(),
        ])
        validateAllForms.then((values) => {
          console.log("All forms validated")
  
          ListingAgentFormRef.submit()
          SellerInformationFormRef.submit()
          ListingTermsFormRef.submit()
  
          const propertyPayload = {
            ...currentPropertyInformation,
            draft: "N",
            current_listing_step: "property_information_update",
          }
  
          const agentsPayload = {
            agents: [...currentListingAgentInformation],
            draft: "N",
            current_listing_step: "agent_info",
          }
  
          const draftAgentsPayload = {
            agents: [...currentListingAgentInformation],
            draft: "Y",
            current_listing_step: "agent_info",
          }
  
          const sellersPayload = {
            sellers: [...currentSellersInformation],
            draft: "N",
            current_listing_step: "seller_info",
          }
  
          const draftSellersPayload = {
            sellers: [...currentSellersInformation],
            draft: "Y",
            current_listing_step: "seller_info",
          }
  
          const listingTermsPayload = {
            ...currentListingTerms,
            draft: "N",
            current_listing_step: "source_time",
          }
  
          const {
            agents,
          } = agentsPayload || {}
  
          const {
            sellers,
          } = sellersPayload || {}
          console.log("sellersPayload.sellers",sellersPayload.sellers)
          sellersPayload.sellers = [...sellers]
          if (sellers && sellers.length > 0) {
            sellersPayload.sellers = [...sellers.map((item, index) => {
              if (index === 0) {
                const curr = {}
                curr.is_primary = 1
                curr.firstname = item.firstname?.length ? item.firstname : ""
                curr.lastname = item.lastname?.length ? item.lastname : ""
                curr.email = item.email?.length ? item.email : ""
                curr.phone = item.phone?.length ? item.phone : ""
                curr.city = item.city?.length ? item.city : ""
                curr.state = item.state?.length ? item.state : ""
                curr.zipcode = item.zipcode?.length ? item.zipcode : ""
                curr.address = item.address?.length ? item.address : ""
                curr.type_of_ownership = item.type_of_ownership?.length ? item.type_of_ownership : ""
                curr.source = item.source?.length ? item.source : ""
                curr.landline = item.landline?.length ? item.landline : ""
                curr.seller_image_url = item.seller_image_url?.length ? item.seller_image_url : ""
                curr.ownership_name = item.ownership_name?.length ? item.ownership_name : ""
                curr.apt = item.apt?.length ? item.apt : ""
                return curr
              }
              const curr = {}
  
              curr.is_primary = 0
              curr.firstname = item.firstname?.length ? item.firstname : ""
              curr.lastname = item.lastname?.length ? item.lastname : ""
              curr.email = item.email?.length ? item.email : ""
              curr.phone = item.phone?.length ? item.phone : ""
              curr.city = item.city?.length ? item.city : ""
              curr.state = item.state?.length ? item.state : ""
              curr.zipcode = item.zipcode?.length ? item.zipcode : ""
              curr.address = item.address?.length ? item.address : ""
              curr.type_of_ownership = item.type_of_ownership?.length ? item.type_of_ownership : ""
              curr.source = item.source?.length ? item.source : ""
              curr.landline = item.landline?.length ? item.landline : ""
              curr.seller_image_url = item.seller_image_url?.length ? item.seller_image_url : ""
              curr.ownership_name = item.ownership_name?.length ? item.ownership_name : ""
              curr.apt = item.apt?.length ? item.apt : ""
              return curr
            })]
          }

            if (sellers && sellers.length > 0) {
            draftSellersPayload.sellers = [...sellers.map((item, index) => {
              if (index === 0) {
                const curr = {}
                curr.is_primary = 1
                curr.firstname = item.firstname?.length ? item.firstname : ""
                curr.lastname = item.lastname?.length ? item.lastname : ""
                curr.email = item.email?.length ? item.email : ""
                curr.phone = item.phone?.length ? item.phone : ""
                curr.city = item.city?.length ? item.city : ""
                curr.state = item.state?.length ? item.state : ""
                curr.zipcode = item.zipcode?.length ? item.zipcode : ""
                curr.address = item.address?.length ? item.address : ""
                curr.type_of_ownership = item.type_of_ownership?.length ? item.type_of_ownership : ""
                curr.source = item.source?.length ? item.source : ""
                curr.landline = item.landline?.length ? item.landline : ""
                curr.seller_image_url = item.seller_image_url?.length ? item.seller_image_url : ""
                curr.ownership_name = item.ownership_name?.length ? item.ownership_name : ""
                curr.apt = item.apt?.length ? item.apt : ""
                return curr
              }
              const curr = {}
  
              curr.is_primary = 0
              curr.firstname = item.firstname?.length ? item.firstname : ""
              curr.lastname = item.lastname?.length ? item.lastname : ""
              curr.email = item.email?.length ? item.email : ""
              curr.phone = item.phone?.length ? item.phone : ""
              curr.city = item.city?.length ? item.city : ""
              curr.state = item.state?.length ? item.state : ""
              curr.zipcode = item.zipcode?.length ? item.zipcode : ""
              curr.address = item.address?.length ? item.address : ""
              curr.type_of_ownership = item.type_of_ownership?.length ? item.type_of_ownership : ""
              curr.source = item.source?.length ? item.source : ""
              curr.landline = item.landline?.length ? item.landline : ""
              curr.seller_image_url = item.seller_image_url?.length ? item.seller_image_url : ""
              curr.ownership_name = item.ownership_name?.length ? item.ownership_name : ""
              curr.apt = item.apt?.length ? item.apt : ""
              return curr
            })]
          }
  
          if (agents && agents.length > 0) {
            agentsPayload.agents = [...agents.map((item, index) => ({
              name: item.name?.length ? item.name : "",
              agent_id: index > 0 ? item.agent_id && parseInt(item.agent_id, 10) || 0 : (parseInt(item.agent_id, 10) || parseInt(listingData.editingAgentId, 10)),
              //agent_id: parseInt(item.agent_id, 10) || parseInt(listingData.editingAgentId, 10),
              email: item.email?.length ? item.email : "",
              phone: item.phone?.length ? item.phone : "",
              landline: item.landline?.length ? item.landline : "",
              listing_brokerage: item.listing_brokerage?.length ? item.listing_brokerage : "",
              brokerage_license_number: item.brokerage_license_number?.length ? item.brokerage_license_number : "",
              brokerage_address: item.brokerage_address?.length ? item.brokerage_address : "",
              apt: item.apt?.length ? item.apt : "",
              city: item.city?.length ? item.city : "",
              zipcode: item.zipcode?.length ? item.zipcode : "",
              agent_license_number: item.agent_license_number?.length ? item.agent_license_number : "",
              is_primary: index > 0 ? 0 : 1,
              state: item.state?.length ? item.state : "",
            }))]
          }

               if (agents && agents.length > 0) {
            draftAgentsPayload.agents = [...agents.map((item, index) => ({
              name: item.name?.length ? item.name : "",
              agent_id: index > 0 ? item.agent_id && parseInt(item.agent_id, 10) || 0 : (parseInt(item.agent_id, 10) || parseInt(listingData.editingAgentId, 10)),
              //agent_id: parseInt(item.agent_id, 10) || parseInt(listingData.editingAgentId, 10),
              email: item.email?.length ? item.email : "",
              phone: item.phone?.length ? item.phone : "",
              landline: item.landline?.length ? item.landline : "",
              listing_brokerage: item.listing_brokerage?.length ? item.listing_brokerage : "",
              brokerage_license_number: item.brokerage_license_number?.length ? item.brokerage_license_number : "",
              brokerage_address: item.brokerage_address?.length ? item.brokerage_address : "",
              apt: item.apt?.length ? item.apt : "",
              city: item.city?.length ? item.city : "",
              zipcode: item.zipcode?.length ? item.zipcode : "",
              agent_license_number: item.agent_license_number?.length ? item.agent_license_number : "",
              is_primary: index > 0 ? 0 : 1,
              state: item.state?.length ? item.state : "",
            }))]
          }
          updateListingAgentInformation({
            dealId,
            payload: draftAgentsPayload,
            callback: () => {
              updateSellerInformationForListing({
                dealId,
                payload: draftSellersPayload,
                callback: () => {
                  validateAndSubmit({
                    dealId,
                    propertyPayload,
                    agentsPayload,
                    sellersPayload,
                    listingTermsPayload,
                    callback: () => {
                      updatePropertyInformation({
                        dealId,
                        payload: propertyPayload,
                        callback: () => {
                          updateListingAgentInformation({
                            dealId,
                            payload: agentsPayload,
                            callback: () => {
                              updateSellerInformationForListing({
                                dealId,
                                payload: sellersPayload,
                                callback: () => {
                                  updateListingTermsForListing({
                                    dealId,
                                    payload: listingTermsPayload,
                                    callback: () => {
                                      setListingCardFromCRM(false)
                                    },
                                  })
                                },
                              })
                            },
                          })
                        },
                      })
                    },
                  })
                },
              })
            },
          })
        }).catch((error) => {
          // createToast("Please check and fill in all the mandatory fields.", "error")
          console.log(error)
          if (error?.errorFields?.length > 0 && error?.errorFields?.[0]?.name) {
            // old logic 
            // const scrollOptions = {
            //   block: "end",
            //   scrollMode: "always",
            //   behavior: (actions) => {
            //     const [{ el, top }] = actions
            //     el.scrollTop = top + 200
  
            //     return "smooth"
            //   },
            // }
  
            // ListingAgentFormRef.scrollToField(error.errorFields[0].name, scrollOptions)
            // SellerInformationFormRef.scrollToField(error.errorFields[0].name, scrollOptions)
            // ListingTermsFormRef.scrollToField(error.errorFields[0].name, scrollOptions)

            // new logic
            // window.scrollTo({
            //   top: 0,
            //   behavior: "smooth",
            // });
          }
        })
      }
    },2000)
  }

  const handleCreateListing = async (values) => {

    const result = await validateAllForms()
    setCoversheetErrorsList(result)

    if (!dealId) {
      const propertyDetails = {
        ...values,
      }

      const apiPayload = {
        ...values,
        mls_unique_id: currentPropertyInformation?.mls_unique_id || null,
        agent_id: parseInt(listingData.editingAgentId, 10),
        current_listing_step: "initiate_property_info",
      }
      setPropertyInformation(propertyDetails)
      initiateListing({
        payload: apiPayload,
        history,
        currentDetails: listingData,
        propertyDetails,
        callback: () => {
          togglePropertyFormOrCardView(true)
          handleCollapseChange(["property_information"])
        },
      })
    } else {
      /**do nothing*/
    }
  }

  const handleCreateListingAfterSelectingMLS = () => {
    const payload = { ...currentPropertyInformation }
    const apiPayload = {
      ...currentPropertyInformation,
      current_listing_step: "initiate_property_info",
    }
    setPropertyInformation(payload)
    initiateListing({
      payload: apiPayload,
      history,
      currentDetails: listingData,
      callback: () => {
        togglePropertyFormOrCardView(true)
        handleCollapseChange(["property_information"])
      },
    })
  }

  const getPrefilledData = () => {
    return currentPropertyInformation
  }
  const getListingAgentData = () => {
    return currentListingAgentInformation
  }
  const getSellersData = () => currentSellersInformation
  const getListingTermsData = () => currentListingTerms

  const getValidatorName = (value) => {
    if (value === "property_information") return "buyers_information_mobile"
    if (value === "property_information") return "property_information_mobile"
    if (value === "deal_terms") return "deal_information_mobile"
  }

  const handleMLSChange = (mlsData) => {
    const {
      BathroomsTotalInteger: bathrooms,
      BedroomsTotal: bedrooms,
      address,
      UnparsedAddress,
      PostalCode: zipcode,
      typedMLSId: mls_id,
      Media,
      ListPrice: price,
      City: city,
      StateOrProvince,
      LivingArea: living_area,
      LivingAreaUnits,
      ListAgentEmail: email,
      ListAgentFirstName,
      ListAgentLastName,
      ListOfficeName: listing_brokerage,
      ListOfficePhone,
      ListAgentPreferredPhone,
      ListAgentMlsId: agent_license_number,
      ListOfficeMlsId: brokerage_license_number,
      Id: mls_unique_id,
      CountyOrParish: county
    } = mlsData || {}

    const phone = ListOfficePhone || ListAgentPreferredPhone || null
    // const street = `${address}, ${StateOrProvince}, ${zipcode}`
    const street = `${UnparsedAddress}`
    const state = getStateFromAbbrevition(StateOrProvince)
    let areaUnit = "Sq.ft"

    if (LivingAreaUnits) {
      areaUnit = LivingAreaUnits === "Square Feet"
        ? "Sq.ft" : LivingAreaUnits.indexOf("Meter") > -1
      || LivingAreaUnits.indexOf("Meters") > -1 ? "Sq.mt" : "Acres"
    }

    const payload = {
      ...currentPropertyInformation,
      bedrooms,
      bathrooms,
      mls_id,
      zipcode,
      street,
      city,
      price,
      state,
      area_unit: areaUnit,
      image_urls: getImages(Media),
      area: living_area,
      mls_unique_id,
      county,
    }

    setPropertyInformation(payload)

    // const listingAgentPayload = {
    //   ...LISTING_AGENT_INFORMATION,
    //   email,
    //   phone,
    //   listing_brokerage,
    //   agent_license_number,
    //   brokerage_license_number,
    //   agent_id: 0,
    //   name: `${ListAgentFirstName}${ListAgentLastName ? ` ${ListAgentLastName}` : ""}`,
    // }

    // setListingAgentInformation(() => {
    //   const newArray = [{ ...listingAgentPayload }]
    //   return newArray
    // })

    const updatePayload = {
      agents: [],
      draft: "Y",
      current_listing_step: "agent_info",
    }

    //updatePayload.agents = [{ ...listingAgentPayload }]

    if (dealId) {
      payload.draft = "Y"
      payload.current_listing_step = "property_information_update"
      updatePropertyInformationPostMLSUpdate({
        payload,
        dealId,
      })
      /**NEED TO HANDLE WHEN MLS PROPERTY CHANGES AND STATE CHANGES*/
      // updateListingAgentInformationPostMlsSelection({
      //   payload: updatePayload,
      //   dealId,
      // })
      fetchAgentInformationForCoverSheet({
        agentId: listingData.editingAgentId,
        state,
        dealId,
      })
    }

    propertyInformationReference.current.setFieldsValue({
      ...payload
    })
    //togglePropertyFormOrCardView(true)
  }

  const handleSellerChange = (form, content) => {
    try {
      let coSellers = null
      console.log("content",content)
      if (content.co_sellers && content.co_sellers.length > 0) {
        coSellers = [...content.co_sellers]
        coSellers.forEach((item, index) => {
          const currentSeller = item
          const currentIndex = index + 1
          if (item && item !== undefined) {
            currentSeller.firstname = item.firstname?.length ? item.firstname : ""
            currentSeller.lastname = item.lastname?.length ? item.lastname : ""
            currentSeller.email = item.email?.length ? item.email : ""
            currentSeller.landline = item.phone?.length ? item.phone : ""
            currentSeller.phone = item.phone?.length ? item.phone : ""
            currentSeller.address = item.address?.length ? item.address : ""
            currentSeller.city = item.city?.length ? item.city : ""
            currentSeller.zipcode = item.zipcode?.length ? item.zipcode : ""
            currentSeller.state = item.state?.length ? item.state : ""
            currentSeller.type_of_ownership = item.type_of_ownership?.length ? item.type_of_ownership : ""
            currentSeller.seller_image_url = currentSellersInformation[currentIndex]?.seller_image_url?.length ? currentSellersInformation[currentIndex]?.seller_image_url : ""
            currentSeller.is_primary = 0
            currentSeller.source = item.source?.length ? item.source : ""
            currentSeller.ownership_name = item.ownership_name?.length ? item.ownership_name : ""
            currentSeller.apt = item.apt?.length ? item.apt : ""
          } else {
            console.log("ERROR IN CLIENT", item)
          }
        })
      }

      const payload = {
        sellers: [],
        draft: "Y",
        current_listing_step: "seller_info",
      }

      const primarySeller = {
        ...content,
        landline: content.phone,
        seller_image_url: currentSellersInformation[0]?.seller_image_url || "",
      }

      delete primarySeller.co_sellers

      payload.sellers.push(primarySeller)
      if(primarySeller){
        if(primarySeller.firstname && primarySeller.lastname && primarySeller.email && primarySeller.source){
          payload.draft = "Y"
        }
      }
      if (coSellers) {
        const coSellersList = coSellers && coSellers.length > 0 
          ? coSellers.filter(item => item && item !== undefined && item.firstname && item) : []
        if (coSellersList && coSellersList.length > 0) {
          payload.sellers = [...payload.sellers, ...coSellersList]
        }
      } else {
        payload.sellers = [...payload.sellers]
      }
      console.log("payload123",payload)
      saveFormDataSellerInfo(payload, "seller_info", dealId, () => {
        setSellerInformation([...payload.sellers])
      })
    } catch (e) {
      console.log("error", e)
    }
  }

  const handleListingAgentInformationChange = (form, content) => {
    let coListingAgentsTemp = null
    if (content.co_listing_agents && content.co_listing_agents.length > 0) {
      coListingAgentsTemp = [...content.co_listing_agents]

      coListingAgentsTemp.forEach((item) => {
        const currentAgent = item
        currentAgent.is_primary = 0
        currentAgent.agent_id = parseInt(item.agent_id, 10) || 0
        currentAgent.name = `${currentAgent.agent_first_name || ""}${currentAgent.agent_last_name ? ` ${currentAgent.agent_last_name}` : ""}`
        currentAgent.agent_first_name = currentAgent.agent_first_name || ""
        currentAgent.agent_last_name = currentAgent.agent_last_name || ""
        currentAgent.agent_license_number = item.agent_license_number || ""
        currentAgent.agent_type = content.agent_type || ""
        currentAgent.apt = item.apt || ""
        currentAgent.brokerage_address = item.brokerage_address || ""
        currentAgent.brokerage_license_number = item.brokerage_license_number || ""
        currentAgent.city = item.city || ""
        currentAgent.email = item.email || ""
        currentAgent.phone = item.phone || ""
        currentAgent.state = item.state || ""
        currentAgent.zipcode = item.zipcode || ""
        currentAgent.brokerage = item.brokerage || ""
      })
    }

    const payload = {
      agents: [],
      draft: "Y",
      current_listing_step: "agent_info",
    }

    const primaryAgent = {
      ...content,
      name: `${content.agent_first_name} ${content.agent_last_name}`,
      agent_id: parseInt(content.agent_id, 10) || parseInt(listingData.editingAgentId, 10), 
      landline: content.phone || "",
      apt: content.apt || "",
      agent_license_number : content.agent_license_number || "",
      brokerage_address : content.brokerage_address || "",
      brokerage_license_number : content.brokerage_license_number || "",
      city : content.city || "",
      email : content.email || "",
      phone : content.phone || "",
      state : content.state || "",
      zipcode : content.zipcode || "",
      brokerage : content.brokerage || "",
      is_primary : 1,
    }

    delete primaryAgent.co_listing_agents
    delete primaryAgent.agent_first_name
    delete primaryAgent.agent_last_name

    // Add primary agent first
    payload.agents.push(primaryAgent)

    // Add co-listing agents if they exist and are valid
    if (coListingAgentsTemp) {
      const coListingAgents = coListingAgentsTemp.filter(item => item && item.name && item.email);
      if (coListingAgents && coListingAgents.length > 0) {
        // Filter out any duplicates based on email
        const uniqueCoAgents = coListingAgents.filter((agent, index, self) =>
          index === self.findIndex((a) => a.email === agent.email)
        );
        payload.agents = [...payload.agents, ...uniqueCoAgents]
      }
    }

    setListingAgentInformation([...payload.agents])
    saveFormDataAgentUpdate(payload, "agent_info", dealId)
  }

  const handlePropertyInformationChange = (form, content) => {
    if (!dealId) return
    const payload = { ...currentPropertyInformation, ...content }
    setPropertyInformation(payload)
    const finalPayload = {
      ...payload,
      mls_unique_id: null,
      draft: "Y",
      current_listing_step: "property_information_update",
    }
    saveFormData(finalPayload, "property_information_update", dealId)
  }

  const handleCollapseChange = (panel) => {
    handleCollapseValues(panel)
  }

  const handleImageUploadSupport = (url, index) => {
    const payload = {
      sellers: [...currentSellersInformation],
      draft: "Y",
      current_listing_step: "seller_info",
    }

    if (payload.sellers.length === 0) {
      const primarySeller = {
        ...SELLER_INFORMATION,
        seller_image_url: url,
      }
      setSellerInformation([primarySeller])
      payload.sellers = [primarySeller]
      saveFormData(payload, "seller_info", dealId)
    } else if (index === payload.sellers.length) {
      const newSeller = {
        ...SELLER_INFORMATION,
        is_primary: 0,
        seller_image_url: url,
      }

      setSellerInformation([...payload.sellers, newSeller])
      payload.sellers = [...payload.sellers, newSeller]
      saveFormData(payload, "seller_info", dealId)
    } else {
      setSellerInformation((item) => {
        const tempArray = [...item]
        tempArray[index].seller_image_url = url
        return [...tempArray]
      })
      payload.sellers[index].seller_image_url = url
      saveFormData(payload, "seller_info", dealId)
    }
  }

  const handleImageDeleteSupport = (index) => {
    const payload = {
      sellers: [...currentSellersInformation],
      draft: "Y",
      current_listing_step: "seller_info",
    }

    setSellerInformation((item) => {
      const tempArray = [...item]
      tempArray[index].seller_image_url = ""
      return [...tempArray]
    })

    payload.sellers[index].seller_image_url = ""

    saveFormData(payload, "seller_info", dealId)
  }

  const handleListingTermsFormChange = (form, content) => {
    if (!dealId || dealId === null) return
    const payload = { ...currentListingTerms, ...content }

    if (payload.listing_contract_date) {
      payload.listing_contract_date = payload.listing_contract_date.unix()
    }
    if (payload.listing_on_market_date) {
      payload.listing_on_market_date = payload.listing_on_market_date.unix()
    }
    if (payload.listing_expiration_date) {
      payload.listing_expiration_date = payload.listing_expiration_date.unix()
    }

    payload.property_marked_public = payload.property_marked_public === "0"
    || !payload.property_marked_public
    || payload.property_marked_public === undefined
    || payload.property_marked_public === 0 ? 0 : 1

    setListingTerms({ ...payload })
    const finalPayload = {
      ...payload,
      draft: "Y",
      current_listing_step: "source_time",
    }
    
    updateListingTermsForListing({
      dealId,
      payload: finalPayload,
    })
    //saveFormData(finalPayload, "source_time", dealId)
  }

  const isCurrentlySaving = false

  const handleCoverSheetModification = () => {
    //close all forms
    handleCollapseValues([])
    if (dealId) {
      const propertyPayload = {
        ...PROPERTY_INFORMATION,
      }

      propertyPayload.agent_id = parseInt(listingData.editingAgentId, 10)

      processDeletionOfFieldsInCoversheet({
        dealId,
        propertyPayload,
        listingAgentPayload: [],
        callback: () => {
          setPropertyInformation({ ...propertyPayload })
          setListingAgentInformation([])
          if (ListingAgentFormRef) {
            ListingAgentFormRef.resetFields()
          }
          togglePropertyFormOrCardView(false)

          setTimeout(() => {
            handleCollapseValues(["property_information"])
          }, 500)
        },
      })
    } else {
      setPropertyInformation({ ...PROPERTY_INFORMATION })
      togglePropertyFormOrCardView(false)
      setTimeout(() => {
        handleCollapseValues(["property_information"])
      }, 500)
    }
    toggleDeleteFieldsConfirmationInCoverSheet(false)
  }

  return (
    <>
      <Wrap>
        <StyledCollapse
          expandIconPosition="start"
          defaultActiveKey={["property_information"]}
          activeKey={listingCollapseValues}
          onChange={handleCollapseChange}
          isPropInfo={true}
        >
          <StyledPanel
            header="Property Information"
            key="property_information"
            extra={<ValidateNumber value={propertyDataErrorCount} />}
          >
            {isCardViewToggled ? (
              <>
                <ListingCard
                  details={currentPropertyInformation}
                  disableForm={coversheetDisabled}
                  handleCoverSheetModification={handleCoverSheetModification}
                  handleDelete={toggleDeleteFieldsConfirmationInCoverSheet}
                  handleEdit={() => {
                    togglePropertyFormOrCardView(false)
                  }}
                />
                {!dealId && (
                  <FloatingCTA
                    customText="Create Listing"
                    disabled={false}
                    onClick={handleCreateListingAfterSelectingMLS}
                    loading={false}
                  />
                )}
              </>
            ) : (
              <PropertyInformationForm
                key="prop_236"
                prefilledData={getPrefilledData()}
                // handleSubmit={handlePropertyFormSubmit}
                reduxState={currentState}
                onValueChange={handlePropertyInformationChange}
                saving={initating}
                handleSubmit={handleCreateListing}
                handleCoverSheetModification={handleCoverSheetModification}
                // disableForm={isContractPhase || !isFormEditable}
                validationReport={propertyDataErrorCount}
                handleInitPropertyChange={(locationObject) => {
                  const payload = {
                    ...currentPropertyInformation,
                    ...locationObject,
                  }
                  setPropertyInformation(payload)
                  const finalPayload = {
                    ...payload,
                    draft: "Y",
                    mls_unique_id: null,
                    current_listing_step: "property_information_update",
                  }
                  setCurrentListingsState(payload.state.toLowerCase())
                  if (dealId) {
                    saveFormData(finalPayload, "property_information_update", dealId)
                  }
                }}
                handleMLSChange={handleMLSChange}
                disableForm={coversheetDisabled}
                form={PropertyInformationFormRef}
                formRef={propertyInformationReference || null}
                onFinishFailed={async ({ values, errorFields, outOfDate }) => {
                  // createToast("Please fill in all the mandatory fields in property information.", "error")
                  const result = await validateAllForms()
                  setCoversheetErrorsList(result)
                }}
              />
            )}
          </StyledPanel>
          <StyledPanel
            header="Listing Agent Information"
            key="listing_agent_information"
            disabled={!isListingAgentData}
            extra={<ValidateNumber value={agentsDataErrorCount} />}
          > 
            <ListingAgentForm
              form={ListingAgentFormRef}
              uniqueIdentifier={uuid.v4()}
              prefilledData={getListingAgentData()}
              // handleSubmit={handlePropertyFormSubmit}
              state
              onValueChange={handleListingAgentInformationChange}
              saving={isCurrentlySaving}
              handleSubmit={() => {}}
              // disableForm={isContractPhase || !isFormEditable}
              validationReport={agentsDataErrorCount}
              handleInitPropertyChange={(locationObject) => {
                if (!dealId) return
                const currentData = getListingAgentData()
                const primaryAgent = currentData && currentData.length > 0 && currentData[0] || null
                //if agent present
                if (primaryAgent) {
                  const agentPayload = {
                    ...primaryAgent,
                    ...locationObject,
                  }

                  let agents = []

                  if (currentData.length > 0 && currentData.length === 1) {
                    agents[0] = agentPayload
                  } else {
                    agents = [agentPayload, currentData.slice(1, currentData.length)]
                  }

                  const payload = {
                    agents,
                    draft: "Y",
                    current_listing_step: "agent_info",
                  }
                  saveFormData(payload, "agent_info", dealId)
                } else {
                  const agentPayload = {
                    ...LISTING_AGENT_INFORMATION,
                    ...locationObject,
                  }
                  const payload = {
                    agents: [agentPayload],
                    draft: "Y",
                    current_listing_step: "agent_info",
                  }
                  saveFormData(payload, "agent_info", dealId)
                }
              }}
              formName="listing_agent_form"
              disableForm={coversheetDisabled}
              //formRef={listingAgentFormRef}
              onFinishFailed={({ values, errorFields, outOfDate }) => {
                createToast("Please fill in all the mandatory fields in listing agent information.", "error")
              }}
            />
          </StyledPanel>
          <StyledPanel
            header="Seller Information"
            key="seller_information"
            disabled={!dealId}
            extra={<ValidateNumber value={sellersDataErrorCount} />}
          >
            <SellerInformationForm
              form={SellerInformationFormRef}
              handleImageDeleteSupport={handleImageDeleteSupport}
              handleImageUploadSupport={handleImageUploadSupport}
              uniqueIdentifier={uuid.v4()}
              prefilledData={getSellersData()}
              state
              onValueChange={handleSellerChange}
              saving={isCurrentlySaving}
              handleSubmit={() => {}}
              validationReport={sellersDataErrorCount}
              handleInitPropertyChange={(locationObject) => {
                if (!dealId) return
                const currentData = getSellersData()
                const primarySeller = currentData && currentData.length > 0 && currentData[0] || null
                //if agent present
                if (primarySeller) {
                  const sellerPayload = {
                    ...primarySeller,
                    ...locationObject,
                  }

                  let sellers = []

                  if (currentData.length > 0 && currentData.length === 1) {
                    sellers[0] = sellerPayload
                  } else {
                    sellers = [sellerPayload, currentData.slice(1, currentData.length)]
                  }

                  const payload = {
                    sellers,
                    draft: "Y",
                    current_listing_step: "seller_info",
                  }
                  saveFormData(payload, "seller_info", dealId, () => {
                    setSellerInformation(payload.sellers)
                  })
                } else {
                  const sellerPayload = {
                    ...SELLER_INFORMATION,
                    ...locationObject,
                  }
                  const payload = {
                    sellers: [sellerPayload],
                    draft: "Y",
                    current_listing_step: "seller_info",
                  }
                  saveFormData(payload, "seller_info", dealId, () => {
                    setSellerInformation(payload.sellers)
                  })
                }
              }}
              formName="seller_agent_form"
              formRef={listingAgentFormRef || null}
              disableForm={coversheetDisabled}
              editingAgentId={listingData?.editingAgentId}
              onFinishFailed={({ values, errorFields, outOfDate }) => {
                createToast("Please fill in all the mandatory fields in seller information.", "error")
              }}
            />
          </StyledPanel>

          {/**LISTING TERMS*/}
          <StyledPanel
            header="Listing Terms"
            key="listing_terms"
            disabled={!dealId}
            extra={<ValidateNumber value={listingTermsErrorCount} />}
          >
            <ListingTermsForm
              form={ListingTermsFormRef}
              uniqueIdentifier={uuid.v4()}
              prefilledData={getListingTermsData()}
              onValueChange={handleListingTermsFormChange}
              saving={isCurrentlySaving}
              handleSubmit={() => {}}
              validationReport={listingTermsErrorCount}
              formName="listing_terms_form"
              state={currentPropertyInformation ? currentPropertyInformation.state : "California"}
              disableForm={coversheetDisabled}
              onFinishFailed={({ values, errorFields, outOfDate }) => {
                createToast("Please fill in all the mandatory fields in listing terms.", "error")
              }}
            />
          </StyledPanel>
        </StyledCollapse>
      </Wrap>
      {dealId && (
        <FloatingCTA
          customText={(updatingAgent || updatingProperty || updatingSeller || updatingListingTerms || validating) ? "Please Wait" : "Save & Continue"}
          disabled={coversheetDisabled}
          onClick={handleFinalSubmit}
          loading={(updatingAgent || updatingProperty || updatingSeller || updatingListingTerms || validating)}
        />
      )}
      {isDeleteCoversheetModalOpen && (
        <ListingModal
          height="232px"
          toClose={() => {
            toggleDeleteFieldsConfirmationInCoverSheet(false)
          }}
        >
          <DeleteInformationFromCoversheet
            handleDelete={handleCoverSheetModification}
            toClose={() => {
              toggleDeleteFieldsConfirmationInCoverSheet(false)
            }}
          />
        </ListingModal>
      )}
    </>
  )
}
export default OffersV2(Listings(withRouter(CollapsibleForm)))

