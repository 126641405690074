import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { authCheck } from "services/auth"
import * as OffersV2Actions from "./actions"
import * as OffersV1Actions from "container/TechAssets/actions"

const mapStateToProps = (state) => {
  const { offersV2, techAssetsRarDashboard } = state
  const isLogged = authCheck()
  return {
    isLogged,
    ...techAssetsRarDashboard,
    ...offersV2,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      /**v1 Offers*/
      getOffers: OffersV1Actions.getAllOffersAction.request,


      /**v2 Offers */
      toggleActiveTab: OffersV2Actions.toggleActiveTabAction.call,
      toggleSideNavTab: OffersV2Actions.toggleSideNavAction.call,
      toggleCoverSheet: OffersV2Actions.toggleCoverSheetAction.call,
      toggleMLSTab: OffersV2Actions.toggleMLSTabAction.call,
      toggleLibraryTab: OffersV2Actions.toggleLibraryTabAction.call,
      setSearchText: OffersV2Actions.setSearchTextAction.call,
      toggleBasicOfferDetailsModal: OffersV2Actions.toggleBasicOfferDetailsAction.call,
      addNewCoAgentForm: OffersV2Actions.addNewCoAgentFormAction.call,
      updateBuyerInformationObject: OffersV2Actions.updateBuyerInformationObjectAction.call,
      addBuyerAgentForm: OffersV2Actions.addBuyerAgentFormAction.call,
      addLenderAgentForm: OffersV2Actions.addLenderAgentFormAction.call,
      addVendorAgentForm: OffersV2Actions.addVendorAgentFormAction.call,
      updatePropertyInformationObject: OffersV2Actions.updatePropertyInformationObjectAction.call,
      updateDealTermsObject: OffersV2Actions.updateDealTermsObjectAction.call,
      addNewListingAgentToggle: OffersV2Actions.addNewListingAgentToggleAction.call,
      addNewSellerAgentToggle: OffersV2Actions.addNewSellerAgentToggleAction.call,
      toggleCreateEnvelopeModal: OffersV2Actions.toggleCreateEnvelopeModalAction.call,
      setCurrentStep: OffersV2Actions.setCurrentStepAction.call,
      updateOffersDocumentsObject: OffersV2Actions.updateOffersDocumentsObjectAction.call,
      updateUploadedDocsObject: OffersV2Actions.updateUploadedDocsObjectAction.call,
      fetchLibraryUploads: OffersV2Actions.fetchLibraryUploadedFilesAction.request,
      fetchActiveEnvelopesFiles: OffersV2Actions.fetchActiveEnvelopesFilesAction.request,
      fetchBuyerOffers: OffersV2Actions.fetchBuyerOffersAction.request,
      fetchInfoBasedOnMLSId: OffersV2Actions.fetchInfoBasedOnMLSIdAction.request,
      fetchInfoBasedOnPropertyAddress: OffersV2Actions.fetchInfoBasedOnPropertyAddressAction.request,
      createOfferFromPropertyType: OffersV2Actions.createOfferFromPropertyTypeAction.request,
      setMLSData: OffersV2Actions.setMLSDataAction.call,
      getOfferStatusMappings: OffersV2Actions.getOfferStatusMappingsAction.request,
      updateBuyerInformationTab: OffersV2Actions.updateBuyerInformationTabAction.request,
      updateLenderInformation: OffersV2Actions.updateLenderInformationAction.request,
      updateVendorInformation: OffersV2Actions.updateVendorInformationAction.request,
      updatePropertyInformationTab: OffersV2Actions.updatePropertyInformationTabAction.request,
      updateDealTermsInformationTab: OffersV2Actions.updateDealTermsInformationTabAction.request,
      fetchDealTermsInformation: OffersV2Actions.fetchDealTermsInformationAction.request,
      fetchPropertyInformation: OffersV2Actions.fetchPropertyInformationAction.request,
      fetchVendorInfo: OffersV2Actions.fetchVendorInfoAction.request,
      fetchValidateCoverSheet: OffersV2Actions.fetchValidateCoverSheetAction.request,
      updateOfferPurchaseType: OffersV2Actions.updateOfferPurchaseTypeAction.request,
      setStateForOffer: OffersV2Actions.setStateForOfferAction.call,
      fetchOfferDocuments: OffersV2Actions.fetchOfferDocumentsAction.request,
      updateOfferDocsList: OffersV2Actions.updateOfferDocsListAction.call,
      updateRecommendedListOfDocs: OffersV2Actions.updateRecommendedListOfDocsAction.request,
      fetchListingDocuments: OffersV2Actions.fetchListingDocumentsAction.request,
      updateListingOfferDocs: OffersV2Actions.updateListingOfferDocsAction.call,
      updateLibraryUploads: OffersV2Actions.updateLibraryUploadsAction.request,
      deleteLibraryUploadedFile: OffersV2Actions.deleteLibraryUploadedFileAction.request,
      deleteOffer: OffersV2Actions.deleteOfferAction.request,
      fetchOfferRelatedDocuments: OffersV2Actions.fetchOfferRelatedDocumentsAction.request,
      uploadOfferRelated: OffersV2Actions.uploadOfferRelatedAction.request,
      sendReminder: OffersV2Actions.sendReminderAction.request,
      addNoteForStatusUpdate: OffersV2Actions.addNoteForStatusUpdateAction.call,
      updateRecommendedDocsObject: OffersV2Actions.updateRecommendedDocsObjectAction.call,
      createReceiverOfferViewV2: OffersV2Actions.createReceiverOfferViewV2Action.request,
      fetchProofOfFunds: OffersV2Actions.fetchProofOfFundsAction.request,
      fetchPreApproved: OffersV2Actions.fetchPreApprovedAction.request,
      fetchCoverLetters: OffersV2Actions.fetchCoverLettersAction.request,
      fetchOtherAdditionalDocs: OffersV2Actions.fetchOtherAdditionalDocsAction.request,
      updateProofOfFunds: OffersV2Actions.updateProofOfFundsAction.request,
      updatePreApproved: OffersV2Actions.updatePreApprovedAction.request,
      updateCoverLetter: OffersV2Actions.updateCoverLetterAction.request,
      updateOtherDocs: OffersV2Actions.updateOtherDocsAction.request,
      transactionUploadFiles: OffersV2Actions.transactionUploadFilesAction.request,
      deleteTransactionFile: OffersV2Actions.deleteTransactionFileAction.request,
      fetchTransactionFiles: OffersV2Actions.fetchTransactionFilesAction.request,
      updateConfirmationNoteBefore: OffersV2Actions.updateConfirmationNoteBeforeAction.request,
      fetchOfferPackageDocuments: OffersV2Actions.fetchOfferPackageDocumentsAction.request,
      updateOfferPackageDetails: OffersV2Actions.updateOfferPackageDetailsAction.call,
      sendOfferPackageMail: OffersV2Actions.sendOfferPackageMailAction.request,
      getOfferPackage: OffersV2Actions.getOfferPackageAction.request,
      updateCurrentOfferPackageObject: OffersV2Actions.updateCurrentOfferPackageObjectAction.call,
      updateStateForOffers: OffersV2Actions.updateStateForOffersAction.call,
      toggleDocumentTab: OffersV2Actions.toggleDocumentTabAction.call,
      getOfferStatus: OffersV2Actions.getOfferStatusAction.request,
      toggleDocumentProcessing: OffersV2Actions.documentsProcessingAction.call,
      toggleAddPaperWorkModal: OffersV2Actions.toggleAddPaperWorkModalAction.call,
      updateEnvelopesObject: OffersV2Actions.updateEnvelopesObjectAction.call,
      clearValidateCoverSheet: OffersV2Actions.fetchValidateCoverSheetAction.clear,
      clearOffersRelatedData: OffersV2Actions.clearOffersRelatedDataAction.call,
      toggleTCTeamDetailsModal: OffersV2Actions.toggleTCTeamDetailsModalAction.call,
      setCoversheetErrorsList: OffersV2Actions.setCoversheetErrorsListAction.call,
      updateDraftCallPayload: OffersV2Actions.updateDraftCallPayloadAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
