import React from 'react';
import { Modal, Button, Typography, List } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { INTER } from "@fonts"

const { Title, Text } = Typography;

const ModalContent = styled.div`
  font-family: ${INTER};
`;

const IconContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const IconCircle = styled.div`
  background-color: #f0f2ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const StyledIcon = styled(InfoCircleOutlined)`
  font-size: 24px;
  color: #5c6bc0;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 18px !important;
  color: #111827;
  font-family: ${INTER};
  font-weight: 500 !important;
`;

const InfoText = styled(Text)`
  font-size: 14px;
  color: #373758;
  display: block;
  margin-bottom: 24px;
  font-family: ${INTER};
`;

const ListContainer = styled.div`
  background-color: #EFEFF4;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  font-family: ${INTER};
`;

const StyledList = styled(List)`
  .ant-list-item {
    border-bottom: none;
    padding: 4px 0;
    color: #373758;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: ${INTER};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const KeepManualButton = styled(Button)`
  min-width: 200px !important;
  height: 48px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 10px 16px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: ${INTER} !important;
`;

const SwitchButton = styled(Button)`
  min-width: 200px !important;
  height: 48px !important;
  border-radius: 8px !important;
  background-color: #6366f1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 10px 16px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-color: transparent !important;
  font-family: ${INTER} !important;
`;

const CloseIcon = styled.span`
  font-size: 24px;
`;

const AgentSearchSwitch = ({ visible, onCancel, onConfirm }) => {
  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={onCancel}
      width={540}
      closeIcon={<CloseIcon>×</CloseIcon>}
      centered
    >
      <ModalContent>
        <IconContainer>
          <IconCircle>
            <StyledIcon />
          </IconCircle>
        </IconContainer>

        <StyledTitle level={3}>
          Switch to Radius agent search?
        </StyledTitle>
        
        <InfoText>
          You've already entered some information. Would you like to:
        </InfoText>

        <ListContainer>
          <StyledList
            itemLayout="horizontal"
            dataSource={[
              'Search for this agent in the Radius directory',
              'Your entered information will be used to pre-fill the search'
            ]}
            renderItem={item => (
              <List.Item>
                <Text>• {item}</Text>
              </List.Item>
            )}
          />
        </ListContainer>

        <ButtonContainer>
          <KeepManualButton 
            onClick={onCancel}
          >
            Keep manual entry
          </KeepManualButton>
          <SwitchButton 
            type="primary" 
            onClick={onConfirm}
          >
            Switch to search
          </SwitchButton>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};

export default AgentSearchSwitch;
