import React, { useState, useEffect } from "react"
import { Form } from "@ui/antd"
import SearchContainer from "container/Search/SearchContainer"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { normalizePhone } from "services/formUtils"
import {
  PlusCircleOutlined, LoadingOutlined, CloseCircleOutlined,
} from "@ant-design/icons"
import {
  Wrap,
  SelectFieldWrap,
  StyledSpin,
  AutoCompleteFieldWrap,
} from "./styles"

const AgentSearchFormUncontrolled = ({
  placeholder,
  isFormField,
  required = false,
  requiredMessage,
  name,
  onChange,
  getSearchedAgents,
  isFetchingAgents,
  agents,
  text,
  isFromOffer = false,
  hideLabel = false,
  hideClickToAddManually=false
}) => {
  const rules = [{ required, message: requiredMessage?.length ? requiredMessage : "Required" }]

  const [dummyValue, setDummyValue] = useState(null)

  const searchAgentDetails = (searchValue) => {
    const newEntry = {
      label: searchValue,
      value: searchValue,
      id: isFromOffer ? null : 9999999,
      firstname: searchValue,
      lastname: "",
      email: ""
    }
    getSearchedAgents({
      newEntry,
      isInitial: true,
      currentPageNumber: 1,
      queryParams: {
        text_value: searchValue,
        unRegistered: false,
        text_search_params: [
          "email",
          "full_name",
        ],
        isBroker: true,
      },
    })
  }

  const getSelectField = () => {
    switch (isFormField) {
      case true:
        return (
          <Form.Item
            name={name}
            rules={rules}
            label={hideLabel ? null : <LabelComponent text={text} required={required} />}
            required={required}
          >
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={onChange}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents).map(d => {
                let agent;
                if(hideClickToAddManually){
                  agent = {
                    label:  (!d.firstname?.length || !d.lastname?.length) && d.email?.length
                      ? `${d.email}`
                  :d.email?.length ? `${d.firstname} ${d.lastname} (${d.email})` : `${d.firstname} ${d.lastname}`,
                    value: `${d.firstname} ${d.lastname} (${d.email})`,
                    key: d.id,
                  }
                }else{
                  agent = {
                    label: (!d.firstname?.length || !d.lastname?.length) && d.email?.length
                      ? `${d.email}`
                    : d.email?.length ? `${d.firstname} ${d.lastname} (${d.email})` : `${d.firstname} ${d.lastname} - click to add manually`,
                  value: `${d.firstname} ${d.lastname} (${d.email})`,
                  key: d.id,
                  }
                }
                console.log(agent,"agent")
                return agent;
              }).filter(d =>d?.label && d?.label?.trim()?.length>0)}
              placeholder={placeholder}
            />
            {/* <AutoCompleteFieldWrap
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={searchAgentDetails}
          onSelect={((e, option) => changeOtherFieldsValues(e, index, false, option))}
          notFoundContent={
            isFetchingAgents ? (
              <StyledSpin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : null
          }
          options={(agents).map(d => ({
            label: (!d.firstname?.length || !d.lastname?.length) && d.email?.length
              ? `${d.email}`
              : d.email?.length ? `${d.firstname} ${d.lastname} (${d.email})` : `${d.firstname} ${d.lastname}`,
            value: `${d.firstname} ${d.lastname} (${d.email})`,
            key: d.id,
          }))}
          placeholder={placeholder}
          // disabled={disabled}
        /> */}
          </Form.Item>
        )
      default:
        return (
          <Wrap>
            {hideLabel ? null : <LabelComponent text="Agent First Name" />}
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={(value,option) => {
                console.log("value", value,option)
                const agent = agents.find(ag => ag.id === option.key)
                onChange(agent,value,option)
              }}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents).map(d => ({
                label: `${d.firstname} ${d.lastname} (${d.email})`,
                value: `${d.firstname} ${d.lastname} (${d.email})`,
                key: d.id,
              }))}
              placeholder={placeholder}
            />
            {/* <AutoCompleteFieldWrap
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={searchAgentDetails}
          onSelect={((e, option) => changeOtherFieldsValues(e, index, false, option))}
          notFoundContent={
            isFetchingAgents ? (
              <StyledSpin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : null
          }
          options={(agents).map(d => ({
            label: `${d.firstname} ${d.lastname} (${d.email})`,
            value: `${d.firstname} ${d.lastname} (${d.email})`,
            key: d.id,
          }))}
          placeholder={placeholder}
          // disabled={disabled}
        /> */}
          </Wrap>
        )
    }
  }
  return (
    <>
      {getSelectField()}
    </>
  )
}

export default SearchContainer(AgentSearchFormUncontrolled)