import React, { useState, useEffect } from 'react';
import { Checkbox, Input, Button, Typography, Space, Alert } from 'antd';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import AgentSearchFormUncontrolled from './AgentSearchFormUncontrolled';
import AgentSearchManualSwitch from './AgentSearchManualSwitch';
import AgentSearchSwitch from './AgentSearchSwitch';
const { Text, Link } = Typography;

const StyledLabel = styled(Text)`
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #111827 !important;
`;

const StyledLink = styled(Link)`
  color: #5A5FF2 !important;
`;

const StyledText = styled(Text)`
  color: #6B7280 !important;
`;

const SearchContainer = styled.div`
  border: 1px solid #E5E7EB !important;
  border-radius: 8px !important;
  padding: 16px !important;
  margin-bottom: 16px !important;
  width: 100% !important;
  background-color: #FFFFFF !important;

  && .ant-form-item {
    margin-bottom: 16px !important;
    width: 100% !important;
  }

  && .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #EEF2FF !important;
  }
`;

const InfoMessage = styled(Alert)`
  background-color: #EEF2FF !important;
  border: none !important;
  margin-bottom: 16px !important;
  border-radius: 4px !important;
  .ant-alert-icon {
    color: #5A5FF2 !important;
  }
  .ant-alert-message { 
    color: #5A5FF2 !important;
  }
`;

const NoResultsContainer = styled.div`
  padding: 8px 0 !important;
`;

const AgentSearchWithConsent = ({ 
  onAgentPartOfRadius, 
  onAddManually,
  valueOfField,
  placeholder = "Search for an agent...",
  consentLabel = "Is agent part of radius?",
  infoMessage = "Great! Let's quickly find your colleague in our directory.",
  name,
  formName,
  lastNameLabel,
  emailLabel,
  agentId,
  onClear,
  onChange,
  ...agentSearchProps
}) => {
  const [hasConsent, setHasConsent] = useState(false);
  const [isManualModePopup, setIsManualModePopup] = useState(false);
  const [isSwitchToSearchPopup, setIsSwitchToSearchPopup] = useState(false);

  const handleConsentChange = (e) => {
    if(valueOfField && !hasConsent){
      setIsSwitchToSearchPopup(true);
    }else if(valueOfField && !e.target.checked){
      setIsManualModePopup(true);
    }else{
      setHasConsent(e.target.checked);
      onAgentPartOfRadius(e.target.checked);
    }
  };

  const handleAddManually = () => {
    onAddManually();
    setHasConsent(false);
    setIsManualModePopup(false);
    if(onClear){
      onClear();
    }
  };

  const handleCancelManually = () => {
    setIsManualModePopup(false);
  }

  const handleConfirmSwitchToSearch = () => {
    if(valueOfField){
      setHasConsent(true);
      onAgentPartOfRadius(true);
      setIsSwitchToSearchPopup(false);
      if(onClear){
        onClear();
      }
    }
  }

  const handleCancelSwitchToSearch = () => {
    setIsSwitchToSearchPopup(false);
  }

 

  useEffect(() => {
    if(agentId){
      setHasConsent(true);
    }
  }, [agentId]);

  return (
    <SearchContainer>
      <Checkbox 
        checked={hasConsent}
        onChange={handleConsentChange}
        style={{ marginBottom: hasConsent ? '16px' : '0px',  }}
      >
        <StyledLabel>{consentLabel}</StyledLabel>
      </Checkbox>
       {
        hasConsent? <>
          <InfoMessage
            icon={<span style={{ fontSize: '18px' }}>ⓘ</span>}
            message={infoMessage}
            type="info"
            showIcon
          />
         <AgentSearchFormUncontrolled
          {...agentSearchProps}
          hideLabel={true}
          hideClickToAddManually={true}
          name={name}
          formName={formName}
          lastNameLabel={lastNameLabel}
          isFormField={false}
          onChange={onChange}
        />
            <NoResultsContainer>
              <Space>
                <StyledText type="secondary">Can't find the agent?</StyledText>
                <StyledLink onClick={() => setIsManualModePopup(true)}>Add manually</StyledLink>
              </Space>
            </NoResultsContainer>
        </>:null
       }
       <AgentSearchManualSwitch
        visible={isManualModePopup}
        onCancel={handleCancelManually}
        onConfirm={handleAddManually}
       />
       <AgentSearchSwitch
        visible={isSwitchToSearchPopup}
        onConfirm={handleConfirmSwitchToSearch}
        onCancel={handleCancelSwitchToSearch}
       />
    </SearchContainer>
  );
};

export default AgentSearchWithConsent;
