import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CRMV2Container from "container/CRM";
import BrbcContainer from "container/BRBC";
import { ShimmerBox } from "shared/styles/animation";
import CircularImage from "dumbComponents/common/CircularImage";
import Box from "@ui/Box";
import DropdownMenu from "@ui/NewTooltip";
import { Li } from "@ui/DragDropColumn/common";
import ArchiveIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/archiveCRM.svg";
import moreMenuSVG from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Menu.svg";
import {
  normalizePhone,
  email,
} from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils";
import Cookies from "react-cookies";

import AddIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/AddIcon.svg";
import {
  Phone as PhoneIcon,
  Mail as MailIcon,
} from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/";
import colors from "@colors";
import { DownOutlined } from "@ant-design/icons";
import { ATC, MONTSERRAT, INTER } from "@fonts";
import { Select, Tabs, Row, Col } from "@ui/antd";
import { get } from "lodash";
import { TimeZone } from "services/Utils";
import Loader from "@ui/Loader";
import BuyerLeadOverview from "./BuyerLeadOverView";
import { LeadInfo, Title, Icon } from "../common";
import { SearchCriteriaInfo, PropertyInfo } from "./Tabs";
import BrbcBanner from "./BRBC/BrbcBanner";
import BrbcModal from "./BRBC/BrbcModal";
import { Dropdown, Space, Skeleton, Modal } from "antd";
import ThreeDotMenu from "../../../../../images/icons/threedotMenu";
import PubNubForBrbc from "./BRBC/PubNubForBrbc/index";
import ConsumerAppContainer from "container/ConsumerApp/index";
import AgentAssignment from "./AgentAssignment";
import AppContainer from "container/App";
import NeedHelpButton from "dumbComponents/NeedHelpButton/index";
import Activity from "./Tabs/Activity";
import Button from "@ui/Button";
import PubNubForClientSummary from "./PubNubForClientSummary";

const CLIENT_SOURCE = [
  {
    label: "Radius Marketplace",
    value: "radius_marketplace",
  },
  {
    label: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  },
  {
    label: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  },
  {
    label: "Zillow Flex",
    value: "zillow_flex",
  },
  {
    label: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    label: "Team/Mentor Lead",
    value: "team_mentor_lead",
  },
  {
    label: "Self",
    value: "self",
  },
  {
    label: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  },
  {
    label: "My mobile app",
    value: "my_mobile_app",
  },
  {
    label: "Referrals (Agent/Lender)",
    value: "referrals_agent_Lender",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "FSBO",
    value: "fsbo",
  },
  {
    label: "Mail",
    value: "mail",
  },
  {
    label: "Networking",
    value: "networking",
  },
  {
    label: "New Construction",
    value: "new_construction",
  },
  {
    label: "Open House",
    value: "open_house",
  },
  {
    label: "Other - Agency",
    value: "other_agency",
  },
  {
    label: "Other - Builder",
    value: "other_builder",
  },
  {
    label: "Paid Marketing - Farming/Direct",
    value: "paid_marketing_farming_direct",
  },
  {
    label: "Paid Marketing - Other",
    value: "paid_marketing_other",
  },
  {
    label: "Paid Marketing - Zillow",
    value: "paid_marketing_zillow",
  },
  {
    label: "Personal Transaction",
    value: "personal_transaction",
  },
  {
    label: "Property Management",
    value: "property_management",
  },
  {
    label: "Floor Call",
    value: "floor_call",
  },
  {
    label: "Referral - Attorney",
    value: "referral_attorney",
  },
  {
    label: "Referral - From Past Client",
    value: "referral_from_past_client",
  },
  {
    label: "Referral - Lendor",
    value: "referral_lendor",
  },
  {
    label: "Referral - Other",
    value: "referral_other",
  },
  {
    label: "Referral - Real Estate Agent(External)",
    value: "referral_real_estate_agent_external",
  },
  {
    label: "Referral - Sphere of Influence",
    value: "referral_sphere_of_influence",
  },
  {
    label: "My mobile app",
    value: "my_mobile_app",
  },
  {
    label: "Referral - Title",
    value: "referral_title",
  },
  {
    label: "Sign Call",
    value: "sign_call",
  },
  {
    label: "Social Profile - Facebook",
    value: "social_profile_facebook",
  },
  {
    label: "Social Profile - Instagram",
    value: "social_profile_instagram",
  },
  {
    label: "Social Profile - Other",
    value: "social_profile_other",
  },
  {
    label: "Events - Other",
    value: "events_other",
  },
  {
    label: "Public property page",
    value: "public_property_page",
  },
];

const { TabPane } = Tabs;

const Wrap = styled.div`
  margin: 32px;
`;
const AppSpace = styled(Space)`
  color: #5a5ff2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
  width: 170px;
  cursor: pointer;
`;
const HeaderWrap = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e0e6ed;
  display: flex;
  padding: 10px 18px;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 32px;
`;

const AgentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AgentNameAndTypeWrap = styled.div`
  display: flex;
  align-items: center;
`;

const AgentName = styled.p`
  color: ${colors.new_colorNeutral900};
  font-family: ${MONTSERRAT};
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin: 0;
`;

const AgentSource = styled.p`
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    border-radius: 100%;
    background-color: ${colors.new_grey50};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;

    svg {
      transform: scale(0.75);
    }
  }

  .info {
    overflow: hidden;
    color: ${colors.new_primary}
    font-family: ${INTER};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`;

const EditClientInfo = styled.p`
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin: 0;
  text-align: right;
`;

const AgentType = styled.p`
  background-color: ${colors.new_primary};
  color: ${colors.white};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius: 24px;
  height: 20px;
  margin: 0;
  margin-left: 8px;
  text-transform: capitalize;
`;

const RightContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
`;

const AgentLastUpdated = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

const AgentLastUpdatedLabel = styled.div`
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const AgentLastUpdatedDate = styled.div`
  background: ${colors.new_borderPurple};
  border: 1px solid ${colors.new_secondary};
  border-radius: 4px;
  display: flex;
  padding: 2px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

const ReqWrap = styled.div`
  width: 626px;
  border: 1px solid #d3dae8;
  border-radius: 11px;
  padding: 23px;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction:
  margin-top: 17px;
  height: 22px;
  justify-content: space-between;
  align-items: space-between;
`;
const AddProperty = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
`;

const SummaryButton = styled.button`
  display: flex; 
  cursor: pointer;
  font-family: Inter;
  gap: 8px; 
  //align-items: center; 
  justify-content: center; 
  width: 100%; 
  font-size: 16px; 
  background: #2C2C2C; 
  color: #FDFDFD; 
  padding: 12px 20px 6px 15px;
  margin-right: 10px;
  border-radius: 24px; 
  border: 1px solid var(--Gradient-1-1, #B6B8FF);
  box-shadow: 0px 29px 8px 0px rgba(0, 0, 0, 0.00), 0px 19px 7px 0px rgba(0, 0, 0, 0.01), 0px 11px 6px 0px rgba(0, 0, 0, 0.05), 0px 5px 5px 0px rgba(0, 0, 0, 0.09), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
`

const SummaryExistsButton = styled.button`
  display: flex; 
  cursor: pointer;
  font-family: Inter;
  gap: 8px; 
  align-items: center; 
  justify-content: center; 
  width: 100%; 
  font-size: 16px; 
  background: ##F8F9FA; 
  padding: 12px 20px 6px 15px;
  border-radius: 24px; 
  border: 1px solid var(--Gradient-1-1, #B6B8FF);
  box-shadow: 0px 29px 8px 0px rgba(0, 0, 0, 0.00), 0px 19px 7px 0px rgba(0, 0, 0, 0.01), 0px 11px 6px 0px rgba(0, 0, 0, 0.05), 0px 5px 5px 0px rgba(0, 0, 0, 0.09), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
`

const MelComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={24}
    viewBox="0 0 23 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.5 23.5C10.1911 14.356 9.16263 13.305 0 12C9.13926 10.7037 10.1911 9.64395 11.5 0.5C12.7973 9.64395 13.8374 10.7037 23 12C13.8374 13.305 12.8323 14.356 11.5 23.5Z"
      fill="url(#paint0_linear_903_27526)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_903_27526"
        x1={3.28571}
        y1={3.78571}
        x2={15.2328}
        y2={16.5088}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6B8FF" />
        <stop offset={0.359375} stopColor="#5A5FF2" />
        <stop offset={0.796875} stopColor="#F08068" />
        <stop offset={0.9999} stopColor="#F0A468" />
        <stop offset={1} stopColor="#F0A468" />
      </linearGradient>
    </defs>
  </svg>
);

const StarComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.73136 20.8325C7.22429 21.0972 6.61084 21.0506 6.14958 20.7124C5.68831 20.3741 5.45945 19.8031 5.55946 19.2399L6.36879 14.6009L2.96137 11.3356C2.54488 10.9384 2.39224 10.338 2.56847 9.7901C2.7447 9.24222 3.21876 8.84339 3.78872 8.76351L8.51969 8.08723L10.6556 3.82645C10.9088 3.31546 11.4298 2.99219 12.0001 2.99219C12.5704 2.99219 13.0915 3.31546 13.3447 3.82645L15.4806 8.08723L20.2116 8.76351C20.7815 8.84339 21.2556 9.24222 21.4318 9.7901C21.608 10.338 21.4554 10.9384 21.0389 11.3356L17.6315 14.6009L18.4408 19.2409C18.5408 19.8041 18.312 20.3751 17.8507 20.7134C17.3894 21.0516 16.776 21.0982 16.2689 20.8335L12.0001 18.6276L7.73136 20.8325Z"
      stroke="#4A4A4A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DrawerHeader = ({
  agentInfo,
  getCrmClientResponse,
  setCurrentDrawerPage,
  setClientID,
  toggleAddNewLeadDrawer,
  toggleUserOverview,
  setCurrentClientType,
  getCtIdResponse,
  fetchPresignedBrbcDocsResponse,
  fetchBrbcStatusResponse,
  inviteClient,
  copyAppInviteLink,
  user,
  getAppStatus,
  getAppStatusResponse, 
  getClient, 
  getClientSummary,
  getClientSummaryResponse,
  generateClientSummary,
  generateClientSummaryResponse,
}) => {
  const { data, isFetching } = getCrmClientResponse || {};
  // const { data: brbcData, isFetching: isFetchingBrbc } =
  //   fetchPresignedBrbcDocsResponse || {};
  const { clientTransactions, name, phones, emails, created_at } = data || {};
  const { data: clientTransactionData } = getCtIdResponse || {};
  const { type, client_source } = clientTransactionData || {};
  const { SELLING: sellerTransactions, BUYING: buyerTransactions } =
    clientTransactions || {};
  const { data: brbcData, isFetching: isFetchingBrbcData } =
    fetchBrbcStatusResponse || {};
  const phone = (!isFetching && phones && get(phones[0], "phone")) || "";
  const { data: clientSummaryData, isFetchingMore: isFetchingClientSummary } = getClientSummaryResponse || {};
  const { engagement_score } = clientSummaryData || {};
  const { score, total_score } = engagement_score || {};
  const email = (!isFetching && emails && get(emails[0], "email")) || "";
  const isBrokerPlan = Cookies.load("isSubscribedToBrokerPlan")
  const isSubscribedToBrokerPlan = isBrokerPlan =="false" ? false : true
  const tcInformation = Cookies.load("tcInformation")
  const askBRBC = Cookies.load("askBRBC")
  const EOIsubscriptionStatus = Cookies.load("EOIstatusForSubscription")
  const subscriptionStatus = Cookies.load("subscriptionStatusForBanner")
const isEOIOrSubsStatusNegative = 
  ["incomplete", "past_due", "paused", "incomplete_expired", "overdue", "canceled", "unpaid"].includes(EOIsubscriptionStatus) || 
  ["incomplete", "past_due", "paused", "incomplete_expired", "overdue", "canceled", "unpaid"].includes(subscriptionStatus);
  

  const dualType =
    sellerTransactions &&
    sellerTransactions.length > 0 &&
    buyerTransactions &&
    buyerTransactions.length > 0;

  const systemTime = TimeZone(created_at, false);
  const { localeDateString } = systemTime;

  const getClientSource = () => {
    const foundSource = CLIENT_SOURCE.find(
      (source) => source.value === client_source
    );

    if (foundSource) {
      return foundSource.label;
    }
    return "-";
  };

  const [isBrbcModalVisible, setIsBrbcModalVisible] = useState(false);

  useEffect(() => {
    getAppStatus();
    const { client_id } = clientTransactionData; 
    getClient({ clientId: client_id}); 
  }, [])
  
  useEffect(() => {
    if (data && data.agent_id) {
      const timer = setTimeout(() => {
        setIsBrbcModalVisible(true);
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [data]);

  const handleProfileMenuClick = (e) => {
    if (e.key === "edit-profile") {
      setCurrentDrawerPage("edit-profile");
    } else {
      // some action
    }
  };

  const handleAppInviteClick = (e) => {
    if (e.key === "invite") {
      const payload = {
        client_ids: [data?.id],
      };
      inviteClient({ payload, callback: () => {} });
    } else {
      copyAppInviteLink();
    }
  };

  const profileMenuItems = [
    {
      label: "Edit",
      key: "edit-profile",
    },
  ];

  const appInviteMenuItems = [
    {
      key: "invite",
      label: "Invite via email & sms",
    },
    {
      key: "copy",
      label: "Copy invite link",
    },
  ];

  const profileMenu = {
    items: profileMenuItems,
    onClick: handleProfileMenuClick,
  };

  const appInviteMenu = {
    items: appInviteMenuItems,
    onClick: handleAppInviteClick,
  };

  return (
    <Wrap>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <HeaderWrap>
            {/* <CircularImage
              size={92}
              name={name}
              charColor={colors.new_primary}
              fontSize="40px"
            /> */}
            {clientTransactionData?.client_id && (
              <PubNubForClientSummary clientId={clientTransactionData.client_id} />
            )}
            <Row>
              <Col span={18}>
                <AgentInfo>
                  <AgentNameAndTypeWrap>
                    <AgentName>{name}</AgentName>
                    <AgentType>{dualType ? "DUAL" : type}</AgentType>
                  </AgentNameAndTypeWrap>
                  <AgentSource>
                    {`Source: ${
                      client_source === null ? "" : getClientSource()
                    }`}
                  </AgentSource>
                  {user.isBrokerageUserTeamLead && type &&
                  (type.toLowerCase() === "buyer" ||
                    type.toLowerCase() === "Buyer") && (
                    <AgentAssignment
                      clientTransactionData={clientTransactionData}
                    />
                  )}

                  {phone && (
                    <ContactInfoContainer>
                      <div className="icon">
                        <PhoneIcon />
                      </div>
                      <p className="info">{phone && normalizePhone(phone)}</p>
                    </ContactInfoContainer>
                  )}

                  <ContactInfoContainer>
                    <div className="icon">
                      <MailIcon />
                    </div>
                    <p className="info">{email}</p>
                  </ContactInfoContainer>
                </AgentInfo>
              </Col>
              <Col span={6}>
                <RightContainerWrap>
                  {/* <EditClientInfo
                    onClick={() => { setCurrentDrawerPage("edit-profile") }}
                  >
                    Edit Client Info
                  </EditClientInfo> */}

                  {type &&
                  getAppStatusResponse?.data?.status === "App Live" &&
                  (type.toLowerCase() === "buyer" ||
                    type.toLowerCase() === "Buyer") ? (
                    <div style={{ display: "flex" }}>
                      <Dropdown menu={appInviteMenu}>
                        <AppSpace>
                          SEND APP INVITE
                          <DownOutlined style={{ marginTop: "-5px" }} />
                        </AppSpace>
                      </Dropdown>

                      <Dropdown menu={profileMenu}>
                        <Space>
                          <ThreeDotMenu />
                        </Space>
                      </Dropdown>
                    </div>
                  ) : (
                    <EditClientInfo
                      onClick={() => {
                        setCurrentDrawerPage("edit-profile");
                      }}
                    >
                      Edit Client Info
                    </EditClientInfo>
                  )}

                  <AgentLastUpdated>
                    <AgentLastUpdatedLabel>Added on</AgentLastUpdatedLabel>
                    <AgentLastUpdatedDate>
                      {localeDateString}
                    </AgentLastUpdatedDate>
                  </AgentLastUpdated>
                </RightContainerWrap>
              </Col>
            </Row>
            </HeaderWrap>
          
          {brbcData && ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative )&& (
            <PubNubForBrbc
              brbcId={brbcData?.docusign[0]?.brbc_id}
              agentId={data?.agent_id}
              clientId={data?.id}
            />
          )}
          {!isFetchingBrbcData && brbcData &&((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative) ? (
            <BrbcBanner />
            ) : (
                isFetchingBrbcData &&
            <ShimmerBox h="50px" w="100%" />
          )}

          {type && ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative) &&
            (type.toLowerCase() === "buyer" ||
              type.toLowerCase() === "Buyer") &&
            !isFetchingBrbcData &&
            brbcData?.status === "get_started" && !askBRBC && (
              <BrbcModal
                brbcModalType="auto-modal"
                toggleModal={isBrbcModalVisible}
                onClose={() => setIsBrbcModalVisible(false)}
              />
            )}
          {type?.toLowerCase().trim() === 'buyer' && (
            <div style={{ display: 'flex', width: '100%', marginTop: '20px' }}>
              <SummaryButton 
                // onClick={() => setCurrentDrawerPage("client-summary")}
                onClick={() => {
                  if (buyerTransactions === undefined || buyerTransactions?.length === 0) {
                    Modal.warning({
                      title: 'No search criteria found',
                      content: 'Please add a search criteria to generate a summary',
                    })
                    return;
                  }
                  setCurrentDrawerPage({
                    page: "client-summary",
                    triggerGenerateSummary: true,
                  })
                }}
                style={{ 
                  flex: getClientSummaryResponse && clientSummaryData && !isFetchingClientSummary ? '1' : '1 1 100%',
                  transition: 'width 0.3s ease'
                }}
              >
                <MelComponent /> 
                <p>Mel, summarize {name}</p>
              </SummaryButton>

              {getClientSummaryResponse && clientSummaryData && clientSummaryData.client_id === clientTransactionData?.client_id && !isFetchingClientSummary && (
                <SummaryExistsButton 
                  style={{ flex: 1, transition: 'width 0.3s ease' }}
                  // onClick={() => setCurrentDrawerPage("client-summary")}
                  onClick={() =>
                    setCurrentDrawerPage({
                      page: "client-summary",
                      triggerGenerateSummary: false,
                    })
                  }
                >
                  <p><StarComponent /> {score}/{total_score}    |    View Last Summary</p>
                </SummaryExistsButton>
              )}
            </div>
          )}

          <LeadInfo defaultActiveKey="1" tabBarExtraContent={<NeedHelpButton />} style={{ marginTop: "15px" }}>
            {sellerTransactions && (
              <>
                <TabPane tab="SELLING" key="2">
                  <PropertyInfo />
                </TabPane>
                <TabPane tab="ACTIVITY" key="3">
                  <Activity dataLoader="backToActivity"/>
                </TabPane>
              </>
            )}
              {buyerTransactions && (
             <>
              <TabPane tab="BUYING" key="4">
                <SearchCriteriaInfo />   
              </TabPane>
              <TabPane tab="ACTIVITY" key="3">
                <Activity dataLoader="backToActivity"/>
              </TabPane>
             </>
            )}
            {!sellerTransactions &&
              !buyerTransactions &&
              type &&
              (type.toLowerCase() === "buyer" ||
                type.toLowerCase() === "Buyer") && (
                <ReqWrap>
                  <TitleDiv>
                    <Title fontSize={18}>Add Search</Title>
                    <AddProperty
                      onClick={() => {
                        setCurrentDrawerPage("add-same-lead");
                        toggleAddNewLeadDrawer(true);
                        toggleUserOverview({
                          showUserOverviewDrawer: false,
                          agentInfo,
                        });
                        setCurrentClientType("buyer");
                        setClientID({
                          id: data.client_id,
                          deal_id: data.id,
                        });
                      }}
                    >
                      <Title fontSize={15}>Add Criteria</Title>
                      <Icon src={AddIcon} />
                    </AddProperty>
                  </TitleDiv>
                </ReqWrap>
              )}
            {!sellerTransactions &&
              !buyerTransactions &&
              type &&
              (type.toLowerCase() === "seller" ||
                type.toLowerCase() === "Seller") && (
                <ReqWrap>
                  <TitleDiv>
                    <Title fontSize={18}>Add Listing</Title>
                    <AddProperty
                      onClick={() => {
                        setCurrentDrawerPage("add-same-lead");
                        toggleAddNewLeadDrawer(true);
                        toggleUserOverview({
                          showUserOverviewDrawer: false,
                          agentInfo,
                        });
                        setCurrentClientType("seller");
                        setClientID({
                          id: data.client_id,
                          deal_id: data.id,
                        });
                      }}
                    >
                      <Title fontSize={15}>Add Listing</Title>
                      <Icon src={AddIcon} />
                    </AddProperty>
                  </TitleDiv>
                </ReqWrap>
                )}
          </LeadInfo>
        </>
      )}
    </Wrap>
  );
};

export default AppContainer(
  ConsumerAppContainer(BrbcContainer(CRMV2Container(DrawerHeader)))
);
