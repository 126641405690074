import React, { useState, useEffect } from "react"
import { Form } from "@ui/antd"
import SearchContainer from "container/Search/SearchContainer"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { normalizePhone } from "services/formUtils"
import {
  LoadingOutlined, SearchOutlined,
} from "@ant-design/icons"
import {
  Wrap,
  SelectFieldWrap,
  StyledSpin,
  AutoCompleteFieldWrap,
} from "./styles"

const AgentSearchFormUncontrolled = ({
  placeholder,
  isFormField,
  required = false,
  requiredMessage,
  name,
  onChange,
  getSearchedAgents,
  isFetchingAgents,
  agents,
  text,
  isFromOffer = false,
  hideLabel = false,
  hideClickToAddManually=false
}) => {
  const rules = [{ required, message: requiredMessage?.length ? requiredMessage : "Required" }]

  const [dummyValue, setDummyValue] = useState(null)

  const searchAgentDetails = (searchValue) => {
    const newEntry = {
      label: searchValue,
      value: searchValue,
      id: isFromOffer ? null : 9999999,
      firstname: searchValue,
      lastname: "",
      email: ""
    }
    getSearchedAgents({
      newEntry,
      isInitial: true,
      currentPageNumber: 1,
      queryParams: {
        text_value: searchValue,
        unRegistered: false,
        text_search_params: [
          "email",
          "full_name",
        ],
        isBroker: true,
      },
    })
  }

  const getSelectField = () => {
    switch (isFormField) {
      case true:
        return (
          <Form.Item
            name={name}
            rules={rules}
            label={hideLabel ? null : <LabelComponent text={text} required={required} />}
            required={required}
          >
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={true}              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={onChange}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents)
                .filter(d => {
                  // Filter out agents with no meaningful data
                  return (d.email?.length > 0) || (d.firstname?.trim()?.length > 0) || (d.lastname?.trim()?.length > 0);
                })
                .map(d => {
                let agent;
                // Replace 'null' with empty string and trim names
                const firstName = (d.firstname === 'null' || !d.firstname) ? '' : d.firstname.trim();
                const lastName = (d.lastname === 'null' || !d.lastname) ? '' : d.lastname.trim();
                const email = (d.email === 'null' || !d.email) ? '' : d.email.trim();
                
                const fullName = [firstName, lastName].filter(Boolean).join(" ").trim();
                const hasValidName = fullName.length > 0;
                const hasValidEmail = email.length > 0;
                
                if(hideClickToAddManually){
                  agent = {
                    label: hasValidEmail 
                      ? (hasValidName ? `${fullName} (${email})` : email)
                      : fullName,
                    value: hasValidEmail 
                      ? (hasValidName ? `${fullName} (${email})` : email)
                      : fullName,
                    key: d.id,
                  }
                } else {
                  const label = hasValidEmail 
                    ? (hasValidName ? `${fullName} (${email})` : email)
                    : (hasValidName ? `${fullName} - click to add manually` : '');
                  
                  agent = {
                    label,
                    value: hasValidEmail 
                      ? (hasValidName ? `${fullName} (${email})` : email)
                      : fullName,
                    key: d.id,
                  }
                }
                return agent;
              })}
              placeholder={placeholder}
              suffixIcon={<SearchOutlined style={{marginLeft: "15px", fontSize: "18px"}}/>} 
            />
            {/* <AutoCompleteFieldWrap
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={searchAgentDetails}
          onSelect={((e, option) => changeOtherFieldsValues(e, index, false, option))}
          notFoundContent={
            isFetchingAgents ? (
              <StyledSpin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : null
          }
          options={(agents).map(d => ({
            label: (!d.firstname?.length || !d.lastname?.length) && d.email?.length
              ? `${d.email}`
              : d.email?.length ? `${d.firstname} ${d.lastname} (${d.email})` : `${d.firstname} ${d.lastname}`,
            value: `${d.firstname} ${d.lastname} (${d.email})`,
            key: d.id,
          }))}
          placeholder={placeholder}
          // disabled={disabled}
        /> */}
          </Form.Item>
        )
      default:
        return (
          <Wrap>
            {hideLabel ? null : <LabelComponent text="Agent First Name" />}
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={(value,option) => {
                console.log("value", value,option)
                const agent = agents.find(ag => ag.id === option.key)
                onChange(agent,value,option)
              }}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents)
                .filter(d => {
                  // Filter out agents with no meaningful data
                  return (d.email?.length > 0) || (d.firstname?.trim()?.length > 0) || (d.lastname?.trim()?.length > 0);
                })
                .map(d => {
                let agent;
                // Replace 'null' with empty string and trim names
                const firstName = (d.firstname === 'null' || !d.firstname) ? '' : d.firstname.trim();
                const lastName = (d.lastname === 'null' || !d.lastname) ? '' : d.lastname.trim();
                const email = (d.email === 'null' || !d.email) ? '' : d.email.trim();
                
                const fullName = [firstName, lastName].filter(Boolean).join(" ").trim();
                const hasValidName = fullName.length > 0;
                const hasValidEmail = email.length > 0;
                
                if(hideClickToAddManually){
                  agent = {
                    label: hasValidEmail 
                      ? (hasValidName ? `${fullName} (${email})` : email)
                      : fullName,
                    value: hasValidEmail 
                      ? (hasValidName ? `${fullName} (${email})` : email)
                      : fullName,
                    key: d.id,
                  }
                } else {
                  const label = hasValidEmail 
                    ? (hasValidName ? `${fullName} (${email})` : email)
                    : (hasValidName ? `${fullName} - click to add manually` : '');
                  
                  agent = {
                    label,
                    value: hasValidEmail 
                      ? (hasValidName ? `${fullName} (${email})` : email)
                      : fullName,
                    key: d.id,
                  }
                }
                return agent;
              })}
              placeholder={placeholder}
              suffixIcon={<SearchOutlined style={{marginLeft: "15px", fontSize: "18px"}}/>} 
            />
            {/* <AutoCompleteFieldWrap
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={searchAgentDetails}
          onSelect={((e, option) => changeOtherFieldsValues(e, index, false, option))}
          notFoundContent={
            isFetchingAgents ? (
              <StyledSpin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : null
          }
          options={(agents).map(d => ({
            label: `${d.firstname} ${d.lastname} (${d.email})`,
            value: `${d.firstname} ${d.lastname} (${d.email})`,
            key: d.id,
          }))}
          placeholder={placeholder}
          // disabled={disabled}
        /> */}
          </Wrap>
        )
    }
  }
  return (
    <>
      {getSelectField()}
    </>
  )
}

export default SearchContainer(AgentSearchFormUncontrolled)