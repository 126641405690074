import React, { useState, useEffect } from "react"
import OffersV2Container from "container/OffersV2"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import OfferOld from "container/TechAssets"
import CRM from "container/CRM/index"
import OfferExtendedDetails from "../OfferExtendedDetailsCard/index"
import { Wrap, TabWrap, TabInfo, TabTitle, DocListLength } from "./styles"
import NeedHelpButton from "dumbComponents/NeedHelpButton/index";

const OfferTabs = ({
  currentTab,
  currentActiveTab,
  toggleTab,
  steps,
  tabWidth,
  id,
  docList,
  getCtIdResponse,
  offerDealId,
  getOfferDocumentsResponse,
  toggleDocumentTab,
  currentDocumentTab,
  isContractPhase,
  fetchingPropertyInformation,
  propertyInformationObject,
  hideExtendedCard,
  dealTermObject,
  getOfferStatusResponse,
  history,
  toggleOfferToContractConversion,
  toggleActiveTab,
  wantDisable,
  showNeedHelpButton,
  ...props
}) => {
  const { location } = history || {}
  const { pathname, search } = location || {}
  const queryParams = queryString.parse(search)
  const { contract_phase } = queryParams || {}
  const [isOfferCreationTabDisabled, setDisabled] = useState(false)
  const [activeDocList, setActiveDocList] = useState(false)

  const { isFetching } = getCtIdResponse || {}

  const { data: envelopes, isFetching: fetchingOfferDocs } =
    getOfferDocumentsResponse || {}

  const { mls_number, currentState, form } = propertyInformationObject || {}

  const { currentState: dealState, form: dealForm } = dealTermObject || {}

  const { data: currentOfferStatus } = getOfferStatusResponse || {}

  const { offerStatus } = currentOfferStatus || {}

  const disableCreateOfferStatuses = ["completed", "sent", "action_required"]

  useEffect(() => {
    setActiveDocList(
      docList && docList.filter(item => item.status === "active").map(item => item)
    )
  }, [docList])

  useEffect(() => {
    if (!fetchingOfferDocs && envelopes && envelopes.length > 0) {
      const filteredEnvelopes = !fetchingOfferDocs && envelopes
        .filter(item => disableCreateOfferStatuses.includes(item.status))
      const disableIf = filteredEnvelopes && typeof filteredEnvelopes === "object"
      && filteredEnvelopes.length > 0
      
      setDisabled(disableIf)
      toggleTab("documents")
      toggleDocumentTab("recommended_docs")
    }
  }, [envelopes, fetchingOfferDocs])

  const getDisabledState = step => (
    (offerStatus === "incomplete" && step.value === "contract_details")
    || ((isContractPhase || isOfferCreationTabDisabled)
      && step.value === "cover_sheet")
  )

  const currentFormState = form[currentState]
  const isStreetAvailable = (currentFormState && currentFormState.street) || null
  const dealFormState = dealForm[dealState]

  return (
    <Wrap>
      <div>
        {!hideExtendedCard && (mls_number || isStreetAvailable) && (
          <OfferExtendedDetails
            mlsId={mls_number}
            offerDealId={offerDealId}
            transactionId={id}
            propertyDetails={currentFormState}
            dealDetails={dealFormState}
          />
        )}
      </div>
      <TabWrap width={tabWidth} showNeedHelpButton={showNeedHelpButton}>
        {steps
        && steps.map(step => (
          <TabInfo
            key={step.value}
            disabled={step.disabled || (getDisabledState(step) && wantDisable)}
            onClick={() => {
              if (step.disabled || (getDisabledState(step) && wantDisable)) {
                return;
              }
              if (step.value === "contract_details" && !contract_phase) {
                toggleOfferToContractConversion({
                  bool: true,
                  url: `/realty/offers-v3/${offerDealId}?transaction_id=${id}&contract_phase=true`,
                })
              } else {
                toggleTab(step.value);
                if (step.value === 'documents' || step.value === 'cover_sheet' || step.value === 'contract_details' || step.value === 'compliance') {
                  toggleActiveTab(step.value);
                } else {
                  toggleDocumentTab(step.value);
                }
              }
            }}
            isActive={currentActiveTab === step.value || currentDocumentTab === step.value}
          >
            <TabTitle
              isActive={currentActiveTab === step.value || currentDocumentTab === step.value}
              disabled={step.disabled || (getDisabledState(step) && wantDisable)}
            >
              {step.label}
              {step.value === "recommended_docs" ? (
                <>
                  <DocListLength>{activeDocList.length}</DocListLength>
                </>
              ) : null}
            </TabTitle>
          </TabInfo>
        ))}

        {showNeedHelpButton && <NeedHelpButton pullToTheRight />}
      </TabWrap>
    </Wrap>
  )
}

export default OfferOld(CRM(OffersV2Container(withRouter(OfferTabs))))
