import React, { useRef, useState, useEffect } from "react"
import {
  Form,
} from "@ui/antd"
import {
  InputField,
  InputNumber,
  InputLocation,
  // AgentSearch,
  HiddenInputField,
} from "dumbComponents/common/InputFields"
import { AddButton } from "dumbComponents/OffersV3/components"
import { normalizePhone } from "services/formUtils"
import {
  Wrap,
  TabsWrap,
  TabsInfo,
  TabsTitle,
} from "dumbComponents/OffersV3/CoverSheet/Components/MLSTabs/styles"
import { StyledCheckbox } from "dumbComponents/OffersV2/DocusignDocs/Components/CheckboxList/styles"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  FormFieldWrap,
  CoAgentWrap,
  ListRowWrap,
} from "./commonStyles"
import {
  BlueButton,
  DangerButton,
} from "../../../../commonStyles"
import CoAgentIcon from "images/icons/co-agent-icons"
import RemoveAgentBinIcon from "images/icons/remove-agent-bin-icon"
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";
import AgentSearchWithConsent from "dumbComponents/common/InputFields/AgentSearchWithConsent";
import TechAssets from "container/TechAssets"

const { List } = Form

/**
 *
 * @param {*} param0
 * @returns
 */

const BuyerAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteAgent,
  form,
  isFetching,
  handleOnTypeChangeForBuyerAgentInfo,
  buyerAgentInformationFormRef,
  buyersAgentInformationObject,
  offerFormValidation
}) => {
  const [isRadiusAgentBool, setRadiusAgentBoolForCoAgent] = useState({})
  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);
  //co-agents
  const [isAddManuallyForCoAgent, setIsAddManuallyForCoAgent] = useState({});
  const formRef = useRef(null)

  const validateBuyerAgentForm = (values) => {
    console.log('Buyer agent form values being validated:', values);
    
    // Required fields for main agent
    const requiredFields = [
      'agent_first_name',
      'agent_last_name',
      'email'
    ];

    // Check main agent fields
    const isMainAgentValid = requiredFields.every(field => {
      const value = values[field];
      const isValid = value && typeof value === 'string' && value.trim() !== '';
      console.log(`Main agent field ${field}:`, value, 'isValid:', isValid);
      return isValid;
    });

    // Check co-agents if they exist
    let areCoAgentsValid = true;
    if (values.co_agent_details && values.co_agent_details.length > 0) {
      areCoAgentsValid = values.co_agent_details.every(coAgent => {
        const coAgentRequiredFields = ['agent_first_name', 'agent_last_name', 'email', 'brokerage'];
        return coAgentRequiredFields.every(field => {
          const value = coAgent[field];
          const isValid = value && typeof value === 'string' && value.trim() !== '';
          console.log(`Co-agent field ${field}:`, value, 'isValid:', isValid);
          return isValid;
        });
      });
    }

    const isFormValid = isMainAgentValid && areCoAgentsValid;
    console.log('Final buyer agent form validation result:', isFormValid);

    // setOfferFormValidation({
    //   ...offerFormValidation,
    //   buyerInfoFormValid: {
    //     ...offerFormValidation.buyerInfoFormValid,
    //     isBuyerAgentValid: isFormValid
    //   }
    // });
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    console.log('Buyer agent form values changed:', allValues);
    // validateBuyerAgentForm(allValues);
    if (handleOnTypeChangeForBuyerAgentInfo) {
      handleOnTypeChangeForBuyerAgentInfo(changedValues, allValues);
    }
  };

  // useEffect(() => {
  //   if (prefilledData && prefilledData[0]) {
  //     validateBuyerAgentForm(getInitialValues());
  //   }
  // }, [prefilledData]);

  // helper functions to manage name states 
  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const getInitialValues = () => {
    let initValues = {}
    console.log("prefilledData buyerAgent", prefilledData)
    if (prefilledData) {
      initValues = {
        ...prefilledData[0],
      }
      initValues.phone = normalizePhone(initValues.phone)
      if (prefilledData.length > 1) {
        const sliced = prefilledData.slice(1)
        initValues.co_agent_details = sliced
      }
    }
    console.log("initValues buyeragent", initValues)

    return initValues
  }
  const saved = "Saved"
  const isSaving = "...Saving";

  
  const handleOnChangeCoBuyerAgent = (agent,value,option,formName,fieldName) => {
    console.log("value", agent,value,option)
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_id: parseInt(agent.id, 10),
          agent_first_name: agent.firstname,
          agent_last_name: agent.lastname !== "" ? agent.lastname : undefined,
          name: agent.name,
          email: agent.email || agent?.brokerage_info_from_LOC?.email,
          phone: normalizePhone(agent.phone) || normalizePhone(agent?.brokerage_info_from_LOC?.phone),
          agent_brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
          agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
          landline: agent?.brokerage_info_from_LOC?.landline,
          state: agent?.brokerage_info_from_LOC?.state,
          zipcode: agent?.brokerage_info_from_LOC?.zipcode,
          city: agent?.brokerage_info_from_LOC?.city,
          // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
          //is_primary: isFirst ? 1 : 0,
          listing_brokerage: agent?.brokerage_info_from_LOC?.listing_brokerage || agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
        }
      }
    });
  }

  const handleOnClearCoBuyerAgent = (formName,fieldName) => {
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_first_name: "",
          agent_last_name: "",
          email: "",
          phone: "",
        }
      }
    });
  }

  // const handleOnValuesChange = (changedValues, allValues) => {
  //   console.log(changedValues, allValues, "changedValues")
  //   handleOnTypeChangeForBuyerAgentInfo(changedValues, allValues)
  // }
  return (
    <FormWrap
      isAddCoAgentForm={isAddCoAgentForm}
    >
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Agent Form"}</p>
        </FormTitle>
        {/* <FormTitle>
          {isFetching === true ? isSaving : saved}
        </FormTitle> */}
      </HeaderWrap>

      <Form
        name="offers_buyer_agent_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        onValuesChange={handleFormValuesChange}
        form={buyerAgentInformationFormRef}
      >
        {/**HIDDEN FIELDS*/}
        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="is_primary"/>

        <FormFieldWrap>
          {isAddCoAgentForm ? (
            <>
              <AgentSearch
                name="agent_first_name"
                inputType="text"
                placeholder="Co-Agent's Name"
                customRules={{}}
                isFormField
                formRef={formRef}
                text="Agent First Name"
                required="true"
              />

              <InputField
                name="agent_last_name"
                inputType="text"
                placeholder="Last Name"
                customRules={{}}
                text="Agent Last Name"
                required="true"
              />
            </>
          ) : (
            <>
              <InputField
                name="agent_first_name"
                inputType="text"
                placeholder="First Name"
                text="Agent First Name"
                required="true"
              />

              <InputField
                name="agent_last_name"
                inputType="text"
                placeholder="Last Name"
                customRules={{}}
                text="Agent Last Name"
                required="true"
              />
            </>
          )}
        </FormFieldWrap>
        
        <FormFieldWrap>
          <InputField
            name="email"
            inputType="email"
            type="email"
            placeholder="Email"
            text="Email"
            required="true"
          />

          <InputNumber
            name="phone"
            inputType="phone"
            type="phone"
            placeholder="Phone"
            text="Phone"
            required={false}
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="agent_license_number"
            inputType="text"
            type="text"
            placeholder="Agent License Number"
            text="Agent License Number"
            required={false}
          />
          {console.log("formRef", formRef)}
          <InputField
            name="brokerage_license_number"
            inputType="text"
            type="text"
            placeholder="Brokerage License Number"
            text="Brokerage License Number"
            required={false}
          />
        </FormFieldWrap>

        {/**Requires Google Address*/}
        <FormFieldWrap>
          <InputLocation
            name="brokerage_address"
            inputType="text"
            type="text"
            placeholder="Brokerage Street Address"
            formRef={formRef}
            text="Brokerage Street Address"
            //required={false}
            // requiredMessage="Required"
          />

<InputField
            name="apt"
            inputType="text"
            type="text"
            placeholder="Apt/Unit/Suite No."
            text="Apt/Unit/Suite No."
          />

         
        </FormFieldWrap>

        <FormFieldWrap>
        <InputField
            name="city"
            inputType="text"
            type="text"
            placeholder="City"
            text="City"
          />
          <InputField
            name="state"
            inputType="text"
            type="text"
            placeholder="State"
            text="State"
          />
        </FormFieldWrap>
        <FormFieldWrap style={{width: "50%"}}> 
        <InputField
            name="zipcode"
            inputType="text"
            type="text"
            placeholder="Zipcode"
            text="Zipcode"
          />
        </FormFieldWrap>
        <List name="co_agent_details">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name }) => (
                <CoAgentWrap>
                  <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} />
                  <HeaderWrap>
                    <FormTitle>
                      <p> <CoAgentIcon/> Co-Buyer Agent Information</p>
                    </FormTitle>
                    <CancelOrGoBackButton
                      onClick={() => {
                        openRemoveAgentModal(name)
                      }}
                    >
                      <RemoveAgentBinIcon/>
                      <p> Remove </p>
                    </CancelOrGoBackButton>
                  </HeaderWrap>
                  {/* <HeaderWrap>
                    <ListRowWrap>
                      <StyledCheckbox
                        value="here"
                        onChange={(e) => {
                          const {
                            target: {
                              checked,
                            },
                          } = e || {}
                          setRadiusAgentBoolForCoAgent(existingValues => ({
                            ...existingValues,
                            [name]: checked,
                          }))
                        }}
                      >
                        Is agent part of Radius ?
                      </StyledCheckbox>
                    </ListRowWrap>
                  </HeaderWrap> */}
                   <AgentSearchWithConsent 
                    name={[name, "agent_first_name"]}
                    inputType="text"
                    placeholder="Search by agent name or email address."
                    customRules={{}}
                    isFormField
                    text="Agent First Name"
                    formName="co_agent_details"
                    required="true"
                    isFromOffer={true}
                    requiredMessage="Agent's first name is required"
                    valueOfField={formRef?.current?.getFieldValue(['co_agent_details', name, 'agent_first_name'])}
                    onChange={(agent,value,option)=>handleOnChangeCoBuyerAgent(agent,value,option,"co_agent_details",name)}
                    onClear={()=>handleOnClearCoBuyerAgent("co_agent_details",name)}
                    agentId={formRef?.current?.getFieldValue(['co_agent_details', name, 'agent_id'])}
                    onAddManually={() => setIsAddManuallyForCoAgent(prev => ({
                      ...prev,
                      [name]: false
                    }))}
                        onAgentPartOfRadius={(value) => {
                            setIsAddManuallyForCoAgent(prev => ({
                              ...prev,
                              [name]: value 
                            }));
                        }}
                        />
                  <FormFieldWrap>
                        <InputField
                        name={[name, "agent_first_name"]}
                        inputType="text"
                        placeholder="First Name"
                        customRules={{}}
                        text="Agent First Name"
                        required="true"
                        disabled={isAddManuallyForCoAgent[name]}
                      />
                    <InputField
                      name={[name, "agent_last_name"]}
                      inputType="text"
                      placeholder="Last Name"
                      customRules={{}}
                      text="Agent Last Name"
                      required="true"
                      disabled={isAddManuallyForCoAgent[name]}
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "email"]}
                      inputType="email"
                      type="email"
                      placeholder="Email"
                      text="Email"
                      required="true"
                      disabled={isAddManuallyForCoAgent[name]}
                    />

                    <InputNumber
                      name={[name, "phone"]}
                      inputType="phone"
                      type="phone"
                      placeholder="Phone"
                      text="Phone"
                      required={false}
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "brokerage"]}
                      inputType="text"
                      type="text"
                      placeholder="Brokerage"
                      text="Brokerage"
                      required="true"
                    />

                    <InputField
                      name={[name, "agent_license_number"]}
                      inputType="text"
                      type="text"
                      placeholder="Agent License Number"
                      text="Agent License Number"
                      required={false}
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "brokerage_license_number"]}
                      inputType="text"
                      type="text"
                      placeholder="Brokerage License Number"
                      text="Brokerage License Number"
                      required={false}
                    />

                    <InputLocation
                      name={[name, "brokerage_address"]}
                      inputType="text"
                      type="text"
                      placeholder="Brokerage Street Address"
                      formRef={formRef}
                      fieldIndex={name}
                      formName="co_agent_details"
                      text="Brokerage Street Address"
                      required={false}
                      // requiredMessage="Required"
                    />
                   
                  </FormFieldWrap>
                  <FormFieldWrap style={{width: "50%"}}> 
                    <InputField
                      name={[name, "apt"]}
                      inputType="text"
                      type="text"
                      placeholder="Apt/Unit/Suite No."
                      text="Apt/Unit/Suite No."
                    />
                  </FormFieldWrap>
                </CoAgentWrap>
              ))}
              <FormFieldWrap>
                <CTAWrap>
                <AddButton 
                    name="ADD A CO-BUYER AGENT" 
                    onClick={() => add({
                      agent_first_name: '',
                      agent_last_name: '',
                      email: '',
                      phone: '',
                      brokerage: '',
                      agent_license_number: '',
                      brokerage_license_number: '',
                      brokerage_address: '',
                      apt: '',
                      city: '',
                      state: '',
                      zipcode: ''
                    })} 
                  />
                </CTAWrap>
              </FormFieldWrap>
            </>
          )}
        </List>
      </Form>
    </FormWrap>
  )
}

export default TechAssets(BuyerAgentForm)
