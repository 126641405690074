import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  take,
  race,
} from "redux-saga/effects"
import createToast from "@ui/Toast"
import { toast as createToastNew } from "react-toastify"
import root, { history } from "window-or-global"
import uuid from "uuid"
import { get } from "lodash"
import deepmerge from "deepmerge"
import { format } from "date-fns"
import { delay } from "redux-saga"
import query from "query-string"
import { getFormValues, reset } from "redux-form"
import Cookies from "react-cookies"
import columnConfig from "dumbComponents/Referral/Inbound/columnConfig"
import * as ClientSaga from "container/Client/saga"
import {
  UPDATE_CURRENT_SALES_STAGE,
  archiveReferralAction,
  closeISAAddClientModalAction,
  userConfirmationModalAction,
  userConfirmAction,
  toggleCallFeedbackModalAction,
} from "container/Client/actions"
import {
  getEpoch,
  getProductFromPlanId,
  getStoreKeyForReferrals,
  isSuccess,
  checkForUserObject,
} from "services/Utils"
import cookie from "services/CookieStorage"
import {
  creatingMailForVerifiedLeadAction,
  claimUnverifiedLeadAction,
  toggleLeadDetailsViewAction,
  getLeadForMarketplaceAction,
  leadViewedAction,
  setCurrentNewLeadsAvailableAction,
  showRedirectionLoaderAction,
} from "container/Marketplace/actions"
import { paymentConfirmationStates } from "dumbComponents/Referral/Checkout"
import {
  showConfirmModalAction,
  toggleLoaderInConfirmAction,
  toggleSignAgreementModalAction,
  toggleRecruitAndEarnFormAction,
  toggleRecruitAndEarnFormViewAction,
} from "container/App/actions"
import {
  countdown,
  startCallingCountdown,
} from "container/Marketplace/saga"
import { getUserProfileAPI } from "container/Profile/api"
import {
  fetchSignedAgreementAPI,
  fetchReferralAPI,
  fetchFilterSkeletonAPI,
  fetchReferalMetricAPI,
  fetchStarReferralAPI,
  signCRSAgreementAPI,
  signCRSSenderAgreementAPI,
  subscribeAPI,
  unsubscribeAPI,
  fetchInvoicesAPI,
  fetchPlansAPI,
  fetchSubscriptionsAPI,
  fetchDefaultCardAPI,
  updateCardAPI,
  fetchProspectsAPI,
  updateProspectAPI,
  referLeadAPI,
  updateLeadReferralStateAPI,
  applyPricingCouponAPI,
  checkoutPricingAPI,
  fetchPartnerSubscriptionInterestAPI,
  submitInterestFormAPI,
  getISAFormDropdownValuesAPI,
  sendSMSMetricsAPI,
  fetchPricingPlansAPI,
  fetchAcceptLeadDropdownValuesAPI,
  setTeamPermissionsAPI,
  fetchTeamMembersAPI,
  fetchTeamFilterValuesAPI,
  referOutAPI,
  fetchSourcesAPI,
  fetchPlanDurationsAPI,
  exportToExcelAPI,
  trigglerISAPageVisitAPI,
  inviteBrokerAPI,
  getBrokerEarningsAPI,
  getBrokerInvitesAPI,
  updateSalesStageChangeFormAPI,
  getClosingStageInfoAPI,
  getInboundPendingReferralDetailsAPI,
  acceptOrRejectLeadAPI,
  getAgentSearchAPI,
  sendToMarketPlaceAPI,
  findAnAgentViewedAPI,
  getTCAgentAssignedAPI,
  fakeSignDocusignAPI,
  fetchSubscriptionStatusAPI,
} from "./api"
import * as ReferralActions from "./actions"
import * as ReferralSelectors from "./selector"
import * as NavbarActions from "../Navbar/actions"
import CookiesStorage from "services/CookieStorage"

const getProcessedInboundReferrals = (inbounds) => {
  const allReferrals = {}
  const dragDropColumns = {}
  columnConfig().map((column) => {
    const referralTypeArray = inbounds[column.value] || []
    dragDropColumns[column.value] = []
    referralTypeArray.map((referral) => {
      dragDropColumns[column.value].push(referral.id)
      allReferrals[referral.id] = referral
    })
  })

  return {
    allReferrals,
    dragDropColumns,
    referralsCount: inbounds.referralsCount,
  }
}

export function* fetchFilterSkeleton() {
  try {
    let res = yield select(state => state.referral.filterSkeleton)
    if (!res || Object.keys(res).length === 0) {
      res = yield call(fetchFilterSkeletonAPI)
      const { response } = res.data
      console.log("AJSDJASDJKAHSDJK", response)
      yield put(ReferralActions.fetchFilterSkeletonAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchFilterSkeletonAction.failure(e))
  }
}

export function* fetchReferrals(action) {
  try {
    const {
      referralSort,
      clientTypes,
      outboundReferralFilters,
      inboundReferralStatuses,
      isaReferralStatuses,
      type,
      offset,
      client_name,
      starred,
      isMerge,
      isISAMerge,
      showMore,
      referralCategories,
      limit,
      teamAgentIds,
      sourceType,
      clientActions,
      baseUrl,
      newStatus,
    } = action.data || {}
    const phoneOptions = action.data["Phone Options"]
    const copyState = yield select(state => state)
    const { search = "" } = yield getFormValues("SEARCH_REFERRAL_FORM")(copyState) || {}
    let params = ""
    if (type) {
      params += `referralType=${type}`
    }
    if (showMore) {
      yield put(ReferralActions.getMoreReferrals(referralCategories || newStatus))
    }
    if (clientTypes && clientTypes.length > 0) {
      params += `&types=${clientTypes.map(item => item.value).join()}`
    }
    if (outboundReferralFilters && outboundReferralFilters.length > 0) {
      params += `&statuses=${outboundReferralFilters.map(item => item.value).join()}`
    }
    if (newStatus) {
      params += `&statuses=${newStatus}`
    }
    if (inboundReferralStatuses && inboundReferralStatuses.length > 0) {
      params += `&statuses=${inboundReferralStatuses.map(item => item.value).join()}`
    }
    if (isaReferralStatuses && isaReferralStatuses.length > 0) {
      params += `&statuses=${isaReferralStatuses.map(item => item.value).join()}`
    }
    if (referralSort) {
      params += `&sortBy=${referralSort.value}`
    }
    if (client_name || search) {
      params += `&text=${client_name || search}`
    }
    if (starred) {
      params += `&starred=${starred}`
    }
    if (offset) {
      params += `&offset=${offset}`
    } else if (type === "outgoing") {
      params += "&offset=0"
    }
    if (limit) {
      params += `&limit=${limit}`
    } else if (type === "outgoing" && false) {
      params += "&limit=3"
    }
    if (referralCategories) {
      params += `&referralCategories=${referralCategories}`
    }
    if (teamAgentIds && teamAgentIds.length > 0) {
      params += `&teamAgentIds=${teamAgentIds.map(x => x.value).join(",")}`
    }
    if (sourceType && sourceType.length > 0) {
      params += `&referralSources=${sourceType.map(x => x.value).join(",")}`
    }
    if (clientActions && clientActions.length > 0) {
      params += `&clientActions=${clientActions.map(x => x.value).join(",")}`
    }

    if (phoneOptions) {
      params += `&isValidPhone=${phoneOptions.value !== "Invalid Phone" ? "true" : "false"}`
    }
    yield put(ReferralActions.saveQueryParamForReferralsAction.call(params))
    yield put(ReferralActions.saveAppliedFilters({ ...action.data, starred, client_name }))
    yield put(ReferralActions.closeFilterPanel())
    const res = yield call(fetchReferralAPI, params, baseUrl)
    let resultedData = { type, ...res.data }

    //Store processed inbound referrals for easy transformation
    if (type === "incoming" || (type === "isa" && referralCategories === "isaPipeline")) {
      const parsedData = getProcessedInboundReferrals(res.data.response)
      const { dragDropColumns, allReferrals, referralsCount } = parsedData
      const prevInboundReferrals = yield select(state => state.referral.inboundReferrals)
      if (isISAMerge) {
        resultedData = {
          type,
          response: deepmerge(prevInboundReferrals, parsedData),
        }
      } else {
        resultedData = {
          type,
          response: {
            dragDropColumns,
            allReferrals,
            referralsCount,
          },
        }
      }
    }

    if (type === "isa" && referralCategories === "isaPipeline") {
      resultedData.type = "incoming"
      yield put(ReferralActions.fetchReferralsAction.success(resultedData))
      return
    }
    const referralType = getStoreKeyForReferrals(type)
    const prevReferral = yield select(state => state.referral[referralType])
    const currentReferrals = get(res, "data.response", [])
    if (isMerge && type === "outgoing") {
      const salesStageIndexPrev = prevReferral.referrals.findIndex(x => x.key === newStatus)
      const salesStageIndexCurrent = currentReferrals.referrals.findIndex(x => x.key === newStatus)
      prevReferral.referrals[salesStageIndexPrev].value = [
        ...get(prevReferral, `referrals[${salesStageIndexPrev}].value`, []),
        ...get(currentReferrals, `referrals[${salesStageIndexCurrent}].value`, []),
      ]
      resultedData = { type, response: prevReferral }
    }
    if (isMerge && referralCategories === "isaSubmitted") {
      resultedData.response = deepmerge(prevReferral, currentReferrals)
    }
    yield put(ReferralActions.fetchReferralsAction.success(resultedData))
  } catch (e) {
    yield put(ReferralActions.fetchReferralsAction.failure(e))
  }
}

export function* fetchArchivedReferrals(action) {
  try {
    const { type, text } = action.data
    let params = ""
    if (type) {
      params += `referralType=${type}`
    }
    if (text) {
      params += `&text=${text}`
    }
    params += "&statuses=Archived&sendSalesData=true&offset=0&limit=100"
    const res = yield call(fetchReferralAPI, params)
    if (isSuccess(res)) {
      const { Archived = [] } = res.data.response
      const resultedData = { type, archived: [...Archived] }
      yield put(ReferralActions.fetchArchivedReferralsAction.success(resultedData))
    }
  } catch (e) {
    yield put(ReferralActions.fetchArchivedReferralsAction.failure(e))
  }
}

function* fetchStarReferral(action) {
  try {
    const {
      id, starred, referralType, index, type,
    } = action.data
    const payload = { starred: !starred }
    const res = yield call(fetchStarReferralAPI, id, payload)
    if (res.status === 200) {
      yield put(
        ReferralActions.fetchStarReferralAction.success({
          id,
          referralType,
          index,
          type: getStoreKeyForReferrals(type),
          starred,
        })
      )
    }
  } catch (e) {
    yield put(ReferralActions.fetchStarReferralAction.failure(e))
  }
}

export function* fetchReferalMetric(action) {
  try {
    const res = yield call(fetchReferalMetricAPI, action.data)
    if (res.status === 200) {
      const { response } = res.data
      yield put(ReferralActions.fetchReferalMetricAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchReferalMetricAction.failure(e))
  }
}

// todo fix it
function* getStarredReferrals(action) {
  try {
    const { isStarred, type, referralCategories } = action.data
    yield put(ReferralActions.fetchReferralsAction.request({ type, starred: !isStarred, referralCategories }))
  } catch (e) {
    yield put(ReferralActions.fetchReferralsAction.failure(e))
  }
}

export function* removeFilter(action) {
  try {
    const { selectedFilters, type, isaType: referralCategories } = action.data
    yield put(ReferralActions.fetchReferralsAction.request({ type, ...selectedFilters, referralCategories }))
  } catch (e) {
    yield put(ReferralActions.fetchReferralsAction.failure(e))
  }
}

function* searchReferralByName(action) {
  try {
    const { search, type, referralCategories } = action.data
    yield put(ReferralActions.fetchReferralsAction.request({
      type,
      client_name: search,
      referralCategories,
      baseUrl: (type === "outgoing" || type === "incoming") ? API_V3_URL : API_V2_URL,
    }))
  } catch (e) {
    yield put(ReferralActions.fetchReferralsAction.failure(e))
  }
}

function* fetchFilteredReferrals(action) {
  try {
    const { type, selectedFilters, referralCategories } = action.data
    yield put(ReferralActions.fetchReferralsAction.request({ type, ...selectedFilters, referralCategories }))
  } catch (e) {
    yield put(ReferralActions.fetchReferralsAction.failure(e))
  }
}

function* changeInboundReferralOrder(action) {
  try {
    const { source, destination, draggableId } = action.data
    const { dragDropColumns } = yield select(state => state.referral.inboundReferrals)
    const newReferralIds = Array.from(dragDropColumns[source.droppableId])
    newReferralIds.splice(source.index, 1)
    newReferralIds.splice(destination.index, 0, draggableId)
    const payload = {
      dragDropColumns: {
        ...dragDropColumns,
        [source.droppableId]: newReferralIds,
      },
    }
    yield put(ReferralActions.changeInboundReferralOrderAction.success(payload))
  } catch (e) {
    console.log("Failed to change order of the inbound referrals")
  }
}

function* handleUpdateSalesStageForm(action) {
  try {
    const { payload } = action.data
    const res = yield call(updateSalesStageChangeFormAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.updateSalesStageFormAction.success(response))
      yield put(ReferralActions.toggleSalesStageChangeFormAction.call({
        type: undefined,
        lead: {},
      }))
    }
  } catch (error) {
    yield put(ReferralActions.updateSalesStageFormAction.failure(error))
  }
}

function* changeInboundReferralStage(action) {
  try {
    const {
      source, destination, draggableId, doNotUpdateClient,
    } = action.data
    const isFormFlow = (
      destination.droppableId === "In Contract"
      || destination.droppableId === "Closed"
    )
    const { allReferrals, dragDropColumns } = yield select(state => state.referral.inboundReferrals)
    const startReferrals = Array.from(dragDropColumns[source.droppableId])
    if (isFormFlow) {
      yield put(ReferralActions.toggleSalesStageChangeFormAction.call({
        type: destination.droppableId,
        lead: allReferrals[draggableId],
      }))
    }
    startReferrals.splice(source.index, 1)

    const endReferrals = Array.from(dragDropColumns[destination.droppableId])
    endReferrals.splice(destination.index, 0, draggableId)
    if (!doNotUpdateClient) {
      const clientId = allReferrals[draggableId].client_id
      const endColumn = columnConfig().find(columnObject => destination.droppableId === columnObject.value)
      let openChecklist = null
      if (!isFormFlow) {
        openChecklist = endColumn.checklist
      }
      yield put({
        type: UPDATE_CURRENT_SALES_STAGE,
        data: {
          clientId,
          referralId: draggableId,
          sales: {
            "Current stage": endColumn.value,
            "Sales stage": {
              [endColumn.value]: {
                date: getEpoch(),
              },
            },
          },
          openChecklist,
        },
      })
    }

    const payload = {
      dragDropColumns: {
        ...dragDropColumns,
        [source.droppableId]: startReferrals,
        [destination.droppableId]: endReferrals,
      },
    }
    yield put(ReferralActions.changeInboundReferralStageAction.success(payload))
  } catch (e) {
    console.log("Failed to change stage of the inbound referrals", e)
  }
}

function* watchReferralSearchType(action) {
  try {
    yield delay(500)
    yield put(ReferralActions.searchReferralByName(action.data))
  } catch (error) {
    console.log("Error")
  }
}

export function* fetchSignedAgreement(action) {
  try {
    const { push, callback, isModal } = action.data
    const res = yield call(fetchSignedAgreementAPI)
    if (isSuccess(res)) {
      yield put(ReferralActions.signCRSAgreementAction.success(res))
      const {
        data: {
          response: { url },
        },
      } = res
      const { search } = root.location
      if (isModal) {
        //callback(url)
        root.open(url, "_blank")
      } else {
        push(`/marketplace/welcome${search}&docusignURL=${encodeURIComponent(url)}`)
        callback()
      }
    }
    yield put(ReferralActions.fetchSignedAgreementAction.success(res))
  } catch (e) {
    yield put(ReferralActions.fetchSignedAgreementAction.failure(e))
  }
}

function* signCRSAgreement(action) {
  try {
    const {
      envelopeId, returnUrl, referralId, push, type,
    } = action.data
    const user = cookie.load("user")
    let res = null
    if (!type) {
      yield put(archiveReferralAction({ referralId }))
      res = yield call(signCRSAgreementAPI, user.id, envelopeId, returnUrl)
    } else {
      res = yield call(signCRSSenderAgreementAPI, envelopeId, returnUrl)
    }
    if (isSuccess(res)) {
      yield put(ReferralActions.signCRSAgreementAction.success(res))
      yield put(archiveReferralAction({ referralId: false }))
      const {
        data: {
          response: { url },
        },
      } = res
      //window.location.href = `${APP_URL}/referrals/sign-contract?url=${encodeURIComponent(url)}`
      push(`/referrals/sign-contract?url=${encodeURIComponent(url)}`)
    }
  } catch (e) {
    yield put(archiveReferralAction({ referralId: false }))
    yield put(ReferralActions.signCRSAgreementAction.failure(e))
  }
}

function* watchArchiveReferralType(action) {
  try {
    const { type, search } = action.data
    yield delay(500)
    yield put(ReferralActions.fetchArchivedReferralsAction.request({ type, text: search }))
  } catch (e) {
    console.log(e)
  }
}

function* subscribe(action) {
  try {
    const toFetchSubscriptions = action.data.toFetchSubscriptions || false
    delete action.data.toFetchSubscriptions
    const response = yield call(subscribeAPI, action.data)
    if (isSuccess(response)) {
      yield put(ReferralActions.subscribeAction.success())

      if (toFetchSubscriptions) {
        yield put(ReferralActions.fetchSubscriptionsAction.request())
      }
    }
  } catch (e) {
    yield put(ReferralActions.subscribeAction.failure(e))
  }
}

function* unsubscribe(action) {
  try {
    const toFetchSubscriptions = action.data.toFetchSubscriptions || false
    delete action.data.toFetchSubscriptions
    const response = yield call(unsubscribeAPI, action.data)
    if (isSuccess(response)) {
      yield put(ReferralActions.unsubscribeAction.success())

      if (toFetchSubscriptions) {
        yield put(ReferralActions.fetchSubscriptionsAction.request())
      }
    }
  } catch (e) {
    yield put(ReferralActions.unsubscribeAction.failure(e))
  }
}

function* fetchInvoices() {
  try {
    const res = yield call(fetchInvoicesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.fetchInvoicesAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchInvoicesAction.failure(e))
  }
}

function* fetchPlans() {
  try {
    const res = yield call(fetchPlansAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.fetchPlansAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchPlansAction.failure(e))
  }
}

function* fetchSubscriptions() {
  try {
    const res = yield call(fetchSubscriptionsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      const { assist_features: assistFeatures, lead_cap_threshold: leadsCapThreshold } = response
      const featureMap = createFeatureMap(assistFeatures)
      yield put(ReferralActions.fetchSubscriptionStatusAction.request())
      yield put(ReferralActions.fetchSubscriptionStatusEOIAction.request())
      yield put(ReferralActions.fetchSubscriptionsAction.success({ response, featureMap, leadsCapThreshold }))
    }
  } catch (e) {
    yield put(ReferralActions.fetchSubscriptionStatusAction.request())
    yield put(ReferralActions.fetchSubscriptionStatusEOIAction.request())
    yield put(ReferralActions.fetchSubscriptionsAction.failure(e))
  }
}

function* handleFetchSubscriptionStatus() {
  try {
    let payload = ""
    const isBroker = cookie.load("isSubscribedToBrokerPlan")
    if (isBroker) {
      payload = "productName=broker"
    }
    const isNetwork = cookie.load("isSubscribedToNetworkPlan")
    if (isNetwork) {
      payload = "productName=rn"
    }
    //const payload = "productName=broker"
    const res = yield call(fetchSubscriptionStatusAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      const { response: responseData } = response
      const status = responseData?.status || null
      const endDate = responseData?.current_period_end || null
      Cookies.save("subscriptionStatus", status)
      yield put(ReferralActions.setSubscriptionStatusAction.call(status))
      yield put(ReferralActions.setSubscriptionEndDateAction.call(endDate))
      yield put(ReferralActions.setSubscriptionExistAction.call(true))
      yield put(ReferralActions.setSubcriptionStatusObjectAction.call(responseData))
      yield put(ReferralActions.fetchSubscriptionStatusAction.success(responseData))
    }
  } catch (e) {
    yield put(ReferralActions.setSubscriptionStatusAction.call(null))
    yield put(ReferralActions.setSubscriptionEndDateAction.call(null))
    yield put(ReferralActions.setSubscriptionExistAction.call(false))
    yield put(ReferralActions.fetchSubscriptionStatusAction.failure(e))
  }
}

function* handleFetchSubscriptionStatusEOI() {
  try {
    let payload = "productName=eoi"

    const res = yield call(fetchSubscriptionStatusAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      const { response: responseData } = response
      const status = responseData?.status || null
      const endDate = responseData?.current_period_end || null
      Cookies.save("EOIstatusForSubscription", status)
      Cookies.save("EOISubIdForPayload",responseData?.id )
      yield put(ReferralActions.fetchSubscriptionStatusEOIAction.success(responseData))
    }
  } catch (e) {
    yield put(ReferralActions.fetchSubscriptionStatusEOIAction.failure(e))
  }
}

function* fetchDefaultCard() {
  try {
    const res = yield call(fetchDefaultCardAPI)
    if (isSuccess(res)) {
      const { card } = res.data.response
      yield put(ReferralActions.fetchDefaultCardAction.success(card))
    }
  } catch (e) {
    yield put(ReferralActions.fetchDefaultCardAction.failure(e))
  }
}

function* updateCard(action) {
  try {
    const res = yield call(updateCardAPI, action.data)
    if (isSuccess(res)) {
      yield put(ReferralActions.updateCardAction.success())
      yield put(ReferralActions.fetchDefaultCardAction.request())
    }
  } catch (e) {
    yield put(ReferralActions.updateCardAction.failure(e))
  }
}

function* fetchProspects(action) {
  try {
    const { agentId, referralType } = action.data
    const res = yield call(fetchProspectsAPI, agentId, referralType)
    if (isSuccess(res)) {
      const {
        response: { prospects },
      } = res.data
      yield put(ReferralActions.fetchProspectsAction.success({ prospects, referralType }))
      yield put(ReferralActions.updateCurrentProspectAction())
    }
  } catch (e) {
    yield put(ReferralActions.fetchProspectsAction.failure(e))
  }
}

function* updateSingleProspect(action) {
  try {
    const {
      agentId,
      referralType,
      referralId,
      prospectStatus,
      modalState,
      isFromLMP,
      skip,
      push,
      isMail,
      isFromDecline,
    } = action.data

    const payload = {
      referral_id: referralId,
      referral_type: referralType,
      prospect_status: prospectStatus,
    }

    if (isFromDecline) {
      payload.archive = true
    }

    if (!skip) {
      const res = yield call(updateProspectAPI, agentId, payload)
      if (isSuccess(res)) {
        const {
          response: { client_id, referral_id },
        } = res.data
        yield put(ReferralActions.updateProspectAction.success())
        if (modalState) {
          yield put(ReferralActions.lastAcceptedReferralAction.call({ clientId: client_id, referralId: referral_id }))
          yield put(ReferralActions.updateProspectModalAction(modalState))
        }
        if (isFromLMP) {
          yield put(claimUnverifiedLeadAction.success({}))
          yield put(ReferralActions.fetchSingleProspectAction.request({
            referralId,
            isCallingPage: true,
            isVerified: true,
            isRedirectAfterFetch: true,
            agentId,
            push,
            isMail,
          }))
        }
        if (isFromDecline) {
          root.location.reload()
        }
      } else {
        yield put(ReferralActions.updateProspectModalAction("failure"))
      }
    }
    if (modalState) {
      yield put(ReferralActions.updateProspectModalAction(modalState))
    }
    yield put(ReferralActions.updateCurrentProspectAction())
    return
  } catch (e) {
    const { status, data: { error } = {} } = e || {}
    if (status === 400) {
      yield put(ReferralActions.updateProspectAction.failure({ errorCode: error.code, errorMessage: error.message }))
    }
    yield put(ReferralActions.updateProspectModalAction("failure"))
    return
  }
}

function* referLead(action) {
  try {
    const { payload, refreshReferrals } = action.data
    const res = yield call(referLeadAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.referLeadAction.success(response))
      if (refreshReferrals) {
        yield put(closeISAAddClientModalAction())
        yield put(ReferralActions.fetchReferalMetricAction.request("isa"))
        yield put(ReferralActions.fetchReferralsAction.request({ type: "isa", referralCategories: "isaSubmitted" }))
      }
    }
  } catch (e) {
    // const {
    //   request: { status, data: { error } = {} },
    // } = e || {}
    const { status, request: { response } } = e
    const responseParsed = JSON.parse(response)
    const { error: { message: errorMessage } } = responseParsed
    const message = status === 400 ? errorMessage : "Something went wrong! Please try again later."
    yield put(ReferralActions.referLeadAction.failure({ message }))
  }
}

function* closeChecklist(action) {
  try {
    yield put(ReferralActions.fetchReferalMetricAction.request(action.data || "in"))
  } catch (e) {
    // Throw exception
  }
}

function* fetchLead(action) {
  let res = ""
  const secureId = Cookies.load("secureId")
  const {
    referralId,
    agentId,
    push,
  } = action.data
  const headers = new Headers()
  headers.append("secure-id", secureId)
  const url = `${API_V2_URL}prospects/${agentId}/${referralId}?referralType=incoming`
  const authFetch = () => fetch(url, {
    headers,
    mode: "cors",
  })
  try {
    res = yield call(authFetch)
    const body = yield res.json()
    // Check for expired lead
    if (res.status > 399) {
      const { error } = body
      if (error.code === 206) {
        yield put(ReferralActions.leadExpiredAction())
        yield fetchLeadObject(referralId, agentId, push)
        return
      }
      if (error.code === 207) {
        push("/error/lead-expired")
        return
      }

      if (error.code === 205) {
        push("/error/lead-not-available")
        return
      }
      yield put(ReferralActions.leadExpiredAction(error))
      push("/error/lead-expired")
    } else {
      const leadToPopUp = body.response
      const newLeadsCount = yield select(state => state.marketplace.currentNewLeadsCount)
      if (leadToPopUp) {
        yield put(toggleLeadDetailsViewAction.call({
          isShowLeadDetailModal: true,
          currentLead: leadToPopUp,
        }))
        yield put(showRedirectionLoaderAction.call(false))
        if (!leadToPopUp.is_seen) {
          yield put(leadViewedAction.request({
            id: referralId,
          }))
          yield put(setCurrentNewLeadsAvailableAction.call(newLeadsCount - 1))
        }
      }
      yield put(ReferralActions.showLeadModalByIdAction(body.response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchLeadAction.failure(e))
    // Ignore Error
  }
}

const getEmailObject = (email, name, type, location) => {
  const user = checkForUserObject("user")

  const subject = `${name}, here to help with your home ${type === "Buyer" ? "search" : "sale"}`

  const body = `
  Hi ${name}

  I understand you reached out about your home ${type === "Buyer" ? "search" : "sale"} in ${location}.
  I’d be happy to share insights and answer any market questions you may have.
  Even if you're not quite ready, it's always best to be prepared in this housing market.
  Do you have a few minutes to chat about this today?

  Thank you,
  ${user.firstname} ${user.lastname}
  `

  return `mailto:${email}?Subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}

function* fetchLeadObject(referralId, agentId, push, isUnverified) {
  try {
    if (!push) {
      return
    }
    const secureId = Cookies.load("secureId")
    const headers = new Headers()
    headers.append("secure-id", secureId)
    const leadCall = () => fetch(`${API_V2_URL}prospects/${agentId}/${referralId}?referralType=incoming&prospect_status=accepted`, {
      headers,
      mode: "cors",
    })
    const res = yield call(leadCall)
    if (isSuccess(res)) {
      const body = yield res.json()
      const { response } = body
      const { client_id, referral_id } = response || {}
      if (isUnverified) {
        yield put(ReferralActions.fetchSingleProspectAction.success(response))
      }
      if (push) {
        const { search } = root.location
        const urlPrams = query.parse(search)
        urlPrams.referralId = referral_id
        yield put(showRedirectionLoaderAction.call(false))
        root.location.href = `/client/${client_id}`
        return
      }
    }
  } catch (error) {
    console.warn("Error Here...", error)
  }
}

function* handleSingleProspects(action) {
  try {
    const {
      referralId,
      agentId,
      isVerified,
      push,
      isRedirectAfterFetch,
      isMail,
    } = action.data
    const secureId = Cookies.load("secureId")
    const user = checkForUserObject("user")
    const headers = new Headers()
    headers.append("secure-id", secureId)
    const leadCall = () => fetch(`${API_V2_URL}prospects/${agentId}/${referralId}?referralType=incoming`, {
      headers,
      mode: "cors",
    })
    const res = yield call(leadCall)
    const body = yield res.json()
    // Check for expired lead
    if (res.status === 400) {
      yield put(toggleLeadDetailsViewAction.call({
        isShowLeadDetailModal: false,
        currentLead: null,
      }))
      const { error } = body
      if (error.code === 206) {
        yield put(ReferralActions.leadExpiredAction())
        yield fetchLeadObject(referralId, agentId, push, true)
        yield put(toggleCallFeedbackModalAction.call(false))
        return
      }
      if (error.code === 207) {
        yield put(toggleCallFeedbackModalAction.call(false))
        push("/error/lead-expired")
        return
      }

      if (error.code === 205) {
        yield put(toggleCallFeedbackModalAction.call(false))
        push("/error/lead-not-available")
        return
      }
      yield put(ReferralActions.leadExpiredAction(error))
      yield put(toggleCallFeedbackModalAction.call(false))
      push("/error/lead-expired")
    } else {
      if (isRedirectAfterFetch && !isMail) {
        const channel = yield call(countdown, 6)
        yield takeEvery(channel, startCallingCountdown)
      }
      yield put(ReferralActions.fetchSingleProspectAction.success(body.response))
      if (isMail) {
        const {
          response,
        } = body

        const {
          referral_type,
          location,
          client: {
            email,
            name,
          },
        } = response
        root.open(getEmailObject(email, name, referral_type, location), "_blank")
        yield put(toggleLeadDetailsViewAction.call({
          isShowLeadDetailModal: false,
          currentLead: null,
        }))
        yield put(getLeadForMarketplaceAction.request({
          agentId: user.agent_id,
        }))
        yield put(creatingMailForVerifiedLeadAction.call(false))
      }
    }
    // if (isSuccess(res)) {
    //   const body = yield res.json()
    //   const { response } = body
    //   yield put(ReferralActions.fetchSingleProspectAction.success(response))
    // }
  } catch (error) {

    yield put(creatingMailForVerifiedLeadAction.call(false))
    yield put(toggleLeadDetailsViewAction.call({
      isShowLeadDetailModal: false,
      currentLead: null,
    }))
    yield put(ReferralActions.fetchSingleProspectAction.failure(error))
  }
}

function* updateLeadReferralState(action) {
  try {
    const { referral_id, agentId } = action.data
    const payload = {
      referral_id,
      referral_type: "incoming",
      prospect_status: 5,
    }
    const res = yield call(updateLeadReferralStateAPI, agentId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.updateLeadReferralStateAction.success(response))
    }
  } catch (err) {
    // Ignore Error
    console.warn(err)
  }
}

// function* addPlanToCart(action) {
//   const planToAdd = action.data
//   try {
//     const cart = yield select(ReferralSelectors.getCartFromStore)
//     const newCart = {
//       ...cart,
//     }
//
//
//     yield put(ReferralActions.updatePricingCart.action({
//       ...cart,
//       [planToAdd]: true,
//     }))
//   } catch (e) {
//     console.warn(e)
//   }
// }
//
// function* removePlanFromCart(action) {
//   const planToRemove = action.data
//   try {
//     const cart = yield select(ReferralSelectors.getCartFromStore)
//     const newCart = {
//       ...cart,
//     }
//     delete newCart[planToRemove]
//     yield put(ReferralActions.updatePricingCart.action(newCart))
//   } catch (e) {
//     console.warn(e)
//   }
// }

function* applyPricingCoupon(action) {
  try {
    const { couponCode } = action.data
    const cart = yield select(ReferralSelectors.getCartFromStore)
    const plans = []
    Object.keys(cart).forEach((product) => {
      if (cart[product]) {
        plans.push(cart[product])
      }
    })

    const payload = {
      plans,
      couponCode,
    }

    const res = yield call(applyPricingCouponAPI, payload)
    if (isSuccess(res)) {
      const { trialInfo } = res.data.response
      let payload
      if (trialInfo) {
        const { trialDays, planId } = trialInfo
        payload = {
          isValid: true,
          planId,
          trialDays,
          couponCode,
        }
      } else {
        payload = {
          isValid: false,
          planId: "",
          trialDays: null,
          couponCode,
        }
      }
      yield put(ReferralActions.applyPricingCoupon.success(payload))
    }
  } catch (e) {
    yield put(ReferralActions.applyPricingCoupon.failure(e))
  }
}

function* checkoutCart(action) {
  try {
    const { tokenId, teamSize, now } = action.data

    const payload = {
      tokenId,
    }
    if (teamSize) payload.admin_count = teamSize
    if (now) payload.now = now

    const cart = yield select(ReferralSelectors.getCartFromStore)
    const plans = []
    Object.keys(cart).forEach((product) => {
      if (cart[product]) {
        plans.push(cart[product])
      }
    })
    // If invite code is there use it in API
    const inviteCode = cookie.load("inviteCode")
    if (inviteCode) {
      payload.stlInvitationCode = inviteCode
    }

    payload.plans = plans

    const coupon = yield select(ReferralSelectors.getCouponObjectFromStore)
    const { isValid, couponCode } = coupon
    if (isValid) {
      payload.couponCode = couponCode
    }

    const res = yield call(checkoutPricingAPI, payload)
    if (isSuccess(res)) {
      const { success, data } = res.data.response
      if (success) {
        yield put(ReferralActions.checkoutPricingCart.success({
          paymentModalState: paymentConfirmationStates.success,
          cart: {},
        }))
        yield put(ReferralActions.clearCouponData.action())
        // Clear the inviteCode
        if (inviteCode) {
          cookie.remove("inviteCode")
        }
      } else {
        const newCart = {}
        data.forEach((planObject) => {
          const product = getProductFromPlanId(planObject.planId)
          newCart[product] = planObject.planId
        })
        yield put(ReferralActions.checkoutPricingCart.success({
          paymentModalState: paymentConfirmationStates.failure,
          cart: newCart,
        }))
        if (isValid) {
          yield put({
            type: ReferralActions.applyPricingCoupon.REQUEST,
            data: {
              couponCode,
            },
          })
        }
      }
      // if (toFetchSubscriptions) {
      //   yield put(ReferralActions.fetchSubscriptionsAction.request())
      // }
    }
  } catch (e) {
    yield put(ReferralActions.checkoutPricingCart.failure(e))
  }
}

function* checkPartnerSubscriptionInterests(action) {
  try {
    const res = yield call(fetchPartnerSubscriptionInterestAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.checkPartnerSubscriptionInterestsAction.success({
        isInterested: response.subscribed
      }))
    }
  } catch (e) {
    yield put(ReferralActions.checkPartnerSubscriptionInterestsAction.failure(e))
  }
}

function* submitInterestForm(action) {
  const { interestFormObject, isLoggedinForm } = action.data
  try {
    const res = yield call(submitInterestFormAPI, interestFormObject)
    if (isSuccess(res)) {
      yield put(ReferralActions.submitInterestFormAction.success())
      if (isLoggedinForm) {
        yield put(userConfirmationModalAction.call({
          question: "Thank you for you interest!",
          isShowModal: true,
        }))
        const { confirm } = yield race({
          confirm: take(ClientSaga.afterConfirmSteps),
        })
        if (confirm.data) {
          yield put(ReferralActions.toggleInterestConfirmationModalAction.call())
        }
      }
    }
  } catch (e) {
    yield put(ReferralActions.submitInterestFormAction.failure(e))
  }
}

function* handleISAFormDropdownValueRequest() {
  try {
    const res = yield call(getISAFormDropdownValuesAPI)
    if (isSuccess(res)) {
      yield put(ReferralActions.getISAFormDropdownValuesAction.success(res))
    }
  } catch (e) {
    yield put(ReferralActions.getISAFormDropdownValuesAction.failure(e))
  }
}

function* handleMetricSMS() {
  try {
    const res = yield call(sendSMSMetricsAPI)
    if (isSuccess(res)) {
      yield put(ReferralActions.sendMetricSMSAction.success())
    }
  } catch (e) {
    yield put(ReferralActions.sendMetricSMSAction.failure())
  }
}

export const getDuration = (duration, id) => {
  if (id.indexOf("yearly") > -1) {
    return "Annual Plan"
  }

  switch (duration) {
    case 1:
      return `${duration} month plan`
    case 6:
      return `${duration} month plan`
    case 12:
      return `${duration} month plan`
    default:
      return `${duration} month plan`
  }
}

function createFeatureMap(assistFeatureData) {
  const featureMapping = {}
  assistFeatureData.forEach((feature) => {
    featureMapping[feature.key] = {
      value: feature.value,
    }
  })
  return featureMapping
}

function createMap(plansObj) {
  const planMapping = {}
  const {
    single_plans: {
      rtl: singleRTL,
      blu: singleBLU,
      broker,
    },
    team_plans: {
      rtl: teamRTL,
      blu: teamBLU,
    },
  } = plansObj

  const getInstallmentDuration = (id) => {
    if (id.indexOf("monthly") > -1) {
      return "per month"
    }

    if (id.indexOf("quarterly") > -1) {
      return "per 3 months"
    }

    if (id.indexOf("six_months") > -1) {
      return "per 6 months"
    }

    return "per month"
  }

  const getCommitment = (duration, id) => {
    if (id.indexOf("yearly") > -1) {
      return "annually"
    }

    if (id.indexOf("six") > -1) {
      return "per 6 months"
    }

    if (id.indexOf("quarterly") > -1) {
      return "per 3 months"
    }
    return "Month(s)"
  }

  const getAmountLabel = (duration, id) => {
    if (id.indexOf("yearly") > -1 && id.indexOf("quarterly") > -1) {
      return 3
    }
    if (id.indexOf("yearly") > -1 && (id.indexOf("six") > -1)) {
      return 2
    }
    if (id.indexOf("yearly") > -1 && (id.indexOf("monthly") > -1)) {
      return 6
    }
    if (id.indexOf("six") > -1 && id.indexOf("quarterly") > -1) {
      return 2
    }

    if (id.indexOf("yearly") > -1) {
      return 12
    }

    return duration
  }

  broker.forEach((planObj) => {
    planMapping[planObj.stripe_plan_id] = {
      name: planObj.name,
      isInstallment: planObj.plan_id.indexOf("installment") > -1,
      id: planObj.stripe_plan_id,
      totalInstallmentPrice: `${planObj.unit_price * planObj.billing_interval}`,
      installmentDuration: getInstallmentDuration(planObj.stripe_plan_id),
      amount: planObj.unit_price,
      amountLabel: planObj.plan_id.indexOf("installment") > -1 ? `$${planObj.unit_price}`
        : `$${planObj.unit_price / getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}
      x ${getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
      duration: getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id),
      interval: planObj.billing_interval,
      description: getDuration(planObj.billing_interval, planObj.stripe_plan_id),
      product: "rtl",
      type: "single",
      tier: planObj.plan_tier,
      isTeam: false,
      commitment: getCommitment(planObj.billing_interval, planObj.stripe_plan_id),
      benefits: planObj.plan_benefits,
      isLegacy: planObj.is_legacy_plan,
      isRecommended: planObj.is_recommended,
      isVisible: planObj.is_instalment_plan,
    }
  })

  singleRTL.forEach((planObj) => {
    planMapping[planObj.stripe_plan_id] = {
      name: planObj.name,
      isInstallment: planObj.plan_id.indexOf("installment") > -1,
      id: planObj.stripe_plan_id,
      amount: planObj.unit_price,
      installmentDuration: getInstallmentDuration(planObj.stripe_plan_id),
      totalInstallmentPrice: `${planObj.unit_price * getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
      amountLabel: planObj.plan_id.indexOf("installment") > -1 ? `$${planObj.unit_price}`
        : `$${planObj.unit_price / getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}
      x ${getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
      duration: getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id),
      interval: planObj.billing_interval,
      description: getDuration(planObj.billing_interval, planObj.stripe_plan_id),
      product: "rtl",
      type: "single",
      tier: planObj.plan_tier,
      isTeam: false,
      commitment: getCommitment(planObj.billing_interval, planObj.stripe_plan_id),
      benefits: planObj.plan_benefits,
      isLegacy: planObj.is_legacy_plan,
      isRecommended: planObj.is_recommended,
      isVisible: planObj.is_instalment_plan,
    }
  })

  singleBLU.forEach((planObj) => {
    planMapping[planObj.stripe_plan_id] = {
      name: planObj.name,
      isInstallment: planObj.plan_id.indexOf("installment") > -1,
      id: planObj.stripe_plan_id,
      installmentDuration: getInstallmentDuration(planObj.stripe_plan_id),
      amount: planObj.unit_price,
      totalInstallmentPrice: `${planObj.unit_price * getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
      amountLabel: planObj.plan_id.indexOf("installment") > -1 ? `$${planObj.unit_price}`
        : `$${planObj.unit_price / getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}
      x ${getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,      interval: planObj.billing_interval,
      duration: getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id),
      description: "Pay as you go",
      product: "blu",
      type: "single",
      tier: planObj.plan_tier,
      isTeam: false,
      commitment: "Annual",
      benefits: planObj.plan_benefits,
      isLegacy: planObj.is_legacy_plan,
      monthlyPrice: planObj.monthly_price,
      isRecommended: planObj.is_recommended,
      quota: planObj.quota,
      isVisible: planObj.is_instalment_plan,
    }
  })

  teamRTL.forEach((planObj) => {
    planMapping[planObj.stripe_plan_id] = {
      name: planObj.name,
      isInstallment: planObj.plan_id.indexOf("installment") > -1,
      id: planObj.stripe_plan_id,
      installmentDuration: getInstallmentDuration(planObj.stripe_plan_id),
      amount: planObj.monthly_price,
      totalInstallmentPrice: `${planObj.unit_price * getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
      amountLabel: planObj.plan_id.indexOf("installment") > -1 ? `$${planObj.unit_price}`
        : `$${planObj.unit_price / getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}
      x ${getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
      duration: getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id),
      interval: planObj.billing_interval,
      description: getDuration(planObj.billing_interval, planObj.stripe_plan_id),
      product: "rtl",
      type: "team",
      tier: planObj.plan_tier,
      isTeam: true,
      outstandingPrice: planObj.outstanding_lead_price,
      commitment: getCommitment(planObj.billing_interval, planObj.stripe_plan_id),
      benefits: planObj.plan_benefits,
      isLegacy: planObj.is_legacy_plan,
      isVisible: planObj.is_instalment_plan,
    }
  })

  if (teamBLU) {
    teamBLU.forEach((planObj) => {
      planMapping[planObj.stripe_plan_id] = {
        name: planObj.name,
        id: planObj.stripe_plan_id,
        isInstallment: planObj.plan_id.indexOf("installment") > -1,
        amount: planObj.unit_price,
        installmentDuration: getInstallmentDuration(planObj.stripe_plan_id),
        totalInstallmentPrice: `${planObj.unit_price * getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
        amountLabel: planObj.plan_id.indexOf("installment") > -1 ? `$${planObj.unit_price}`
          : `$${planObj.unit_price / getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}
        x ${getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id)}`,
        duration: getAmountLabel(planObj.billing_interval, planObj.stripe_plan_id),
        interval: planObj.billing_interval,
        description: `${planObj.billing_interval} month${planObj.billing_interval > 1 ? "s" : ""} plan`,
        product: "blu",
        type: "team",
        tier: planObj.plan_tier,
        isTeam: true,
        commitment: "Annual",
        benefits: planObj.plan_benefits,
        isLegacy: planObj.is_legacy_plan,
        isVisible: planObj.is_instalment_plan,
      }
    })
  }

  return planMapping
}

function* handlePricingPlans() {
  try {
    const res = yield call(fetchPricingPlansAPI)
    if (isSuccess(res)) {
      const { data: { response } } = res
      const mapping = createMap(response)
      yield put(ReferralActions.createPricingMapAction.call(mapping))
      yield put(ReferralActions.fetchPricingPlansAction.success(response))
    }
  } catch (e) {
    console.log(e)
    yield put(ReferralActions.fetchPricingPlansAction.failure(e))
  }
}

function* fetchAcceptLeadValues() {
  try {
    const res = yield call(fetchAcceptLeadDropdownValuesAPI)
    if (isSuccess(res)) {
      const { data: { response } } = res
      yield put(ReferralActions.fetchAcceptLeadDropdownValuesAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchAcceptLeadDropdownValuesAction.failure())
  }
}

function* handleSetPermission(action) {
  try {
    const { data } = action
    const {
      value: {
        type,
        data: permissionValue,
        id,
      },
    } = data
    let payload = {}
    if (type === "accept") {
      payload = {
        team_broker_acceptance_preference: {
          id,
          value: permissionValue,
        },
      }
      const res = yield call(setTeamPermissionsAPI, payload)
      if (isSuccess(res)) {
        yield put(ReferralActions.setPermissionValueAction.success(res))
        yield put(ReferralActions.configureAcceptPermissionAction.call({ data: data.value }))
      }
    } else {
      payload = {
        team_broker_assign_preference: {
          id,
          value: permissionValue,
        },
      }
      const res = yield call(setTeamPermissionsAPI, payload)
      if (isSuccess(res)) {
        yield put(ReferralActions.setPermissionValueAction.success(res))
        yield put(ReferralActions.configureAssignPermissionAction.call({ data: data.value }))
      }
    }
  } catch (e) {
    yield put(ReferralActions.setPermissionValueAction.failure())
  }
}

function* handleFetchTeams(action) {
  try {
    const { data } = action
    const { days, label } = data
    const res = yield call(fetchTeamMembersAPI, days)
    if (isSuccess(res)) {
      const { data: { response } } = res
      yield put(ReferralActions.setCurrentTeamFilterAction.call({ value: days, label }))
      yield put(ReferralActions.updateTeamSizeAction.call(response.total_seats))
      yield put(ReferralActions.fetchTeamMembersAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchTeamMembersAction.failure(e))
  }
}

function* handleFetchFilterValues() {
  try {
    const res = yield call(fetchTeamFilterValuesAPI)
    if (isSuccess(res)) {
      const { data: { response } } = res
      yield put(ReferralActions.fetchTeamFilterValuesAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchTeamFilterValuesAction.failure(e))
  }
}

function* handleReferOut(action) {
  const {
    id,
    referralRecieverID,
    push,
    isFromClientPage,
    referralType = "isa",
    isMarkAsQualified,
    clientId,
  } = action.data
  let timeout = false
  const leadAgreementSigned = checkForUserObject("leadAgreementSigned")
  const isSubscribedToAnyISAPlan = yield select(state => state.app.user.isSubscribedToAnyISAPlan)
  if (!isMarkAsQualified && leadAgreementSigned !== "signed") {
    yield put(toggleSignAgreementModalAction.call(true))
    return
  }
  if (!isMarkAsQualified) {
    yield put(ReferralActions.referOutModalAction.call({
      question: "Are you sure?",
      isShowModal: true,
    }))
  }
  const { confirm, timedout } = yield race({
    confirm: take(ClientSaga.afterConfirmSteps),
    timedout: isMarkAsQualified ? delay(10) : delay(1000 * 60 * 60 * 10)
  })
  if ((confirm && confirm.data) || timedout) {
    try {
      const payload = {
        referral_id: id,
        prospect_status: 2,
        referral_type: referralType,
      }
      let res = null
      if (referralType === "crm") {
        // handle differently
        const { clientObjFull: { type } } = action.data
        const crmPayload = {
          clientId,
          type,
          is_isa: isMarkAsQualified,
        }
        res = yield call(referLeadAPI, crmPayload)
      } else {
        yield put(ReferralActions.referOutAction.request())
        res = yield call(referOutAPI, referralRecieverID, payload)
      }

      if (isSuccess(res)) {
        yield put(ReferralActions.referOutAction.success(res))
        yield put(ReferralActions.referConfirmedAction.call())
        if (isFromClientPage) {
          const { response } = res.data
          const { client_id } = response || {}
          if (referralType === "crm") {
            if (isMarkAsQualified) {
              push(`/outbound/${clientId}?type=isa`)
            } else {
              push(`/outbound/${clientId}`)
            }
          } else {
            push(`/outbound/${client_id}`)
          }
        } else {
          yield put(ReferralActions.fetchReferralsAction.request({ type: "isa", referralCategories: "isaPipeline" }))
        }
      }
    } catch (e) {
      if (e && e.status === 400) {
        yield put(ReferralActions.referOutModalAction.call({
          question: "Error Occurred",
          isShowModal: true,
        }))
      }
      yield put(ReferralActions.referOutAction.failure(e))
    }
  }
}

function* handleAgentSources() {
  try {
    const res = yield call(fetchSourcesAPI)
    if (isSuccess(res)) {
      const { data: { response } } = res
      yield put(ReferralActions.fetchSourcesAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchSourcesAction.failure(e))
  }
}

function* handlePlanDurationFetch(action) {
  try {
    const { type } = action.data
    const res = yield call(fetchPlanDurationsAPI, type)
    if (isSuccess(res)) {
      const { data: { response } } = res
      yield put(ReferralActions.fetchPlanDurationAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.fetchPlanDurationAction.failure(e))
  }
}

function* handleInviteFlow(action) {
  try {
    yield put(ReferralActions.getInviteeForSTLAction.request())
    const { invitee, isShowInviteBanner = true } = action.data
    const isNum = val => /^\d+$/.test(val)
    const agentQuery = isNum(invitee) ? `?agentId=${invitee}` : `?profileName=${invitee}`
    const res = yield call(getUserProfileAPI, agentQuery)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getInviteeForSTLAction.success(response))
      if (isShowInviteBanner) {
        yield put(ReferralActions.toggleInviteModalAction.call(true))
      }
    }
  } catch (error) {
    yield put(ReferralActions.getInviteeForSTLAction.failure(error))
  }
}

function* handleInviteModalClose(action) {
  if (!action.data) {
    yield put(ReferralActions.toggleInviteBannerAction.call(true))
  }
}

function* handleExportToExcel() {
  try {
    const params = yield select(state => state.referral.parsedQueryFinal)
    const res = yield call(exportToExcelAPI, params)
    const { data } = res
    const csv = `data:text/csv;charset=utf-8,${data}`
    const encodedUri = encodeURI(csv)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", `${format(new Date(), "MMM-DD-YYYY")}-${uuid()}.csv`)
    document.body.appendChild(link)
    link.click()
    yield put(ReferralActions.exportToExcelAction.success(data))
  } catch (error) {
    yield put(ReferralActions.exportToExcelAction.failure(error))
  }
}

function* handlISAPageVisited(action) {
  try {
    const { payload } = action.data
    const res = yield call(trigglerISAPageVisitAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.FSISAPageVisitedAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.FSISAPageVisitedAction.failure(error))
  }
}

function* handleGetBrokerInvites() {
  try {
    const res = yield call(getBrokerInvitesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getBrokerInvitesAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.getBrokerInvitesAction.failure(error))
  }
}

function* handleGetBrokerEarnings(action) {
  try {
    const { createdAt } = action.data
    const res = yield call(getBrokerEarningsAPI, createdAt)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getBorkerEarningsAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.getBorkerEarningsAction.failure(error))
  }
}

const toastConfig = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}

function* handleInviteBroker(action) {
  try {
    const { payload, history: hst } = action.data

    const res = yield call(inviteBrokerAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.inviteBrokerAction.success(response))
      createToastNew("Referral Sent!", toastConfig)
      yield put(ReferralActions.getCurrentUserRecruitAndEarnDetailsAction.request())
      /*Close the modal
      yield put(toggleRecruitAndEarnFormAction.call(false))
      hst.push("/realty/recruit-and-earn/earnings")
      */
      CookiesStorage.save("OnboardedRAE", true)
      hst.push("/realty/recruit-and-earn")
      yield put(toggleRecruitAndEarnFormViewAction.call(false))
    }
  } catch (error) {
    createToastNew(
      `Could not send the referral, please try again.${
        error?.data?.error?.message?.length
          ? ` - ${error.data.error.message}`
          : error?.data?.error?.dev_message?.length
            ? ` - ${error.data.error.dev_message}`
            : ""
      }`,
      {
        ...toastConfig,
        className: "red-background",
      }
    )
    yield put(ReferralActions.inviteBrokerAction.failure(error))
  }
}

function* handleGetClosingInfo(action) {
  try {
    const { referralId } = action.data
    const res = yield call(getClosingStageInfoAPI, referralId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getClosingStageInfoAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.getClosingStageInfoAction.failure(error))
  }
}

function* handleFetchPendingClientInboundDetails(action) {
  try {
    const {
      id,
    } = action.data || {}

    const res = yield call(getInboundPendingReferralDetailsAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getInboundPendingReferralDetailsAction.success(response))
    }
  } catch (e) {
    yield put(ReferralActions.getInboundPendingReferralDetailsAction.failure(e))
  }
}

function* handleAcceptOrRejectLead(action) {
  try {
    const {
      id,
      val,
    } = action.data || {}

    const res = yield call(acceptOrRejectLeadAPI, id, val)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.acceptOrRejectLeadAction.success(response))
      if (val === 1) {
        root.location.href = `/client/${response.client_id}/timeline?referralId=${id}`
      } else {
        root.location.href = "/referrals/inbound"
      }
    }
  } catch (e) {
    yield put(ReferralActions.acceptOrRejectLeadAction.failure(e))
    const errorMessage = get(e, "data.error.message")
    createToast(errorMessage, "error", {
      autoClose: 8000,
    })
  }
}

function* handleAgentSearch(action) {
  try {
    const { payload, onSuccess } = action.data
    const res = yield call(getAgentSearchAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.fetchAgentSearchAction.success(response))
      if (onSuccess) {
        onSuccess(response.agentsTotalCount || 0)
      }
    }
  } catch (error) {
    yield put(ReferralActions.fetchAgentSearchAction.failure(error))
  }
}

function* handleSendtoLMP(action) {
  try {
    const {
      id,
      flag,
      history,
    } = action.data
    const leadAgreementSigned = checkForUserObject("leadAgreementSigned")
    const user = checkForUserObject("user")
    const agentId = user && user.id

    if (leadAgreementSigned !== "signed") {
      //push("/unsigned")
      yield put(toggleLoaderInConfirmAction.call(false))
      yield put(ReferralActions.closeLMPModalAction.call())
      yield put(toggleSignAgreementModalAction.call(true))
      return
    }
    yield put(showConfirmModalAction.call({
      title: "Are you sure you want to send to marketplace?",
      body: " ",
      showCloseButton: true,
      successButtonText: "Yes, send to marketplace",
    }))
    const { confirm } = yield race({
      confirm: take(confirmAction),
    })
    const { data: isConfimed } = confirm
    if (isConfimed) {
      yield put(toggleLoaderInConfirmAction.call(true))
      const res = yield call(sendToMarketPlaceAPI, id)
      if (isSuccess(res)) {
        const { response } = res.data
        yield put(ReferralActions.closeLMPModalAction.call())
        // yield put(ReferralActions.fetchProspectsAction.request({
        //   agentId,
        //   referralType: "outgoing",
        // }))
        const { pathname } = root.location
        if (pathname && pathname.includes("outbound")) {
          root.location.href = "/referrals/outbound"
        }
        root.location.reload()
      }
    } else {
      yield put(toggleLoaderInConfirmAction.call(false))
      yield put(ReferralActions.closeLMPModalAction.call())
    }
  } catch (e) {
    console.log(e)
    yield put(ReferralActions.closeLMPModalAction.call())
  }
}

export function* handleFindAnAgentViewed(action) {
  try {
    const {
      type,
    } = action.data
    const payload = {
      featureName: type,
    }
    const res = yield call(findAnAgentViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.findAnAgentViewedAction
        .success(response))
    }
  } catch (error) {
    yield put(ReferralActions.findAnAgentViewedAction.failure(error))
  }
}

export function* confirmAction(action) {
  if (action.data) {
    return action.data
  }
  return false
}

export function* handlePsuedoSignUp(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(fakeSignDocusignAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.psuedoDocuSignAction.success(response))
      yield delay(10000)
      root.localStorage.setItem("isDocuSignSignedSuccessfully", true)
      root.close()
    }
  } catch (error) {
    yield put(ReferralActions.psuedoDocuSignAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(ReferralActions.fetchSignedAgreementAction.REQUEST, fetchSignedAgreement)
  yield takeLatest(ReferralActions.fetchReferralsAction.REQUEST, fetchReferrals)
  yield takeLatest(ReferralActions.fetchArchivedReferralsAction.REQUEST, fetchArchivedReferrals)
  yield takeLatest(ReferralActions.fetchFilterSkeletonAction.REQUEST, fetchFilterSkeleton)
  yield takeLatest(ReferralActions.fetchReferalMetricAction.REQUEST, fetchReferalMetric)
  yield takeLatest(ReferralActions.fetchStarReferralAction.REQUEST, fetchStarReferral)
  yield takeLatest(ReferralActions.signCRSAgreementAction.REQUEST, signCRSAgreement)
  yield takeLatest(ReferralActions.changeInboundReferralOrderAction.REQUEST, changeInboundReferralOrder)
  yield takeLatest(ReferralActions.changeInboundReferralStageAction.REQUEST, changeInboundReferralStage)
  yield takeLatest(ReferralActions.subscribeAction.REQUEST, subscribe)
  yield takeLatest(ReferralActions.unsubscribeAction.REQUEST, unsubscribe)
  yield takeLatest(ReferralActions.fetchInvoicesAction.REQUEST, fetchInvoices)
  yield takeLatest(ReferralActions.fetchPlansAction.REQUEST, fetchPlans)
  yield takeLatest(ReferralActions.fetchSubscriptionsAction.REQUEST, fetchSubscriptions)
  yield takeLatest(ReferralActions.fetchDefaultCardAction.REQUEST, fetchDefaultCard)
  yield takeLatest(ReferralActions.updateCardAction.REQUEST, updateCard)
  yield takeLatest(ReferralActions.fetchProspectsAction.REQUEST, fetchProspects)
  yield takeLatest(ReferralActions.updateProspectAction.REQUEST, updateSingleProspect)
  yield takeLatest(ReferralActions.referLeadAction.REQUEST, referLead)
  yield takeLatest(ReferralActions.SEARCH_REFERRAL_BY_NAME, searchReferralByName)
  yield takeLatest(ReferralActions.REMOVE_FILTER, removeFilter)
  yield takeLatest(ReferralActions.GET_STARRED_REFERRALS, getStarredReferrals)
  yield takeLatest(ReferralActions.FETCH_FILTERED_REFERRALS, fetchFilteredReferrals)
  yield takeLatest(ReferralActions.SEARCH_REFERRALS_TYPE_EVENT, watchReferralSearchType)
  yield takeLatest(ReferralActions.WATCH_ARCHIVE_REFERRAL_TYPE, watchArchiveReferralType)
  yield takeLatest(ReferralActions.CLOSE_CHECKLIST_MODAL, closeChecklist)
  yield takeLatest(ReferralActions.fetchLeadAction.REQUEST, fetchLead)
  yield takeLatest(ReferralActions.updateLeadReferralStateAction.REQUEST, updateLeadReferralState)
  yield takeLatest(ReferralActions.applyPricingCoupon.REQUEST, applyPricingCoupon)
  yield takeLatest(ReferralActions.checkoutPricingCart.REQUEST, checkoutCart)
  yield takeLatest(ReferralActions.fetchSingleProspectAction.REQUEST, handleSingleProspects)
  yield takeLatest(ReferralActions.checkPartnerSubscriptionInterestsAction.REQUEST, checkPartnerSubscriptionInterests)
  yield takeLatest(ReferralActions.submitInterestFormAction.REQUEST, submitInterestForm)
  yield takeLatest(ReferralActions.getISAFormDropdownValuesAction.REQUEST, handleISAFormDropdownValueRequest)
  yield takeLatest(ReferralActions.sendMetricSMSAction.REQUEST, handleMetricSMS)
  yield takeLatest(ReferralActions.fetchPricingPlansAction.REQUEST, handlePricingPlans)
  yield takeLatest(ReferralActions.fetchAcceptLeadDropdownValuesAction.REQUEST, fetchAcceptLeadValues)
  yield takeLatest(ReferralActions.setPermissionValueAction.REQUEST, handleSetPermission)
  yield takeLatest(ReferralActions.fetchTeamMembersAction.REQUEST, handleFetchTeams)
  yield takeLatest(ReferralActions.fetchTeamFilterValuesAction.REQUEST, handleFetchFilterValues)
  yield takeLatest(ReferralActions.toggleReferOutAction.type, handleReferOut)
  yield takeLatest(ReferralActions.fetchSourcesAction.REQUEST, handleAgentSources)
  yield takeLatest(ReferralActions.fetchPlanDurationAction.REQUEST, handlePlanDurationFetch)
  yield takeLatest(ReferralActions.startShareTheLoveInviteFlowAction.type, handleInviteFlow)
  yield takeLatest(ReferralActions.exportToExcelAction.REQUEST, handleExportToExcel)
  yield takeLatest(ReferralActions.toggleInviteModalAction.type, handleInviteModalClose)
  yield takeLatest(ReferralActions.FSISAPageVisitedAction.REQUEST, handlISAPageVisited)
  // Recruit and earn
  yield takeLatest(ReferralActions.getBorkerEarningsAction.REQUEST, handleGetBrokerEarnings)
  yield takeLatest(ReferralActions.getBrokerInvitesAction.REQUEST, handleGetBrokerInvites)
  yield takeLatest(ReferralActions.inviteBrokerAction.REQUEST, handleInviteBroker)

  yield takeLatest(ReferralActions.updateSalesStageFormAction.REQUEST, handleUpdateSalesStageForm)
  yield takeLatest(ReferralActions.getClosingStageInfoAction.REQUEST, handleGetClosingInfo)
  //Inbound Pending
  yield takeLatest(ReferralActions.getInboundPendingReferralDetailsAction.REQUEST, handleFetchPendingClientInboundDetails)
  yield takeLatest(ReferralActions.acceptOrRejectLeadAction.REQUEST, handleAcceptOrRejectLead)
  //Find Agent
  yield takeLatest(ReferralActions.fetchAgentSearchAction.REQUEST, handleAgentSearch)
  yield takeLatest(ReferralActions.toggleSendToMarketPlaceModalAction.type, handleSendtoLMP)
  yield takeLatest(ReferralActions.findAnAgentViewedAction.REQUEST, handleFindAnAgentViewed)
  yield takeLatest(ReferralActions.getClosingStageInfoAction.REQUEST, handleGetClosingInfo)
  yield takeLatest(ReferralActions.psuedoDocuSignAction.REQUEST, handlePsuedoSignUp)
  yield takeLatest(ReferralActions.fetchSubscriptionStatusAction.REQUEST, handleFetchSubscriptionStatus)
  yield takeLatest(ReferralActions.fetchSubscriptionStatusEOIAction.REQUEST, handleFetchSubscriptionStatusEOI)

  // yield takeLatest(ReferralActions.getTCAgentAssignedAction.REQUEST, handleFetchTCAgentAssigned)
}
