import React, { useCallback, useEffect, useState, useRef } from "react"
import uuid from "uuid"
import {
  SelectField,
} from "dumbComponents/common/InputFields"
import queryString from "query-string"
import styled from "styled-components"
import root from "window-or-global"
import { Form, Button, Select } from "@ui/antd"
import FormShimmer from "dumbComponents/common/ContractDetailsForm/components/FormShimmer"
import Offer from "container/TechAssets"
import { ShimmerBox } from "shared/styles/animation"
import { getFinancingTypeOptions } from "dumbComponents/OffersV2/consts"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import OffersV2 from "container/OffersV2"
import { withRouter } from "react-router-dom"
import { isEmpty, debounce } from "lodash"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  CTAWrap,
  ListWrap,
} from "./styles"
import AgentCard from "../AgentCard/index"
import BuyerInformationCard from "../BuyerInformationCard"
import LenderCard from "../LenderInformationCard"
import VendorCard from "../VendorInformationCard"
import {
  AddButtonCTA,
  AddButton,
} from "../../../components"
// import {
//   SelectField,
// } from "../InputFields"
import {
  BlueButton,
} from "../../../commonStyles"
import {
  BuyerAgentForm,
  BuyerForm,
  LenderForm,
  VendorForm,
} from "./Forms"
import { BUYER_INFORMATION_STEP } from "../../../consts"

const STEP = "buyers_information_mobile"
const { Option } = Select

const SelectFieldWrap = styled(Select)`
  border-radius: 4px;
  border: 0.7px solid #898989;
  width: 100%;

  .ant-select-selector {
    ${props => props.isFormField && `
      border-radius: 8px !important;
    `}
    height: 42px !important;
  }
`

const BuyerInformation = ({
  isCoAgentFormVisible,
  isAddNewLenderFormVisible,
  isAddNewVendorFormVisible,
  buyersAgentInformationObject,
  addNewCoAgentForm,
  updateBuyerInformationObject,
  isBuyerFormVisible,
  addBuyerAgentForm,
  addLenderAgentForm,
  addVendorAgentForm,
  stateForOffer,
  updateBuyerInformationTab,
  dealToView,
  toggleBasicOfferDetailsModal,
  getCurrentDealDetailsResponse,
  updateBuyerInformationTabResponse,
  updateLenderInformation,
  updateVendorInformation,
  toggleSideNavTab,
  fetchVendorInfo,
  fetchVendorInfoResponse,
  updatingBuyer: updatingBuyer,
  editingDealTeamMemberId,
  getDealDetails,
  updateLenderInformationResponse,
  history,
  isVendorUpdating,
  isLenderUpdating,
  buyerForm,
  buyerAgentFormRef,
  buyerInformationFormRef,
  buyerAgentInformationFormRef,
  financingInformationFormRef,
  vendorInformationFormRef,
}) => {
  const [data, setData] = useState({})
  const payload = { ...buyersAgentInformationObject } || {}
  const {
    financingType,
    buyers,
    agents,
  } = buyersAgentInformationObject || {}


  useEffect(() => {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    Object.keys(parsedQuery).forEach((key) => {
      if (parsedQuery[key] === "null") {
        parsedQuery[key] = null
      }
    })
    const {
      transaction_id,
      req_id,
      client_id,
    } = parsedQuery || {}

    const parsedData = {
      ...parsedQuery,
    }
    console.log("===parsedData===", parsedData)
    setData(parsedData)
  }, [])

  const {
    isFetching: fetchingBuyerData,
  } = getCurrentDealDetailsResponse || {}

  const {
    isFetching: updating,
    clients: updatedBuyers,
    agents: updatedAgents,
  } = updateBuyerInformationTabResponse || {}

  // const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(agents) && !fetchingBuyerData) {
      //toggleBasicOfferDetailsModal(true)
    }
  }, [buyersAgentInformationObject, fetchingBuyerData])

  // if (isEmpty(agents)) {
  //   return <></>
  // }

  const handleLenderGoBack = () => {
    addLenderAgentForm(false)
  }

  const handleVendorGoBack = () => {
    addVendorAgentForm(false)
  }
  const handleLenderEditToggle = () => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentSelectedLender = buyersAgentInformationObject.selectedLender
    currentSelectedLender.isLenderEditVisible = !currentSelectedLender.isLenderEditVisible
    payload.selectedLender = currentSelectedLender

    updateBuyerInformationObject(payload)
  }

  /**HANDLE LENDER FUNCTIONS*/
  const handleFinancingTypeChange = (value) => {
    const options = getFinancingTypeOptions(stateForOffer)
    console.log("value", value)

    if (value !== "cash") {
      customToast("Fetching lenders, please wait.")
    }

    const filteredForValue = options.filter(item => item.value === value)

    const payload = {
      ...buyersAgentInformationObject,
      financingType: filteredForValue[0].value,
    }

    setTimeout(() => {
      updateBuyerInformationObject(payload)
      handleFinalSubmit(true, payload,"handleFinancingTypeChange")
    }, 3000);
    // updateBuyerInformationObject(payload)
    // handleFinalSubmit(true, payload,"handleFinancingTypeChange")
  }

  const onFinishAddLender = (value) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentFinancingType = payload.financingType
    const name = value.lender_officer_name?.split(" ") || ""
    const firstName = name && name[0]
    const lastName = name && name.length > 0 && name[1] || ""
    const id = uuid.v4()
    const newLender = {
      ...value,
      value: id,
      lender_id: id,
      label: value.lender_officer_name,
      isLenderEditVisible: false,
    }
    const addNewLenderPayload = {
      agent_id: parseInt(editingDealTeamMemberId, 10),
      draft: 0,
      lender_name: value.lender_co || "",
      lender_email: value.lender_email,
      lender_address: value.lender_office_address,
      lender_phone: value.lender_phone,
      lender_officer_first_name: firstName,
      lender_officer_last_name: lastName,
      current_offer_step: "insert_lender",
      lender_city: value.city,
      lender_state: value.state,
      lender_zipcode: value.zipcode,
    }
    const lenders = [...payload.lenders, newLender]
    payload.lenders = [...lenders]
    updateBuyerInformationTab({
      id: dealToView,
      payload: addNewLenderPayload,
      currentFinancingType,
    })
    getDealDetails({
      id: dealToView,
      step: BUYER_INFORMATION_STEP,
    })
    // handleFinalSubmit(true, payload)
    addLenderAgentForm(false)
  }

  const onFinishEditLender = (values) => {
    const payload = {
      ...buyersAgentInformationObject,
    }
    const name = values.lender_officer_name?.split(" ") || ""
    const firstName = name && name[0]
    const lastName = name && name.length > 0 && name[1] || ""

    const addNewLenderPayload = {
      agent_id: parseInt(editingDealTeamMemberId, 10),
      draft: 0,
      lender_name: values.lender_co || "",
      lender_email: values.lender_email,
      lender_address: values.lender_office_address,
      lender_phone: values.lender_phone,
      lender_officer_first_name: firstName,
      lender_officer_last_name: lastName,
      lender_city: values.city,
      lender_state: values.state,
      lender_zipcode: values.zipcode
      // lender
    }
    const currentEditLender = payload.selectedLender
    const updated = {
      ...currentEditLender,
      ...values,
      label: values.lender_co,
      isLenderEditVisible: false,
    }

    payload.lenders.forEach((item, iter) => {
      if (item.lender_id === currentEditLender.lender_id) {
        payload.lenders[iter] = { ...updated }
      }
    })

    payload.selectedLender = { ...updated }
    updateBuyerInformationObject(payload)
    updateLenderInformation({
      lenderId: currentEditLender.lender_id,
      payload: addNewLenderPayload,
    })
      getDealDetails({
          id: dealToView,
          step: BUYER_INFORMATION_STEP,
        })
    handleFinalSubmit(true, payload)
  }

  const debouncedSubmit = useCallback(
    debounce(async (flag, provided, customCallback) => {
      try {
        if (!customCallback) {
          handleFinalSubmit(flag, provided)
          updateBuyerInformationObject(provided)
        } else {
          customCallback()
        }
      } catch (error) {
        console.log("Error saving form data:", error)
      }
    }, 1000),
    []
  )

  const handleChangeForLender = (value) => {
    const payload = {
      ...buyersAgentInformationObject,
    }
    const currentSelected = payload.lenders.filter(item => item.value === value || item.lender_id === value)[0]
    payload.selectedLender = currentSelected
    payload.selected_lender_id = currentSelected.lender_id

    updateBuyerInformationObject(payload)

    handleFinalSubmit(true, payload)
  }
  /**HANDLE LENDER FUNCTIONS*/

  const handleDeselectLender = () => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    payload.selectedLender = null
    payload.selected_lender_id = null
    updateBuyerInformationObject(payload)
    handleFinalSubmit(true, payload)
  }

  /**HANDLE VENDOR FUNCTIONS*/
  const onFinishAddVendor = (value) => {
    const finalPayload = {
      ...buyersAgentInformationObject,
    }

    const id = uuid.v4()

    const newVendor = {
      ...value,
      value: id,
      vendor_id: id,
      label: value.vendor_officer_name,
      isVendorEditVisible: false,
    }
    const name = value.vendor_officer_name?.split(" ") || ""
    const firstName = name && name[0]
    const lastName = name && name.length > 0 && name[1] || ""

    const addNewVendorPayload = {
      draft:0,
      vendor_name: value.vendor_office_name || "",
      vendor_email: value.vendor_email,
      vendor_address: value.vendor_address,
      vendor_phone: value.vendor_phone,
      vendor_officer_first_name: firstName,
      vendor_officer_last_name: lastName,
      current_offer_step: "insert_vendor",
      vendor_type: value.vendor_type,
      vendor_city: value.city,
      vendor_state: value.state,
      vendor_zipcode: value.zipcode,
    }

    const vendors = [...finalPayload.vendors, newVendor]
    finalPayload.vendors = [...vendors]


    debouncedSubmit(true, payload, () => {
      updateBuyerInformationTab({
        id: dealToView,
        payload: addNewVendorPayload,
        callback: (() => {
          fetchVendorInfo({
            id: dealToView,
            agentId: editingDealTeamMemberId ? parseInt(editingDealTeamMemberId, 10) : agents[0].agent_id,
            current_offer_step: "vendor_info",
            callback: ((res) => {
              const vendorList = res.vendors
              const item = vendorList[vendorList.length - 1]
              const currentVendor = {
                value : item.id,
                label : item.vendor_name,
                vendor_officer_name : `${item.vendor_officer_first_name} ${item.vendor_officer_last_name}`,
                vendor_id : item.id,
                vendor_office_name : item.vendor_name,
                isVendorEditVisible : false,
                vendor_office_address : `${item.vendor_address || ""} ${item.vendor_city || ""} ${item.vendor_state || ""} ${item.vendor_zipcode || ""}`
              }
              finalPayload.selected_vendor_id=item.id
              handleFinalSubmit(true, finalPayload)
            })
          })
        })
      })
    })

    addVendorAgentForm(false)
  }

  const handleDeselectVendor = () => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    payload.selectedVendor = null
    payload.selected_vendor_id = null
    updateBuyerInformationObject(payload)
    handleFinalSubmit(true, payload)
  }

  const onFinishEditVendor = (values) => {
    const payload = {
      ...buyersAgentInformationObject,
    }
    const currentEditVendor = payload.selectedVendor
    const name = values.vendor_officer_name?.split(" ") || ""
    const firstName = name && name[0]
    const lastName = name && name.length > 0 && name[1] || ""
    const addNewVendorPayload = {
      agent_id: parseInt(editingDealTeamMemberId, 10),
      draft: 0,
      vendor_name: values.vendor_office_name || "",
      vendor_email: values.vendor_email,
      vendor_address: values.vendor_address,
      vendor_phone: values.vendor_phone,
      vendor_officer_first_name: firstName,
      vendor_officer_last_name: lastName,
      vendor_type: values.vendor_type,
      vendor_city: payload.city,
      vendor_state: payload.state,
      vendor_zipcode: payload.zipcode
    }
    const updated = {
      ...currentEditVendor,
      ...values,
      label: values.vendor_office_name,
      isVendorEditVisible: false,
    }

    payload.vendors.forEach((item, iter) => {
      if (item.vendor_id === currentEditVendor.vendor_id) {
        payload.vendors[iter] = { ...updated }
      }
    })

    payload.selectedVendor = { ...updated }
    updateBuyerInformationObject(payload)
    updateVendorInformation({
      vendorId: currentEditVendor.vendor_id,
      payload: addNewVendorPayload,
    })
    const temp = {
      ...updateBuyerInformationObject,
    }
    //handleFinalSubmit(true, payload)
  }

  const handleVendorEditToggle = () => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentSelectedVendor = payload.selectedVendor

    currentSelectedVendor.isVendorEditVisible = !currentSelectedVendor.isVendorEditVisible

    payload.selectedVendor = currentSelectedVendor

    updateBuyerInformationObject(payload)
  }

  const handleChangeForVendor = (value) => {
    const payload = {
      ...buyersAgentInformationObject,
    }
    const currentSelected = payload.vendors.filter(item => item.value === value)[0]
    payload.selectedVendor = currentSelected
    payload.selected_vendor_id = currentSelected.id

    handleFinalSubmit(true, payload)
    updateBuyerInformationObject(payload)
  }
  /**HANDLE VENDOR FUNCTIONS*/

  const handleOnTypeChangeForBuyerAgentInfo = (changedFields, allFields) => {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const { co_agent_details } = allFields || {}
    console.log("draftPayload allFields", allFields)
    // eslint-disable-next-line no-prototype-builtins
    if (changedFields.hasOwnProperty("co_agent_details")) {
      const coAgents = [...co_agent_details]
      payload.agents = [{}, ...coAgents]
      const primaryAgent = { ...allFields }
      delete primaryAgent.co_agent_details
      payload.agents[0] = {
        ...primaryAgent,
        req_id: parsedQuery.req_id,
        client_id: parsedQuery.client_id,
      }
    } else {
      const primaryAgent = { ...allFields }
      primaryAgent.is_primary = 1
      payload.agents[0] = {
        ...primaryAgent,
        req_id: parsedQuery.req_id,
        client_id: parsedQuery.client_id,
      }
    }
    debouncedSubmit(true, payload)

  }

  const handleOnTypeChangeBuyerInfo = (changedFields, allFields) => {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const { co_buyer_details } = allFields || {}
    console.log("allFields", allFields)
    // eslint-disable-next-line no-prototype-builtins
    if (changedFields.hasOwnProperty("co_buyer_details")) {
      payload.buyers = [{}, ...co_buyer_details]
      const primaryBuyer = { ...allFields }
      delete primaryBuyer.co_buyer_details
      payload.buyers[0] = {
        ...primaryBuyer,
        transaction_id: parseInt(data.transaction_id, 10),
        req_id: parseInt(data.req_id, 10),
        client_id: data.client_id,
      }
    } else {
      const primaryBuyer = { ...allFields }
      primaryBuyer.is_primary = 1
      payload.buyers[0] = {
        ...primaryBuyer,
        transaction_id: parseInt(data.transaction_id, 10),
        req_id: parseInt(data.req_id, 10),
        client_id: data.client_id,
      }
      payload.source = primaryBuyer.source
      payload.other_source = primaryBuyer.other_source || ""
    }
    console.log("===payload===", payload)
    debouncedSubmit(true, payload)
    // handleFinalSubmit(true, payload)
    // updateBuyerInformationObject(payload)
  }


  const handleFinalSubmit = (draft = false, draftPayload) => {
    console.log("draftPayload draftPayload", draftPayload)
    const {
      buyers: currentBuyers,
      agents: currentAgents,
      selectedVendor,
      selected_vendor_id,
      selectedLender,
      selected_lender_id,
      vendors,
      lenders: currentLenders,
      financingType: newFinanceType,
      source,
      other_source,
    } = draftPayload || {}
    const isAllCashOrSelfFinancing = !selected_lender_id
    && newFinanceType === "cash"
    || newFinanceType === "seller_financing" ? 1 : 0

    console.log("buyerAgentsPayload draftPayload", currentAgents)
    let buyerAgentsPayload = currentAgents.map((item, iter) => (
      {
      agent_id: item?.agent_id,
      is_primary: iter === 0 ? 1 : 0,
      name: `${item?.agent_first_name ? `${item?.agent_first_name} ${item?.agent_last_name}` : `${item?.name}`}`,
      email: item?.email,
      phone: item?.phone,
      landline: "",
      agent_license_number: item?.agent_license_number || "",
      brokerage: item?.brokerage || "",
      brokerage_license_number: item?.brokerage_license_number || "",
      brokerage_address: item?.brokerage_address || "",
      apt: item?.apt || "",
      city: item?.city || "",
      state: item?.state || "",
      zipcode: item?.zipcode || "",
    }))

    buyerAgentsPayload = buyerAgentsPayload.filter((item) => {
      if (item.is_primary === 1) {
        return item
      }

      if (item.is_primary === 0) {
        const copy = { ...item }
        delete copy.is_primary

        const isEmptyTemp = Object.values(copy).every(key => key === null || key === undefined)
        if (!isEmptyTemp && item.name !== "undefined") {
          return item
        }
      }
    })

    let clientsPayload = currentBuyers.map((item, iter) => ({
      client_id: item.client_id ? item.client_id : null,
      req_id: item.req_id,
      transaction_id: item.transaction_id,
      is_primary: iter === 0 ? 1 : 0,
      firstname: item.firstname ? item.firstname : null,
      lastname: item.lastname ? item.lastname : null,
      email: item.email ? item.email : null,
      landline: null,
      phone: item.phone ? item.phone : null,
      address: item.address ? item.address : null,
      apt: item.apt ? item.apt : null,
      city: item.city ? item.city : null,
      state: item.state ? item.state : null,
      zipcode: item.zipcode ? item.zipcode : null,
      ownership_name: item.ownership_name ? item.ownership_name : null,
      type_of_ownership: item.type_of_ownership ? item.type_of_ownership : null,
    }))

    clientsPayload = clientsPayload.filter((item) => {
      if (item.is_primary === 1) {
        return item
      }

      if (item.is_primary === 0) {
        const copy = { ...item }
        delete copy.is_primary

        const isEmptyTemp = Object.values(copy).every(key => key === null || key === undefined)
        if (!isEmptyTemp && item.name !== "undefined") {
          return item
        }
      }
    })

    //const lenderPayload = 

    const finalPayload = {
      agents: [...buyerAgentsPayload],
      clients: [...clientsPayload],
      draft: draft ? 1 : 0,
      new_route : true,
      lender_id: selected_lender_id,
      vendor_id: selected_vendor_id,
      financing_information: newFinanceType,
      source,
      other_source,
      is_all_cash: isAllCashOrSelfFinancing,
      current_offer_step: "buyers_information_mobile",
    }

    updateBuyerInformationTab({
      id: dealToView,
      payload: finalPayload,
    })
  }

  const formRef = useRef(null)

  useEffect(() => {
    // Update the field value when buyersAgentInformationObject changes
    if (buyersAgentInformationObject?.selected_lender_id) {
      financingInformationFormRef.setFieldsValue({
        financing_lender: buyersAgentInformationObject?.selected_lender_id,
      });
    }
    else{
      financingInformationFormRef.setFieldsValue({
        financing_lender: null,
      });
    }
  }, [buyersAgentInformationObject, financingInformationFormRef]);

  return (
    <>
      {/* {fetchingBuyerData ? (<p>  </p>) :  */}
      <Wrap>
        <Form.Provider>
         {/*Buyer's Information*/}
          <SectionWrap>
            <BuyerForm
              title="Buyer's Information"
              prefilledData={buyers}
              buyerInformationFormRef={buyerInformationFormRef}
              onFinish={() => {}}
              handleOnTypeChangeBuyerInfo={handleOnTypeChangeBuyerInfo}
              additionalData={data}
            />
          </SectionWrap>
          {/*Buyer's Agent Information*/}
          <SectionWrap>
            {fetchingBuyerData ? (
              <FormShimmer />
            ) : (
              <BuyerAgentForm
                title="Buyer's Agent Information"
                //form={form}
                handleOnTypeChangeForBuyerAgentInfo={handleOnTypeChangeForBuyerAgentInfo}
                prefilledData={agents}
                buyersAgentInformationObject={buyersAgentInformationObject}
                isFetching={updating}
                onFinish={() => {}}
                buyerAgentInformationFormRef={buyerAgentInformationFormRef}
              />
            )}
          </SectionWrap>


          {/*Lendor's Information*/}
          {/*Financing Type*/}
          <SectionWrap>
            <SectionTitle>
              <p>
                Financing Information
              </p>
            </SectionTitle>
            <ListWrap>
            <Form
              // id={`${key}_1`}
              name="offers_financing_information"
              preserve
              colon={false}
              layout="vertical"
              requiredMark={false}
              // onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              // initialValues={getInitialValues()}
              ref={formRef}
              form={financingInformationFormRef}
              initialValues={{
                financing_type: buyersAgentInformationObject?.financingType
              }}
            >
              {buyersAgentInformationObject?.financingType === null && (
                <SelectField
                options={getFinancingTypeOptions(stateForOffer)}
                name="financing_type"
                placeholder="Select Financing Type"
                // defaultValue={buyersAgentInformationObject?.financingType}
                // value={buyersAgentInformationObject?.financingType}
                onChange={handleFinancingTypeChange}
                text="Select Financing Type"
                required={true}
                isFormField={true}
              />
              )}

              {buyersAgentInformationObject?.financingType && (
                <SelectField
                options={getFinancingTypeOptions(stateForOffer)}
                name="financing_type"
                placeholder="Select Financing Type"
                // defaultValue={buyersAgentInformationObject?.financingType}
                // value={buyersAgentInformationObject?.financingType}
                onChange={handleFinancingTypeChange}
                text="Select Financing Type"
                required={true}
                isFormField={true}
              />
              )}
              
              {isLenderUpdating ? (
                <ShimmerBox w="470px" h="50px" />
              ) : (
                <>
                  {financingType &&
                  financingType !== "cash" &&
                  financingType !== "seller_financing" && (
                    <>
                      {buyersAgentInformationObject?.selected_lender_id && (
                        <Form.Item
                        name="financing_lender"
                      >
                          <SelectFieldWrap
                            placeholder="Select Lender"
                            // options={[...buyersAgentInformationObject.lenders]}
                            // defaultValue={buyersAgentInformationObject?.selected_lender_id}
                            onChange={handleChangeForLender}
                            isRequired={true}
                          >
                            {[...buyersAgentInformationObject.lenders].map(item => (
                          <Option value={item.lender_id}>
                            {item.lender_name}
                          </Option>
                        ))}
                          </SelectFieldWrap>
                        </Form.Item>
                      )}
                      {buyersAgentInformationObject?.selected_lender_id === null && (
                        <Form.Item
                        name="financing_lender"
                      >
                        <SelectFieldWrap
                        placeholder="Select Lender"
                        // options={[...buyersAgentInformationObject.lenders]}
                        // defaultValue={buyersAgentInformationObject?.selected_lender_id}
                        onChange={handleChangeForLender}
                        isRequired={true}
                      >
                        {[...buyersAgentInformationObject.lenders].map(item => (
                      <Option value={item.lender_id}>
                        {item.lender_name}
                      </Option>
                    ))}
                      </SelectFieldWrap>
                      </Form.Item>
                      )}
                    </>
                  )}
                </>
              )}
              </Form>
              
              {isLenderUpdating ? (
                <ShimmerBox w="100%" h="215px" />
              ) : (
                  <>
                  {buyersAgentInformationObject.selectedLender && (
                  <>
                    {buyersAgentInformationObject.selectedLender.isLenderEditVisible ? (
                      <LenderForm
                        title="Edit Lender Information"
                        prefilledData={buyersAgentInformationObject.selectedLender}
                        handleEditModeChange={() => handleLenderEditToggle()}
                        onFinish={onFinishEditLender}
                        financingInformationFormRef={financingInformationFormRef}
                      />
                    ) : (
                      <LenderCard
                        title="Lender Information"
                        data={buyersAgentInformationObject.selectedLender}
                        editLender={() => handleLenderEditToggle()}
                        deselectLender={() => handleDeselectLender()}
                      />
                    )}
                  </>
                  )}
                </>
              )}
              
              {isAddNewLenderFormVisible ? (
                <LenderForm
                  title="New Lender Information"
                  handleEditModeChange={handleLenderGoBack}
                  onFinish={onFinishAddLender}
                  isAddNewLender
                  financingInformationFormRef={financingInformationFormRef}
                />
              ) : (
                financingType && (financingType !== "cash") && (
                  <CTAWrap>
                  <AddButton name="ADD A NEW LENDER" onClick={() => addLenderAgentForm(true)} />
                </CTAWrap>
                )
              )}
            </ListWrap>
          </SectionWrap>

          {/*Buyer's Information*/}
          <SectionWrap>
            <SectionTitle>
              <p>Escrow &amp; Title Information</p>
            </SectionTitle>
            <ListWrap>
            {isVendorUpdating && !updateBuyerInformationTabResponse?.isError ? (
              <ShimmerBox w="470px" h="50px" />
            ) : (
              <>
                {buyersAgentInformationObject?.selected_vendor_id && (
                  <SelectField
                    options={[...buyersAgentInformationObject.vendors]}
                    placeholder="Select Vendor"
                    defaultValue={buyersAgentInformationObject?.selected_vendor_id}
                    onChange={handleChangeForVendor}
                  />
                )}
                {buyersAgentInformationObject?.selected_vendor_id === null && (
                  <SelectField
                    options={[...buyersAgentInformationObject.vendors]}
                    placeholder="Select Vendor"
                    defaultValue={buyersAgentInformationObject?.selected_vendor_id}
                    onChange={handleChangeForVendor}
                  />
                )}
              </>
            )}
             
              {isVendorUpdating && !updateBuyerInformationTabResponse?.isError ? (
                <ShimmerBox w="100%" h="215px" />
              ) : (
                <>
                  {buyersAgentInformationObject.selectedVendor && (
                    <>
                      {buyersAgentInformationObject.selectedVendor.isVendorEditVisible ? (
                        <VendorForm
                          title="Edit Vendor Information"
                          prefilledData={buyersAgentInformationObject.selectedVendor}
                          handleEditModeChange={() => handleVendorEditToggle()}
                          onFinish={onFinishEditVendor}
                          vendorInformationFormRef={vendorInformationFormRef}
                        />
                      ) : (
                        <VendorCard
                          title="Vendor Information"
                          data={buyersAgentInformationObject.selectedVendor}
                          editVendor={() => handleVendorEditToggle()}
                          deselectVendor={() => handleDeselectVendor()}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              
              {isAddNewVendorFormVisible ? (
                <VendorForm
                  title="New Vendor Information"
                  handleEditModeChange={handleVendorGoBack}
                  onFinish={onFinishAddVendor}
                  isAddNewVendor
                  options={[...buyersAgentInformationObject.vendors]}
                  vendorInformationFormRef={vendorInformationFormRef}
                />
              ) : (
                <CTAWrap>
                  <AddButton name="ADD A NEW VENDOR" onClick={() => addVendorAgentForm(true)} />
                </CTAWrap>
              )}
            </ListWrap>
          </SectionWrap>
          {/* <SectionWrap>
              <BlueButton maxWidth onClick={() => {handleClicksubmit()}}>
                  <p>Save &amp; continue</p>
              </BlueButton>
            </SectionWrap> */}
        </Form.Provider>
      </Wrap>
      {/* } */}
    </>
  )
}

export default withRouter(Offer(OffersV2(BuyerInformation)))
