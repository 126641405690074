import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "RAR_TECH_ASSETS"

export const getRarProductAndServices = createSignalAction(BASE, "RAR_PRODUCTS_SERVICES")
export const saveRarTechRequest = createSignalAction(BASE, "SAVE_RAR_TECH_REQUEST")
export const sendSlackNotificationForProduct = createSignalAction(BASE, "SLACK_PRODUCT_NOTIFICATION")
export const getRarAssets = createSignalAction(BASE, "RAR_ASSETS")
export const rarSaveCreativeRequest = createSignalAction(BASE, "RAR_SAVE_CREATIVE_REQUEST_ASSETS")
export const saveDesignReferenceImage = createSignalAction(BASE, "RAR_SAVE_DESIGN_REFERENCE_IMAGE")
export const removeDesignReferenceImage = createSignalAction(BASE, "RAR_REMOVE_DESIGN_REFERENCE_IMAGE")
export const getFinancingClientList = createSignalAction(BASE, "GET_FINANCING_CLIENT_LIST")
export const getFinancingSetting = createSignalAction(BASE, "RAR_FINANCING_SETTING")
export const rarSaveFinancingClientList = createSignalAction(BASE, "RAR_SAVE_FINANCING_CLIENT_LIST")
export const toggleFinanceModal = createSignalAction(BASE, "TOGGLE_FINANCE_MODAL")
export const showSuccessBar = createSignalAction(BASE, "SHOW_SUCCESS_BAR")
export const fetchState = createSignalAction(BASE, "FETCH_STATE")
export const fetchTodaysPriceAction = createSignalAction(BASE, "FETCH_TODAYS_PRICE")
export const getPopupDetailsAction = createSignalAction(BASE, "GET_POPUP_DETAILS")
export const showPopupAction = createSimpleCreator(BASE, "SHOW_POPUP")
export const toggleScreenPopup = createSimpleCreator(BASE, "TOGGLE_BRANDING_POPUP")
export const setFileUploading = createSimpleCreator(BASE, "SET_FILE_UPLOADING")
export const setBothDone = createSimpleCreator(BASE, "SET_BOTH_DONE")
export const setPreferrerdTitle = createSimpleCreator(BASE, "SET_PREFERRED_TITLE")
export const setBusinessCard = createSimpleCreator(BASE, "SET_BUSINESS_CARD")
export const setYardSign = createSimpleCreator(BASE, "SET_YARD_SIGN")
export const setIsShow = createSimpleCreator(BASE, "SET_SHOW_POPUP")
export const fetchBrandingAssetsAction = createSignalAction(BASE, "GET_BRANDING_ASSETS")
export const fetchBrandingInfoAction = createSignalAction(BASE, "GET_BRANDING_INFO")
export const updateBrandingInfoAction = createSignalAction(BASE, "UPDATE_BRANDING_INFO")
export const createBrandingInfoAction = createSignalAction(BASE, "CREATE_BRANDING_INFO")
export const uploadDocsToBucketAction = createSignalAction(BASE, "UPLOAD_BRANDING_DOCS_TO_BUCKET")

// listings
export const getAllListings = createSignalAction(BASE, "GET_ALL_LISTINGS")
export const setCurrentContractIDAction = createSimpleCreator(BASE, "CONTRACT_ID_FOR_LISTING")
export const getListingStatusMap = createSignalAction(BASE, "GET_LISTING_STATUS_MAP")
export const getListingCoverSheet = createSignalAction(BASE, "GET_LISTING_COVERSHEET")
export const createListingCoverSheet = createSignalAction(BASE, "CREATE_LISTING_COVERSHEET")
export const updateListingCoverSheet = createSignalAction(BASE, "UPDATE_LISTING_COVERSHEET")
export const saveListingStepsAction = createSignalAction(BASE, "SAVE_LISTING_STEPS")
export const setAgentId = createSimpleCreator(BASE, "SET_AGENT_ID")

// offers
export const getCurrentDealDetailsAction = createSignalAction(BASE, "GET_DEAL_DETAILS")
export const getAllOffersAction = createSignalAction(BASE, "GET_ALL_OFFERS")
export const createOfferAction = createSignalAction(BASE, "CREATE_OFFER")
export const updateOfferAction = createSignalAction(BASE, "UPDATE_OFFER")
export const updateOfferDealStatusAction = createSignalAction(BASE, "UPDATE_OFFER_DEAL_STATUS")
export const resetLastDealAction = createSimpleCreator(BASE, "RESET_DEAL_FETCHED")
export const setCurrentVendorEditDetailsAction = createSimpleCreator(BASE, "SET_CURRENT_VENDOR_EDIT")
export const setCurrentLenderEditDetailsAction = createSimpleCreator(BASE, "SET_CURRENT_LENDER_EDIT")
export const updateVendorAction = createSignalAction(BASE, "UPDATE_VENDOR")
export const updateLenderAction = createSignalAction(BASE, "UPDATE_LENDER")
export const toggleAllCashOfferAction = createSimpleCreator(BASE, "TOGGLE_ALL_CASH_OFFER")
export const updateStatusNoteForChangeAction = createSimpleCreator(BASE, "UPDATE_NOTE_FOR_STATUS_CHANGE")
export const setOfferInfoDetailsAction = createSimpleCreator(BASE, "SET_OFFER_INFO")
export const setLoaderAction = createSimpleCreator(BASE, "SET_LOADER")
export const uploadCoverLetterAction = createSignalAction(BASE, "UPLOAD_COVER_LETTER")
export const setUnselect = createSimpleCreator(BASE, "SET_UNSELECT")

// contracts
export const getContractById = createSignalAction(BASE, "GET_CONTRACT_BY_ID")
export const createContract = createSignalAction(BASE, "CREATE_CONTRACT")
export const updateContract = createSignalAction(BASE, "UPDATE_CONTRACT")

export const setDealToView = createSimpleCreator(BASE, "SET_LISTING_TO_VIEW")
export const setEscrowIdToEdit = createSimpleCreator(BASE, "SET_ESCROW_ID_EDIT")
export const setLoaderOnAgentInfo = createSimpleCreator(BASE, "SET_LOADER_ON_AGENT_INFO")
export const updateDealStatus = createSignalAction(BASE, "UPDATE_DEAL_STATUS")

export const setAgentGoogleContacts = createSimpleCreator(BASE, "SET_AGENT_GOOGLE_CONTACTS")

export const getGoogleAuthToken = createSignalAction(BASE, "GET_GOOGLE_AUTH_TOKEN")

export const resetContractsForm = createSignalAction(BASE, "RESET_CONTRACTS_FORM")
export const resetListingsForm = createSignalAction(BASE, "RESET_LISTINGS_FORM")

export const toggleViewListingHistory = createSimpleCreator(BASE, "TOGGLE_VIEW_LISTING_HISTORY")
export const toggleComfirmToGoToContract = createSimpleCreator(BASE, "TOGGLE_CONFIRM_TO_GO_TO_CONTRACT")
export const toggleConfirmToExecute = createSimpleCreator(BASE, "TOGGLE_CONFIRM_TO_EXECUTE")
export const getListingHistory = createSignalAction(BASE, "GET_LISTING_HISTORY")

export const resetGoogleContacts = createSimpleCreator(BASE, "RESET_GOOGLE_CONTACTS")
export const fetchDealTimelineAction = createSignalAction(BASE, "FETCH_DEAL_TIMELINE")
export const fetchDealTCDetailsAction = createSignalAction(BASE, "FETCH_DEAL_TC_DETAILS")
export const updateDealTimelineAction = createSignalAction(BASE, "UPDATE_DEAL_TIMELINE")

// docusign
export const toggleDocumentSelectionConfirm = createSimpleCreator(BASE, "TOGGLE_DOCUMENTS_SELECTION_CONFIRM")
export const toggleDocumentSentPopup = createSimpleCreator(BASE, "TOGGLE_DOCUMENTS_SENT_POPUP")
export const getListingDocuments = createSignalAction(BASE, "GET_LISTING_DOCUMENTS")
export const sendListingDocuments = createSignalAction(BASE, "SEND_LISTING_DOCUMENTS")

export const createSendeView = createSignalAction(BASE, "CREATE_SENDER_VIEW")
export const createReceiverView = createSignalAction(BASE, "CREATE_RECEIVER_VIEW")
export const createEditView = createSignalAction(BASE, "CREATE_EDIT_VIEW")
export const downloadEnvelopeDoc = createSignalAction(BASE, "DOWNLOAD_ENVELOPE_DOC")

export const getListingSteps = createSignalAction(BASE, "GET_LISTING_STEPS")

export const downloadingDocs = createSimpleCreator(BASE, "DOWNLOADING_DOCS")

export const toggleInterestFormAction = createSimpleCreator(BASE, "TOGGLE_FORM")

export const toggleMortgagePopupAction = createSimpleCreator(BASE, "TOGGLE_MORTGAGE_POPUP")

export const refreshListingDocuments = createSignalAction(BASE, "REFRESH_LISTING_DOCUMENTS")

export const updateDocusignEnvelopeStatus = createSignalAction(BASE, "UPDATE_DOCUSIGN_ENVELOPE_STATUS")

export const imageUploadForListing = createSignalAction(BASE, "IMAGE_UPLOAD_FOR_LISTING")

export const createAllListingImages = createSignalAction(BASE, "CREATE_ALL_LISTING_IMAGES")

export const toggleUploadListingImages = createSimpleCreator(BASE, "TOGGLE_UPLOAD_LISTING_IMAGES")

export const toggleUploadListingDocs = createSimpleCreator(BASE, "TOGGLE_UPLOAD_LISTING_DOCS")

export const toggleUploadCoverLetter = createSimpleCreator(BASE, "TOGGLE_UPLOAD_COVER_LETTER")

export const showCoverLetterResponseAction = createSignalAction(BASE, "SET_COVER_LETTER_RESPONSE")

export const togglePreviewListingImages = createSimpleCreator(BASE, "TOGGLE_PREVIEW_LISTING_IMAGES")

//Docusign Offers
export const getOfferDocumentsAction = createSignalAction(BASE, "GET_OFFER_DOCUMENTS")

export const createEditViewOffer = createSignalAction(BASE, "CREATE_EDIT_VIEW_OFFER")

export const toogleVoidAndRestart = createSimpleCreator(BASE, "TOGGLE_VOID_AND_RESTART")

export const toogleTroubleshoot = createSimpleCreator(BASE, "TOGGLE_TROUBLESHOOT")

export const sendForVoidAndRestart = createSignalAction(BASE, "SEND_FOR_VOID_AND_RESTART")

export const sendTroubleShootMessage = createSignalAction(BASE, "SEND_TROUBLESHOOT_MESSAGE")

export const uploadListingDocToDocusign = createSignalAction(BASE, "UPLOAD_LISTING_DOC_TO_DOCUSIGN")

export const toggleUploadLoader = createSimpleCreator(BASE, "TOGGLE_UPLOAD_LOADER")

export const sendForVoidAndDelete = createSignalAction(BASE, "SEND_FOR_VOID_AND_DELETE")

export const toogleVoidAndDelete = createSimpleCreator(BASE, "TOGGLE_VOID_AND_DELETE")

export const regenerateListingDocuments = createSignalAction(BASE, "REGENERATE_LISTING_DOCUMENTS")

export const getOfferDocuments = createSignalAction(BASE, "GET_OFFER_DOCUMENTS")
export const sendOfferDocuments = createSignalAction(BASE, "SEND_OFFER_DOCUMENTS")
export const createSendeViewOffer = createSignalAction(BASE, "CREATE_SENDER_VIEW_OFFER")
export const createReceiverViewOffer = createSignalAction(BASE, "CREATE_RECEIVER_VIEW_OFFER")
export const downloadEnvelopeDocOffer = createSignalAction(BASE, "DOWNLOAD_ENVELOPE_DOC_OFFER")
export const getOfferSteps = createSignalAction(BASE, "GET_OFFER_STEPS")
export const updateOfferDocusignEnvelopeStatus = createSignalAction(BASE, "UPDATE_OFFER_DOCUSIGN_ENVELOPE_STATUS")
export const toogleVoidAndRestartOffer = createSimpleCreator(BASE, "TOOGLE_VOIDE_AND_RESTART_OFFER")
export const toogleTroubleshootOffer = createSimpleCreator(BASE, "TOOGLE_TROUBLESHOOT_OFFER")
export const sendForVoidAndRestartOffer = createSignalAction(BASE, "SEND_FOR_VOID_AND_RESTART_OFFER")
export const sendTroubleShootMessageOffer = createSignalAction(BASE, "SEND_TROUBLESHOOT_MESSAGE_OFFER")
export const uploadOfferDocToDocusign = createSignalAction(BASE, "UPLOAD_OFFER_DOC_TO_DOCUSIGN")
export const uploadCoverLetterDocToDocusign = createSignalAction(BASE, "UPLOAD_COVER_LETTER_TO_DOCUSIGN")
export const sendForVoidAndDeleteOffer = createSignalAction(BASE, "SEND_FOR_VOID_AND_DELETE_OFFER")
export const toogleVoidAndDeleteOffer = createSimpleCreator(BASE, "TOOGLE_VOID_AND_DELETE_OFFER")
export const regenerateOfferDocuments = createSignalAction(BASE, "REGENERATE_OFFER_DOCUMENTS")
export const putCoverLetterDocsAction = createSignalAction(BASE, "PUT_COVER_LETTER_DOCS")
export const fetchCoverLetterDocsAction = createSignalAction(BASE, "FETCH_COVER_LETTER_DOCS")
export const setUploadedFilesNullAction = createSimpleCreator(BASE, "SET_UPLOADED_FILES_NULL")

export const submitAndSendAllOffersFile = createSignalAction(BASE, "SUBMIT_AND_SEND_ALL_OFFERS_FILE")
export const sendAllOffersDocsAction = createSignalAction(BASE, "SEND_ALL_OFFERS_DOCS")
export const sendDocusignDocsAction = createSignalAction(BASE, "SEND_ALL_DOCUSIGN_DOCS")

export const refreshOfferDocuments = createSignalAction(BASE, "REFRESH_OFFER_DOCUMENTS")

export const requestToCreateZip = createSignalAction(BASE, "REQUEST_TO_CREATE_ZIP")

export const setOfferDocumentDownloadStatus = createSimpleCreator(BASE, "SET_OFFER_DOCUMENT_DOWNLOAD_STATUS")

export const submitAndSendAllOffersFileReset = createSimpleCreator(BASE, "RESET_SUBMIT_AND_SEND_ALL_OFFERS_FILE_RESET")

export const setEditingDealTeamMemberId = createSimpleCreator(BASE, "EDITING_DEAL_TEAM_MEMBER_ID")

export const toggleAlertNavigation = createSimpleCreator(BASE, "TOOGLE_ALERT_NAVIGATION")

export const resetTransactionState = createSimpleCreator(BASE, "RESET_TRANSACTION_STATE")
export const toggleAddTransactionPopup = createSimpleCreator(BASE, "TOGGLE_ADD_TRANSACTION_POPUP")

export const generateListingEnvelopeFromTemplate = createSignalAction(BASE, "GENERATE_LISTING_ENVELOPE_FROM_TEMPLATE")

export const generateOfferEnvelopeFromTemplate = createSignalAction(BASE, "GENERATE_OFFER_ENVELOPE_FROM_TEMPLATE")

export const fetchListingDocusignTemplates = createSignalAction(BASE, "FETCH_LISTING_DOCUSIGN_TEMPLATES")

export const fetchOfferocusignTemplates = createSignalAction(BASE, "FETCH_OFFER_DOCUSIGN_TEMPLATES")

export const getTemplatePreview = createSignalAction(BASE, "GET_TEMPLATE_PREVIEW")

export const voidAndDuplicateCompletedOfferAction = createSignalAction(BASE, "VOID_DUPL_COMP_OFFER")

export const voidAndDuplicateCompletedListingAction = createSignalAction(BASE, "VOID_DUPL_COMP_LISTING")

export const deleteCompletedListingAction = createSignalAction(BASE, "DELETE_COMPLETED_LISTING")

export const deleteCompletedOfferAction = createSignalAction(BASE, "DELETE_COMPLETED_OFFER")

export const setCurrentEnvelopeDetailsAction = createSimpleCreator(BASE, "SET_CURRENT_DETAILS_ACTION")

export const deleteCoverLetter = createSignalAction(BASE, "DELETE_COVER_LETTER")
export const setComposeEmail = createSimpleCreator(BASE, "SET_COMPOSE_EMAIL")

export const setSuccessPage = createSimpleCreator(BASE, "SHOW_SUCCESS_PAGE")

export const setOfferSentPopup = createSimpleCreator(BASE, "SHOW_OFFER_SENT_POPUP")

export const sendMailToListingAgents = createSignalAction(BASE, "SEND_MAIL_TO_LISTING_AGENTS")

export const toggleMortgageForm = createSimpleCreator(BASE, "TOGGLE_MORTGAGE_FORM")

export const sendMortgagePropertiesAction = createSignalAction(BASE, "SEND_MORTGAGE_ACTION")

export const sendClientDetailsAction = createSignalAction(BASE, "SEND_CLIENT_DETAILS")

export const toggleNetworkAgentPopupAction = createSimpleCreator(BASE, "TOGGLE_NETWORK_AGENT")

export const toggleModalPageAction = createSimpleCreator(BASE, "TOGGLE_MODAL_PAGE")

//CONTRACT V2
export const createContractV2Action = createSignalAction(BASE, "CREATE_CONTRACT_V2")

export const updateContractV2Action = createSignalAction(BASE, "UPDATE_CONTRACT_V2")

export const showSuccessModalAction = createSimpleCreator(BASE, "TOGGLE_SUCCESS_MODAL_V2")

export const getAgentDetailsAction = createSignalAction(BASE, "GET_AGENT_INFORMATION_WITH_STATE")

export const showUpdatingBuyerInformationLoader = createSimpleCreator(BASE, "SHOW_BUYER_INFO_UPDATE_LOADER")

export const getOfferContractDetailsAction = createSignalAction(BASE, "GET_OFFER_BASED_DETAILS")

export const getContractFieldValidationAction = createSignalAction(BASE, "VALIDATION_CHECK_CONTRACTS")

export const createOfferConvertedContractAction = createSignalAction(BASE, "CREATE_OFFER_COVERTED_CONTRACT")

export const createTransactionConvertedContractAction = createSignalAction(BASE, "CREATE_TRANSACTION_CONVERTED_CONTRACT")

export const dealCountUpdateAction = createSimpleCreator("UPDATE_DEAL_COUNT")

export const processBeforeViewingContractAction = createSignalAction(BASE, "PROCESS_BEFORE_VIEWING_CONTRACT")

export const createListingConvertedToContractAction = createSignalAction(BASE, "CREATE_LISTING_TO_CONTRACT_CONVERSION")

export const updateExistingContractBySubmitAction = createSignalAction(BASE, "UPDATE_BY_SUBMIT_FOR_CONTRACT")

export const toggleOfferToContractConversionAction = createSimpleCreator(BASE, "TOGGLE_OFFER_TO_CONTRACT_CONVERSION")

export const toggleStatusChangeModalAction = createSimpleCreator(BASE, "TOGGLE_STATUS_CHANGE_MODAL")

export const setOfferIdToViewAction = createSimpleCreator(BASE, "SET_OFFER_ID")

// export const processOffersToContractAction
export const toggleShowUpdateAction = createSimpleCreator(BASE,"TOGGLE_UPLOAD_UPDATE")

export const setContentForUpdateAction = createSimpleCreator(BASE,"SHOW_UPDATE_CONTENT")

export const createSendeViewOfferChecklistAction = createSignalAction(BASE, "CREATE_SENDER_VIEW_OFFER_CHECKLIST")

export const createEditViewOfferChecklistAction = createSignalAction(BASE,"CREATE_EDIT_VIEW_OFFER_CHECKLIST")

export const downloadEnvelopeDocOfferChecklistAction = createSignalAction(BASE, "DOWNLOAD_ENVELOPE_DOC_OFFER_CHECKLIST")

export const sendForVoidAndRestartOfferChecklistAction = createSignalAction(BASE, "SEND_FOR_VOID_AND_RESTART_OFFER_CHECKLIST")

export const sendForVoidAndDeleteOfferChecklistAction = createSignalAction(BASE, "SEND_FOR_VOID_AND_DELETE_OFFER_CHECKLIST")

export const createReceiverViewOfferChecklistAction = createSignalAction(BASE, "CREATE_RECEIVER_VIEW_OFFER_CHECKLIST")

export const defineAgentTypeAction = createSimpleCreator(BASE, "DEFINE_AGENT_TYPE")

export const setListingAddressAction = createSimpleCreator(BASE, "SET_LISTING_ADDRESS")

export const setTransactionDashboardTabAction = createSimpleCreator(BASE, "SET_TRANSACTION_DASHBOARD_TAB")

export const fetchTransactionDataAction = createSignalAction(BASE, "FETCH_TRANSACTION_DATA")

export const setTransactionDataObjectAction = createSimpleCreator(BASE, "SET_TRANSACTION_OBJECT")

export const fetchContractStatusesAction = createSignalAction(BASE, "FETCH_CONTRACT_STATUSES")

export const toggleTransactionCreationForOfferAction = createSimpleCreator(BASE, "TOGGLE_TRANSACTION_CREATION")

export const setCreateTransactionalFlowAction = createSimpleCreator(BASE, "CREATE_TRANSACTION_FLOW")

export const constructTransactionQueryAction = createSimpleCreator(BASE, "CONSTRUCT_TRANSACTION_QUERY")

export const clearExistingTransactionDataAction = createSimpleCreator(BASE, "CLEAR_EXISTING_DATA")

export const fetchTransactionOverviewAction = createSignalAction(BASE, "FETCH_TRANSACTION_OVERVIEW")

export const deleteContractAction = createSignalAction(BASE, "DELETE_CONTRACT_ACTION")

export const setFilterTabOpenAction = createSimpleCreator(BASE, "SET_FILTER_TAB_OPEN")

export const setContractFormValidationAction = createSimpleCreator(BASE, "SET_CONTRACT_FORM_VALIDATION")

export const setOfferFormValidationAction = createSimpleCreator(BASE, "SET_OFFER_FORM_VALIDATION")

export const setListingAgentFormValidationAction = createSimpleCreator(BASE, "SET_LISTING_AGENT_FORM_VALIDATION")
