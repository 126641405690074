/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react"
import Listings from "container/Listings"
import ListingsV2 from "container/ListingsV2"
import root from "window-or-global"
import TechAssets from "container/TechAssets"
import ContractDetails from "dumbComponents/common/ContractDetailsForm"
import { ContractCard } from "dumbComponents/common/ListingsCommon"
import { withRouter } from "react-router-dom"
import ComplianceSection from "dumbComponents/Compliance"
import queryString from "query-string"
import {
  ListingsWrap,
  MenuWrap,
  ListingDetailsDiv,
  MenuUiWrap
} from "./styles"
//import Tabs from "dumbComponents/ListingsV2/Components/Tabs"
import {
  Tabs,
  HeaderSectionCommon,
} from "./components"

const ContractsTabs = ({
    currentContractsTab = "contract_details",
    setCurrentContractsTab,
    dealToView,
    contractFormValidation,
}) => {

  const { search } = root.location
  const parsedQuery = queryString.parse(search)

  const steps = [
    {
      value: "contract_details",
      label: "Contract Details",
    },
    {
      value: "compliance",
      label: "Compliance",
      disabled: !(contractFormValidation.buyerAgentFormValid && contractFormValidation.clientFormValid) ,
    },
  ]

 

  useEffect(() => {
    console.log("contractFormValidation", contractFormValidation)
    if(!contractFormValidation.entireFormValid){
      setCurrentContractsTab("contract_details")
    }
  }, [contractFormValidation])

  return (
    <>
    <MenuUiWrap>
      <Tabs
        steps={steps}
        toggleTab={currentTab => {
          // Prevent switching to disabled tab
          const selectedStep = steps.find(step => step.value === currentTab);
          if (selectedStep?.disabled) {
            return;
          }
          setCurrentContractsTab(currentTab);
        }}
        currentTab={currentContractsTab}
        showNeedHelpButton
      />
      {currentContractsTab === "contract_details" && (
        <ContractDetails />
      )}
      {currentContractsTab === "compliance" && (
       <ComplianceSection />
      )}
    </MenuUiWrap>
    </>
  )
}

export default TechAssets(withRouter((Listings(ContractsTabs))))
