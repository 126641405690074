import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "NAVBAR"

export const startTabsSpotlightFlowAction = createSimpleCreator(BASE, "START_NAVBAR_SPOTLIGHT_FLOW")
export const finishTabsSpotlightFlowAction = createSignalAction(BASE, "FINISH_NAVBAR_SPOTLIGHT_FLOW")

// API Actions
export const fetchNotificationAction = createSignalAction(BASE, "FETCH_NOTIFICATION")
export const fetchNotificationUnreadCountAction = createSignalAction(BASE, "FETCH_UNREAD_NOTIFICATIONS")
export const togglePressReleaseBannerAction = createSimpleCreator(BASE, "TOGGLE_PRELEASE_BANNER")
export const notificationViewedAction = createSignalAction(BASE, "NOTIFICATION_VIEW_ACTION")

export const getFeedByHomeClickAction = createSimpleCreator(BASE, "CLICKED_ON_HOME")

// New brokerage page
export const setPlanAvailableObjectAction = createSimpleCreator(BASE, "SET_PLANS")
export const getSubscriptionPlansAction = createSignalAction(BASE, "GET_SUBSCRIPTION_PLANS")
export const addSubscriptionPlanAction = createSignalAction(BASE, "ADD_PLAN")
export const fetchPaymentAction = createSignalAction(BASE, "FETCH_PAYMENT")
export const editPaymentAction = createSignalAction(BASE, "EDIT_PAYMENT")
export const deletePaymentAction = createSignalAction(BASE, "DELETE_PAYMENT")
export const defaultPaymentAction = createSignalAction(BASE, "PRIMARY_PAYMENT")
export const fetchAddOnsAction = createSignalAction(BASE, "FETCH_ADD_ON")
export const updateSubscriptionAction = createSignalAction(BASE, "UPDATE_SUBSCRITPION")
export const fetchInvoiceDataAction = createSignalAction(BASE, "FETCH_INVOICE_DATA")
export const setPaymentObjectAction = createSimpleCreator(BASE, "SET_PAYMENT_OBJECT")
export const setLoadingCardAction = createSimpleCreator(BASE, "SET_LOADING_CARD")
export const makeDefaultMethodAction = createSignalAction(BASE, "MAKE_DEFULT_METHOD")
export const handleExpiredSubscriptionAction = createSignalAction(BASE, "HANDLE_EXPIRE_SUBS")
