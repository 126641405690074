import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { copyAppInviteLinkAction, fetchAllClientsAction,fetchAppSettingsAction, fetchRecommendedPropertiesAction, getAllAgentsForAssignmentAction, getAppStatusAction, inviteBulkClientsAction, inviteClientsAction, isMelRecommendedAction,saveAppSettingsAction, joinWaitlistAction, rejectPropertyStatusAction, removeBulkPropertyAction, transferAgentAction, turnOnMelRecommendationsAction, getClientSummaryAction, generateClientSummaryAction, getClientRecommendedPropertiesAction } from "./actions"

const mapStateToProps = (state) => {
  const { consumerApp } = state
  return {
    ...consumerApp,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      fetchAllClients: fetchAllClientsAction.request,
      copyAppInviteLink: copyAppInviteLinkAction.request,
      inviteBulkClients: inviteBulkClientsAction.request,
      joinWaitlist: joinWaitlistAction.request,
      inviteClient: inviteClientsAction.request,
      getAppStatus: getAppStatusAction.request,
      turnOnMelRecommendation: turnOnMelRecommendationsAction.request,
      fetchRecommendedProperties: fetchRecommendedPropertiesAction.request,
      rejectPropertyStatus: rejectPropertyStatusAction.request,
      removeBulkProperty: removeBulkPropertyAction.request,
      fetchAppSettings: fetchAppSettingsAction.request,
      saveAppSettings:saveAppSettingsAction.request,
      getAllAgentsForAssignment: getAllAgentsForAssignmentAction.request,
      transferAgentForAssignment: transferAgentAction.request,
      isMelRecommendedOn: isMelRecommendedAction.call,
      getClientSummary: getClientSummaryAction.request,
      generateClientSummary: generateClientSummaryAction.request,
      getClientRecommendedProperties: getClientRecommendedPropertiesAction.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
