import React, { useEffect, useState } from "react"
import OffersV2 from "container/ListingsV2"
import TechAssets from "container/TechAssets"
import { Checkbox, Modal } from "antd"
import Loader from "@ui/Loader"
import AddButtonCTA from "dumbComponents/ListingsV2/Components/AddButtonCTA"
import { BlueButton, BlueButtonInverse } from "dumbComponents/ListingsV2/commonStyles"
import { EyeIcon, EyeIconEnvelope } from "dumbComponents/ListingsV2/icons"
import Listings from "container/Listings"
import LibraryModal from "./LibraryModal"
import DocumentShimmer from "dumbComponents/ListingsV2/Components/DocumentShimmer"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { ShimmerBox } from "shared/styles/animation"
import {getBrokerageType} from "dumbComponents/Compliance/utils"
import createToast from "dumbComponents/common/Toast/customToast"
import {DeleteOutlined} from '@ant-design/icons';
import PurpleDownloadIcon from "images/PurpleDownloadIcon"
import {
  Wrap,
  HeaderWrap,
  Header,
  ButtonWrap,
  LoaderWrap,
  CheckboxWrap,
  ShimmerWrap,
  DownloadPurpleButton,
} from "dumbComponents/ListingsV2/Components/RecommendedDocs/styles.js"
import CheckboxListV2 from "../../CheckboxListV2/index"
import EmptyState from "./EmptyState"
import { OFFERS, LISTINGS, PURCHASE } from "dumbComponents/OffersV3/consts"

const Envelopes = ({
  docList,
  updateRecommendedDocsObject,
  toggleCreateEnvelopeModal,
  getOfferDocumentsResponse,
  deleteListingEnvelopeResponse,
  deleteOfferEnvelopeResponse,
  dealToView,
  listingIdToView,
  offerIdToView,
  getOfferDocuments, 
  deleteListingEnvelope,
  deleteOfferEnvelope,
  fetchEnvelopeListingsResponse,
  updateEnvelopeObject,
  fetchEnvelopeListings,
  envelopeListings,
  toggleCreateEnvelope,
  fetchMergedEnvelopesResponse,
  createSendeView,
  createSendeViewOfferChecklist,
  showAddDocument,
  isShowAddDocument,
  downloadEnvelopeDocOffer,
}) => {
  const currentBrokerageType = getBrokerageType(location)
  const {
    isFetching : fetchingEnvelopes,
    data: envelopeData
  } = fetchEnvelopeListingsResponse || []
  const { isFetching: fetchingMergedEnvelopes } = fetchMergedEnvelopesResponse || {}
  const [isDocusignOpen, setIsDocsignOpen] = useState(localStorage.getItem('isDocusignOpen'));
  const payload = {...envelopeListings}
  const [showModal, setModal] = useState(false)

  const {
    isFetching: deletingEnvelope,
  } = deleteListingEnvelopeResponse || deleteOfferEnvelopeResponse || {}

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'isDocusignOpen') {
        const newStatus = localStorage.getItem('isDocusignOpen');
        setIsDocsignOpen(newStatus);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  useEffect(() => {

    if (isDocusignOpen == 2) {
       if(currentBrokerageType === OFFERS){
      fetchEnvelopeListings({
        type: currentBrokerageType,
        offerId: (offerIdToView || dealToView),
      });
    } else {
      fetchEnvelopeListings({
        type: currentBrokerageType,
        listingId: listingIdToView,
      });
    }
      localStorage.setItem('isDocusignOpen', 1);
      setIsDocsignOpen(1);
    }
  }, [isDocusignOpen]);

  const handleSelectedState = (e, eID, value, subSection) => {
    const checked = e.target.checked
    const currentDocs = [
      ...envelopeListings[subSection],
    ]
    currentDocs.forEach((item) => {
      if (item.id === eID) {
        item.isSelected = checked
      }
    })
    payload[subSection] = currentDocs
    updateEnvelopeObject(payload)
  }

  const selectAll = () => {
    const currentDocs = JSON.parse(JSON.stringify(envelopeListings))

    for (const key in currentDocs) {
      if (Array.isArray(currentDocs[key])) {
        currentDocs[key] = currentDocs[key].map(item => ({
          ...item,
          isSelected: true
        }))
      }
    }
    //payload = {...payload, ...currentDocs }
    updateEnvelopeObject(currentDocs)
  }

  const deselectAll = () => {
    const currentDocs = JSON.parse(JSON.stringify(envelopeListings))

    for (const key in currentDocs) {
      if (Array.isArray(currentDocs[key])) {
        currentDocs[key] = currentDocs[key].map(item => ({
          ...item,
          isSelected: false
        }))
      }
    }
    //payload = {...payload, ...currentDocs }
    updateEnvelopeObject(currentDocs)
  }

  const handleSelectAllToggle = (e) => {
    const checked = e.target.checked
    if (checked) {
      selectAll()
    } else {
      deselectAll()
    }
  }

  const mainList = [
    {
      label: "VIEW & FILL",
      image: <EyeIconEnvelope />,
      onClick: (id) => {
        createToast("Viewing Document")
        localStorage.setItem('isDocusignOpen', 1)
        const payload = {
          envelopeId: id,
        }
        if(currentBrokerageType == OFFERS){
          createSendeViewOfferChecklist(
            payload,
          )
        } else if(currentBrokerageType == LISTINGS){
          createSendeView(
            payload,
          )
        }
      },
      showDivide: false,
    },
  ]

  const checkSelected = () => {
  const currentDocs = JSON.parse(JSON.stringify(envelopeListings))
  for (const key in currentDocs) {
    if (Array.isArray(currentDocs[key])) {
      for (const item of currentDocs[key]) {
        if (item.isSelected === true) {
          return true
        }
      }
    }
  }
  return false
}
const getSelectedEnvelopeIds = () => {
  const selectedIds = [];
  for (const key in envelopeListings) {
    if (Array.isArray(envelopeListings[key])) {
      envelopeListings[key].forEach((item) => {
        if (item.isSelected) {
          selectedIds.push(item.envelope_id);
        }
      });
    }
  }
  return selectedIds;
};

  const [deleteModal, setDeleteModal] = useState(false);

  const handleEnvelopeDelete = () => {
    const selectedIds = getSelectedEnvelopeIds();
    if(currentBrokerageType == "offers"){
      deleteOfferEnvelope({
        envelopeIds: selectedIds,
        callback: () => {
          fetchEnvelopeListings({
            type:currentBrokerageType,
            offerId: offerIdToView || dealToView,
          });
        }
      })
      setDeleteModal(false);
    } else {
        deleteListingEnvelope({
        envelopeIds: selectedIds,
        callback: () => {
          fetchEnvelopeListings({
            type:currentBrokerageType,
            listingId: listingIdToView,
          });
        }
      });
      setDeleteModal(false);
    }
}

  const handleDownload = () => {
    const selectedIds = [];
    for (const key in envelopeListings) {
      if (Array.isArray(envelopeListings[key])) {
        envelopeListings[key].forEach((item) => {
          if (item.isSelected) {
            downloadEnvelopeDocOffer({
              payload: {
                envelopeId: item.envelope_id,
              },
              documentDetail: {
                name: `${item.name}`,
              },
            });
          }
        });
      }
    }
  };

  return (
    <Wrap>
        <>
        <Modal title="Confirm Deletion" open={deleteModal} onOk={handleEnvelopeDelete} onCancel={() => setDeleteModal(false)} okText="Delete" 
          okButtonProps={{
            style: {
              borderRadius: "33.5px",
              background: "#5A5FF2",
              width: "200px"
            },
          }}
          cancelButtonProps={{
            style: {
              borderRadius: "33.5px",
              border: "1px solid #5A6CF2"
            },
          }}>
          <p>You are about to delete the document. This action will remove all document from your envelope. Do you wish to proceed?</p>
        </Modal>
        <HeaderWrap>
        <Header>DOC PREPARATION</Header>
        <AddButtonCTA name = "Add from library" onClick={() => setModal(true)} />
      </HeaderWrap>
      {
        (fetchingEnvelopes) ? (
          <ShimmerWrap>
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
        </ShimmerWrap>
        ) : (
          <>
            {Object.keys(envelopeListings).length > 0  ? (
        <>
          <CheckboxWrap style={{display: "flex", justifyContent: "space-between"}}>
            <Checkbox 
            onChange={handleSelectAllToggle}
            >
              Select all
            </Checkbox>
            <DownloadPurpleButton disabled={!checkSelected()} onClick={handleDownload}><PurpleDownloadIcon/> Download</DownloadPurpleButton>
          </CheckboxWrap>
          <CheckboxListV2
            list={envelopeListings}
            onClick={() => {
              console.log("clicked")
            }}
            handleSelectedCheck={handleSelectedState}
            // dropdownList={OptionList2}
             mainList={mainList}
            isActiveFilter
          />
          <ButtonWrap>
            <BlueButton
              disabled={!checkSelected()}
              maxWidth
              onClick={() => toggleCreateEnvelope(true)}
            >
            <p>Create and send envelope</p>
            </BlueButton>
            <BlueButtonInverse
              disabled={!checkSelected()}
              maxWidth
              // onClick={() => toggleCreateEnvelope(true)}
              onClick={() => setDeleteModal(true)}
            >
            {deletingEnvelope ? (
                <ThreeDotLoader color="#5A5FF2" />
              ) : (
                <p><DeleteOutlined />Delete</p>
              )}
            </BlueButtonInverse>
          </ButtonWrap>
        </>
      ) : (
        <EmptyState
          emptyHeader="No active documents yet"
          emptyDescription="Add documents from library"
        />
      )}
          </>
          
        )
      }
        </>
      
      {showModal && <LibraryModal showModal={showModal} setModal={setModal} currentBrokerageType={currentBrokerageType} isAddChecklistDocument={false} />}
      {isShowAddDocument && <LibraryModal showModal={isShowAddDocument} setModal={showAddDocument} currentBrokerageType={currentBrokerageType} isAddChecklistDocument={true}/>}
    </Wrap>
  )
}

export default Listings(OffersV2(TechAssets(Envelopes)))
