import React, { useState } from "react"
import { Form } from "@ui/antd"
import SearchContainer from "container/Search/SearchContainer"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { normalizePhone } from "services/formUtils"
import {
  PlusCircleOutlined, LoadingOutlined, CloseCircleOutlined,
} from "@ant-design/icons"
import {
  Wrap,
  SelectFieldWrap,
  StyledSpin,
  AutoCompleteFieldWrap,
} from "./styles"

const AgentSearch = ({
  placeholder,
  options,
  style,
  arrowIcon,
  isFormField,
  required = false,
  requiredMessage,
  defaultValue,
  name,
  onChange,
  formRef,
  getSearchedAgents,
  isFetchingAgents,
  agents,
  firstNameLabel = "agent_first_name",
  lastNameLabel = "agent_last_name",
  isFirst = false,
  fieldIndex,
  formName,
  text,
  callback,
  isFromOffer = false,
  hideLabel = false,
  hideClickToAddManually=false
}) => {
  const rules = [{ required, message: requiredMessage?.length ? requiredMessage : "Required" }]

  const [dummyValue, setDummyValue] = useState(null)

  const searchAgentDetails = (searchValue) => {
    const newEntry = {
      label: searchValue,
      value: searchValue,
      id: isFromOffer ? null : 9999999,
      firstname: searchValue,
      lastname: "",
      email: ""
    }
    getSearchedAgents({
      newEntry,
      isInitial: true,
      currentPageNumber: 1,
      queryParams: {
        text_value: searchValue,
        unRegistered: false,
        text_search_params: [
          "email",
          "full_name",
        ],
        isBroker: true,
      },
    })
  }

  const changeOtherFieldsValues = (objString, index, fromContacts, option) => {
    try {
      const agent = agents.find(ag => ag.id === option.key)
     console.log(objString,'objString', agents, 'agents')

      console.log("field index", fieldIndex, 'pragya')

      if (fieldIndex >= 0) {
        const fields = formRef.current.getFieldsValue()
        const currentFields = fields[formName]

        const curr = {
          agent_id: parseInt(agent.id, 10),
          [firstNameLabel]: agent.firstname,
          [lastNameLabel]: agent.lastname !== "" ? agent.lastname : undefined,
          name: objString,
          email: agent.email || agent?.brokerage_info_from_LOC?.email,
          phone: normalizePhone(agent.phone) || normalizePhone(agent?.brokerage_info_from_LOC?.phone),
          agent_brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
          agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
          landline: agent?.brokerage_info_from_LOC?.landline,
          state: agent?.brokerage_info_from_LOC?.state,
          zipcode: agent?.brokerage_info_from_LOC?.zipcode,
          city: agent?.brokerage_info_from_LOC?.city,
          // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
          //is_primary: isFirst ? 1 : 0,
          listing_brokerage: agent?.brokerage_info_from_LOC?.listing_brokerage || agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
        }
        console.log(curr, 'pragya', 'curr')
        Object.assign(currentFields[fieldIndex], curr)
        formRef.current.setFieldsValue({
          [formName]: currentFields,
        })

        // formRef.current.setFieldsValue()
        if (callback) {
          callback(curr, parseInt(fieldIndex, 10))
        }
      } else {
        console.log(agent, 'pragya', 'agent')
        const currentAgent = {
          agent_id: parseInt(agent.id, 10),
          [firstNameLabel]: agent.firstname,
          [lastNameLabel]: agent.lastname !== "" ? agent.lastname : undefined,
          name: objString,
          email: agent.email || agent?.brokerage_info_from_LOC?.email,
          phone: normalizePhone(agent.phone) || normalizePhone(agent?.brokerage_info_from_LOC?.phone),
          listing_brokerage: agent?.brokerage_info_from_LOC?.listing_brokerage || agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
          agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
          landline: agent?.brokerage_info_from_LOC?.landline,
          state: agent?.brokerage_info_from_LOC?.state,
          zipcode: agent?.brokerage_info_from_LOC?.zipcode,
          city: agent?.brokerage_info_from_LOC?.city,
          // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
          //is_primary: isFirst ? 1 : 0,
        }
          console.log(currentAgent, 'pragya', 'currentAgent')

        formRef.current.setFieldsValue({
          ...currentAgent,
        })

        if (callback) {
          callback(currentAgent)
        }
      }
    } catch (e) {
      //fail silently
    }
  }

  const getSelectField = () => {
    switch (isFormField) {
      case true:
        return (
          <Form.Item
            name={name}
            rules={rules}
            label={hideLabel ? null : <LabelComponent text={text} required={required} />}
            required={required}
          >
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={((e, option) => changeOtherFieldsValues(e, index, false, option))}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents).map(d => {
                let agent;
                if(hideClickToAddManually){
                  agent = {
                    label:  (!d.firstname?.length || !d.lastname?.length) && d.email?.length
                      ? `${d.email}`
                  :d.email?.length ? `${d.firstname} ${d.lastname} (${d.email})` : `${d.firstname} ${d.lastname}`,
                    value: `${d.firstname} ${d.lastname} (${d.email})`,
                    key: d.id,
                  }
                }else{
                  agent = {
                    label: (!d.firstname?.length || !d.lastname?.length) && d.email?.length
                      ? `${d.email}`
                    : d.email?.length ? `${d.firstname} ${d.lastname} (${d.email})` : `${d.firstname} ${d.lastname} - click to add manually`,
                  value: `${d.firstname} ${d.lastname} (${d.email})`,
                  key: d.id,
                  }
                }
                console.log(agent,"agent")
                return agent;
              }).filter(d =>d?.label && d?.label?.trim()?.length>0)}
              placeholder={placeholder}
            />
            {/* <AutoCompleteFieldWrap
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={searchAgentDetails}
          onSelect={((e, option) => changeOtherFieldsValues(e, index, false, option))}
          notFoundContent={
            isFetchingAgents ? (
              <StyledSpin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : null
          }
          options={(agents).map(d => ({
            label: (!d.firstname?.length || !d.lastname?.length) && d.email?.length
              ? `${d.email}`
              : d.email?.length ? `${d.firstname} ${d.lastname} (${d.email})` : `${d.firstname} ${d.lastname}`,
            value: `${d.firstname} ${d.lastname} (${d.email})`,
            key: d.id,
          }))}
          placeholder={placeholder}
          // disabled={disabled}
        /> */}
          </Form.Item>
        )
      default:
        return (
          <Wrap>
            {hideLabel ? null : <LabelComponent text="Agent First Name" />}
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={((e, option) => changeOtherFieldsValues(e, index, false, option))}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents).map(d => ({
                label: `${d.firstname} ${d.lastname} (${d.email})`,
                value: `${d.firstname} ${d.lastname} (${d.email})`,
                key: d.id,
              }))}
              placeholder={placeholder}
            />
            {/* <AutoCompleteFieldWrap
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={searchAgentDetails}
          onSelect={((e, option) => changeOtherFieldsValues(e, index, false, option))}
          notFoundContent={
            isFetchingAgents ? (
              <StyledSpin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : null
          }
          options={(agents).map(d => ({
            label: `${d.firstname} ${d.lastname} (${d.email})`,
            value: `${d.firstname} ${d.lastname} (${d.email})`,
            key: d.id,
          }))}
          placeholder={placeholder}
          // disabled={disabled}
        /> */}
          </Wrap>
        )
    }
  }

  return (
    <>
      {getSelectField()}
    </>
  )
}

export default SearchContainer(AgentSearch)