import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import root from "window-or-global"
import queryString from "query-string"
import * as Actions from "./actions"

const DEAL_TERMS_COMMON = {
  price: "",
  loan_amount_percentage: "",
  loan_amount: "",
  earnest_money_deposit: "",
  balance_of_down_payment: "",
  initial_deposit_amount_percentage: "",
  initial_deposit_amount: "",
  increased_deposit_amount_percentage: "",
  increased_deposit_amount: "",
  additional_financial_amount_percentage: "",
  additional_financial_amount: "",
}

const initState = {
  noteForStatusUpdate: "",
  currentActiveTab: "cover_sheet",
  currentSideNavTab: "property_information",
  currentCoverSheetTab: "property_information",
  currentDocumentTab: "recommended_docs",
  currentMLSTab: "address",
  currentLibraryTab: "offer_docs",
  currentSearchText: "",
  currentStep: "",
  stateForOffer: "california",
  isBasicOfferDetailsModalOpen: false,
  offerStatus: "incomplete",
  buyersAgentInformationObject: {
    agents: [],
    buyers: [],
    lenders: [],
    selectedLender: null,
    selected_lender_id: null,
    vendors: [],
    selectedVendor: null,
    selected_vendor_id: null,
    financingType: null,
    source: "",
    other_source: "",
  },
  propertyInformationObject: {
    addressRepresentation: false,
    currentState: "california",
    form: {},
    listing_agents: [],
    sellers: [],
    mls_number: "",
  },
  dealTermObject: {
    currentState: "california",
    form: {
      california: {
        ...DEAL_TERMS_COMMON,
        additional_earnest_money: "",
      },
      texas: {
        ...DEAL_TERMS_COMMON,
      },
      florida: {
        ...DEAL_TERMS_COMMON,
        other: "",
        additional_earnest_money: "",
        additional_deposit: "",
      },
    },
  },
  docList: [],
  offersDocList: [],
  listingDocsList: [],
  uploadedDocs: {
    sharedWithMe: [],
    teamDocs: [],
  },
  envelopes: [],
  proofOfFundsDocs: [],
  preApprovedDocs: [],
  coverLetterDocs: [],
  othersDocs: [],
  offerPackages: {},
  completeOfferPackageToBeSent: {},
  isCoAgentFormVisible: false,
  isBuyerFormVisible: false,
  isAddNewLenderFormVisible: false,
  isAddNewVendorFormVisible: false,
  isAddNewSellerVisible: false,
  isAddNewListingAgentVisible: false,
  isCreateEnvelopeModalOpen: false,
  mlsOrPropertyData: {},
  confirmationNote: "",
  isDocumentProcessing: false,
  isShowAddPaperworkModalVisible: false,
  isLenderUpdating: false,
  isVendorUpdating: false,
  isTCTeamDetailsModalAction: false,
  coversheetErrorsList: [],
  draftCallPayload: {

  },
  ...createDeltaReducer("getAllPIMResponse"),
  ...createDeltaReducer("postFinalPIMResponse"),
  ...createDeltaReducer("fetchLibraryUploadedFilesResponse"),
  ...createDeltaReducer("fetchActiveEnvelopesFilesResponse"),
  ...createDeltaReducer("fetchBuyerOffersResponse"),
  ...createDeltaReducer("fetchInfoBasedOnMLSIdResponse"),
  ...createDeltaReducer("fetchInfoBasedOnPropertyAddressResponse"),
  ...createDeltaReducer("createOfferFromPropertyTypeResponse"),
  ...createDeltaReducer("getOfferStatusMappingsResponse"),
  ...createDeltaReducer("updateBuyerInformationTabResponse"),
  ...createDeltaReducer("updateLenderInformationResponse"),
  ...createDeltaReducer("updateVendorInformationResponse"),
  ...createDeltaReducer("updatePropertyInformationTabResponse"),
  ...createDeltaReducer("updateDealTermsInformationTabResponse"),
  ...createDeltaReducer("fetchDealTermsInformationResponse"),
  ...createDeltaReducer("fetchPropertyInformationResponse"),
  ...createDeltaReducer("fetchVendorInfoResponse"),
  ...createDeltaReducer("fetchValidateCoverSheetResponse"),
  ...createDeltaReducer("updateOfferPurchaseTypeResponse"),
  ...createDeltaReducer("fetchOfferDocumentsResponse"),
  ...createDeltaReducer("updateRecommendedListOfDocsResponse"),
  ...createDeltaReducer("fetchListingDocumentsResponse"),
  ...createDeltaReducer("updateLibraryUploadsResponse"),
  ...createDeltaReducer("deleteLibraryUploadedFileResponse"),
  ...createDeltaReducer("fetchOfferRelatedDocumentsResponse"),
  ...createDeltaReducer("uploadOfferRelatedResponse"),
  ...createDeltaReducer("sendReminderResponse"),
  ...createDeltaReducer("createReceiverOfferViewV2Response"),
  ...createDeltaReducer("fetchTransactionFilesResponse"),
  ...createDeltaReducer("deleteTransactionFileResponse"),
  ...createDeltaReducer("transactionUploadFilesResponse"),
  ...createDeltaReducer("fetchOfferPackageDocumentsResponse"),
  ...createDeltaReducer("sendOfferPackageMailResponse"),
  ...createDeltaReducer("getOfferPackageResponse"),
  ...createDeltaReducer("getOfferStatusResponse"),
}

export default function offersV2Reducer(state = initState, action) {
  switch (action.type) {
    case Actions.getAllPimAction.REQUEST:
    case Actions.getAllPimAction.SUCCESS:
    case Actions.getAllPimAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAllPimAction, "getAllPIMResponse"),
      }
    }

    case Actions.postFinalCTAAction.REQUEST:
    case Actions.postFinalCTAAction.SUCCESS:
    case Actions.postFinalCTAAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.postFinalCTAAction, "postFinalPIMResponse"),
      }
    }

    case Actions.fetchLibraryUploadedFilesAction.REQUEST:
    case Actions.fetchLibraryUploadedFilesAction.SUCCESS:
    case Actions.fetchLibraryUploadedFilesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchLibraryUploadedFilesAction, "fetchLibraryUploadedFilesResponse"),
      }
    }

    case Actions.fetchActiveEnvelopesFilesAction.REQUEST:
    case Actions.fetchActiveEnvelopesFilesAction.SUCCESS:
    case Actions.fetchActiveEnvelopesFilesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchActiveEnvelopesFilesAction, "fetchActiveEnvelopesFilesResponse"),
      }
    }

    case Actions.fetchBuyerOffersAction.REQUEST:
    case Actions.fetchBuyerOffersAction.SUCCESS:
    case Actions.fetchBuyerOffersAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchBuyerOffersAction, "fetchBuyerOffersResponse"),
      }
    }

    case Actions.fetchInfoBasedOnMLSIdAction.REQUEST:
    case Actions.fetchInfoBasedOnMLSIdAction.SUCCESS:
    case Actions.fetchInfoBasedOnMLSIdAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchInfoBasedOnMLSIdAction, "fetchInfoBasedOnMLSIdResponse"),
      }
    }

    //fetchInfoBasedOnPropertyAddressAction
    case Actions.fetchInfoBasedOnPropertyAddressAction.REQUEST:
    case Actions.fetchInfoBasedOnPropertyAddressAction.SUCCESS:
    case Actions.fetchInfoBasedOnPropertyAddressAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchInfoBasedOnPropertyAddressAction, "fetchInfoBasedOnPropertyAddressResponse"),
      }
    }

    case Actions.createOfferFromPropertyTypeAction.REQUEST:
    case Actions.createOfferFromPropertyTypeAction.SUCCESS:
    case Actions.createOfferFromPropertyTypeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createOfferFromPropertyTypeAction, "createOfferFromPropertyTypeResponse"),
      }
    }

    case Actions.getOfferStatusMappingsAction.REQUEST:
    case Actions.getOfferStatusMappingsAction.SUCCESS:
    case Actions.getOfferStatusMappingsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getOfferStatusMappingsAction, "getOfferStatusMappingsResponse"),
      }
    }

    //updateBuyerInformationTabAction
    case Actions.updateBuyerInformationTabAction.REQUEST:
    case Actions.updateBuyerInformationTabAction.SUCCESS:
    case Actions.updateBuyerInformationTabAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateBuyerInformationTabAction, "updateBuyerInformationTabResponse"),
      }
    }

    case Actions.updateLenderInformationAction.REQUEST:
    case Actions.updateLenderInformationAction.SUCCESS:
    case Actions.updateLenderInformationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateLenderInformationAction, "updateLenderInformationResponse")
      }
    }

    case Actions.updateVendorInformationAction.REQUEST:
    case Actions.updateVendorInformationAction.SUCCESS:
    case Actions.updateVendorInformationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateVendorInformationAction, "updateVendorInformationResponse")
      }
    }

    case Actions.updatePropertyInformationTabAction.REQUEST:
    case Actions.updatePropertyInformationTabAction.SUCCESS:
    case Actions.updatePropertyInformationTabAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updatePropertyInformationTabAction, "updatePropertyInformationTabResponse"),
      }
    }

    case Actions.updateDealTermsInformationTabAction.REQUEST:
    case Actions.updateDealTermsInformationTabAction.SUCCESS:
    case Actions.updateDealTermsInformationTabAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateDealTermsInformationTabAction, "updateDealTermsInformationTabResponse"),
      }
    }

    case Actions.fetchDealTermsInformationAction.REQUEST:
    case Actions.fetchDealTermsInformationAction.SUCCESS:
    case Actions.fetchDealTermsInformationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchDealTermsInformationAction, "fetchDealTermsInformationResponse"),
      }
    }

    case Actions.fetchPropertyInformationAction.REQUEST:
    case Actions.fetchPropertyInformationAction.SUCCESS:
    case Actions.fetchPropertyInformationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchPropertyInformationAction, "fetchPropertyInformationResponse"),
      }
    }

    case Actions.fetchVendorInfoAction.REQUEST:
    case Actions.fetchVendorInfoAction.SUCCESS:
    case Actions.fetchVendorInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchVendorInfoAction, "fetchVendorInfoResponse"),
      }
    }

    case Actions.fetchValidateCoverSheetAction.REQUEST:
    case Actions.fetchValidateCoverSheetAction.SUCCESS:
    case Actions.fetchValidateCoverSheetAction.CLEAR:
    case Actions.fetchValidateCoverSheetAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchValidateCoverSheetAction, "fetchValidateCoverSheetResponse"),
      }
    }
    //
    case Actions.updateOfferPurchaseTypeAction.REQUEST:
    case Actions.updateOfferPurchaseTypeAction.SUCCESS:
    case Actions.updateOfferPurchaseTypeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateOfferPurchaseTypeAction, "updateOfferPurchaseTypeResponse"),
      }
    }
    //fetchOfferDocumentsAction
    case Actions.fetchOfferDocumentsAction.REQUEST:
    case Actions.fetchOfferDocumentsAction.SUCCESS:
    case Actions.fetchOfferDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchOfferDocumentsAction, "fetchOfferDocumentsResponse"),
      }
    }

    case Actions.updateRecommendedListOfDocsAction.REQUEST:
    case Actions.updateRecommendedListOfDocsAction.SUCCESS:
    case Actions.updateRecommendedListOfDocsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateRecommendedListOfDocsAction, "updateRecommendedListOfDocsResponse"),
      }
    }

    case Actions.fetchListingDocumentsAction.REQUEST:
    case Actions.fetchListingDocumentsAction.SUCCESS:
    case Actions.fetchListingDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchListingDocumentsAction, "fetchListingDocumentsResponse"),
      }
    }

    case Actions.updateLibraryUploadsAction.REQUEST:
    case Actions.updateLibraryUploadsAction.SUCCESS:
    case Actions.updateLibraryUploadsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateLibraryUploadsAction, "updateLibraryUploadsResponse"),
      }
    }

    case Actions.deleteLibraryUploadedFileAction.REQUEST:
    case Actions.deleteLibraryUploadedFileAction.SUCCESS:
    case Actions.deleteLibraryUploadedFileAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteLibraryUploadedFileAction, "deleteLibraryUploadedFileResponse"),
      }
    }

    case Actions.deleteOfferAction.REQUEST:
    case Actions.deleteOfferAction.SUCCESS:
    case Actions.deleteOfferAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteOfferAction, "deleteOfferResponse"),
      }
    }

    case Actions.fetchOfferRelatedDocumentsAction.REQUEST:
    case Actions.fetchOfferRelatedDocumentsAction.SUCCESS:
    case Actions.fetchOfferRelatedDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchOfferRelatedDocumentsAction, "fetchOfferRelatedDocumentsResponse"),
      }
    }

    case Actions.uploadOfferRelatedAction.REQUEST:
    case Actions.uploadOfferRelatedAction.SUCCESS:
    case Actions.uploadOfferRelatedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.uploadOfferRelatedAction, "uploadOfferRelatedResponse"),
      }
    }

    case Actions.sendReminderAction.REQUEST:
    case Actions.sendReminderAction.SUCCESS:
    case Actions.sendReminderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendReminderAction, "sendReminderResponse"),
      }
    }

    case Actions.createReceiverOfferViewV2Action.REQUEST:
    case Actions.createReceiverOfferViewV2Action.SUCCESS:
    case Actions.createReceiverOfferViewV2Action.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createReceiverOfferViewV2Action, "createReceiverOfferViewV2Response"),
      }
    }

    case Actions.fetchProofOfFundsAction.REQUEST:
    case Actions.fetchProofOfFundsAction.SUCCESS:
    case Actions.fetchProofOfFundsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchProofOfFundsAction, "fetchProofOfFundsResponse"),
      }
    }

    case Actions.fetchPreApprovedAction.REQUEST:
    case Actions.fetchPreApprovedAction.SUCCESS:
    case Actions.fetchPreApprovedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchPreApprovedAction, "fetchPreApprovedResponse"),
      }
    }

    case Actions.fetchCoverLettersAction.REQUEST:
    case Actions.fetchCoverLettersAction.SUCCESS:
    case Actions.fetchCoverLettersAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchCoverLettersAction, "fetchCoverLettersResponse"),
      }
    }

    case Actions.fetchOtherAdditionalDocsAction.REQUEST:
    case Actions.fetchOtherAdditionalDocsAction.SUCCESS:
    case Actions.fetchOtherAdditionalDocsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchOtherAdditionalDocsAction, "fetchOtherAdditionalDocsResponse"),
      }
    }

    case Actions.transactionUploadFilesAction.REQUEST:
    case Actions.transactionUploadFilesAction.SUCCESS:
    case Actions.transactionUploadFilesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.transactionUploadFilesAction, "transactionUploadFilesResponse"),
      }
    }

//fetchTransactionFilesAction
    case Actions.deleteTransactionFileAction.REQUEST:
    case Actions.deleteTransactionFileAction.SUCCESS:
    case Actions.deleteTransactionFileAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteTransactionFileAction, "deleteTransactionFileResponse"),
      }
    }

    case Actions.fetchTransactionFilesAction.REQUEST:
    case Actions.fetchTransactionFilesAction.SUCCESS:
    case Actions.fetchTransactionFilesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchTransactionFilesAction, "fetchTransactionFilesResponse"),
      }
    }

    case Actions.fetchOfferPackageDocumentsAction.REQUEST:
    case Actions.fetchOfferPackageDocumentsAction.SUCCESS:
    case Actions.fetchOfferPackageDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchOfferPackageDocumentsAction, "fetchOfferPackageDocumentsResponse"),
      }
    }

    //sendOfferPackageMailAction
    case Actions.sendOfferPackageMailAction.REQUEST:
    case Actions.sendOfferPackageMailAction.SUCCESS:
    case Actions.sendOfferPackageMailAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendOfferPackageMailAction, "sendOfferPackageMailResponse"),
      }
    }

    //getOfferPackageAction
    case Actions.getOfferPackageAction.REQUEST:
    case Actions.getOfferPackageAction.SUCCESS:
    case Actions.getOfferPackageAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getOfferPackageAction, "getOfferPackageResponse"),
      }
    }

    case Actions.getOfferStatusAction.REQUEST:
    case Actions.getOfferStatusAction.SUCCESS:
    case Actions.getOfferStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getOfferStatusAction, "getOfferStatusResponse"),
      }
    }

    case Actions.toggleActiveTabAction.type: {
      return {
        ...state,
        currentActiveTab: action.data,
        currentSideNavTab: action.data === "cover_sheet" ? "buyer_information" : "templates",
      }
    }

    case Actions.toggleSideNavAction.type: {
      return {
        ...state,
        currentSideNavTab: action.data,
      }
    }

    case Actions.toggleCoverSheetAction.type: {
      return {
        ...state,
        currentCoverSheetTab: action.data,
      }
    }

    case Actions.toggleMLSTabAction.type: {
      return {
        ...state,
        currentMLSTab: action.data,
      }
    }

    case Actions.toggleLibraryTabAction.type: {
      return {
        ...state,
        currentLibraryTab: action.data,
      }
    }

    case Actions.setSearchTextAction.type: {
      return {
        ...state,
        currentSearchText: action.data,
      }
    }

    case Actions.toggleBasicOfferDetailsAction.type: {
      return {
        ...state,
        isBasicOfferDetailsModalOpen: action.data,
      }
    }

    case Actions.addNewCoAgentFormAction.type: {
      return {
        ...state,
        isCoAgentFormVisible: action.data,
      }
    }

    case Actions.addBuyerAgentFormAction.type: {
      return {
        ...state,
        isBuyerFormVisible: action.data,
      }
    }

    case Actions.addLenderAgentFormAction.type: {
      return {
        ...state,
        isAddNewLenderFormVisible: action.data,
      }
    }

    case Actions.addVendorAgentFormAction.type: {
      return {
        ...state,
        isAddNewVendorFormVisible: action.data,
      }
    }

    case Actions.updateBuyerInformationObjectAction.type: {
      return {
        ...state,
        buyersAgentInformationObject: {
          ...action.data,
        },
      }
    }

    case Actions.updatePropertyInformationObjectAction.type: {
      return {
        ...state,
        propertyInformationObject: {
          ...action.data,
        },
      }
    }

    case Actions.updateDealTermsObjectAction.type: {
      return {
        ...state,
        dealTermObject: {
          ...action.data,
        },
      }
    }

    case Actions.addNewListingAgentToggleAction.type: {
      return {
        ...state,
        isAddNewListingAgentVisible: action.data,
      }
    }

    case Actions.addNewSellerAgentToggleAction.type: {
      return {
        ...state,
        isAddNewSellerVisible: action.data,
      }
    }

    case Actions.toggleCreateEnvelopeModalAction.type: {
      return {
        ...state,
        isCreateEnvelopeModalOpen: action.data,
      }
    }

    case Actions.setCurrentStepAction.type: {
      return {
        ...state,
        currentStep: action.data,
      }
    }

    case Actions.updateOffersDocumentsObjectAction.type: {
      return {
        ...state,
        docList: [...action.data],
      }
    }

    case Actions.updateUploadedDocsObjectAction.type: {
      return {
        ...state,
        uploadedDocs: action.data,
      }
    }

    case Actions.updateEnvelopesObjectAction.type: {
      return {
        ...state,
        envelopes: action.data,
      }
    }

    case Actions.setMLSDataAction.type: {
      return {
        ...state,
        mlsOrPropertyData: action.data,
      }
    }

    case Actions.updateStatusForCurrentCoverSheet.type: {
      return {
        ...state,
        offerStatus: action.data,
      }
    }

    case Actions.setStateForOfferAction.type: {
      return {
        ...state,
        stateForOffer: action.data,
      }
    }

    case Actions.updateOfferDocsListAction.type: {
      return {
        ...state,
        offersDocList: action.data,
      }
    }

    case Actions.updateListingOfferDocsAction.type: {
      return {
        ...state,
        listingDocsList: action.data,
      }
    }

    case Actions.addNoteForStatusUpdateAction.type: {
      return {
        ...state,
        noteForStatusUpdate: action.data,
      }
    }

    case Actions.updateRecommendedDocsObjectAction.type: {
      return {
        ...state,
        docList: action.data,
      }
    }

    case Actions.updateProofOfFundsAction.type: {
      return {
        ...state,
        proofOfFundsDocs: action.data,
      }
    }

    case Actions.updatePreApprovedAction.type: {
      return {
        ...state,
        preApprovedDocs: action.data,
      }
    }

    case Actions.updateCoverLetterAction.type: {
      return {
        ...state,
        coverLetterDocs: action.data,
      }
    }

    case Actions.updateOtherDocsAction.type: {
      return {
        ...state,
        othersDocs: action.data,
      }
    }

    case Actions.updateConfirmationNoteBeforeAction.type: {
      return {
        ...state,
        confirmationNote: action.data,
      }
    }
   
    case Actions.updateOfferPackageDetailsAction.type: {
      return {
        ...state,
        completeOfferPackageToBeSent: action.data,
      }
    }

    case Actions.updateCurrentOfferPackageObjectAction.type: {
      return {
        ...state,
        offerPackages: action.data,
      }
    }

    case Actions.updateStateForOffersAction.type: {
      return {
        ...state,
        stateForOffer: action.data,
      }
    }

    case Actions.toggleDocumentTabAction.type: {
      return {
        ...state,
        currentDocumentTab: action.data,
      }
    }

    case Actions.documentsProcessingAction.type: {
      return {
        ...state,
        isDocumentProcessing: action.data,
      }
    }

    case Actions.toggleAddPaperWorkModalAction.type: {
      return {
        ...state,
        isShowAddPaperworkModalVisible: action.data,
      }
    }

    case Actions.debuggerAction.type: {
      console.log(action.data)
      return {
        ...state,
      }
    }

    case Actions.reduxUpdatingLenderAction.type: {
      return {
        ...state,
        isLenderUpdating: action.data,
      }
    }

    case Actions.reduxUpdatingVendorAction.type: {
      return {
        ...state,
        isVendorUpdating: action.data,
      }
    }

    case Actions.toggleTCTeamDetailsModalAction.type: {
      return {
        ...state,
        isTCTeamDetailsModalAction: action.data,
      }
    }

    case Actions.setCoversheetErrorsListAction.type: {
      return {
        ...state,
        coversheetErrorsList: action.data,
      }
    }

    case Actions.updateDraftCallPayloadAction.type: {
      return {
        ...state,
        draftCallPayload: action.data,
      }
    }

    case Actions.clearOffersRelatedDataAction.type: {
      return {
        ...state,
        isTCTeamDetailsModalAction: false,
        currentActiveTab: "cover_sheet",
        currentDocumentTab: "recommended_docs",
        ...createDeltaReducer("fetchInfoBasedOnMLSIdResponse"),
        ...createDeltaReducer("fetchDealTermsInformationResponse"),
        ...createDeltaReducer("fetchPropertyInformationResponse"),
        ...createDeltaReducer("fetchValidateCoverSheetResponse"),
        propertyInformationObject: {
          addressRepresentation: false,
          currentState: "california",
          form: {},
          listing_agents: [],
          sellers: [],
          mls_number: "",
        },
        docList: [],
        currentLibraryTab: "offer_docs",
        buyersAgentInformationObject: {
          agents: [],
          buyers: [],
          lenders: [],
          selectedLender: null,
          selected_lender_id: null,
          vendors: [],
          selectedVendor: null,
          selected_vendor_id: null,
          financingType: null,
          source: "",
          other_source: "",
        },
        mlsOrPropertyData: {},
        offersDocList: [],
        listingDocsList: [],
        uploadedDocs: {
          sharedWithMe: [],
          teamDocs: [],
        },
        envelopes: [],
        proofOfFundsDocs: [],
        preApprovedDocs: [],
        coverLetterDocs: [],
        othersDocs: [],
        offerPackages: {},
        completeOfferPackageToBeSent: {},
        isCoAgentFormVisible: false,
        isBuyerFormVisible: false,
        isAddNewLenderFormVisible: false,
        isAddNewVendorFormVisible: false,
        isAddNewSellerVisible: false,
        isAddNewListingAgentVisible: false,
        isCreateEnvelopeModalOpen: false,
        confirmationNote: "",
        isDocumentProcessing: false,
        isShowAddPaperworkModalVisible: false,
        isBasicOfferDetailsModalOpen: false,
        dealTermObject: {
          currentState: "california",
          form: {
            california: {
              ...DEAL_TERMS_COMMON,
              additional_earnest_money: "",
            },
            texas: {
              ...DEAL_TERMS_COMMON,
            },
            florida: {
              ...DEAL_TERMS_COMMON,
              other: "",
              additional_earnest_money: "",
              additional_deposit: "",
            },
          },
        },
      }
    }

    default: {
      return state
    }
  }
}
