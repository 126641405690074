import React, { Component } from 'react'
import { LoadScript } from 'services/Utils'
import { Input } from 'antd'
import { INTER } from '@fonts'
import colors from '@colors'
// import SearchIconBlack from 'src/images/searchIconBlack'

export default class NewGoogleLocation extends Component {
  loadScript() {
    if (typeof window.google === 'undefined') {
      LoadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_APIKEY}&libraries=places`,
        () => {
          this.googleAutoCompleteInitializer()
        }
      )
    } else {
      this.googleAutoCompleteInitializer()
    }
  }

  googleAutoCompleteInitializer() {
    const { types, restrict } = this.props
    const options = {
      types
    }
    const geoComponents = new window.google.maps.places.Autocomplete(this.inputText, options)
    if (restrict) {
      const cityBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(restrict.south_west.lat, restrict.south_west.lng),
        new window.google.maps.LatLng(restrict.north_east.lat, restrict.north_east.lng)
      )
      options.strictBounds = true
      options.bounds = cityBounds
      options.componentRestrictions = {}
      options.componentRestrictions.country = restrict.country.short_name.toLowerCase()
      geoComponents.setOptions(options)
    }
    // window.google.maps.event.addDomListener(this.inputText, 'keydown', e => {
    //   if (e.keyCode === 13) {
    //     e.preventDefault()
    //   }
    // })
    this.inputText.addEventListener('keydown', e => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });

    window.google.maps.event.addListener(geoComponents, 'place_changed', () => {
      const place = geoComponents.getPlace()
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        south_west: {
          lat: place.geometry.viewport.getSouthWest().lat(),
          lng: place.geometry.viewport.getSouthWest().lng()
        },
        north_east: {
          lat: place.geometry.viewport.getNorthEast().lat(),
          lng: place.geometry.viewport.getNorthEast().lng()
        },
        address: place.formatted_address
      }
      place.address_components.map(component => (location[component.types[0]] = component))
      if (this.props.clearInput) {
        this.inputText.value = ''
        this.inputText.focus()
      }
      this.props.location(location)
      if (this.props.onChange) {
        this.props.onChange({ target: { value: location.address } }); // Trigger Ant Design onChange
      }
    })
  }

  componentDidMount() {
    this.loadScript()
    // this.inputElement.focus();
  }

  referenceHandler = ref => {
    // console.log("REFERENCE", ref)
    this.inputText = ref?.input
  }

  render() {
    const { placeholder, formValue, value, onChange } = this.props
    // console.log("==value, onChange==", value, onChange)
    return (
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        ref={this.referenceHandler}
        style={{
          borderRadius: "8px",
          border: `1px solid ${colors.new_borderGrey}`,
          color: colors.new_grey400,
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          fontFamily: INTER,
          fontWeight: 400,
          fontSize: "16px",
          // padding: "10px 14px",
        }}
        prefix={
          <img
            src="https://s3.us-east-1.amazonaws.com/cdn.agentdesks.com/images/SearchAddressblack.svg"
            alt="Search"
            style={{ width: 20, height: 20, padding: "0px" }}
          />
        }
      />
    )
  }
}