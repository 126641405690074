import React, { useState, useEffect } from 'react';
import { Checkbox, Input, Button, Typography, Space, Alert } from 'antd';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import AgentSearchFormUncontrolled from './AgentSearchFormUncontrolled';
import AgentSearchManualSwitch from './AgentSearchManualSwitch';
import AgentSearchSwitch from './AgentSearchSwitch';
import AgentSearch from './AgentSearch';
const { Text, Link } = Typography;

const StyledLabel = styled(Text)`
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #111827 !important;
`;

const StyledLink = styled(Link)`
  color: #5A5FF2 !important;
`;

const StyledText = styled(Text)`
  color: #6B7280 !important;
`;

const SearchContainer = styled.div`
  border-radius: 8px !important;
  padding: 0px !important;
  margin: 8px 0 12px 0px !important;
  width: 100% !important;
  && .ant-form-item {
    margin-bottom: 0px !important;
    width: 100% !important;
  }
  && .ant-select-selection-search-input {
    padding-left: 2rem !important;
  }
  && .ant-select-selection-item {
    padding-left: 2rem !important;
  }
  && .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #EEF2FF !important;
  }
    && .ant-select-arrow {
      left:0 !important;
  }
  && .ant-select-selection-search-input {
    padding-left: 2rem !important;
  }
  && .ant-select-selection-placeholder {
      padding-left: 2rem !important;
    }
`;

const InfoMessage = styled(Alert)`
  background-color: transparent !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding-left: 4px !important;
  .ant-alert-icon {
    color: #7D7DA9 !important;
  }
  .ant-alert-message { 
    color: #7D7DA9 !important;
  }
`;

const NoResultsContainer = styled.div`
  padding: 8px 0 !important;
`;

const AgentSearchWithConsent = ({ 
  onAgentPartOfRadius, 
  onAddManually,
  valueOfField,
  placeholder = "Search by agent name or email address.",
  consentLabel = "Is agent part of radius?",
  infoMessage = "If agent is part of Radius, you can search by agent name or email address. Results will appear below automatically. Select an agent from the list.",
  formRef,
  name,
  formName,
  lastNameLabel,
  emailLabel,
  agentId,
  onClear,
  onChange,
  ...agentSearchProps
}) => {
  const [isManualModePopup, setIsManualModePopup] = useState(false);
  const [isSwitchToSearchPopup, setIsSwitchToSearchPopup] = useState(false);



  const handleAddManually = () => {
    onAddManually();
    setIsManualModePopup(false);
    if(onClear){
      onClear();
    }
  };

  const handleCancelManually = () => {
    setIsManualModePopup(false);
  }

  const handleConfirmSwitchToSearch = () => {
    if(valueOfField){
      onAgentPartOfRadius(true);
      setIsSwitchToSearchPopup(false);
      if(onClear){
        onClear();
      }
    }
  }

  const handleCancelSwitchToSearch = () => {
    setIsSwitchToSearchPopup(false);
  }


  return (
    <SearchContainer>
        
         <AgentSearchFormUncontrolled
          {...agentSearchProps}
          hideLabel={true}
          hideClickToAddManually={true}
          name={name}
          formName={formName}
          lastNameLabel={lastNameLabel}
          onChange={onChange}
          placeholder={placeholder}
          isFormField={false}
        />
          <InfoMessage
            icon={<span style={{ fontSize: '18px' }}>ⓘ</span>}
            message={infoMessage}
            type="info"
            showIcon
          />
       <AgentSearchManualSwitch
        visible={isManualModePopup}
        onCancel={handleCancelManually}
        onConfirm={handleAddManually}
       />
       <AgentSearchSwitch
        visible={isSwitchToSearchPopup}
        onConfirm={handleConfirmSwitchToSearch}
        onCancel={handleCancelSwitchToSearch}
       />
    </SearchContainer>
  );
};

export default AgentSearchWithConsent;
