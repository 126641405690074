export const getInputFieldsBasedOnState = (state) => {
  switch (state) {
    case "california":
      return [{
        name: "street",
        type: "googleLocation",
        placeholder: "Street Address",
      }, {
        name: "apt",
        type: "text",
        placeholder: "Apt/unit/suite no.",
      }, {
        name: "city",
        type: "text",
        placeholder: "City",
      }, {
        name: "state",
        type: "text",
        placeholder: "State",
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zipcode",
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN number",
      }]
    case "texas":
      return [{
        name: "price",
        type: "text",
        placeholder: "Current Listing Price",
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN number",
      }, {
        name: "street",
        type: "text",
        placeholder: "Street Address",
      }, {
        name: "apt",
        type: "text",
        placeholder: "Apt/unit/suite no.",
      }, {
        name: "city",
        type: "text",
        placeholder: "City",
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zipcode",
      }, {
        name: "lot",
        type: "text",
        placeholder: "Lot",
      }, {
        name: "block",
        type: "text",
        placeholder: "Block",
      }, {
        name: "county",
        type: "text",
        placeholder: "County",
      }, {
        name: "listing_type",
        type: "select",
        placeholder: "Listing Type*",
      }]
    case "florida":
      return [{
        name: "price",
        type: "text",
        placeholder: "Current Listing Price",
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN Number",
      }, {
        name: "street",
        type: "text",
        placeholder: "Street Address",
      }, {
        name: "apt",
        type: "text",
        placeholder: "Apt/unit/suite no.",
      }, {
        name: "city",
        type: "text",
        placeholder: "City",
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zipcode",
      }, {
        name: "county",
        type: "text",
        placeholder: "County",
      }, {
        name: "tax_id_number",
        type: "text",
        placeholder: "Tax ID number",
      }, {
        name: "legal_description",
        type: "text",
        placeholder: "Legal Description",
      }, {
        name: "listing_type",
        type: "select",
        placeholder: "Listing Type",
      }]
    case "washington":
      return [{
        name: "price",
        type: "text",
        placeholder: "Current Listing Price",
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN number",
      }, {
        name: "street",
        type: "text",
        placeholder: "Street Address",
      }, {
        name: "apt",
        type: "text",
        placeholder: "Apt/unit/suite no.",
      }, {
        name: "city",
        type: "text",
        placeholder: "City",
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zipcode",
      }, {
        name: "listing_type",
        type: "select",
        placeholder: "Listing type",
      }]
    case "oregon":
      return [{
        name: "price",
        type: "text",
        placeholder: "Current Listing Price",
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN number",
      }, {
        name: "street",
        type: "text",
        placeholder: "Street Address",
      }, {
        name: "apt",
        type: "text",
        placeholder: "Apt/unit/suite no.",
      }, {
        name: "city",
        type: "text",
        placeholder: "City",
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zipcode",
      }, {
        name: "lot",
        type: "text",
        placeholder: "Lot",
      }, {
        name: "block",
        type: "text",
        placeholder: "Block",
      }, {
        name: "county",
        type: "text",
        placeholder: "County",
      }, {
        name: "listing_type",
        type: "select",
        placeholder: "Listing type",
      }]
    case "colorado":
      return [{
        name: "price",
        type: "text",
        placeholder: "Current Listing Price",
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN number",
      }, {
        name: "street",
        type: "text",
        placeholder: "Street address",
      }, {
        name: "apt",
        type: "text",
        placeholder: "Apt/unit/suite no.",
      }, {
        name: "city",
        type: "text",
        placeholder: "City",
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zipcode",
      }, {
        name: "county",
        type: "select",
        placeholder: "County",
      }, {
        name: "tax_id_number",
        type: "text",
        placeholder: "",
      }, {
        name: "legal_description",
        type: "text",
        placeholder: "Tax Id number",
      }, {
        name: "listing_type",
        type: "select",
        placeholder: "Listing type",
      }]
    case "georgia":
      return [{
        name: "price",
        type: "text",
        placeholder: "Current listing price",
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN number",
      }, {
        name: "street",
        type: "text",
        placeholder: "Street Address",
      }, {
        name: "apt",
        type: "text",
        placeholder: "Apt/unit/suite no.",
      }, {
        name: "city",
        type: "text",
        placeholder: "City",
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zipcode",
      }, {
        name: "county",
        type: "select",
        placeholder: "County",
      }, {
        name: "tax_parcel_id_number",
        type: "text",
        placeholder: "Tax Parcel ID number",
      }, {
        name: "land_lots",
        type: "text",
        placeholder: "Land Lots",
      }, {
        name: "district",
        type: "text",
        placeholder: "District",
      }, {
        name: "section_gmd",
        type: "text",
        placeholder: "Section/GMD",
      }, {
        name: "lot",
        type: "text",
        placeholder: "Lot",
      }, {
        name: "block",
        type: "text",
        placeholder: "Block",
      }, {
        name: "unit",
        type: "text",
        placeholder: "Unit",
      }, {
        name: "phase",
        type: "text",
        placeholder: "Phase",
      }, {
        name: "subdivision",
        type: "text",
        placeholder: "Sub-division",
      }, {
        name: "plat_book",
        type: "text",
        placeholder: "Plat-book",
      }, {
        name: "page",
        type: "text",
        placeholder: "page",
      }, {
        name: "listing_type",
        type: "select",
        placeholder: "Listing type",
      }]
    default:
      return ""
  }
}
