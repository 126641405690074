/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState, useEffect, useMemo } from "react"
import {
  Form,
} from "@ui/antd"
import { AddButton } from "dumbComponents/OffersV3/components"
import { StyledCheckbox } from "dumbComponents/OffersV2/DocusignDocs/Components/CheckboxList/styles"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  CoWrap,
  FormFieldWrap,
  DangerButton,
  SubmitWrap,
  ListRowWrap,
  ValidationNumberCircle,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  // AgentSearch,
  InputLocation,
  SelectField,
  HiddenInputField,
} from "../../../../InputFields"
import {
  validatePhoneNumber,
} from "./utils"
import {
  CLIENT_SOURCE,
} from "./formConfig"
import { normalizePhone } from "services/formUtils"
import CoAgentIcon from "../../../../../../images/icons/co-agent-icons";
import RemoveAgentBinIcon from "../../../../../../images/icons/remove-agent-bin-icon";
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";
import AgentSearchWithConsent from "dumbComponents/common/InputFields/AgentSearchWithConsent";
import TechAssetsContainer from "container/TechAssets"

const { List } = Form

const BuyerAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  isFirst,
  listingAgents = [],
  onValueChange,
  saving,
  disableForm,
  key,
  formName,
  coCTAName,
  validationReport,
  contractFormValidation,
  setContractFormValidation
}) => {
  const [isRadiusAgentBool, setRadiusAgentBoolForCoAgent] = useState({})
  const [defaultValuesForIsRadiusAgentBool, setDefaultValuesForIsRadiusAgentBool] = useState({})
  const formRef = useRef(null)
  const [isRadiusAgent, setRadiusAgent] = useState(false)
  const [currentBuyerAgentCount, setBuyerAgentCount] = useState(0)
  const isSellerForm = title?.toLowerCase().includes("listing")

  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);
  //co-agents
  const [isAddManuallyForCoAgent, setIsAddManuallyForCoAgent] = useState({});

  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const handleRepresentation = (e) => {
    setRadiusAgent(e.target.checked)
  }

  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData?.contract_information?.agents_info?.length && prefilledData.contract_information.agents_info.length > 0) {
      initValues = prefilledData

      const firstname = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].name.split(" ")[0]
      // const lastname = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].name.split(" ")[1]
      const lastname = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].name.split(" ").slice(1).join(" ")

      console.log("lastname", lastname);

      initValues.agent_first_name = firstname
      initValues.agent_last_name = lastname
      initValues.name = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].name
      initValues.phone = prefilledData.contract_information.agents_info && normalizePhone(prefilledData.contract_information.agents_info[0].phone)
      initValues.email = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].email
      initValues.brokerage = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].brokerage
      initValues.brokerage_address = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].brokerage_address
      initValues.brokerage_license_number = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].brokerage_license_number
      initValues.agent_license_number = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].agent_license_number
      initValues.apt = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].apt
      initValues.agent_type = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].agent_type
      initValues.zipcode = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].zipcode
      initValues.city = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].city
      initValues.state = prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info[0].state

      if (prefilledData.contract_information.agents_info && prefilledData.contract_information.agents_info.length > 1) {
        const otherAgents = prefilledData.contract_information.agents_info.slice(1, prefilledData.contract_information.agents_info.length)

        otherAgents.forEach((item, index) => {
          const curr = item
          const coAgentFirstName = curr.name.split(" ")[0] || ""
          const coAgentLastName = curr.name.split(" ")[1] || ""
          curr.agent_first_name = coAgentFirstName || ""
          curr.agent_last_name = coAgentLastName || ""
          curr.phone = curr.phone && normalizePhone(curr.phone) || ""
          curr.email = curr.email || ""
          curr.apt = curr.apt || ""
          curr.state = curr.state || ""
          curr.zipcode = curr.zipcode || ""
          curr.city = curr.city || ""
          curr.agent_license_number = curr.agent_license_number || ""
          curr.brokerage_license_number = curr.brokerage_license_number || ""
          curr.brokerage = curr.brokerage || ""
          curr.brokerage_address = curr.brokerage_address || ""

          setDefaultValuesForIsRadiusAgentBool(exitingValues => ({
            ...exitingValues,
            [index]: !!curr.agent_id,
          }))

          setRadiusAgentBoolForCoAgent(exitingValues => ({
            ...exitingValues,
            [index]: !!curr.agent_id,
          }))
        })

        if (isSellerForm) {
          initValues.co_seller_agents = [...otherAgents]

          initValues.co_seller_agents = [...otherAgents]
        } else {
          initValues.co_buyer_agents = [...otherAgents]

          initValues.co_buyer_agents = [...otherAgents]
        }
      }

      return initValues
    }

    if (prefilledData && prefilledData.agents_info && prefilledData.agents_info.length > 0) {
      initValues = prefilledData

      const firstname = prefilledData.agents_info && prefilledData.agents_info[0].name.split(" ")[0]
      const lastname = prefilledData.agents_info && prefilledData.agents_info[0].name.split(" ").slice(1).join(" ")
      initValues.agent_first_name = firstname
      initValues.agent_last_name = lastname
      initValues.name = prefilledData.agents_info && prefilledData.agents_info[0].name
      initValues.phone = prefilledData.agents_info && normalizePhone(prefilledData.agents_info[0].phone)
      initValues.email = prefilledData.agents_info && prefilledData.agents_info[0].email
      initValues.brokerage = prefilledData.agents_info && prefilledData.agents_info[0].brokerage
      initValues.brokerage_address = prefilledData.agents_info && prefilledData.agents_info[0].brokerage_address
      initValues.brokerage_license_number = prefilledData.agents_info && prefilledData.agents_info[0].brokerage_license_number
      initValues.agent_license_number = prefilledData.agents_info && prefilledData.agents_info[0].agent_license_number
      initValues.apt = prefilledData.agents_info && prefilledData.agents_info[0].apt
      initValues.agent_type = prefilledData.agents_info && prefilledData.agents_info[0].agent_type
      initValues.zipcode = prefilledData.agents_info && prefilledData.agents_info[0].zipcode
      initValues.city = prefilledData.agents_info && prefilledData.agents_info[0].city
      initValues.state = prefilledData.agents_info && prefilledData.agents_info[0].state

      if (prefilledData.agents_info && prefilledData.agents_info.length > 1) {
        const otherAgents = prefilledData.agents_info.slice(1, prefilledData.agents_info.length)
        otherAgents.forEach((item, index) => {
          const curr = item
          const coAgentFirstName = curr.name.split(" ")[0] || null
          const coAgentLastName = curr.name.split(" ").slice(1).join(" ") || null
          curr.agent_first_name = coAgentFirstName
          curr.agent_last_name = coAgentLastName
          curr.phone = curr.phone && normalizePhone(curr.phone) || ""
          curr.email = curr.email || ""
          curr.apt = curr.apt || ""
          curr.state = curr.state || ""
          curr.zipcode = curr.zipcode || ""
          curr.city = curr.city || ""
          curr.agent_license_number = curr.agent_license_number || ""
          curr.brokerage_license_number = curr.brokerage_license_number || ""
          curr.brokerage = curr.brokerage || ""
          curr.brokerage_address = curr.brokerage_address || ""

          setDefaultValuesForIsRadiusAgentBool(exitingValues => ({
            ...exitingValues,
            [index]: !!curr.agent_id,
          }))

          setRadiusAgentBoolForCoAgent(exitingValues => ({
            ...exitingValues,
            [index]: !!curr.agent_id,
          }))
        })

        if (isSellerForm) {
          initValues.co_seller_agents = [...otherAgents]

          initValues.co_seller_agents = [...otherAgents]
        } else {
          initValues.co_buyer_agents = [...otherAgents]

          initValues.co_buyer_agents = [...otherAgents]
        }
      }
    }
    // else if (prefilledData && prefilledData.agents_info && prefilledData.agents_info <= 0) {
    //   const firstname = prefilledData.agent_firstname
    //   const lastname = prefilledData.agent_lastname
    //   initValues.agent_first_name = firstname
    //   initValues.agent_last_name = lastname
    //   initValues.name = `${firstname} ${lastname}`
    //   initValues.phone = prefilledData.agent_phone
    //   initValues.email = prefilledData.agent_email
    //   initValues.brokerage_address = null
    //   initValues.brokerage_license_number = null
    //   initValues.agent_license_number = null
    //   initValues.apt = prefilledData.agents_info && prefilledData.agents_info[0].apt
    //   initValues.agent_type = prefilledData.agents_info && prefilledData.agents_info[0].agent_type
    // }
    return initValues
  }

  useEffect(() => {
    if (prefilledData && prefilledData.agents_info && prefilledData.agents_info.length > 0) {
      setBuyerAgentCount(prefilledData.agents_info.length)
    }
  }, [prefilledData])

  useEffect(() => {
    if (formRef.current) {
      const values = formRef.current.getFieldsValue()
      const isValid = Boolean(values.agent_first_name && 
        values.agent_last_name && 
        values.email && 
        values.phone &&
        values.brokerage_address &&
        values.city &&
        values.state &&
        values.zipcode)

      setContractFormValidation({
        ...contractFormValidation,
        buyerAgentFormValid: isValid,
        entireFormValid: isValid && 
                        contractFormValidation.clientFormValid 
                       
      })
    }
  }, [formRef.current?.getFieldValue('agent_first_name'), 
      formRef.current?.getFieldValue('agent_last_name'),
      formRef.current?.getFieldValue('email'),
      formRef.current?.getFieldValue('phone'),
      formRef.current?.getFieldValue('brokerage_address'),
      formRef.current?.getFieldValue('city'),
      formRef.current?.getFieldValue('state'),
      formRef.current?.getFieldValue('zipcode'),
      contractFormValidation.propertyFormValid,
      contractFormValidation.clientFormValid,
      contractFormValidation.transactionFormValid])

  const getFieldText = (suffix) => {
    let name = ""

    if (isSellerForm) {
      name = "Co-listing agent"
    } else {
      name = "Co-buyer agent"
    }

    if (prefilledData?.property_info?.client_type === "landlord") {
      name = "Co-listing agent"
    }

    if (suffix) {
      name = `${name}${suffix}`
    }

    return name
  }

  const handleInitChange = (e) => {
    console.log("e", e, formRef?.current?.getFieldsValue())
    if(onValueChange){
      onValueChange({}, formRef?.current?.getFieldsValue())
    }
  }

  const handleOnChangeCoBuyerAgent = (agent,value,option,formName,fieldName) => {
    console.log("value", agent,value,option)
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_id: parseInt(agent.id, 10),
          agent_first_name: agent.firstname,
          agent_last_name: agent.lastname !== "" ? agent.lastname : undefined,
          name: agent.name,
          email: agent.email || agent?.brokerage_info_from_LOC?.email,
          phone: normalizePhone(agent.phone) || normalizePhone(agent?.brokerage_info_from_LOC?.phone),
          agent_brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
          agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
          landline: agent?.brokerage_info_from_LOC?.landline,
          state: agent?.brokerage_info_from_LOC?.state,
          zipcode: agent?.brokerage_info_from_LOC?.zipcode,
          city: agent?.brokerage_info_from_LOC?.city,
          // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
          //is_primary: isFirst ? 1 : 0,
          listing_brokerage: agent?.brokerage_info_from_LOC?.listing_brokerage || agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
        }
      }
    });
  }

  const handleOnClearCoBuyerAgent = (formName,fieldName) => {
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_first_name: "",
          agent_last_name: "",
          email: "",
          phone: "",
        }
      }
    });
  }

  return (
    <FormWrap
      id={`${key}_wrap`}
      key={key}
    >
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Buyer Agent Information"}</p>
        </FormTitle>
      </HeaderWrap>
      {/* <ListRowWrap>
        <StyledCheckbox value="here" onChange={e => handleRepresentation(e)}>
          Is agent part of Radius ?
        </StyledCheckbox>
      </ListRowWrap> */}

      <Form
        id={`${key}_form`}
        key={key}
        name={formName}
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={useMemo(() => getInitialValues(), [prefilledData])}
        ref={formRef}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        onValuesChange={onValueChange}
        //onValuesChange={handleOnTypeChangeForListingAgents}
      >
        <HiddenInputField name="is_primary" value={1} />
        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="agent_type" />

        <InputField
          name="agent_first_name"
          inputType="text"
          placeholder="First Name"
          customRules={{}}
          text="First Name"
          required={true}
          requiredMessage="Agent's First Name is required."
          disabled={disableForm}
        />

        <InputField
          name="agent_last_name"
          inputType="text"
          placeholder="Last Name"
          text="Last Name"
          required={true}
          requiredMessage="Agent's Last Name is required."
          disabled={disableForm}
        />

        <InputField
          name="email"
          inputType="email"
          placeholder="Email"
          text="Email"
          type="email"
          required={true}
          disabled={disableForm}
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone"
          text="Phone"
          //requiredMessage="Phone is required."
          required={true}
          //disabled={disableForm}
          customRules={{
            validator: validatePhoneNumber,
          }}
        />
           <InputLocation
          name="brokerage_address"
          inputType="text"
          type="text"
          placeholder="Brokerage Street Address*"
          formRef={formRef}
          text="Brokerage Street Address"
          required={true}
          handleInitChange={handleInitChange}
          //ignoreFields={["state", "city", "zipcode"]}
        />
  <InputField
          name="apt"
          inputType="text"
          placeholder="Apt/unit/suite no."
          text="Apt/unit/suite no."
          type="text"
        />

          <InputField
          name="city"
          inputType="text"
          placeholder="City"
          text="City"
          type="text"
          required={true}
          requiredMessage="City is required."
        />

        <InputField
          name="state"
          inputType="text"
          placeholder="State"
          text="State"
          type="text"
          required={true}
          requiredMessage="State is required."
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zipcode"
          text="Zip Code"
          type="text"
          required={true}
          requiredMessage="Zip Code is required."
        />
        
        <InputField
          name="agent_license_number"
          inputType="text"
          type="text"
          placeholder="Agent License Number"
          text="Agent License Number"
          required={false}
          requiredMessage="Agent's License Number is required."
        />

        <InputField
          name="brokerage_license_number"
          inputType="text"
          type="text"
          placeholder="Brokerage License Number"
          text="Brokerage License Number"
          required={false}
          //requiredMessage="Brokerage License Number is required."
        />

        <InputField
          name="brokerage"
          inputType="text"
          type="text"
          placeholder="Brokerage Name"
          text="Brokerage Name"
          required={false}
        />

        <CTAWrap>
          <List name={isSellerForm ? "co_seller_agents" : "co_buyer_agents"}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }) => (
                  <CoWrap>
                    <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} />
                    <HeaderWrap>
                      <FormTitle>
                        <p>
                          <CoAgentIcon />
                          {getFieldText()}
                        </p>
                      </FormTitle>
                      <CancelOrGoBackButton
                        type="button"
                        onClick={() => openRemoveAgentModal(name)}
                      >
                        <RemoveAgentBinIcon />
                        <p> Remove agent </p>
                      </CancelOrGoBackButton>
                    </HeaderWrap>
                    {/* <ListRowWrap>
                      <StyledCheckbox
                        checked={isRadiusAgentBool[name]}
                        defaultChecked={defaultValuesForIsRadiusAgentBool[name]}
                        onChange={(e) => {
                          const {
                            target: {
                              checked,
                            },
                          } = e || {}
                          setRadiusAgentBoolForCoAgent(existingValues => ({
                            ...existingValues,
                            [name]: checked,
                          }))
                        }}
                      >
                        Is agent part of Radius ?
                      </StyledCheckbox>
                    </ListRowWrap> */}
                    <HiddenInputField name={[name, "agent_id"]} />

                    <AgentSearchWithConsent
                        name={[name, "agent_first_name"]}
                        inputType="text"
                        placeholder="First Name"
                        customRules={{}}
                        isFormField
                        valueOfField={formRef?.current?.getFieldValue([isSellerForm ? "co_seller_agents" : "co_buyer_agents", name, 'agent_first_name'])}
                        text="First Name"
                        required="true"
                        requiredMessage="Please select a value"
                        agentId={formRef?.current?.getFieldValue([isSellerForm ? "co_seller_agents" : "co_buyer_agents", name, 'agent_id'])}
                        disabled={isAddManuallyForCoAgent[name]}
                        onChange={(agent,value,option)=>handleOnChangeCoBuyerAgent(agent,value,option,isSellerForm ? "co_seller_agents" : "co_buyer_agents",name)}
                        onClear={()=>handleOnClearCoBuyerAgent(isSellerForm ? "co_seller_agents" : "co_buyer_agents",name)}
                        onAddManually={() => setIsAddManuallyForCoAgent(prev => ({
                          ...prev,
                          [name]: false
                        }))}
                        onAgentPartOfRadius={(value) => {
                            setIsAddManuallyForCoAgent(prev => ({
                              ...prev,
                              [name]: value 
                            }));
                        }}
                      />
                    <FormFieldWrap>
    <InputField
                          name={[name, "agent_first_name"]}
                          inputType="text"
                          placeholder={isSellerForm ? "Co-listing agent's first name" : "Co-buyer agent's first name"}
                          text={isSellerForm ? "Co-listing agent's first name" : "Co-buyer agent's first name"}
                          required={true}
                          requiredMessage={isSellerForm ? "Co-listing agent's first name is required." : "Co-buyer agent's first name is required."}
                          disabled={isAddManuallyForCoAgent[name]}
                        />
                      <InputField
                        name={[name, "agent_last_name"]}
                        inputType="text"
                        placeholder={getFieldText("'s last name")}
                        text={getFieldText("'s last name")}
                        type="text"
                        required={true}
                        requiredMessage={getFieldText("'s last name is required.")}
                        disabled={isAddManuallyForCoAgent[name]}
                      />

                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "email"]}
                        type="email"
                        inputType="email"
                        placeholder="Email"
                        text="Email"
                        required={true}
                        disabled={isAddManuallyForCoAgent[name]}
                      />

                      <InputNumber
                        name={[name, "phone"]}
                        inputType="phone"
                        placeholder="Phone"
                        text="Phone"
                        type="phone"
                        required={true}
                        //requiredMessage="Phone is required."
                        customRules={{
                          validator: validatePhoneNumber,
                        }}
                      />
                    </FormFieldWrap>

                    <FormFieldWrap>
                      
                          <InputLocation
                        name={[name, "brokerage_address"]}
                        inputType="text"
                        type="text"
                        placeholder="Brokerage Address*"
                        formRef={formRef}
                        formName={isSellerForm ? "co_seller_agents" : "co_buyer_agents"}
                        fieldIndex={name}
                        required={true}
                        //ignoreFields={["state", "city", "zipcode"]}
                        text="Brokerage Address"
                      />
                   
                    
                   <InputField
                        name={[name, "apt"]}
                        inputType="text"
                        placeholder="Apt/unit/suite no."
                        text="Apt/unit/suite no."
                        type="text"
                      />

                    </FormFieldWrap>

                    <FormFieldWrap>
                         <InputField
                        name={[name, "city"]}
                        inputType="text"
                        placeholder="City"
                        text="City"
                        type="text"
                        required={true}
                        requiredMessage="City is required."
                      />

                      <InputField
                        name={[name, "state"]}
                        inputType="text"
                        placeholder="State"
                        text="State"
                        type="text"
                        required={true}
                        requiredMessage="State is required."
                      />

                    
                    </FormFieldWrap>
                    
                

                    <FormFieldWrap>
                        <InputField
                        name={[name, "zipcode"]}
                        inputType="text"
                        placeholder="Zip Code"
                        text="Zip Code"
                        type="text"
                        required={true}
                        requiredMessage="Zip Code is required."
                      />
                      <InputField
                        name={[name, "agent_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="License Number"
                        text="License Number"
                        requiredMessage="Agent's License is required."
                        required={false}
                      />

                    
                    </FormFieldWrap>

                    <FormFieldWrap>
                        <InputField
                        name={[name, "brokerage_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="Brokerage License Number"
                        text="Brokerage License Number"
                        requiredMessage="Brokerage License Number is required."
                        required={false}
                      />
                      <InputField
                        name={[name, "brokerage"]}
                        inputType="text"
                        type="text"
                        placeholder="Brokerage Name"
                        text="Brokerage Name"
                        required={false}
                      />

                  
                    </FormFieldWrap>
                  </CoWrap>
                ))}
                <FormFieldWrap>
                  <CTAWrap>
                    <Form.Item>
                      <AddButton
                        disabled={currentBuyerAgentCount <= 0}
                        name={coCTAName}
                        onClick={() => {
                          setBuyerAgentCount(count => count + 1)
                          add()
                        }}
                      />
                    </Form.Item>
                  </CTAWrap>
                </FormFieldWrap>
              </>
            )}
          </List>
        </CTAWrap>

        {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )}

        {saving && (
          <SubmitWrap>
            <button
              type="button"
              onClick={() => {}}
            >
              Saving..
            </button>
          </SubmitWrap>
        )}

      </Form>
    </FormWrap>
  )
}

export default TechAssetsContainer(BuyerAgentForm)
