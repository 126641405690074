/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useRef, useState } from "react"
import {
  Form,
} from "@ui/antd"
import { AddButton } from "dumbComponents/OffersV3/components"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  CoWrap,
  FormFieldWrap,
  DangerButton,
  SubmitWrap,
  SaveButton,
  ValidationNumberCircle,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  AgentSearch,
  InputLocation,
  SelectField,
  HiddenInputField,
  ClientsByNameSearch,
} from "../../../../InputFields"
import { normalizePhone } from "services/formUtils"
import {
  CLIENT_SOURCE,
} from "./formConfig"
import {
  validatePhoneNumber,
} from "./utils"
import CoAgentIcon from "../../../../../../images/icons/co-agent-icons";
import RemoveAgentBinIcon from "../../../../../../images/icons/remove-agent-bin-icon";
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";
import TechAssetsContainer from "container/TechAssets"
const { List } = Form

const ClientInformation = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  isFirst,
  listingAgents = [],
  onValueChange,
  saving,
  disableForm,
  validationReport,
  form: formReference,
  editingAgentId,
  contractFormValidation,
  setContractFormValidation
}) => {
  const formRef = useRef(null)
  const [additionalClients, updateAdditionalClients] = useState([])
  const [currentSource, setSource] = useState("")
  const [otherSource, setOtherSource] = useState("")

  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);
  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      if(prefilledData.contract_information){
        const data = prefilledData.contract_information
        initValues = data
        initValues.name = data.clients && data.clients[0]?.name
        initValues.phone = data.clients && normalizePhone(data.clients[0]?.phone)
        initValues.email = data.clients && data.clients[0]?.email
        initValues.source = data.clients && data.clients[0]?.source
        if (data.clients && data.clients.length > 1) {
          const otherClients = data.clients.slice(1, data.clients.length)
          initValues.co_clients = [...otherClients]
        }
      } else {
        initValues = prefilledData
        initValues.name = prefilledData.clients && prefilledData.clients[0]?.name
        initValues.phone = prefilledData.clients && normalizePhone(prefilledData.clients[0]?.phone)
        initValues.email = prefilledData.clients && prefilledData.clients[0]?.email
        initValues.source = prefilledData.clients && prefilledData.clients[0]?.source
        if (prefilledData.clients && prefilledData.clients.length > 1) {
          const otherClients = prefilledData.clients.slice(1, prefilledData.clients.length)
          initValues.co_clients = [...otherClients]
        }
      }
    }
    return initValues
  }

  useEffect(() => {
    if (formRef.current) {
      const values = formRef.current.getFieldsValue()
      const isValid = Boolean(values.name &&
        values.email &&
        values.phone &&
        values.source)

      setContractFormValidation({
        ...contractFormValidation,
        clientFormValid: isValid,
        entireFormValid: isValid && 
                        contractFormValidation.buyerAgentFormValid 
                       
      })
    }
  }, [formRef.current?.getFieldValue('name'),
      formRef.current?.getFieldValue('email'),
      formRef.current?.getFieldValue('phone'),
      formRef.current?.getFieldValue('source'),
      contractFormValidation.propertyFormValid,
      contractFormValidation.buyerAgentFormValid,
      contractFormValidation.transactionFormValid])

  useEffect(() => {
    if (prefilledData && prefilledData.clients && prefilledData.clients.length > 0) {
      updateAdditionalClients(prefilledData.clients.length)
    }
  }, [prefilledData])

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Client Information"}</p>
        </FormTitle>
      </HeaderWrap>

      <Form
        name="contract_client_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        form={formReference}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        //form, content
        onValuesChange={(form, content) => {
          if (additionalClients.length === 0 && (content.co_clients === null || content.co_clients === undefined)) {
            updateAdditionalClients(1)
          }

          onValueChange(form, content)
        }}
        //onValuesChange={handleOnTypeChangeForListingAgents}
      >
        <HiddenInputField name="is_primary" value={1} />
        <HiddenInputField name="client_id" value={null} />
        <HiddenInputField name="referral_id" value={null} />

        <SelectField
          isFormField
          name="source"
          options={CLIENT_SOURCE}
          placeholder="Client Source"
          text="Client Source"
          onChange={(value) => { setSource(value) }}
          required={true}
          requiredMessage="Client Source is required."
          disabled={disableForm}
        />

        {currentSource === "other" && (
          <InputField
            name="other_source"
            inputType="text"
            placeholder="Other source"
            text="Mention source"
            required={true}
            onChange={(e) => { setOtherSource(e.target.value) }}
            requiredMessage="Other source is required"
            disabled={disableForm}
          />
        )}

        {/* <InputField
          name="name"
          inputType="text"
          placeholder="Full Name*"
          text="Full Name"
          required={true}
          requiredMessage="Client's Name is required."
          disabled={disableForm}
        /> */}

        <ClientsByNameSearch
          formRef={formRef}
          fullNameInputFieldName="name"
          emailInputFieldName="email"
          phoneInputFieldName="phone"
          formItemName="name"
          formItemPlaceholder="Full Name"
          formItemLabelText="Full Name"
          editingAgentId={editingAgentId}
          required={true}
          disabled={disableForm}
          callback={() => {
            const formValues = formRef.current.getFieldsValue()
            
            if (additionalClients.length === 0 && (formValues.co_clients === null || formValues.co_clients === undefined)) {
              updateAdditionalClients(1)
            }

            onValueChange({}, formValues)
          }}
        />

        <InputField
          name="email"
          inputType="email"
          placeholder="Email"
          text="Email"
          type="email"
          required={true}
          disabled={disableForm}
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Client Phone"
          text="Client Phone"
          //requiredMessage="Phone is required."
          //disabled={disableForm}
          required={true}
          customRules={{
            validator: validatePhoneNumber,
          }}
        />

        <CTAWrap>
          {prefilledData && prefilledData.listing_agent_id && (
            <Form.Item>
              <DangerButton
                type="button"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => {}}
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )}

          <List name="co_clients">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }) => (
                  <CoWrap>
                    <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} />
                    <HeaderWrap>
                      <FormTitle>
                        <p> <CoAgentIcon /> Additional Client</p>
                      </FormTitle>
                      <CancelOrGoBackButton
                        type="button"
                        onClick={() => openRemoveAgentModal(name)}
                      >
                        <RemoveAgentBinIcon />
                        <p> Remove </p>
                      </CancelOrGoBackButton>
                    </HeaderWrap>
                    <FormFieldWrap>
                      <HiddenInputField name={[name, "is_primary"]} />
                      <HiddenInputField name={[name, "source"]} value={currentSource} />
                      <HiddenInputField name={[name, "client_id"]} value={null} />
                      <HiddenInputField name={[name, "referral_id"]} value={null} />
                      {currentSource === "other" && (
                        <HiddenInputField name={[name, "other_source"]} value={otherSource} />
                      )}
                    </FormFieldWrap>
                    <FormFieldWrap>
                      {/* <InputField
                        name={[name, "name"]}
                        inputType="text"
                        placeholder="Full Name"
                        text="Full Name"
                        required={true}
                        requiredMessage="Client's Name is required."

                      /> */}
                      <ClientsByNameSearch
                        formRef={formRef}
                        fullNameInputFieldName="name"
                        emailInputFieldName="email"
                        phoneInputFieldName="phone"
                        formItemName={[name, "name"]}
                        formItemPlaceholder="Full Name"
                        formItemLabelText="Full Name"
                        editingAgentId={editingAgentId}
                        fieldIndexInTheSubForm={name}
                        subFormName="co_clients"
                        required={true}
                        callback={() => {
                          const formValues = formRef.current.getFieldsValue()
                          
                          if (additionalClients.length === 0 && (formValues.co_clients === null || formValues.co_clients === undefined)) {
                            updateAdditionalClients(1)
                          }
              
                          onValueChange({}, formValues)
                        }}
                      />
                      <InputField
                        name={[name, "email"]}
                        type="email"
                        inputType="email"
                        placeholder="Email"
                        text="Email"
                        required={true}
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputNumber
                        name={[name, "phone"]}
                        inputType="phone"
                        placeholder="Client Phone"
                        text="Client Phone"
                        type="phone"
                        required={true}
                        requiredMessage="Phone is required."
                        customRules={{
                          validator: validatePhoneNumber,
                        }}
                      />
                    </FormFieldWrap>
                  </CoWrap>
                ))}
                <FormFieldWrap>
                  <CTAWrap>
                    <Form.Item>
                      <AddButton
                        disabled={additionalClients.length <= 0}
                        name="ADD ADDITIONAL CLIENT"
                        onClick={() => {
                          updateAdditionalClients(count => count + 1)
                          add()
                        }}
                      />
                    </Form.Item>
                  </CTAWrap>
                </FormFieldWrap>
              </>
            )}
          </List>
        </CTAWrap>

        {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )}

        {saving && (
          <SubmitWrap>
            <button
              type="button"
              onClick={() => {}}
            >
              Saving..
            </button>
          </SubmitWrap>
        )}
      </Form>
    </FormWrap>
  )
}

export default TechAssetsContainer(ClientInformation)
