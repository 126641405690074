import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils";
import * as Actions from "./actions";

const initState = {
  ...createDeltaReducer("fetchAllClientsResponse"),
  ...createDeltaReducer("copyAppInviteLinkResponse"),
  ...createDeltaReducer("inviteBulkClientResponse"),
  ...createDeltaReducer("joinWaitlistResponse"),
  ...createDeltaReducer("inviteClientsResponse"),
  ...createDeltaReducer("getAppStatusResponse"),
  ...createDeltaReducer("turnOnMelRecommendationResponse"),
  ...createDeltaReducer("fetchRecommendedPropertiesResponse"),
  ...createDeltaReducer("rejectPropertyStatusResponse"),
  ...createDeltaReducer("removeBulkPropertyResponse"),
  ...createDeltaReducer("fetchAppSettingsResponse"),
  ...createDeltaReducer("saveAppSettingsResponse"),
  ...createDeltaReducer("getAllAgentsForAssignmentResponse"),
  ...createDeltaReducer("transferAgentsResponse"),
  ...createDeltaReducer("getClientSummaryResponse"),
  ...createDeltaReducer("generateClientSummaryResponse"),
  ...createDeltaReducer("getClientRecommendedPropertiesResponse"),
  isMelRecommendedData: false,
};

export default function consumerAppReducer(state = initState, action) {
  switch (action.type) {
    case Actions.fetchAllClientsAction.REQUEST:
    case Actions.fetchAllClientsAction.SUCCESS:
    case Actions.fetchAllClientsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchAllClientsAction,
          "fetchAllClientsResponse"
        ),
      };
    }
    case Actions.isMelRecommendedAction.type: {
      return {
        ...state,
        isMelRecommendedData: action.data,
      };
    }

    case Actions.copyAppInviteLinkAction.REQUEST:
    case Actions.copyAppInviteLinkAction.SUCCESS:
    case Actions.copyAppInviteLinkAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.copyAppInviteLinkAction,
          "copyAppInviteLinkResponse"
        ),
      };
    }

    case Actions.inviteBulkClientsAction.REQUEST:
    case Actions.inviteBulkClientsAction.SUCCESS:
    case Actions.inviteBulkClientsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.inviteBulkClientsAction,
          "inviteBulkClientResponse"
        ),
      };
    }

    case Actions.inviteClientsAction.REQUEST:
    case Actions.inviteClientsAction.SUCCESS:
    case Actions.inviteClientsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.inviteClientsAction,
          "inviteClientsResponse"
        ),
      };
    }

    case Actions.joinWaitlistAction.REQUEST:
    case Actions.joinWaitlistAction.SUCCESS:
    case Actions.joinWaitlistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.joinWaitlistAction,
          "joinWaitlistResponse"
        ),
      };
    }

    case Actions.getAppStatusAction.REQUEST:
    case Actions.getAppStatusAction.SUCCESS:
    case Actions.getAppStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getAppStatusAction,
          "getAppStatusResponse"
        ),
      };
    }

    case Actions.turnOnMelRecommendationsAction.REQUEST:
    case Actions.turnOnMelRecommendationsAction.SUCCESS:
    case Actions.turnOnMelRecommendationsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.turnOnMelRecommendationsAction,
          "turnOnMelRecommendationResponse"
        ),
      };
    }
    case Actions.fetchRecommendedPropertiesAction.REQUEST:
    case Actions.fetchRecommendedPropertiesAction.SUCCESS:
    case Actions.fetchRecommendedPropertiesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchRecommendedPropertiesAction,
          "fetchRecommendedPropertiesResponse"
        ),
      };
    }
    case Actions.rejectPropertyStatusAction.REQUEST:
    case Actions.rejectPropertyStatusAction.SUCCESS:
    case Actions.rejectPropertyStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.rejectPropertyStatusAction,
          "rejectPropertyStatusResponse"
        ),
      };
    }
   case Actions.removeBulkPropertyAction.REQUEST:
    case Actions.removeBulkPropertyAction.SUCCESS:
    case Actions.removeBulkPropertyAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.removeBulkPropertyAction,
          "removeBulkPropertyResponse"
        ),
      };
    }
      
    case Actions.fetchAppSettingsAction.REQUEST:
    case Actions.fetchAppSettingsAction.SUCCESS:
    case Actions.fetchAppSettingsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchAppSettingsAction,
          "fetchAppSettingsResponse"
        ),
      };
    }
      
    case Actions.getAllAgentsForAssignmentAction.REQUEST:
    case Actions.getAllAgentsForAssignmentAction.SUCCESS:
    case Actions.getAllAgentsForAssignmentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getAllAgentsForAssignmentAction,
          "getAllAgentsForAssignmentResponse"
        ),
      };
    }
      
    case Actions.saveAppSettingsAction.REQUEST:
    case Actions.saveAppSettingsAction.SUCCESS:
    case Actions.saveAppSettingsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.saveAppSettingsAction,
          "saveAppSettingsResponse"
        ),
      };
    }

    case Actions.transferAgentAction.REQUEST:
    case Actions.transferAgentAction.SUCCESS:
    case Actions.transferAgentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.transferAgentAction,
          "transferAgentsResponse"
        ),
      };
    }

    case Actions.getClientSummaryAction.REQUEST:
    case Actions.getClientSummaryAction.SUCCESS:
    case Actions.getClientSummaryAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state, 
          action, 
          Actions.getClientSummaryAction, 
          "getClientSummaryResponse"
        ),
      };
    }

    case Actions.generateClientSummaryAction.REQUEST:
    case Actions.generateClientSummaryAction.SUCCESS:
    case Actions.generateClientSummaryAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state, 
          action, 
          Actions.generateClientSummaryAction, 
          "generateClientSummaryResponse"
        ),
      };
    }

    case Actions.getClientRecommendedPropertiesAction.REQUEST:
    case Actions.getClientRecommendedPropertiesAction.SUCCESS:
    case Actions.getClientRecommendedPropertiesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getClientRecommendedPropertiesAction,
          "getClientRecommendedPropertiesResponse"
        ),
      }
    }
  
    default: {
      return state;
    }
  }
}
