import React, { useEffect, useRef, useState } from "react";
import {
  InputField,
  InputNumber,
  SelectField,
  AgentSearch,
  HiddenInputField,
  InputLocation,
} from "dumbComponents/common/InputFields"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd";
import { AddButton } from "dumbComponents/OffersV3/components";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { StyledCheckbox } from "dumbComponents/OffersV2/DocusignDocs/Components/CheckboxList/styles";
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  CoWrap,
  FormFieldWrap,
  ListRowWrap,
} from "./commonStyles"
// import {
//   InputField,
//   InputNumber,
//   SelectField,
//   AgentSearch,
//   HiddenInputField,
//   InputLocation,
// } from "../../InputFields";
import { BlueButton, DangerButton } from "../../../../commonStyles"
import { normalizePhone } from "services/formUtils"
import CoAgentIcon from "images/icons/co-agent-icons";
import RemoveAgentBinIcon from "images/icons/remove-agent-bin-icon";
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";
import AgentSearchWithConsent from "dumbComponents/common/InputFields/AgentSearchWithConsent";
import TechAssets from "container/TechAssets"

const { List } = Form;

const ListingAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteListingAgent,
  isFirst,
  listingAgents,
  updatePropertyInformationObject,
  propertyInformationObject,
  payload,
  handleOnTypeChangeForListingAgents,
  addressRepresentation,
  disabled,
  key,
  form,
  radiusAgentCallbackSave,
  listingAgentInformationFormRef,
  // setOfferFormValidation,
  offerFormValidation
}) => {
  const formRef = useRef(null)
  const [isRadiusAgentBool, setRadiusAgentBoolForCoAgent] = useState({})
  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);

  //listing agent
  const [isAddManually, setIsAddManually] = useState(true);

  //co-agents
  const [isAddManuallyForCoAgent, setIsAddManuallyForCoAgent] = useState({});
  const validateListingAgentForm = (values) => {
    console.log('Form values being validated:', values);
    const mandatoryFields = ['listing_agent_first_name', 'listing_agent_last_name', 'email'];
    const isFormValid = mandatoryFields.every(field => {
      const value = values[field];
      const isValid = value && typeof value === 'string' && value.trim() !== '';
      console.log(`Field ${field}:`, value, 'isValid:', isValid);
      return isValid;
    });
    
    console.log('Final validation result:', isFormValid);
    
    // setOfferFormValidation({
    //   ...offerFormValidation,
    //   propertyFormValid: {
    //     ...offerFormValidation.propertyFormValid,
    //     isListingAgentValid: isFormValid
    //   }
    // });
  };

  useEffect(() => {
    if (formRef.current) {
      const values = formRef.current.getFieldsValue();
      validateListingAgentForm(values);
    }
  }, []);

  const handleFormValuesChange = (changedValues, allValues) => {
    console.log('Form values changed:', allValues);
    validateListingAgentForm(allValues);
    if (handleOnTypeChangeForListingAgents) {
      handleOnTypeChangeForListingAgents(changedValues, allValues);
    }
  };

  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  useEffect(() => {
    if (propertyInformationObject?.addressRepresentation) {
      const values = getInitialValues()
      formRef.current.setFieldsValue({
        //agent_id: values.agent_id,
        brokerage: values.brokerage,
        landline: values.landline,
        is_primary: values.is_primary,
        listing_agent_last_name: values.listing_agent_last_name,
        listing_agent_first_name: values.listing_agent_first_name,
        email: values.email,
        phone: values.phone,
        agent_license_number: values.agent_license_number,
        brokerage: values.brokerage,
        brokerage_license_number: values.brokerage_license_number,
        brokerage_address: values.brokerage_address,
        apt: values.apt,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
      });
      const currentFormValues = formRef.current.getFieldsValue();
      handleOnTypeChangeForListingAgents({}, currentFormValues);
    }
  }, [propertyInformationObject?.addressRepresentation]);

  const [isRadiusAgent, setRadiusAgent] = useState(false);
  const getInitialValues = () => {
    let initValues = {};

    if (listingAgents && listingAgents.length > 0) {
      initValues = {
        ...listingAgents[0],
      };
      initValues.phone = normalizePhone(initValues.phone);
      if (listingAgents.length > 1) {
        const sliced = listingAgents.slice(1);
        initValues.co_listing_agent_details = sliced;
      }
    }

    return initValues;
  }

  useEffect(()=>{
    let initValues = {};
    let newRadiusAgentBool = {}
    if (listingAgents && listingAgents.length > 0) {
      initValues = {
        ...listingAgents[0],
      };
      if(initValues.agent_id){
        setRadiusAgent(true)
      }
      if (listingAgents.length > 1){
        let otherAgents = listingAgents.slice(1, listingAgents.length)
        otherAgents = otherAgents.map((item, index) => {
          const curr = item
          if(curr.agent_id){
            newRadiusAgentBool[index] = curr.agent_id != 9999999 ? true : false
          }
          return curr
        })
      }
      setRadiusAgentBoolForCoAgent(newRadiusAgentBool)
    }
  },[listingAgents])

  const handleRepresentation = (e) => {
    setRadiusAgent(e.target.checked)
  }

  const handleOnChangeListingAgent = (agent,value,option) => {
    formRef.current.setFieldsValue({
        listing_agent_first_name: agent.firstname,
        listing_agent_last_name: agent.lastname !== "" ? agent.lastname : '',
          agent_id: parseInt(agent.id, 10),
          name: agent.name,
          email: agent.email || agent?.brokerage_info_from_LOC?.email,
          phone: normalizePhone(agent.phone) || normalizePhone(agent?.brokerage_info_from_LOC?.phone),
          agent_brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
          agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
          landline: agent?.brokerage_info_from_LOC?.landline,
          state: agent?.brokerage_info_from_LOC?.state,
          zipcode: agent?.brokerage_info_from_LOC?.zipcode,
          city: agent?.brokerage_info_from_LOC?.city,
          // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
          //is_primary: isFirst ? 1 : 0,
          listing_brokerage: agent?.brokerage_info_from_LOC?.listing_brokerage || agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
    });
    handleFormValuesChange({}, formRef.current.getFieldsValue());
    radiusAgentCallbackSave(agent,0)
  }

  const handleOnClearListingAgent = () => {
    formRef.current.setFieldsValue({
        listing_agent_first_name: "",
        listing_agent_last_name: "",
        email: "",
        phone: "",
    });
  }

  const handleOnChangeCoListingAgent = (agent,value,option,formName,fieldName) => {
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_id: parseInt(agent.id, 10),
          listing_agent_first_name: agent.firstname,
          listing_agent_last_name: agent.lastname !== "" ? agent.lastname : undefined,
          name: agent.name,
          email: agent.email || agent?.brokerage_info_from_LOC?.email,
          phone: normalizePhone(agent.phone) || normalizePhone(agent?.brokerage_info_from_LOC?.phone),
          agent_brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
          agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
          landline: agent?.brokerage_info_from_LOC?.landline,
          state: agent?.brokerage_info_from_LOC?.state,
          zipcode: agent?.brokerage_info_from_LOC?.zipcode,
          city: agent?.brokerage_info_from_LOC?.city,
          // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
          //is_primary: isFirst ? 1 : 0,
          listing_brokerage: agent?.brokerage_info_from_LOC?.listing_brokerage || agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
        }
      }
    });
    handleFormValuesChange({}, formRef.current.getFieldsValue());
    radiusAgentCallbackSave(agent,fieldName)
  }

  const handleOnClearCoListingAgent =(formName,fieldName) => {
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_first_name: "",
          agent_last_name: "",
          email: "",
          phone: "",
          brokerage: "",
          brokerage_license_number: "",
          agent_license_number: "",
          brokerage_address: "",
          city: "",
          state: "",
          zipcode: "",
        }
      }
    });
  }

  return (
    <FormWrap
      id={`${key}_wrap`}
    >
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Listing Agent Form"}</p>
        </FormTitle>
      </HeaderWrap>
      <ListRowWrap>
        {/* <StyledCheckbox value="here" checked={isRadiusAgent} onChange={(e) => handleRepresentation(e)}>
          Is agent part of Radius ?
        </StyledCheckbox> */}
      </ListRowWrap>

      <Form
        id={`${key}_loader`}
        name="offers_listing_agent_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        onValuesChange={handleFormValuesChange}
        disabled={disabled}
        form={listingAgentInformationFormRef}
      >
        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="is_primary" />

        <AgentSearchWithConsent
          name="listing_agent_first_name"
          inputType="text"
          placeholder="Search by agent name or email address."
          customRules={{}}
          isFormField
          valueOfField={formRef?.current?.getFieldValue("listing_agent_first_name")}
          agentId={formRef?.current?.getFieldValue("listing_agent_id")}
          text="First Name"
          required="true"
          requiredMessage="Please select a value"
          onAddManually={() => setIsAddManually(true)}
          onChange={(agent,value,option)=>handleOnChangeListingAgent(agent,value,option)}
          onClear={()=>handleOnClearListingAgent()}
          disabled={isAddManually}
          onAgentPartOfRadius={(value) => {
            setIsAddManually(!value)
          }}
        />
    <InputField
          name="listing_agent_first_name"
          inputType="text"
          placeholder="First Name"
          customRules={{}}
          text="First Name"
          required="true"
          disabled={!isAddManually}
        />


        <InputField
          name="listing_agent_last_name"
          inputType="text"
          placeholder="Last Name"
          customRules={{}}
          text="Last Name"
          required="true"
          disabled={!isAddManually}
        />

        <InputField
          name="email"
          inputType="email"
          type="email"
          placeholder="Email"
          text="Email"
          required="true"
          disabled={!isAddManually}
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone"
          text="Phone"
        />

        <InputField
          name="agent_license_number"
          inputType="text"
          type="text"
          placeholder="Agent License Number"
          text="Agent License Number"
          required={false}
        />

        <InputField
          name="brokerage"
          inputType="text"
          type="text"
          placeholder="Listing Brokerage"
          text="Listing Brokerage"
          required={false}
        />
        <InputField
          name="brokerage_license_number"
          inputType="text"
          type="text"
          placeholder="Listing Brokerage License Number"
          text="Listing Brokerage License Number"
          required={false}
        />

        {/* <InputField
          name="brokerage_address"
          inputType="text"
          type="text"
          placeholder="Brokerage Address*"
        /> */}

        <InputLocation
          name="brokerage_address"
          inputType="text"
          type="text"
          placeholder="Brokerage Street Address"
          formRef={formRef}
          text="Brokerage Street Address"
        />

        <InputField
          name="apt"
          inputType="text"
          type="text"
          placeholder="Apt/Unit/Suite No."
          text="Apt/Unit/Suite No."
        />

        <InputField
          name="city"
          inputType="text"
          placeholder="City"
          text="City"
        />

        <InputField
          name="state"
          inputType="text"
          placeholder="State"
          text="State"
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zipcode"
          text="Zipcode"
        />
        <CTAWrap>
          {prefilledData && prefilledData.listing_agent_id && (
            <Form.Item>
              <DangerButton
                type="button"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => deleteListingAgent(prefilledData.listing_agent_id) }
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )}

          <List name="co_listing_agent_details">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }) => (
                  <CoWrap>
                    <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} />
                    {console.log("name", name)}
                    <HeaderWrap>
                      <FormTitle>
                        <p> <CoAgentIcon/> Co-Listing Agent Information</p>
                      </FormTitle>
                      <CancelOrGoBackButton
                        type="button"
                        onClick={() => openRemoveAgentModal(name)}
                      >
                        <p> <RemoveAgentBinIcon/> Remove </p>
                      </CancelOrGoBackButton>
                    </HeaderWrap>
                    {/* <HeaderWrap>
                      <ListRowWrap>
                        <StyledCheckbox
                          value="here"
                          checked={isRadiusAgentBool[name]}
                          onChange={(e) => {
                            const {
                              target: {
                                checked,
                              },
                            } = e || {}
                            setRadiusAgentBoolForCoAgent(existingValues => ({
                              ...existingValues,
                              [name]: checked,
                            }))
                          }}
                        >
                          Is agent part of Radius ?
                        </StyledCheckbox>
                      </ListRowWrap>
                    </HeaderWrap> */}
                     <AgentSearchWithConsent
                        name={[name, "listing_agent_first_name"]}
                        inputType="text"
                        placeholder="Search by agent name or email address."
                        customRules={{}}
                        isFormField
                        formRef={formRef}
                        valueOfField={formRef?.current?.getFieldValue(['co_listing_agent_details', name, 'listing_agent_first_name'])}
                        agentId={formRef?.current?.getFieldValue(['co_listing_agent_details', name, 'agent_id'])}
                        firstNameLabel="listing_agent_first_name"
                        lastNameLabel="listing_agent_last_name"
                        fieldIndex={name}
                        formName="co_listing_agent_details"
                        isFirst={isFirst}
                        text="First Name"
                        required="true"
                        requiredMessage="Please select a value"
                        disabled={isAddManuallyForCoAgent[name]}
                        onChange={(agent,value,option)=>handleOnChangeCoListingAgent(agent,value,option,"co_listing_agent_details",name)}
                        onClear={()=>handleOnClearCoListingAgent("co_listing_agent_details",name)}
                        onAddManually={() => setIsAddManuallyForCoAgent(prev => ({
                          ...prev,
                          [name]: false
                        }))}
                        onAgentPartOfRadius={(value) => {
                            setIsAddManuallyForCoAgent(prev => ({
                              ...prev,
                              [name]: value 
                            }));
                        }}
                      />

                        
                    <FormFieldWrap>
                  
                    <InputField
                        name={[name, "listing_agent_first_name"]}
                        inputType="text"
                        placeholder="First Name"
                        customRules={{}}
                        text="First Name"
                        required="true"
                        disabled={isAddManuallyForCoAgent[name]}
                      />

                      <InputField
                        name={[name, "listing_agent_last_name"]}
                        inputType="text"
                        placeholder="Last Name"
                        customRules={{}}
                        text="Last Name"
                        required="true"
                        disabled={isAddManuallyForCoAgent[name]}
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "email"]}
                        inputType="email"
                        type="email"
                        placeholder="Email"
                        text="Email"
                        required="true"
                        disabled={isAddManuallyForCoAgent[name]}
                      />

                      <InputNumber
                        name={[name, "phone"]}
                        inputType="phone"
                        type="phone"
                        placeholder="Phone"
                        text="Phone"
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "brokerage"]}
                        inputType="text"
                        type="text"
                        placeholder="Listing Brokerage"
                        text="Listing Brokerage"
                        required={false}
                      />

                      <InputField
                        name={[name, "agent_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="Agent License Number"
                        text="Agent License Number"
                        required={false}
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "brokerage_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="Listing Brokerage License No."
                        onPressEnter={() => {
                          //
                        }}
                        text="Listing Brokerage License No."
                        required="false"
                      />

                      {/* <InputField
                        name={[name, "brokerage_address"]}
                        inputType="text"
                        type="text"
                        placeholder="Brokerage Street Address"
                      /> */}

                      <InputLocation
                        name={[name, "brokerage_address"]}
                        inputType="text"
                        type="text"
                        placeholder="Brokerage Street Address"
                        formRef={formRef}
                        formName="co_listing_agent_details"
                        fieldIndex={name}
                        text="Brokerage Address"
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "apt"]}
                        inputType="text"
                        type="text"
                        placeholder="Apt/Unit/Suite No."
                        text="Apt/Unit/Suite No."
                      />

                      <InputField
                        name={[name, "city"]}
                        inputType="text"
                        placeholder="City"
                        text="City"
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "state"]}
                        inputType="text"
                        placeholder="State"
                        text="State"
                      />

                      <InputField
                        name={[name, "zipcode"]}
                        inputType="text"
                        placeholder="Zipcode"
                        text="Zipcode"
                      />
                    </FormFieldWrap>
                  </CoWrap>
                ))}
                <FormFieldWrap>
                  <CTAWrap>
                    <Form.Item>
                      <AddButton
                        disabled={listingAgents.length <= 0}
                        name={
                          listingAgents.length > 0
                            ? "ADD ADDITIONAL LISTING AGENT"
                            : "ADD LISTING AGENT"
                        }
                        onClick={() => {
                          add()
                        }}
                      />
                    </Form.Item>
                  </CTAWrap>
                </FormFieldWrap>
              </>
            )}
          </List>

          <Form.Item>
            {/* <BlueButton
              type="submit"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton> */}
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  );
};

export default TechAssets(ListingAgentForm);
