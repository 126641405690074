import axios from "axios"

export const fetchNotificationAPI = (count = 15, startTime = 0) => axios
  .get(`${API_V2_URL}notification?count=${count}&startTime=${startTime}`)

export const fetchNotificationUnreadCountAPI = () => axios
  .get(`${API_V2_URL}notifications/unread-count`)

export const finishOnboardingAPI = () => axios
  .post(`${API_URL}user/onboarding`)

// View API
export const notificationViewedAPI = payload => axios
  .post(`${API_URL}side-menu-updates/feature-viewed`, payload)

export const getSubscriptionPlansAPI = () => axios.get(`${API_V2_URL}stripe/plans`)
export const addSubscriptionPlanAPI = payload => axios.post(`${API_V2_URL}stripe/checkout-blink`, payload)
export const fetchPaymentAPI = () => axios.get(`${API_URL}stripe/customers/payment`)
export const editPaymentAPI = payload => axios.put(`${API_URL}stripe/customers/payment-method`, payload)
export const deletePaymentAPI = payload => axios.post(`${API_URL}stripe/customers/payment-method/delete`, payload)
export const defaultPaymentAPI = payload => axios.post(`${API_URL}stripe/customers/payment-method/default`, payload)
export const fetchAddOnsAPI = query => axios.get(`${API_V2_URL}stripe/add-ons${query}`)
export const updateSubscriptionAPI = payload => axios.post(`${API_V2_URL}user/addCustomizedSubscriptionSettings`, payload)
export const fetchInvoiceDataAPI = () => axios.get(`${API_V3_URL}stripe/invoices`)
export const handleExpiredSubscriptionAPI = payload => axios.post(`${API_URL}stripe/invoices/pay`, payload);
