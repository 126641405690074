import React, { useRef, useState, useEffect } from "react"
import { Form } from "@ui/antd"
import {
  InputField,
  InputNumber,
  SelectField,
  InputLocation,
  HiddenInputField,
  ClientsByNameSearch
} from "dumbComponents/common/InputFields"
import { AddButton } from "dumbComponents/OffersV3/components"
import {
  TYPE_OF_OWNERSHIP,
  CLIENT_SOURCE,
} from "dumbComponents/OffersV2/consts"
import { normalizePhone } from "services/formUtils"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  FormFieldWrap,
  CoAgentWrap,
} from "./commonStyles"
// import {
//   InputField,
//   InputNumber,
//   SelectField,
//   InputLocation,
//   HiddenInputField,
// } from "../../InputFields"
import { BlueButton, DangerButton } from "../../../../commonStyles"
import CoAgentIcon from "images/icons/co-agent-icons"
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";
import RemoveAgentBinIcon from "images/icons/remove-agent-bin-icon"
import TechAssets from "container/TechAssets"
const { List } = Form

const BuyerForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteBuyer,
  form,
  additionalData,
  handleOnTypeChangeBuyerInfo,
  buyerInformationFormRef,
  editingAgentId, 
  // setOfferFormValidation,
  offerFormValidation
}) => {
  const formRef = useRef(null)
  let testSource = false
  const [showOtherSource, setShowOtherSource] = useState(false)
  const [typeOfOwnership, setOwnership] = useState("individual")
  const [coBuyertypeOfOwnership, coBuyersetOwnership] = useState("individual") 

  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);

  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = {
        ...prefilledData[0],
        client_id: additionalData.client_id,
        req_id: additionalData.req_id,
        transaction_id: additionalData.transaction_id,
      }
      if (initValues.source === "other") {
        testSource = true
      }

      initValues.phone = normalizePhone(initValues.phone)
      if (prefilledData.length > 1) {
        const sliced = prefilledData.slice(1)
        initValues.co_buyer_details = sliced
      }
    }

    return initValues
  }


 useEffect(() => {
  if (prefilledData[0]?.type_of_ownership) {
    setOwnership(prefilledData[0].type_of_ownership)
  }
}, [prefilledData])


  const handleClientSourceChange = (value) => {
    if (value === "other") {
      setShowOtherSource(true)
    } else {
      setShowOtherSource(false)
      testSource = false
    }
  }

  const handleOwnershipchange = (value) => {
    setOwnership(value)
    formRef.current.setFieldsValue({
        ownership_name  : '',
        })
  };

  const getFieldName = (typeOfOwnership) => {
    if (typeOfOwnership === "trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "successor_trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "entity") {
      return "Full Name of Entity"
    }
    if (typeOfOwnership === "probate") {
      return "Full Name of Executer"
    }
    if (typeOfOwnership === "estate") {
      return "Full Name of Estate"
    }
    if (typeOfOwnership === "power_of_attorney") {
      return "Full Name of Attorney-in-fact"
    }
    return "Full Name"
  }

  const handleOwnershipchangeCoBuyer = (value) => {
    coBuyersetOwnership(value)
  };

  const validateBuyerForm = (values) => {
    console.log('Buyer form values being validated:', values);
    
    // Required fields for main buyer
    const requiredFields = [
      'firstname',
      'lastname',
      'email',
      'type_of_ownership',
      'source'
    ];

    // Add other_source to required fields if source is "other"
    if (values.source === 'other') {
      requiredFields.push('other_source');
    }

    // Add ownership_name to required fields if type_of_ownership is not "individual"
    if (values.type_of_ownership && values.type_of_ownership !== 'individual') {
      requiredFields.push('ownership_name');
    }

    // Check main buyer fields
    const isMainBuyerValid = requiredFields.every(field => {
      const value = values[field];
      const isValid = value && typeof value === 'string' && value.trim() !== '';
      console.log(`Main buyer field ${field}:`, value, 'isValid:', isValid);
      return isValid;
    });

    // Check co-buyers if they exist
    let areCoBuyersValid = true;
    if (values.co_buyer_details && values.co_buyer_details.length > 0) {
      areCoBuyersValid = values.co_buyer_details.every(coBuyer => {
        const coBuyerRequiredFields = ['firstname', 'lastname', 'email', 'type_of_ownership'];
        
        // Add ownership_name if type_of_ownership is not individual
        if (coBuyer.type_of_ownership && coBuyer.type_of_ownership !== 'individual') {
          coBuyerRequiredFields.push('ownership_name');
        }

        return coBuyerRequiredFields.every(field => {
          const value = coBuyer[field];
          const isValid = value && typeof value === 'string' && value.trim() !== '';
          console.log(`Co-buyer field ${field}:`, value, 'isValid:', isValid);
          return isValid;
        });
      });
    }

    const isFormValid = isMainBuyerValid && areCoBuyersValid;
    console.log('Final buyer form validation result:', isFormValid);

    // setOfferFormValidation({
    //   ...offerFormValidation,
    //   buyerInfoFormValid: {
    //     ...offerFormValidation.buyerInfoFormValid,
    //     isBuyerInfoValid: isFormValid
    //   }
    // });
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    console.log('Buyer form values changed:', allValues);
    validateBuyerForm(allValues);
    if (handleOnTypeChangeBuyerInfo) {
      handleOnTypeChangeBuyerInfo(changedValues, allValues);
    }
  };

  useEffect(() => {
    if (prefilledData && prefilledData[0]) {
      validateBuyerForm(getInitialValues());
    }
  }, [prefilledData]);

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Buyer Form"}</p>
        </FormTitle>
      </HeaderWrap>

      <Form
        name="offers_buyer_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        form={buyerInformationFormRef}
        onValuesChange={handleFormValuesChange}
      >

        <HiddenInputField name="client_id" />
        <HiddenInputField name="transaction_id" />
        <HiddenInputField name="req_id" />

        <FormFieldWrap>
          {/* <InputField
            name="firstname"
            inputType="text"
            placeholder="First Name"
            required
            text="First Name"
            required="true"
          /> */}

          <ClientsByNameSearch 
            formRef={formRef}
            firstNameInputFieldName="firstname"
            lastNameInputFieldName="lastname"
            emailInputFieldName="email"
            phoneInputFieldName="phone"
            formItemName="firstname"
            formItemPlaceholder="First Name"
            formItemLabelText="First Name"
            editingAgentId={editingAgentId}
            required={true}
          />

          <InputField
            name="lastname"
            inputType="text"
            placeholder="Last Name"
            text="Last Name"
            required="true"
          />
        </FormFieldWrap>

        {/* <InputField
          name="buyer_client_source"
          inputType="text"
          placeholder="Client Source*"
        /> */}

        <FormFieldWrap>
          <InputField
            name="email"
            inputType="email"
            type="email"
            placeholder="Email"
            text="Email"
            required="true"
          />
          <InputNumber
            name="phone"
            inputType="phone"
            type="phone"
            placeholder="Phone"
            text="Phone"
            //required="true"
          />
        </FormFieldWrap>

        {/**Requires Google Address*/}
        {/* <InputField
          name="buyer_street_address"
          inputType="text"
          type="text"
          placeholder="Street Address*"
        /> */}

        <FormFieldWrap>
          <InputLocation
            name="address"
            inputType="text"
            type="text"
            placeholder="Street Address"
            formRef={formRef}
            text="Street Address"
          />

          <InputField
            name="apt"
            inputType="text"
            placeholder="Apt / Unit / Suite No."
            text="Apt/Unit/Suite No."
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="city"
            inputType="text"
            placeholder="City"
            text="City"
          
          />

          <InputField
            name="state"
            inputType="text"
            placeholder="State"
            text="State"
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="zipcode"
            inputType="text"
            placeholder="Zip Code"
            text="Zip Code"
          />
          <SelectField
            isFormField
            name="type_of_ownership"
            options={TYPE_OF_OWNERSHIP}
            placeholder="Type of Ownership"
            text="Type Of Ownership"
            required="true"
            requiredMessage="Please select a value"
            onChange={handleOwnershipchange}
          />
        </FormFieldWrap>
        {typeOfOwnership !== "individual" && (
          <FormFieldWrap>
            <InputField
              name="ownership_name"
              inputType="text"
              placeholder={getFieldName(typeOfOwnership)}
              text={getFieldName(typeOfOwnership)}
            />
          </FormFieldWrap>
        )}
        <FormFieldWrap>
          <SelectField
            isFormField
            name="source"
            options={CLIENT_SOURCE}
            defaultValue={prefilledData ? prefilledData.source : null}
            type="text"
            placeholder="Client Source"
            text="Client Source"
            required="true"
            onChange={handleClientSourceChange}
            requiredMessage="Please select a value"
          />
          {(testSource || showOtherSource) && (
            <InputField
              name="other_source"
              inputType="text"
              placeholder="Other Source"
              text="Other Source"
              required="true"
            />
          )}
        </FormFieldWrap>

        <List name="co_buyer_details">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name }) => (
                <CoAgentWrap>
                  <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} 
                      heading="Remove Buyer"
                      subText="Are you sure you want to remove this buyer?"
                      />
                  <HeaderWrap>
                    <FormTitle>
                      <p> <CoAgentIcon/> Co-Buyer Information</p>
                    </FormTitle>
                    <CancelOrGoBackButton
                      onClick={() => {
                        openRemoveAgentModal(name)
                      }}
                    >
                      <p> <RemoveAgentBinIcon/> Remove </p>
                    </CancelOrGoBackButton>
                  </HeaderWrap>
                  <FormFieldWrap>
                    {/* <InputField
                      name={[name, "firstname"]}
                      inputType="text"
                      placeholder="First Name"
                      text="First Name"
                      required="true"
                    /> */}

                    <ClientsByNameSearch
                      formRef={formRef}
                      firstNameInputFieldName="firstname"
                      lastNameInputFieldName="lastname"
                      emailInputFieldName="email"
                      phoneInputFieldName="phone"
                      formItemName={[name, "firstname"]}
                      formItemPlaceholder="First Name"
                      formItemLabelText="First Name"
                      editingAgentId={editingAgentId}
                      fieldIndexInTheSubForm={name}
                      subFormName="co_buyer_details"
                      required={true}
                    />

                    <InputField
                      name={[name, "lastname"]}
                      inputType="text"
                      placeholder="Last Name"
                      customRules={{}}
                      text="Last Name"
                      required="true"
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "email"]}
                      inputType="email"
                      type="email"
                      placeholder="Email"
                      text="Email"
                      required="true"
                    />

                    <InputNumber
                      name={[name, "phone"]}
                      inputType="phone"
                      type="phone"
                      placeholder="Phone"
                      text="Phone"
                      //required="true"
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputLocation
                      name={[name, "address"]}
                      inputType="text"
                      type="text"
                      placeholder="Street Address"
                      fieldIndex={name}
                      formRef={formRef}
                      formName="co_buyer_details"
                      text="Street Address"
                    />

                    <InputField
                      name={[name, "apt"]}
                      inputType="text"
                      type="text"
                      placeholder="Apt/unit/suite no."
                      text="Apt/unit/suite no."
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "city"]}
                      inputType="text"
                      type="text"
                      placeholder="City"
                      text="City"
                    />

                    <InputField
                      name={[name, "state"]}
                      inputType="text"
                      type="text"
                      placeholder="State"
                      text="State"
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "zipcode"]}
                      inputType="text"
                      type="text"
                      placeholder="Zipcode"
                      text="Zipcode"
                    />

                    <SelectField
                      isFormField
                      name={[name, "type_of_ownership"]}
                      options={TYPE_OF_OWNERSHIP}
                      defaultValue={
                        prefilledData ? prefilledData.type_of_ownership : null
                      }
                      placeholder="Type of Ownership"
                      text="Type of Ownership"
                      onChange={handleOwnershipchangeCoBuyer}
                      required="true"
                      requiredMessage="Please select a value"
                    />
                  </FormFieldWrap>
                  {coBuyertypeOfOwnership !== "individual" && (
                    <FormFieldWrap>
                      <InputField
                        name={[name,"ownership_name"]}
                        inputType="text"
                        placeholder={getFieldName(coBuyertypeOfOwnership)}
                        text={getFieldName(coBuyertypeOfOwnership)}
                      />
                    </FormFieldWrap>
                  )}
                </CoAgentWrap>
              ))}
              <FormFieldWrap>
                <CTAWrap>
                  <AddButton name="ADD A CO-BUYER" onClick={() => add()} />
                </CTAWrap>
              </FormFieldWrap>
            </>
          )}
        </List>

        <CTAWrap></CTAWrap>
      </Form>
    </FormWrap>
  )
};

export default TechAssets(BuyerForm)
