import styled from "styled-components"
import {
  Select,
  Input,
  InputNumber,
  Spin,
  Form,
  GoogleAutoComplete,
  DatePicker,
  Button,
  AutoComplete,
} from "@ui/antd"
import { Popover } from "antd"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const { TextArea, Search } = Input

export const StyledGoogleAutoCompleted = styled(GoogleAutoComplete)`
  display: none !important;
`

export const Wrap = styled.div`
  width: 100%;

  ${props => props.noWidth && `
    width: unset;
  `}
  
  ${props => props.width && `
    width: ${props.width};
  `}
`

export const FormWrap = styled.form`
&>span {
  position: relative;
  bottom: 17px;
}
& label input {
  border-radius: 8px;
}
 & label span, & div label {
   color: black;
   font-family: DM Sans;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 16px;
   letter-spacing: 0px;
   text-align: left;
   margin-bottom: 10px;
 }

 & label {
   margin-bottom: 22px;
 }

 ${props => props.disabled && `
    opacity: 0.5;
    cursor: not-allowed;
 `}
`
export const SelectFieldNewAttachedStyled = styled(Select)`
.ant-select-selector {
    position: relative;
    top: 0px;
    box-shadow: none;
    /* border-radius: 8px !important; */
    border-top: 1px solid #898989 !important;
    height: 42px !important;
    border-right: 1px solid #898989 !important;
    border-bottom: 1px solid #898989 !important;
    box-shadow: none;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`
export const SelectFieldAttachedStyled = styled(Select)`
  .ant-select-selector {
    position: relative;
    left: -5px;
    top: 0px;
    box-shadow: none;
    /* border-radius: 8px !important; */
    border-top: 1px solid #898989 !important;
    height: 42px !important;
    border-right: 1px solid #898989 !important;
    border-bottom: 1px solid #898989 !important;
    box-shadow: none;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`

export const SelectFieldWrap = styled(Select)`
  // border-radius: 4px;
  // border: 0.7px solid #898989;
  width: 100%;

  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, ${props => props.borderColor ? props.borderColor : "#9CA3AF"}) !important;

  .ant-select-selector {
    ${props => props.isFormField && `
      border-radius: 8px !important;
    `}
    height: 40px !important;
    ${props => props.isRequired && `
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
      background-repeat: no-repeat !important;
      background-position: right 20px center !important;
      background-size: 15px !important;
      transition-property: background, background-image, background-repeat, background-position, background-size !important;
      transition-duration: 0s !important;
    `}
  }
`

export const UserInfo = styled.p`
  color: var(--Neutral-500, #6B7280);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const AutoCompleteFieldWrap = styled(AutoComplete)`
  // border-radius: 4px;
  // border: 0.7px solid #898989;
  width: 100%;

  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, ${props => props.borderColor ? props.borderColor : "#9CA3AF"}) !important;

  .ant-select-selector {
    ${props => props.isFormField && `
      border-radius: 8px !important;
    `}
    height: 42px !important;
  }
`

export const InputSearchWrap = styled(Search)`
  // border-radius: 4px;
  // border: 0.7px solid #898989;
  width: 100%;

  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, ${props => props.borderColor ? props.borderColor : "#898989"}) !important;
  //height: 40px !important;

  input {
    height: 40px;
    border: 1px solid white;
    border-radius: 6px;
  }
`

export const InputFieldWrap = styled(Input)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #9CA3AF) !important;
  padding: 12px 16px;
  height: 42px !important;

  ${props => props.isRequired && `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right 12px center !important;
    background-size: 15px !important;
    transition-property: background, background-image, background-repeat, background-position, background-size !important;
    transition-duration: 0s !important;
  `}

  &.ant-input-status-error {
    border: 1px solid #DA4A4B !important;
  }
`

export const InputNumberWrap = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #9CA3AF) !important;
  height: 42px !important;

  ${props => props.isRequired && `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right 12px center !important;
    background-size: 20px !important;
    transition-property: background, background-image, background-repeat, background-position, background-size !important;
    transition-duration: 0s !important;
  `}

  .ant-input-number-input {
    height: 42px !important;
  }
`

export const InputNumberNewWrap = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #9CA3AF) !important;
  height: 42px !important;
  .ant-input-number-input {
    height: 42px !important;
  }
  width:850px;
    margin-right: -7px !important;
`

export const InputNumberWithPercentageWrap = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #9CA3AF) !important;
  height: 42px !important;
  width: 100% !important;
  overflow: hidden !important;

  .ant-input-number-input {
    height: 42px !important;
  }

  .ant-input-number {
    border: 0 !important;
  }

  .ant-input-number-group-addon:last-child {
    border: 0 !important;
    border-left: 1px solid var(--Color-Primary-Primary, #9CA3AF) !important;
  }
     ${props => props.isRequired && `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right 12px center !important;
    background-size: 20px !important;
    transition-property: background, background-image, background-repeat, background-position, background-size !important;
    transition-duration: 0s !important;
  `}
`
export const StyledSpin = styled(Spin)`
  width: 100%;
`

export const StyledFormItem = styled(Form.Item)`
  ${props => props.hidden && `
    min-height: 0px;
    max-height: 0px;
  `}
`

export const InputTextAreaWrap = styled(TextArea)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  padding: 12px 16px;
  height: 125px !important;
`

export const InputGroupStyled = styled(Input.Group)`
  display: flex !important;
  height: 42px;
`

export const GroupedWrap = styled.div`
  display: flex;
`

export const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #9ba2ad !important;
  border-radius: 8px !important;
  height: 42px !important;

  ${props => props.isRequired && `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right 25px center !important;
    background-size: 22px !important;
    transition-property: background, background-image, background-repeat, background-position, background-size !important;
    transition-duration: 0s !important;
  `}

  .ant-picker {
    border: 1px solid #9ba2ad !important;
    border-radius: 8px !important;
  }
`
export const FilterButton = styled.button`
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 15px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  p {
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin: 0;
  }

  img {
    width: 20px;
    height: 20px;
  }
`

export const BlueButton = styled(Button)`
  display: flex;
  width: max-content
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 24.5px;
  background: var(--Color-Primary-Primary, #5A5FF2);

  p {
    color: var(--Color-Neutral-50, #FDFDFD);
    text-align: center;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    margin: 0;
  }

  &:hover{
    p {
      color: #5A5FF2
    }

    background: #fff;

    border-color: #fff;
  }

  &:focus {
    outline: none;
    background: var(--Color-Primary-Primary, #5A5FF2);
    p {
      color: #FDFDFD;
    }
  }

`

export const DangerButton = styled(Button)`
  display: flex;
  width: max-content
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 24.5px;
  background: red;

  p {
    color: var(--Color-Neutral-50, #FDFDFD);
    text-align: center;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    margin: 0;
  }

  &:hover{
    p {
      color: red;
    }

    background: #fff;

    border-color: #fff;
  }

  &:focus {
    outline: none;
    background: red;
    p {
      color: #FDFDFD;
    }
  }
`

export const ThreeDotContainer = styled.div`
  img {
    width: 24px;
    height: 24px;
  }
`
//ant-popover-inner-content
export const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0;
  }

  &:.ant-popover-inner-content {
    padding: 0;
  }
`

export const ImageUploadWrap = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: row;
  gap: 20px;
  border-radius: 5px;
  border: 1px dashed var(--Color-Secondary-Secondary, #A5A8FF);
  align-items: center;
`

export const AvatarWrap = styled.div`
  width: 108.989px;
  height: 108.989px;
  flex-shrink: 0;
  border-radius: 6.539px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
  }
`

export const UploadImageDisclaimer = styled.div`
  p {
    color: #000;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
`

export const UploadImageButton = styled.button`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  border-radius: 34px;
  background: transparent;
  border: 1px solid var(--Color-Primary-Primary, #5A5FF2);
  color: var(--Color-Primary-Primary, #5A5FF2);
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`

export const DeleteButton = styled.button`
  display: flex;
  padding: 0px;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  border-radius: 34px;
  background: transparent;
  border: none;
  
  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    leading-trim: both;
    text-edge: cap;
    font-family: ${MONTSERRAT};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 12px */
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin: 0;
  }
`

export const FlexWrap = styled.div`
  display: flex;
`

export const ImageStyledWrap = styled.div``

export const RadioWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
`

export const RadioQuestion = styled.div`
 p {
    color: #6E6E6E;
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.5px;
    margin: 0;
 }

`
