import React from "react"
import { moneyFormatter } from "shared/currencyUtils"
import * as ListingCardStyles from "./styles"
import { getStateShortName } from "../../ListingsV2/Components/ListingTabs/consts";
const ListingCard = ({
  details,
  handleEdit,
  handleDelete,
}) => {
  const {
    bedrooms,
    bathrooms,
    street,
    price,
    area_unit,
    image_urls,
    state,
    apt,
    city,
    zipcode,
    area,
  } = details || {}
  const IMAGE = image_urls && image_urls.length > 0 ? image_urls[0] : `${CLOUDFRONT}/LISTING_V2/MLSGeneric.png`
  const stateShort = getStateShortName(state);
  return (
    <ListingCardStyles.ListingCardWrap>
      <ListingCardStyles.AddressCard>
        <ListingCardStyles.AddressImageCard>
          <img src={IMAGE} alt="card.png" />
        </ListingCardStyles.AddressImageCard>
        <ListingCardStyles.AddressCardDetails>
          <h1>{street}</h1>
          <h1>{apt ? `${apt},` : ""}</h1>
          <h1>{city ? `${city},` : ""} {stateShort ? stateShort : ""}{" "}</h1>
          <h1>{ zipcode}</h1>
          <p>House for sale</p>
        </ListingCardStyles.AddressCardDetails>
      </ListingCardStyles.AddressCard>

      <ListingCardStyles.StatCard>
        <ListingCardStyles.StyledPrice>
          {`$${moneyFormatter(price || 0)}`}
        </ListingCardStyles.StyledPrice>
      </ListingCardStyles.StatCard>

      <ListingCardStyles.StatCard>
        <ListingCardStyles.StyledStat>
          {`${bedrooms || 0} ${bedrooms && bedrooms > 1 ? "beds" : "bed"}`}
        </ListingCardStyles.StyledStat>
      </ListingCardStyles.StatCard>

      <ListingCardStyles.StatCard>
        <ListingCardStyles.StyledStat>
          {`${bathrooms || 0} ${bathrooms && bathrooms > 1 ? "baths" : "bath"}`}
        </ListingCardStyles.StyledStat>
      </ListingCardStyles.StatCard>

      <ListingCardStyles.StatCard>
        <ListingCardStyles.StyledStat>
        {`${area || 0} ${area_unit}`}
        </ListingCardStyles.StyledStat>
      </ListingCardStyles.StatCard>

      {/* <ListingCardStyles.ActionCard>
        <ListingCardStyles.StyledAction
          onClick={handleDelete}
        >
          <img src={`${CLOUDFRONT}/LISTING_V2/dustbin.svg`} alt="chevron.png" />
          <p>DELETE</p>
        </ListingCardStyles.StyledAction>
      </ListingCardStyles.ActionCard> */}

      <ListingCardStyles.ActionCard>
        <ListingCardStyles.StyledAction
          onClick={handleEdit}
        >
          <img src={`${CLOUDFRONT}/LISTING_V2/Edit.svg`} alt="chevron.png" />
          <p>EDIT</p>
        </ListingCardStyles.StyledAction>
      </ListingCardStyles.ActionCard>

    </ListingCardStyles.ListingCardWrap>
  )
}

export default ListingCard
