import { Modal } from "@ui/antd"
import styled from "styled-components"
import { ATC, INTER } from "@fonts"

export const Wrap = styled.div`
  width: 50%;
  padding: 15px 19px;
  position:relative;
  ${props => props.isBg && `
    background-color: #FAFAFF;
    border-radius: 4px;
  `}
`

export const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 32px
`

export const Header = styled.h1`
  margin: 0;
  color: var(--Color-Gray-400, #41446A);
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.084px;
  text-transform: uppercase;
`

export const ModalWrap = styled.div`
  width: 1000px !important;
  border-radius: 9px !important;
`

export const StyledModal = styled(Modal)`
  width: 1000px !important;
  .ant-modal-content {
    border-radius: 9px !important;
  }
`

export const EmptyStateWrap = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const EmptyStateHeader = styled.h1`
  margin: 0;
`

export const EmptyStateContent = styled.p`
  margin: 0;
`

export const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px
`

export const HeaderButtonWrap = styled.div`
  display: flex;
  align-items: center;
`

export const LoaderWrap = styled.div`
  padding-bottom: 50px;
`

export const ProcessingWrap = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: ${props => props.isDocumentProcessing ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  background-color: #fffdfd;
  opacity: 0.8;
  z-index: 2;
`

export const ProcessingTitle = styled.p`
  margin: 0;
  display: flex;
  gap: 3px;
`
export const CheckboxWrap = styled.div`
  margin-bottom: 20px;
  padding-left: 15px;
`

export const ShimmerWrap = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
`

export const DownloadPurpleButton = styled.button `
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: var(--Global-Mapping-Primary, #5A5FF2);
    text-align: center;
    border-radius: 8px;
    border: 1px solid var(--Global-Mapping-Primary, #5A5FF2);
    background: var(--Color-Primary-Primary-50, #EFEFFE);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;

    ${props => props.disabled && `
      cursor: not-allowed;
    `}
`
