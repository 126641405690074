import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Progress } from 'antd';
import CRMV2Container from "container/CRM"; 
import Loader from "@ui/Loader";
import ConsumerAppContainer from "container/ConsumerApp/index";
import { LeftOutlined } from "@ant-design/icons"

const StyledContainer = styled.div`
  h1 {
    color: red;
    font-size: 30px;
  }
  p {
    color: #4A4A4A;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
  }
  li {
    color: #4A4A4A;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
  }
  ul {
    list-style-type: disc; 
    margin: 0;
    padding-left: 20px;
  }  
  h3 {
    color: #4A4A4A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 34px auto;
  padding: 20px;
  //border: 1px solid #e0e0e0;
  //border-radius: 8px;
  color: #4A4A4A;;
  background-color: #fff;
  font-family: Inter;
`;

const RandomText = styled.h1`
  color: red; 
  font-size: 30px; 
`

const Header = styled.div`
  display: flex; 
  gap: 12px; 
  align-items: center; 
  margin-bottom: 20px;
  border-bottom: 1px solid #E0E6ED; 
  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  p {
    margin: 5px 0;
    font-size: 14px; 
    font-weight: 400;
    color: #666;
  }
`;

const HeaderText = styled.div`
  
`

const Section = styled.div`
  margin-bottom: 20px;
  border: 1px solid #e0e0e0; 
  border-radius: 8px; 
  padding: 10px; 
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SectionSubHeading = styled.h4`
  font-weight: bold; 
`

const EngagementScore = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ScoreText = styled.div`
  margin-left: 10px;
  font-weight: bold;
`;

const SummaryText = styled.p`
  margin: 0;
  color: #5CBF9E
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #5a5ff2;
  color: white;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 45.6%;
  padding: 20px;
  background-color: #F8F9FA;
  display: flex;
  margin-right
  justify-content: flex-start;
  z-index: 1000; /* Ensure it's above other content */
`;

const CardContainer = styled.div`
  display: flex;
  overflow: hidden; 
  background-color: #545487; 
  border-radius: 6px;
  border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
  background: var(--Global-Mapping-Gray-50, #EFEFF4);
  padding: 12px 16px; 
  margin-top: 8px; 
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 100px;
  overflow: hidden;
`;

const PropertyImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px; 
`;

const PropertyDetails = styled.div`
  padding: 10px;
`;

const PropertyTitle = styled.h2`
  margin: 0 0 5px 0;
  font-size: 16px;
`;

const PropertyInfo = styled.p`
  margin: 0 0 4px 0;
  font-size: 14px;
  color: #666;
`;

const RecommendedDate = styled.p`
  margin: 0;
  font-size: 12px;
  color: #999;
`;

const ArrowIcon = styled.span`
  margin-left: auto; 
  padding: 10px;
  font-size: 20px;
  color: #5A5FF2; 
  cursor: pointer; 
  &:hover {
    background-color: #f0f0f0;
  }
`;

const MelBackgroundForHeading = styled.div`
  display: flex; 
  align-items: center; 
  background-color: #F8F9FA; 
  border-radius: 24px;
  padding: 0 2px; 
`

const ArrowComponent = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="#5A5FF2"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
);

const StarComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.73136 20.8325C7.22429 21.0972 6.61084 21.0506 6.14958 20.7124C5.68831 20.3741 5.45945 19.8031 5.55946 19.2399L6.36879 14.6009L2.96137 11.3356C2.54488 10.9384 2.39224 10.338 2.56847 9.7901C2.7447 9.24222 3.21876 8.84339 3.78872 8.76351L8.51969 8.08723L10.6556 3.82645C10.9088 3.31546 11.4298 2.99219 12.0001 2.99219C12.5704 2.99219 13.0915 3.31546 13.3447 3.82645L15.4806 8.08723L20.2116 8.76351C20.7815 8.84339 21.2556 9.24222 21.4318 9.7901C21.608 10.338 21.4554 10.9384 21.0389 11.3356L17.6315 14.6009L18.4408 19.2409C18.5408 19.8041 18.312 20.3751 17.8507 20.7134C17.3894 21.0516 16.776 21.0982 16.2689 20.8335L12.0001 18.6276L7.73136 20.8325Z"
      stroke="#4A4A4A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function formatEpochTime(epochTime) {
    const epochTimeInMillis = epochTime * 1000;
    const date = new Date(epochTimeInMillis); 
    
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true
    };
  
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate.replace(',', ' at');
}    

const summaryText = `

### Key Insights on Client Preferences and Behavior Patterns

1. **Location Preference:** 
   Jasom has a strong preference for Santa Monica, California, which indicates a desire for a specific geographical area, potentially due to lifestyle, work, or community factors.

2. **Budget Flexibility:** 
   The budget range is quite broad, extending up to $2 million, suggesting that Jasom is open to a variety of options. This flexibility may indicate a willingness to invest in quality or desirable features.

3. **Undefined Size and Bedroom/Bathroom Preferences:** The lack of specific requirements regarding size and number of bedrooms/bathrooms suggests that Jasom may prioritize location or price over specific property characteristics. This could also indicate a level of indecision or a need for guidance in understanding market options.

### Overall Deal Progress Assessment and Probability Score

- **Deal Progress Assessment:** Currently, the status is marked as "New Client," which indicates that no transactions have been finalized yet. 
- **Probability Score:** Given the newness of the client and the lack of detailed requirements, the probability score is around **40%**. This reflects potential interest but also the need for further engagement and exploration of options.

### Recommendations for Next Steps

1. **Engagement and Clarification:**
   - Schedule a meeting or call with Jasom to clarify specific needs regarding property size, number of bedrooms/bathrooms, and any other preferences that might help narrow down the search.
   - Discuss lifestyle needs or future plans that could impact property choices (e.g., work, family size).

2. **Market Analysis:**
   - Provide a detailed market analysis for Santa Monica, highlighting properties within the budget that meet potential criteria.
   - Share insights into property appreciation trends and neighborhood amenities that might interest Jasom.

3. **Property Tours:**
   - Arrange property tours for a selection of homes that fit within the budget, emphasizing various styles and sizes to help Jasom visualize options.

### Potential Challenges and How to Address Them

1. **Indecision or Lack of Clarity:**
   - **Solution:** Facilitate discussions to help Jasom articulate preferences. Use visual aids (like photos or virtual tours) to stimulate interest.

2. **Market Competition:**
   - Santa Monica can be competitive. 
   - **Solution:** Keep Jasom informed about new listings promptly and be prepared to act quickly when suitable properties arise.

### Timeline Analysis and Suggestions for Faster Deal Closure

- **Current Timeline:** As of now, the client is in the early stages of the buying journey, which typically requires more engagement to transition from "New Client" to an active buyer. 

- **Suggestions for Faster Closure:**
  1. **Frequent Communication:** Maintain regular contact to keep Jasom engaged and informed.
  2. **Set Clear Milestones:** Establish timelines for property tours, decision-making, and offer submissions to create urgency.
  3. **Utilize Technology:** Use property alerts and virtual tours to enhance the buying experience and expedite decision-making.

By focusing on clear communication, a thorough understanding of Jasom's needs, and proactive engagement, the likelihood of moving forward successfully can be increased, potentially leading to a quicker deal closure.
`;

const ClientSummary = ({
    handleClose, 
    getClientSummary,
    getClientSummaryResponse,
    getCtIdResponse, 
    generateClientSummary,
    generateClientSummaryResponse,
    getClientRecommendedProperties,
    getClientRecommendedPropertiesResponse,
    clientId, 
    triggerGenerateSummary, 
    isFreshSummaryReady,
}) => {

  const [isShowRecommendedProperties, setIsShowRecommendedProperties] = useState(false); 
  const { data, isFetchingMore } = getClientSummaryResponse || {}; 
  const { data: clientTransactionData } = getCtIdResponse || {};
  const { client_id } = clientTransactionData; 
  const { data: clientRecommendedPropertiesData } = getClientRecommendedPropertiesResponse || {};
  const { client_name, creation_time, client_email, client_contact, summary, engagement_score} = data || {};
  const { score, total_score, score_insights, score_text } = engagement_score || {};

  const MarkdownIt = require('markdown-it');
  const md = new MarkdownIt();

  const scrollToRecommendedProperties = () => {
    const section = document.getElementById('recommended-properties');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  useEffect(() => {
    if (isShowRecommendedProperties) {
      scrollToRecommendedProperties();
    }
  }, [isShowRecommendedProperties]);
  
  useEffect(() => {
    if (!triggerGenerateSummary) return;

    const payload = {
      client_id: client_id
    }
    generateClientSummary({
      payload,
      /*callback: () => {
        console.log("generateClientSummaryResponse is: ", generateClientSummaryResponse);
      }*/
    })
  }, [client_id, triggerGenerateSummary])
  
  return (
    <>
      {(isFetchingMore === undefined || !data || !getClientSummaryResponse || getClientSummaryResponse?.data?.client_id !== client_id) ? (
        <>
          <Loader />
        </>
      ) : (
        <Container>
          <Header>
            <LeftOutlined style={{cursor: "pointer", marginBottom: "25px", fontSize: "20px" }} onClick = {() => {
                handleClose()
            }}/>
            <HeaderText>
              <h2>Summary of {client_name}</h2>
              <p>Generated on {formatEpochTime(creation_time)}</p>
            </HeaderText>
          </Header>
          <Section>
            <SectionHeader>

              <img 
                src={`${CLOUDFRONT}/star-client-summary.svg`} 
                alt="arrow-green" 
                style={{ marginRight: "8px" }}
              />
              Engagement Score
            </SectionHeader>
            <EngagementScore>
              <Progress percent={score * 10} showInfo={false} strokeColor='#5a5ff2' />
              <ScoreText>{score}/{total_score}</ScoreText>
            </EngagementScore>
            <SummaryText>
              {/* {score_insights} */}
              {score_text}
            </SummaryText>
          </Section>
          {isShowRecommendedProperties && (
            <Section id='recommended-properties'>
              {getClientRecommendedPropertiesResponse?.data?.search_boards?.map((item, index) => (
                <>
                  <SectionSubHeading>
                    {item.search_board_name}
                  </SectionSubHeading>
                  {item.recommendations.map((property, index) => (
                    <a
                      href={property.property_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit' }} // Optional: keep original text styles
                    >
                      <CardContainer key={index}>
                        <ImageContainer>
                          <PropertyImage src={property.media_urls[0]} alt="Property image" />
                        </ImageContainer>
                        <PropertyDetails>
                          <PropertyTitle>{property.address}</PropertyTitle>
                          <PropertyInfo>
                            $ {property.list_price}, {property.bedrooms} beds, {property.bathrooms} baths, {property.area} {property.area_units}
                          </PropertyInfo>
                          <RecommendedDate>Recommended on {formatEpochTime(property.updated_at)}</RecommendedDate>
                        </PropertyDetails>

                        <ArrowIcon>
                          <ArrowComponent />
                        </ArrowIcon>
                      </CardContainer>
                    </a>

                ))}
                </>
              ))}
            </Section>
          )}
          
          <Section>
            <SectionHeader style={{display: "flex", alignItems: "center"}}>
              <MelBackgroundForHeading>
                <img 
                  src={`${CLOUDFRONT}/client-summary-mel-icon.svg`} 
                  alt="mel-icon" 
                  style={{ marginRight: "8px" }}
                />
                <p style = {{marginTop: "10px" }}>MEL AI-generated summary</p>
              </MelBackgroundForHeading>
            </SectionHeader>
            <StyledContainer>
              {console.log("summary is: ", md.render(summary))}
              <div dangerouslySetInnerHTML={{ __html: md.render(summary) }} />
            </StyledContainer>
          </Section>
          {!isShowRecommendedProperties && (
            <ButtonWrapper>
              <Button
                onClick={() => {
                  setIsShowRecommendedProperties(true);
                  getClientRecommendedProperties({
                    client_id: client_id,
                  });
                }}
              >
                Generate recommendations
              </Button>
            </ButtonWrapper>
          )}
        </Container>
      )}
    </>
  );
};

export default ConsumerAppContainer(CRMV2Container(ClientSummary));
