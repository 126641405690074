import React from 'react';
import { Modal, Button, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { INTER } from "@fonts"

const { Title, Text, Paragraph } = Typography;

const ModalContent = styled.div`
  font-family: ${INTER};
`;

const IconContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const IconCircle = styled.div`
  background-color: #f0f2ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const StyledIcon = styled(InfoCircleOutlined)`
  font-size: 24px;
  color: #5c6bc0;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 18px !important;
  color : #111827;
  font-family: ${INTER};
  font-weight: 500 !important;
`;

const Description = styled(Paragraph)`
  font-size: 14px;
  color: #666;
  margin-bottom: 24px;
  color : #373758;
  font-family: ${INTER};
`;

const InfoBox = styled.div`
  background-color: #EFEFF4;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  font-family: ${INTER};
`;

const InfoTitle = styled(Text)`
  font-size: 16px;
  display: block;
  margin-bottom: 16px;
  color : #373758;
  font-size: 14px;
  font-weight: 400;
  font-family: ${INTER};
`;

const StyledList = styled.ul`
  margin: 0;
  list-style-type: disc;
  padding-left: 16px;
  color : #373758;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: ${INTER};
`;

const ListItem = styled.li`
  margin-bottom: ${props => props.last ? '0' : '12px'};
  color : #373758;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: ${INTER};
`;

const ListItemText = styled(Text)`
  font-size: 14px;
`;

const ConfirmText = styled(Paragraph)`
  font-size: 14px;
  margin-bottom: 24px;
  color: #6B7280
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const CancelButton = styled(Button)`
  min-width: 200px !important;
  height: 48px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 10px 16px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

const ConfirmButton = styled(Button)`
  min-width: 200px !important;
  height: 48px !important;
  border-radius: 8px !important;
  background-color: #6366f1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 10px 16px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-color: transparent !important;
`;

const CloseIcon = styled.span`
  font-size: 24px;
`;

const AgentSearchManualSwitch = ({ visible, onCancel, onConfirm }) => {
  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={onCancel}
      width={540}
      closeIcon={<CloseIcon>×</CloseIcon>}
      centered
    >
      <ModalContent>
        <IconContainer>
          <IconCircle>
            <StyledIcon />
          </IconCircle>
        </IconContainer>

        <StyledTitle level={3}>
          Switch to manual entry mode?
        </StyledTitle>
        
        <Description>
          You are about to switch from Radius agent search to manual entry mode.
        </Description>

        <InfoBox>
          <InfoTitle >
            This will:
          </InfoTitle>
          
          <StyledList>
            <ListItem>
              <ListItemText>Clear any selected Radius agent data</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Allow manual entry of agent information</ListItemText>
            </ListItem>
          </StyledList>
        </InfoBox>

        <ConfirmText>
          Are you sure you want to continue?
        </ConfirmText>

        <ButtonContainer>
          <CancelButton 
            size="large" 
            onClick={onCancel}
          >
            No, keep as radius agent
          </CancelButton>
          <ConfirmButton 
            type="primary" 
            size="large" 
            onClick={onConfirm}
          >
            Yes, switch to manual
          </ConfirmButton>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};

export default AgentSearchManualSwitch;
