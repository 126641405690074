import React from "react"
import root from "window-or-global"
import { LoadScript, checkForUserObject } from "services/Utils"
import { withRouter } from "react-router-dom"
import CRMV2Container from "container/CRM";
import ConsumerAppContainer from "container/ConsumerApp/index";

const user = checkForUserObject("user")
const agentId = user && user.id

class PubNubForClientSummary extends React.Component {
  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    const {
      generateClientSummary,
      generateClientSummaryResponse,
      clientId,
      getClientSummary, 
      getClientSummaryResponse,
    } = this.props

    const generateClientSummaryChannel = `ai-summary-${clientId}`

    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {
      root.pubNubGenerateClientSummary = new root.PubNub({
        publishKey: `${PUBNUB_FEED_PUBLISH_KEY}`,
        subscribeKey: `${PUBNUB_FEED_SUBSCRIBE_KEY}`,
        uuid: agentId,
      })

      root.pubNubGenerateClientSummary.subscribe({
        channels: [generateClientSummaryChannel],
      })

      root.pubNubGenerateClientSummary.addListener({
        message: (data) => {
          try {
            const { message, channel } = data
            const { event_type } = message
            console.log(message)

            if (getClientSummaryResponse?.data?.client_id !== clientId && message.type === "ai-summary" && message?.action_id === 1) {
                getClientSummary({
                  client_id: clientId
                })

            }
          } catch (e) {
            console.log("exception", e)     
          }
        }
      })
    })
  }

  componentWillUnmount() {
    const { clientId } = this.props

    const generateClientSummaryChannel = `ai-summary-${clientId}`
    if (root.pubNubGenerateClientSummary) {
      root.pubNubGenerateClientSummary.unsubscribe({
        channels: [generateClientSummaryChannel],
      })
    }
  }

  render() {
    return null
  }
}

export default withRouter(ConsumerAppContainer(CRMV2Container(PubNubForClientSummary))); 