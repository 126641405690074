/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState, useEffect, useMemo } from "react"
import RemoveAgentBinIcon from "../../../../../../../images/icons/remove-agent-bin-icon.js"
import CoAgentIcon from "../../../../../../../images/icons/co-agent-icons.js"
import PurpleWarnIcon from "../../../../../../../images/icons/purple-warn-icon.js"
import { Checkbox, Input, Modal } from 'antd';
import uuid from "uuid"
import {
  Form,
  NewRadioButton,
  NewRadioGroup,
} from "@ui/antd"
import {
  InputField,
  InputNumber,
  AgentSearch,
  InputLocation,
  SelectField,
  HiddenInputField,
  AddButton,
} from "dumbComponents/common/InputFields"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  CoWrap,
  FormFieldWrap,
  DangerButton,
  SubmitWrap,
  ListRowWrap,
  ValidationNumberCircle,
  RadioWrap,
  RadioQuestion,
  CheckboxWrap,
  CoAgentWarnDiv,
  CircleModalDiv,
  RemoveTitleModal,
  ModalDesc,
  ModalNoteDiv,
} from "./commonStyles"
import {
  validatePhoneNumber,
} from "./utils"
import { normalizePhone } from "services/formUtils"
import {
  CLIENT_SOURCE,
} from "./formConfig"
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";
import AgentSearchWithConsent from "dumbComponents/common/InputFields/AgentSearchWithConsent";
import TechAssets from "container/TechAssets";
const { List } = Form

const BuyerAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  isFirst,
  listingAgents = [],
  onValueChange,
  saving,
  disableForm,
  key,
  formName,
  form,
  validationReport,
  uniqueIdentifier,
  handleInitPropertyChange,
  // listingAgentFormValid,
  // setListingAgentFormValidation,
}) => {
  const [isRadiusAgentBool, setRadiusAgentBoolForCoAgent] = useState({})
  const [defaultValuesForIsRadiusAgentBool, setDefaultValuesForIsRadiusAgentBool] = useState({})
  const formRef = useRef(null)
  const [isRadiusAgent, setRadiusAgent] = useState(false)
  const [currentListingAgentCount, setListingAgentCount] = useState(0)

  const handleRepresentation = (val) => {
    setRadiusAgent(val)
  }

  const getInitialValues = () => {
    let initValues = {}
    let newRadiusAgentBool = {}
    if (prefilledData && prefilledData.length > 0) {
      initValues = { ...prefilledData }
      const firstname = prefilledData[0].name ? prefilledData[0].name.split(" ")[0] : ""
      const lastname = prefilledData[0].name ? prefilledData[0].name.split(" ").slice(1).join(" ") : "";
      initValues.agent_first_name = firstname
      initValues.agent_last_name = lastname
      initValues.name = prefilledData[0].name
      initValues.phone = prefilledData[0].phone ? normalizePhone(prefilledData[0].phone) : prefilledData[0].phone
      initValues.email = prefilledData[0].email
      initValues.listing_brokerage = prefilledData[0].listing_brokerage || prefilledData[0].brokerage
      initValues.brokerage_address = prefilledData[0].brokerage_address
      initValues.brokerage_license_number = prefilledData[0].brokerage_license_number
      initValues.agent_license_number = prefilledData[0].agent_license_number
      initValues.apt = prefilledData[0].apt
      initValues.state = prefilledData[0].state
      initValues.city = prefilledData[0].city
      initValues.zipcode = prefilledData[0].zipcode
      initValues.agent_type = prefilledData[0].agent_type
      initValues.agent_id = prefilledData[0].agent_id

      let primaryAgentValid = 
        initValues.agent_first_name && 
        initValues.agent_last_name && 
        initValues.email && 
        initValues.agent_license_number && 
        initValues.listing_brokerage && 
        initValues.brokerage_license_number;

      // setListingAgentFormValidation({
      //   ...listingAgentFormValid,
      //   isListingAgentValid: Boolean(primaryAgentValid)
      // });

      if (prefilledData.length > 1) {
        let otherAgents = prefilledData.slice(1, prefilledData.length)
        otherAgents = otherAgents.map((item, index) => {
          console.log(item)
          const curr = item
          const coAgentFirstName = curr.name ? curr.name.split(" ")[0] : null
          const coAgentLastName = curr.name ? curr.name.split(" ").slice(1).join(" ") : null;

          curr.agent_first_name = coAgentFirstName
          curr.agent_last_name = coAgentLastName
          curr.agent_id = curr.agent_id || 0
          curr.listing_brokerage = curr.listing_brokerage || curr.brokerage || ""
          curr.brokerage_address = curr.brokerage_address || ""
          curr.phone = curr.phone ? normalizePhone(curr.phone) : ""

          const coAgentValid = 
            curr.agent_first_name && 
            curr.agent_last_name && 
            curr.email && 
            curr.agent_license_number && 
            curr.listing_brokerage && 
            curr.brokerage_license_number;

          if (!coAgentValid) {
            primaryAgentValid = false;
          }

          setDefaultValuesForIsRadiusAgentBool({
            ...defaultValuesForIsRadiusAgentBool,
            [index]: !!curr.agent_id,
          })
          newRadiusAgentBool[index] = curr.agent_id != 9999999 && curr.agent_id != 0 ? true : false
          console.log('check here', curr.agent_id);

          return curr
        })

        initValues.co_listing_agents = otherAgents && otherAgents.length > 0 ? [...otherAgents] : []
      }
    }
    setRadiusAgentBoolForCoAgent(newRadiusAgentBool)
    
    return initValues
  }

  useEffect(() => {
    if (prefilledData && prefilledData.length > 0) {
      setListingAgentCount(prefilledData.length);
      // Validate the prefilled data
      const values = form.getFieldsValue();
      validateListingAgentForm(values);
    }
  }, [prefilledData]);

  // Reset validation on unmount
  // useEffect(() => {
  //   return () => {
  //     setListingAgentFormValidation({
  //       ...listingAgentFormValid,
  //       isListingAgentValid: false
  //     });
  //   };
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     if (formRef.current) {
  //       formRef.current.resetFields();
  //     }
  //   };
  // }, []);

  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);

    //co-agents
    const [isAddManuallyForCoAgent, setIsAddManuallyForCoAgent] = useState({});

  // helper functions to manage name states 
  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const handleCheckboxChange = (e, name) => {
      const {
        target: {
          checked,
        },
      } = e || {}
      setRadiusAgentBoolForCoAgent(existingValues => ({
        ...existingValues,
        [name]: checked,
      }))
  };

  const handleOnChangeCoListingAgent = (agent,value,option,formName,fieldName) => {
    console.log("value", agent,value,option)
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_id: parseInt(agent.id, 10),
          agent_first_name: agent.firstname,
          agent_last_name: agent.lastname !== "" ? agent.lastname : undefined,
          name: agent.name,
          email: agent.email || agent?.brokerage_info_from_LOC?.email,
          phone: normalizePhone(agent.phone) || normalizePhone(agent?.brokerage_info_from_LOC?.phone),
          agent_brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
          agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
          landline: agent?.brokerage_info_from_LOC?.landline,
          state: agent?.brokerage_info_from_LOC?.state,
          zipcode: agent?.brokerage_info_from_LOC?.zipcode,
          city: agent?.brokerage_info_from_LOC?.city,
          // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage: agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
          //is_primary: isFirst ? 1 : 0,
          listing_brokerage: agent?.brokerage_info_from_LOC?.listing_brokerage || agent?.brokerage_info_from_LOC?.brokerage,
          brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
        }
      }
    });
  }

  const handleOnClearCoListingAgent =(formName,fieldName) => {
    formRef.current.setFieldsValue({
      [formName]: {
        [fieldName]: {
          agent_first_name: "",
          agent_last_name: "",
          email: "",
          phone: "",
        }
      }
    });
  }
  // Add validation function
  const validateListingAgentForm = (values) => {
    console.log('Listing agent form values being validated:', values);
    
    // Required fields for primary listing agent
    const requiredFields = {
      'agent_first_name': values?.agent_first_name,
      'agent_last_name': values?.agent_last_name,
      'email': values?.email,
      'phone': values?.phone,
      'agent_license_number': values?.agent_license_number,
      'listing_brokerage': values?.listing_brokerage,
      'brokerage_license_number': values?.brokerage_license_number
    };

    let isFormValid = true;

    // Check each required field
    Object.entries(requiredFields).forEach(([field, value]) => {
      const isValid = value && value.toString().trim() !== '';
      console.log(`Field ${field}:`, value, 'isValid:', isValid);
      if (!isValid) {
        isFormValid = false;
      }
    });

    // If there are co-listing agents, validate their required fields too
    if (values?.co_listing_agents?.length > 0) {
      values.co_listing_agents.forEach((agent, index) => {
        const coAgentRequired = {
          'agent_first_name': agent?.agent_first_name,
          'agent_last_name': agent?.agent_last_name,
          'email': agent?.email,
          'agent_license_number': agent?.agent_license_number,
          'listing_brokerage': agent?.listing_brokerage,
          'brokerage_license_number': agent?.brokerage_license_number
        };

        Object.entries(coAgentRequired).forEach(([field, value]) => {
          const isValid = value && value.toString().trim() !== '';
          console.log(`Co-agent ${index} field ${field}:`, value, 'isValid:', isValid);
          if (!isValid) {
            isFormValid = false;
          }
        });
      });
    }

    console.log('Final listing agent form validation result:', isFormValid);
    
    // Update validation state using listingAgentFormValid from props
    // setListingAgentFormValidation({
    //   ...listingAgentFormValid,
    //   isListingAgentValid: Boolean(isFormValid)
    // });

    return isFormValid;
  };

  // Add form change handler
  const handleFormChange = (_, allValues) => {
    console.log('Listing agent form values changed:', allValues);
    // validateListingAgentForm(allValues);
    if (onValueChange) {
      onValueChange(_, allValues);
    }
  };

  return (
    <FormWrap
      id={`${key}_wrap`}
      key={key}
    >
      {/* <HeaderWrap>
        <FormTitle>
          <p>Listing Agent Information</p>
        </FormTitle>
      </HeaderWrap> */}
      {/* <RadioWrap>
        <RadioQuestion>
          <p>Is agent part of Radius?</p>
        </RadioQuestion>
        <NewRadioGroup
          onChange={e => handleRepresentation(e.target.value)}
          value={isRadiusAgent}
          size="small"
        >
          <NewRadioButton value={true}>Yes</NewRadioButton>
          <NewRadioButton value={false}>No</NewRadioButton>
        </NewRadioGroup>
      </RadioWrap> */}

      <Form
        id={`${key}_form`}
        key={key}
        name={formName}
        // preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={useMemo(() => getInitialValues(), [prefilledData])}
        ref={formRef}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        onValuesChange={handleFormChange}
        form={form}
        //onValuesChange={handleOnTypeChangeForListingAgents}
      >
        <HiddenInputField name="is_primary" value={1} />
        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="agent_type" />

        {isRadiusAgent ? (
          <>
          <AgentSearch
            name="agent_first_name"
            inputType="text"
            placeholder="First Name"
            customRules={{}}
            isFormField
            formRef={formRef}
            firstNameLabel="agent_first_name"
            lastNameLabel="agent_last_name"
            isFirst={isFirst}
            text="First Name*"
          />
          </>
        ) : (
          <InputField
            name="agent_first_name"
            inputType="text"
            placeholder="First Name"
            customRules={{}}
            text="First Name"
            required={true}
            requiredMessage="Agent's First Name is required"
            disabled={disableForm}
          />
        )}

        <InputField
          name="agent_last_name"
          inputType="text"
          placeholder="Last Name"
          text="Last Name"
          required={true}
          requiredMessage="Agent's Last Name is required"
          disabled={disableForm}
        />

        <InputField
          name="email"
          inputType="email"
          placeholder="Email"
          text="Email"
          type="email"
          required={true}
          disabled={disableForm}
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone"
          text="Phone"
          //requiredMessage="Phone is required."
          //required={true}
          disabled={disableForm}
          customRules={{
            validator: validatePhoneNumber,
          }}
        />

      

        <InputLocation
          name="brokerage_address"
          inputType="text"
          type="colisting_agent_address"
          placeholder="Brokerage Address"
          formRef={formRef}
          text="Brokerage Address"
          // required={true}
          //ignoreFields={["state", "city", "zipcode"]}
          disabled={disableForm}
          handleInitChange={handleInitPropertyChange}
        />
  <InputField
          name="apt"
          inputType="text"
          placeholder="Apt/unit/suite no."
          text="Apt/unit/suite no."
          type="text"
          disabled={disableForm}
        />
        <InputField
          name="city"
          inputType="text"
          placeholder="City"
          text="City"
          type="text"
          //required={true}
          //requiredMessage="City is required."
          disabled={disableForm}
        />
        
        <InputField
          name="state"
          inputType="text"
          placeholder="State"
          text="State"
          type="text"
          // required={true}
          // requiredMessage="State is required."
          disabled={disableForm}
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zipcode"
          text="Zip Code"
          type="text"
          // required={true}
          // requiredMessage="Zip Code is required."
          disabled={disableForm}
        />

        <InputField
          name="agent_license_number"
          inputType="text"
          type="text"
          placeholder="Agent License Number"
          text="Agent License Number"
          required={true}
          requiredMessage="Agent's License Number is required"
          disabled={disableForm}
        />

        <InputField
          name="brokerage_license_number"
          inputType="text"
          type="text"
          placeholder="Listing Brokerage License Number"
          text="Listing Brokerage License Number"
          required={true}
          requiredMessage="Listing Brokerage License Number is required"
          disabled={disableForm}
        />

        <InputField
          name="listing_brokerage"
          inputType="text"
          type="text"
          placeholder="Listing Brokerage"
          text="Listing Brokerage"
          requiredMessage="Listing brokerage is required"
          disabled={disableForm}
          required={true}
        />

        <CTAWrap>
          <List name="co_listing_agents">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }) => (
                  <CoWrap >
                    {/* <Modal
                      visible={isRemoveAgentModalVisible}
                      onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)}
                      okText="Remove agent"
                      okButtonProps={{ danger: true }}
                      cancelText="Cancel"
                    >
                      <div>
                        <CircleModalDiv><PurpleWarnIcon/></CircleModalDiv>
                        <RemoveTitleModal>Remove Agent</RemoveTitleModal>
                        <ModalDesc>Are you sure you want to remove this agent? This action cannot be undone. The agent will lose access to all associated resources and permissions.</ModalDesc>
                        <ModalNoteDiv>
                          Note: Any ongoing tasks or processes assigned to this agent will need to be reassigned manually.
                        </ModalNoteDiv>
                      </div>
                    </Modal> */}
                    <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} />
                    <HeaderWrap>
                      <FormTitle>
                        <p> <CoAgentIcon/> Co-Listing Agent</p>
                      </FormTitle>
                      <CancelOrGoBackButton
                        type="button"
                        onClick={() => openRemoveAgentModal(name)}
                      >
                        <RemoveAgentBinIcon />
                        <p> Remove agent </p>
                      </CancelOrGoBackButton>
                    </HeaderWrap>
                  
                     <AgentSearchWithConsent 
                         name={[name, "agent_first_name"]}
                         inputType="text"
                         placeholder="Co-listing agent's first name"
                         customRules={{}}
                         isFormField
                         text="Co-listing agent's first name"
                         formName="co_listing_agents"
                         required={true}
                         requiredMessage="Agent's first name is required"
                         onChange={(agent,value,option)=>handleOnChangeCoListingAgent(agent,value,option,"co_listing_agents",name)}
                         onClear={()=>handleOnClearCoListingAgent("co_listing_agents",name)}
                         valueOfField={formRef?.current?.getFieldValue(['co_listing_agents', name, 'agent_first_name'])}
                         agentId={formRef?.current?.getFieldValue(['co_listing_agents', name, 'agent_id'])}
                         onAddManually={() => setIsAddManuallyForCoAgent(prev => ({
                          ...prev,
                          [name]: false
                        }))}
                        onAgentPartOfRadius={(value) => {
                            setIsAddManuallyForCoAgent(prev => ({
                              ...prev,
                              [name]: value 
                            }));
                        }}
                        />
                    <FormFieldWrap>
                      <HiddenInputField name={[name, "agent_id"]} />

                    
                       
                       

                        <InputField
                          name={[name, "agent_first_name"]}
                          inputType="text"
                          placeholder="Co-listing agent's first name"
                          text="Co-listing agent's first name"
                          required={true}
                          disabled={isAddManuallyForCoAgent[name]}
                          requiredMessage="Agent's first name is required."
                        /> 

                      <InputField
                        name={[name, "agent_last_name"]}
                        inputType="text"
                        placeholder="Co-listing agent's last name"
                        text="Co-listing agent's last name"
                        type="text"
                        required={true}
                        requiredMessage="Agent's last name is required"
                        disabled={isAddManuallyForCoAgent[name]}
                      />

                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "email"]}
                        type="email"
                        inputType="email"
                        placeholder="Email"
                        text="Email"
                        required={true}
                        disabled={isAddManuallyForCoAgent[name]}
                      />

                      <InputNumber
                        name={[name, "phone"]}
                        inputType="phone"
                        placeholder="Phone"
                        text="Phone"
                        type="phone"
                        //required={true}
                        //requiredMessage="Phone is required."
                        customRules={{
                          validator: validatePhoneNumber,
                        }}
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                             <InputLocation
                        name={[name, "brokerage_address"]}
                        inputType="text"
                        type="colisting_agent_address"
                        placeholder="Brokerage Address"
                        formRef={formRef}
                        formName="co_listing_agents"
                        fieldIndex={name}
                        // required={true}
                        //ignoreFields={["state", "city", "zipcode"]}
                        text="Brokerage Address"
                      />
                    <InputField
                        name={[name, "apt"]}
                        inputType="text"
                        placeholder="Apt/unit/suite no."
                        text="Apt/unit/suite no."
                        type="text"
                      />
                         
                    </FormFieldWrap>
                    
                    <FormFieldWrap>
                      <InputField
                        name={[name, "city"]}
                        inputType="text"
                        placeholder="City"
                        text="City"
                        type="text"
                        // required={true}
                        // requiredMessage="City is required."
                      />

                         <InputField
                        name={[name, "state"]}
                        inputType="text"
                        placeholder="State"
                        text="State"
                        type="text"
                        // required={true}
                        // requiredMessage="State is required."
                      />
                    </FormFieldWrap>

                    <FormFieldWrap>
                        <InputField
                        name={[name, "zipcode"]}
                        inputType="text"
                        placeholder="Zip Code"
                        text="Zip Code"
                        type="text"
                        // required={true}
                        // requiredMessage="Zip Code is required."
                      />
                      <InputField
                        name={[name, "agent_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="Agent License Number"
                        text="Agent License Number"
                        requiredMessage="Agent's License is required"
                        required={true}
                      />

                  
                    </FormFieldWrap>

                    <FormFieldWrap>
                      <InputField
                        name={[name, "listing_brokerage"]}
                        inputType="text"
                        type="text"
                        placeholder="Listing Brokerage"
                        text="Listing Brokerage"
                        requiredMessage="Listing Brokerage is required"
                        required={true}
                      />

                          <InputField
                        name={[name, "brokerage_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="Listing Brokerage License Number"
                        text="Listing Brokerage License Number"
                        requiredMessage="Listing Brokerage License Number is required"
                        required={true}
                      />
                    </FormFieldWrap>
                  </CoWrap>
                ))}
                <FormFieldWrap>
                  <CTAWrap>
                    <Form.Item>
                      <AddButton
                        disabled={currentListingAgentCount <= 0}
                        name="ADD CO LISTING AGENT"
                        onClick={(e) => {
                          e.stopPropagation()
                          setListingAgentCount(count => count + 1)
                          setRadiusAgentBoolForCoAgent({...isRadiusAgentBool, [currentListingAgentCount-1]: false})
                          add()
                        }}
                      />
                    </Form.Item>
                  </CTAWrap>
                </FormFieldWrap>
              </>
            )}
          </List>
        </CTAWrap>

        {/* {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )} */}

        {saving && (
          <SubmitWrap>
            <button
              type="button"
              onClick={() => {}}
            >
              Saving..
            </button>
          </SubmitWrap>
        )}

      </Form>
    </FormWrap>
  )
}

export default TechAssets(BuyerAgentForm)
